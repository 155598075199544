<div class="history-proforma" [ngClass]="proforma.uiState">
    <div class="title">
        Plata
        anticipata {{ proforma.contract_change.change_type === 'FULL' ? 'integrala' : 'partiala' }},
        contract {{ proforma.contract.contract_number }}
        ({{ proforma.contract.contract_objects[0].registration_number }})
        @if (proforma.contract_change.change_type !== 'FULL') {
            <div class="proforma-extend" (click)="extendProforma(proforma)"><span
                class="icon-mobilize-chevron-down"></span></div>
        }
    </div>

    <div class="history-proforma__row">
        data initierii: <span>{{ proforma.date | date: 'dd/MM/yyyy' }}</span>
    </div>
    <div class="history-proforma__row">
        stare cerere: <span [innerHTML]="requestStatus(proforma.contract_change.status)"></span>
    </div>
    <div class="history-proforma__row">
        suma rambursata anticipat:
        <span
            [innerHTML]="formatAmount(proforma.contract_change.repayment_amount, proforma.currency)"></span>
    </div>
    <div class="history-proforma__row">
        bun finantat:
        <span>
                                {{ proforma.contract.contract_objects[0].vehicle_brand }}
            {{ proforma.contract.contract_objects[0].vehicle_model }}
                            </span>
        <div class="history-proforma__extended-view">
            @if (proforma.contract_change.change_type !== 'FULL') {
                <div class="history-proforma__row">
                    noua rata lunara va fi: <span>{{ proforma.newInstallmentAmount }}</span>
                </div>
                <div class="history-proforma__row">
                    numarul de rate de achitat: <span>{{ proforma.newInstallmentsNumber }}</span>
                </div>
            }
        </div>
    </div>

    <div class="history-proforma__actions">
        <button class="mobilize-btn btn-orange" (click)="downloadProforma()" [mobilizeButtonLoader]="isProcessing"
                originalContent="descarca proforma"></button>
    </div>
</div>
