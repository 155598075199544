import { Component, inject, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgClass } from '@angular/common';
import { ButtonLoaderDirective } from '../../../../directives/button-loader.directive';
import { ConsentsService } from '../../../../services/consents.service';
import { LoadingService } from '../../../../services/loading.service';
import { environment } from '../../../../../environments/environment';
import { MiscService } from '../../../../services/misc.service';
import { finalize } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
    selector: 'mobilize-contact-preferences',
    standalone: true,
    imports: [
        FormsModule,
        NgClass,
        ButtonLoaderDirective
    ],
    templateUrl: './contact-preferences.component.html',
    styleUrl: './contact-preferences.component.scss'
})
export class ContactPreferencesComponent implements OnChanges, OnInit {
    private _consents = inject(ConsentsService);
    private _loading = inject(LoadingService);
    private _misc = inject(MiscService);
    private _snackBar = inject(MatSnackBar);

    email!: boolean;
    sms!: boolean;
    post!: boolean;
    phone!: boolean;
    isProcessing = false;
    showCodes = environment.displayOptions.showContactCodeLabels;
    contactMethodsDisabled = false;

    agreementsOptions: { id: null | number; name: string; checked: boolean; label: string; type: string; display?: boolean }[] = [
        {
            name: 'Acord de Marketing RCI',
            id: null,
            type: 'CMKRCI',
            checked: false,
            label: '(CMKRCI)',
            display: true
        }, {
            name: 'Acord de Marketing Renault Commercial Roumanie',
            id: null,
            type: 'CMKRCR',
            checked: false,
            label: '(CMKRCR)',
            display: true
        }, {
            name: 'Acord de profilare',
            id: null,
            type: 'CMKADP',
            checked: false,
            label: '(CMKADP)',
            display: true
        },
    ];

    contactOptions: {
        id: null | number;
        name: string;
        checked: boolean;
        label: string;
        type: string;
        display: boolean
    }[] = [
        {
            name: 'Email',
            id: null,
            type: 'CMKEML',
            checked: false,
            label: '(CMKEML)',
            display: true
        }, {
            name: 'Sms',
            id: null,
            type: 'CMKSMS',
            checked: false,
            label: '(CMKSMS)',
            display: true
        }, {
            name: 'Posta',
            id: null,
            type: 'CMKMIL',
            checked: false,
            label: '(CMKMIL)',
            display: true
        }, {
            name: 'Telefon',
            id: null,
            type: 'CMKTLF',
            checked: false,
            label: '(CMKTLF)',
            display: true
        }
    ];

    ngOnInit() {
        this._loading.setLoadingState(true);
        this._consents.getConsents().subscribe(rsp => {
            this.agreementsOptions = this.agreementsOptions.map((item) => {
                const matchingConsent = rsp.find(
                    (consent: any) =>
                        consent.consent_type === item.type && consent.accepted === true
                );
                if (matchingConsent) {
                    this.contactMethodsDisabled = false;
                }
                return {
                    ...item,
                    checked: !!matchingConsent,
                };
            });
            this.contactOptions = this.contactOptions.map((item) => {
                const matchingConsent = rsp.find(
                    (consent: any) =>
                        consent.consent_type === item.type && consent.accepted === true
                );
                return {
                    ...item,
                    checked: !!matchingConsent,
                };
            });
            this._loading.setLoadingState(false);
        });
    }

    ngOnChanges(changes: SimpleChanges) {
        // if (changes['contactOptions']) {
        //     this.checkOptions();
        // }
    }

    onCheckboxChange(option?: any): void {
        this.contactMethodsDisabled = !this.agreementsOptions.some(option => option.checked);

        if (option.type === 'CMKRCI') {
            this.agreementsOptions.forEach(item => {
                if (item.type === 'CMKADP') {
                    item.display = option.checked;

                    if (!option.checked) {
                        item.checked = false;
                    }
                }
            });
        }

        if (this.contactMethodsDisabled) {
            this.email = false;
            this.sms = false;
            this.post = false;
            this.phone = false;
        }
    }

    sendAgreements() {
        this.isProcessing = true;
        const payload = {consents: [...this.contactOptions, ...this.agreementsOptions]};

        this._misc.sendAgreements(payload)
            .pipe(
                finalize(() => {
                    this.isProcessing = false;
                })
            )
            .subscribe(rsp => {
                if (rsp.success) {
                    this._snackBar.open('Preferintele de comunicare au fost salvate!', 'Ok');
                }
            });

    }
}
