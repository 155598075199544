import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import { PageHeaderComponent } from '../../../components/page-header/page-header.component';
import { FormsModule } from '@angular/forms';
import { MatFormField, MatSuffix } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { PartnersService } from '../../../services/partners.service';
import { PaginationComponent } from '../../../components/pagination/pagination.component';
import { LoadingService } from '../../../services/loading.service';
import { UserService } from '../../../services/user.service';
import { StorageService } from '../../../services/storage.service';
import { KeycloakService } from 'keycloak-angular';
import { ActivatedRoute, Router } from '@angular/router';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { finalize, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { MiscService } from '../../../services/misc.service';
import { RequestsSearchComponent } from '../../components/requests-search/requests-search.component';
import { BaseComponent } from '../../../components/base.component';

@Component({
    selector: 'mobilize-impersonation',
    standalone: true,
    imports: [
        PageHeaderComponent,
        FormsModule,
        MatFormField,
        MatInput,
        MatSuffix,
        PaginationComponent,
        MatProgressSpinner,
        RequestsSearchComponent
    ],
    templateUrl: './impersonation.component.html',
    styleUrl: './impersonation.component.scss'
})
export class ImpersonationComponent extends BaseComponent implements OnInit, OnDestroy {

    private _partners = inject(PartnersService);
    private _loading = inject(LoadingService);
    private _user = inject(UserService);
    private _misc = inject(MiscService);
    private _route = inject(ActivatedRoute);
    private _router = inject(Router);
    private _kc = inject(KeycloakService);

    pageTitle = 'Impersonare client';
    query: any = '';
    partners: any[] = [];
    dataReady = false;
    totalPages: any;
    currentPage = 1;
    limit = 10;
    pagesArray: any;
    processing = false;

    ngOnInit() {
        this._route.queryParams.subscribe(params => {
            console.log(params)
            this.currentPage = params['page'] ? +params['page'] : 1;
            this.limit = params['limit'] ? +params['limit'] : this.limit;
            this.query = params['query'] ? params['query'] : this.query;
            this.getPartners({page: this.currentPage, query: this.query});
        });
    }

    getPartners(payload?: any) {
        console.log(payload)
        this._loading.setLoadingState(true);
        this._partners.getPartners({
            ...payload,

            page: this.currentPage,
            limit: this.limit
        }).subscribe(rsp => {
            this.partners = rsp.data;
            this.dataReady = true;
            this._loading.setLoadingState(false);
            this.totalPages = rsp.totalPages
            this.pagesArray = Array.from({length: this.totalPages}, (_, i) => i + 1);
        });
    }

    search(event: any) {
        this._misc.onSearch(this, {query: event}, this._router, this._route);
    }

    onPageChange(event: any) {
        this.dataReady = false;
        this.scrollToTop();
        this._misc.onPageChange(this, event, this._router, this._route, this.totalPages);
    }

    accessPartnerAccount(partner: any) {
        this.processing = true;
        this._user.impersonate({partner_id: partner.partner_id, name: partner.partner_name})
            .pipe(
                finalize(() => {
                    this.processing = false;
                }),
                catchError(err => {
                    return of(err)
                })
            )
            .subscribe(rsp => {
                if (rsp.success) {
                    this._kc.updateToken(-1).then(() => {
                        window.location.href = '/dashboard';
                    });
                }
            });
    }

    ngOnDestroy() {
        this.processing = false;
    }
}
