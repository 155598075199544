import { AfterViewInit, Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ContractOptionDetails } from '../../interfaces/contract';
import { BaseComponent } from '../base.component';
import { DatePipe } from '@angular/common';
import { MiscService } from '../../services/misc.service';
import { ButtonLoaderDirective } from '../../directives/button-loader.directive';

@Component({
    selector: 'mobilize-option-details',
    standalone: true,
    imports: [
        DatePipe,
        ButtonLoaderDirective
    ],
    templateUrl: './option-details.component.html',
    styleUrl: './option-details.component.scss'
})
export class OptionDetailsComponent extends BaseComponent implements OnInit, AfterViewInit {

    insuranceOption = false;
    loaded = false;
    insurance!: any;
    requestSent = false;
    showButton = false;
    isProcessing = false;

    constructor(@Inject(MAT_DIALOG_DATA) public data: any,
                private modalRef: MatDialogRef<OptionDetailsComponent>,
                private _misc: MiscService) {
        super();
    }

    ngOnInit() {
        this.insurance = this.data.option;
        console.log(this.insurance.service_name)
        this.insuranceOption = !this.insurance.service_name;
        console.log(this.insuranceOption)
        this._misc.checkLastRequest({insurance: this.insurance}).subscribe(rsp => {
            this.showButton = !rsp.data;
            if (!this.showButton) this.requestSent = true;
        })
    }

    ngAfterViewInit() {
        setTimeout(() => {
            this.loaded = true;
        }, 2000);
    }

    closeModal() {
        this.modalRef.close();
    }

    getDocument() {
        this.isProcessing = true;
        const payload: any = {
            site_id: !this.insuranceOption ? '' : this.insurance.insurance_site_id,
            id: !this.insuranceOption ? this.insurance.contract_service_id : this.insurance.insurance_id,
            details: this.insurance
        }
        this._misc.requestDocument(payload).subscribe(rsp => {
            this.requestSent = true;
            this.isProcessing = false;
        });
    }
}
