<div class="invisible-click" (click)="showFeedback()"></div>

@if (!superAccount || !registerPage) {
    <div class="main-wrapper" [ngClass]="{'impersonated': impersonatedAccount}">
        @if (userLogged) {
            <div class="main-wrapper__menu">
                @if (showMenu) {
                    <mobilize-main-menu [backOffice]="backOffice" [impersonated]="impersonatedAccount"></mobilize-main-menu>
                }
            </div>

            <div class="main-wrapper__content">
                @if (dataLoading()) {
                    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
                }
                <router-outlet/>

                @if (!backOffice) {
                    <div class="footer">
                        <div class="footer__left">
                            <a class="footer-link" routerLink="terms-and-conditions" target="_blank">Termeni si conditii</a>
                            <a class="footer-link" routerLink="privacy-policy" target="_blank">Politica de confidentialitate</a>
                            <a class="footer-link" routerLink="cookie-policy" target="_blank">Politica de cookie</a>
                            <a class="footer-link" target="_blank" routerLink="contact-data">Date de contact</a>
                            <a class="footer-link" target="_blank" routerLink="partners">Lista parteneri</a>
                            <a class="footer-link">Intrebari frecvente</a>
                            <a class="footer-link" target="_blank" href="//anpc.ro">ANPC</a>
                        </div>
                        <div class="footer__right">
                            <div class="support-title">Suport clienti</div>
                            <div class="support-phone">021/201.20.00 (L-V: 9-18)</div>
                            <button class="mobilize-btn btn-clear" routerLink="/my-requests/new-request">trimite
                                solicitare
                            </button>
                        </div>
                    </div>
                }
            </div>
        }
    </div>
    @if (impersonatedAccount) {
        <div class="impersonated-account-alert">
            @if (!processing) {
                Ai impersonat contul partenerului '{{ impersonatedPartner }}'. Click <span
                    (click)="cancelImpersonation()">aici</span> pentru a reveni in BackOffice
            } @else {
                <mat-progress-spinner diameter="20" strokeWidth="2" mode="indeterminate"></mat-progress-spinner>
            }
        </div>
    }
} @else {
    <div class="main-wrapper" [ngClass]="{'register-wrapper': registerPage}">
        <div class="main-wrapper__content">
            <router-outlet/>
        </div>
    </div>
}
