<div class="option">
    <div class="header">{{ optionName }} <span class="bullet"
                                                [ngClass]="{'online': option.active, 'offline': !option.active}"></span>
    </div>
    <div class="date" *ngIf="option.active">expira la: {{ option.service_end_date | date: 'dd/MM/YYYY' }}</div>

    <span class="icon-mobilize-chevron-right" (click)="optionDetails()"></span>

    @if (!option.active && contractState !== 'Inchis') {
<!--        <button class="mobilize-btn btn-clear">activeaza</button>-->
    }
</div>
