<mobilize-page-header title="Documente utile" [reverse]="true"></mobilize-page-header>

<div class="tabs-wrapper">
    <div class="tabs-header">
        <div class="tabs-header__tab selected"><span>Genereaza document</span></div>
        @if (!impersonatedAccount) {
            <div class="tabs-header__tab" routerLink="/existing-documents"><span>Documente generate</span></div>
        }
    </div>
</div>

<div class="generate-document-wrapper">

    @if (currentStep > 0) {
        <div class="select-contract-option">
            <div class="title"
                 [ngClass]="{'font-orange': currentStep === 1}">{{ showForm ? 'Ai ales contractul' : 'Alege contractul' }}
            </div>

            <div class="search-contract">
                <mat-form-field>
                    <mat-label>Cautare</mat-label>
                    <input matInput placeholder="Cauta dupa numar contract sau numar de inmatriculare"
                           [(ngModel)]="searchText"
                           (input)="searchContracts()">
                </mat-form-field>
            </div>

            @for (contract of filteredContracts; track contract) {
                @if (contract.visible) {
                    <div class="contract-row card-row" [ngClass]="{'selected': currentStep > 1 && contract.visible}"
                         (click)="selectContract(contract)">
                        <div class="name">
                            <span class="title">Contract {{ contract.contract_number }}</span>
                            <span
                                class="subtitle">{{ contract.contract_objects[0].vehicle_brand }} {{ contract.contract_objects[0].vehicle_model }}
                                , {{ contract.contract_objects[0].registration_number }}
                                , {{ contract.contract_objects[0].colour }}
                                , serie sasiu {{ contract.contract_objects[0].chassis_number }}</span>
                        </div>
                        <span class="icon-mobilize-chevron-right"></span>
                    </div>
                }
            }

            @if (currentStep > 1 && contracts.length > 1) {
                <button class="mobilize-btn btn-clear" (click)="changeContract()">schimba</button>
            }
        </div>
    }

    @if (currentStep > 1) {
        <div class="document-selection-wrapper">
            <div class="title" [ngClass]="{'font-orange': currentStep === 2}">Ce document
                doresti?
            </div>

            @if (selectedContract.contract_type === 'Wholesale') {
                <div class="mobilize-card small-card text-center">
                    Pentru acest tip de contract nu se pot genera documente. Va rugam sa va adresati Mobilize FS pentru
                    mai multe detalii
                </div>
            } @else {
                <div class="document-selection">
                    @for (doc of documents; track doc) {
                        @if (doc.visible) {
                            <div class="document-selection__document card-row"
                                 [ngClass]="{'selected': currentStep > 2 && doc.visible}" (click)="selectDocument(doc)">
                                <div class="name">{{ doc.document_type_name }}</div>
                                <span class="icon-mobilize-chevron-right"></span>
                            </div>
                        }
                    }
                </div>
            }

            @if (currentStep > 2) {
                <button class="mobilize-btn btn-clear" (click)="changeDocument()">schimba</button>
            }
        </div>
    }
    @if (currentStep === 3) {

        @if (formData) {
            <div class="form-wrapper">
                <div class="title" [ngClass]="{'font-orange': currentStep === 3}">Datele delegatului:</div>
                <mobilize-json-form [contract]="this.selectedContract" [processing]="processing"
                                    (onSubmit)="onSubmit($event)"
                                    [jsonFormData]="formData"></mobilize-json-form>
            </div>
        } @else {
            <div class="form-wrapper">
                @if (!requestSent) {
                <button class="mobilize-btn btn-orange" [mobilizeButtonLoader]="processing"
                        originalContent="descarca document" (click)="downloadStaticDocument()">descarca document
                </button>
                    @if (hasAnotherRequest) {
                        <p class="custom-inline-alert">Ai deja o cerere trimisa in ultimele 24 de ore</p>
                    }
                } @else {
                    <p class="custom-inline-alert">Documentul solicitat va fi trimis pe adresa ta de e-mail</p>
                }
            </div>
        }
    }
</div>
