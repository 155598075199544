<div class="head" style="display: flex; align-items: center;">
    <img src="assets/images/mobilize-logo-small.png" alt="logo" width="150px">
    <a href="javascript: window.close()" class="mobilize-btn btn-orange">inchide</a>
</div>
<div class="static-page-wrapper">
    <img src="assets/images/logo.png">
    <div class="static-page-title">Politica de Cookie-uri</div>
    <br><br>

    <div class="static-page-content">
        Site-urile și aplicațiile noastre utilizează tehnologii precum modulele cookie pentru a vă oferi o experiență de
        înaltă calitate, fluidă și personalizată. <a routerLink="privacy-policy">Informațiile din prezenta politică
        completează Informațiile privind
        protecția datelor cu caracter personal.</a><br><br>


        Protejarea datelor dumneavoastră online este o prioritate pentru RCI ROMANIA. Vă puteți reconsidera alegerile cu
        privire la cookie-uri în orice moment, utilizând instrumentul nostru de gestionare a cookie-urilor:
        GESTIONAȚI-MI ALEGERILE.<br><br><br>

        <mat-accordion>
            <mat-expansion-panel  (opened)="panelOpenState.set(true)" (closed)="panelOpenState.set(false)">
                <mat-expansion-panel-header>
                    <mat-panel-title> Cine suntem noi</mat-panel-title>
                </mat-expansion-panel-header>
                <p>RCI LEASING ROMANIA IFN SA, RCI FINANTARE ROMANIA SRL și RCI BROKER DE ASIGURARE SRL [uzual denumite
                    RCI Romania] sunt operatori independenți de date cu privire la datele colectate de cookie-uri și
                    alte trackere citite sau plasate pe dispozitivul dumneavoastră (în continuare "noi", "noi", "al
                    nostru").<br><br>


                    Pentru a afla mai multe, vă invităm să citiți Informațiile privind protecția datelor cu caracter
                    personal.</p>
            </mat-expansion-panel>
            <mat-expansion-panel (opened)="panelOpenState.set(true)" (closed)="panelOpenState.set(false)">
                <mat-expansion-panel-header>
                    <mat-panel-title> CE SUNT COOKIE-URILE?</mat-panel-title>
                </mat-expansion-panel-header>
                <p>Un cookie este un fișier de calculator de mici dimensiuni, un tracker, depus și citit atunci când
                    vizitați un site web, utilizați o aplicație mobilă, citiți un e-mail, instalați sau utilizați
                    software, indiferent de tipul de terminal utilizat (computer, smartphone, cititor digital, consolă
                    de jocuri video conectată la Internet etc.).<br><br>


                    Atunci când consultă un site web, cookies poate trimite informații către browserul utilizatorului și
                    poate permite browserului să trimită informații înapoi la site-ul web original (de exemplu, un ID de
                    sesiune sau alegerea limbii).<br><br>


                    Cookie-urile nu ne permit să vă identificăm direct, ci doar să colectăm informații specifice despre
                    dispozitivul, browserul dvs., interacțiunile cu site-ul web sau aplicația pe care o utilizați și, în
                    unele cazuri, poziția dvs. geografică.<br><br>


                    Tehnologiile utilizate pot lega mai multe dintre dispozitivele pe care le-ați utilizat pentru a
                    determina, cu o probabilitate suficientă, că aceste dispozitive vă aparțin.</p>
            </mat-expansion-panel>

            <mat-expansion-panel (opened)="panelOpenState.set(true)" (closed)="panelOpenState.set(false)">
                <mat-expansion-panel-header>
                    <mat-panel-title>PENTRU CE SUNT UTILIZATE COOKIE-URILE PE ACEST SITE?</mat-panel-title>
                </mat-expansion-panel-header>
                <p>Acest site web utilizează diferite tipuri de cookie-uri.<br><br>


                    Cookie-uri tehnice (necesare)<br><br>


                    Aceste cookie-uri sunt destinate să permită sau să faciliteze comunicarea electronică (detectarea
                    erorilor de conectare, identificarea punctelor de conectare, securitatea site-ului webetc.) sau să
                    furnizeze servicii de comunicare online pe care le-ați putea solicita în mod specific (preferințe de
                    afișare a ecranului, salvarea informațiilor introduse în formulare, coșul de cumpărături (dacă este
                    cazul),accesul la contul dvs. de utilizator, chat sau chatbot pe site-ul nostru etc.).<br><br>


                    Aceste cookie-uri sunt necesare pentru buna funcționare a unui site-ului nostru.<br><br>


                    Nu aveți dreptul să obiectați la utilizarea acestor cookie-uri și consimțământul dumneavoastră nu
                    este necesar, deoarece serviciile de pe site-ul nostru se bazează pe aceste cookie-uri.<br><br>


                    Cookie-uri sociale<br><br>


                    Cookie-urile sociale sunt folosite de noi în scopuri publicitare și de analiză, în special pentru a
                    vă afișa anunțurile noastre atunci când sunteți conectat la rețele sociale (Facebook, Twitter,
                    LinkedIn, TikTok etc) și pentru a ne ajuta să înțelegem interacțiunile și interacțiunile
                    utilizatorilor cu anunțurile noastre pe aceste rețele sociale.<br><br>


                    Aceste cookie-uri sunt, de asemenea, menite să facă site-ul web mai ușor de utilizat și mai
                    interactiv, permițându-vă să interacționați cu aceste rețele sociale și prin încorporarea
                    conținutului din aceste rețele sociale pe site-ul nostru (cum ar fi videoclipurile YouTube).<br><br>


                    Aceste cookie-uri pot prelucra date precum numărul de conținuturi partajate prin intermediul
                    rețelelor sociale, faptul că aveți un cont pe o rețea socială, urmărirea navigării pe site-uri web
                    sau pe aceste rețele sociale etc. Aceste caracteristici se bazează pe aplicații terțe integrate în
                    site-ul nostru web. Rețeaua socială care furnizează un astfel de buton de aplicație este probabil să
                    vă identifice prin acest buton, chiar dacă nu ați utilizat acest buton atunci când ați vizitat
                    site-ul nostru web. Tipul său de buton de aplicație poate permite rețelei sociale în cauză să vă
                    urmărească navigarea pe site-ul nostru web, prin simplul fapt că contul dvs. pe rețeaua socială în
                    cauză a fost activat pe dispozitivul dvs. (sesiune deschisă) în același timp în care navigați pe
                    site-ul nostru web.<br><br>


                    Vă recomandăm să consultați politicile de confidențialitate relevante ale acestor rețele sociale
                    pentru a afla despre modalitățile în care aceste rețele sociale utilizează informațiile pe care le
                    pot colecta, prin intermediul acestor butoane de aplicație sau în scopuri publicitare. Aceste
                    politici de confidențialitate trebuie să vă permită să vă exprimați preferințele pe aceste rețele
                    sociale, în special prin configurarea conturilor de utilizator pentru fiecare dintre aceste
                    rețele.<br><br>


                    Utilizarea și stocarea acestor cookie-uri este condiționată de consimțământul dumneavoastră.<br><br>


                    Cookie-uri personalizate pentru publicitate și conținut<br><br>


                    Aceste cookie-uri sunt menite să îmbunătățească relevanța reclamelor și a conținutului de pe acest
                    site web sau de pe suporturi terțe. Ori de câte ori vă trimitem mesaje promoționale sau afișăm
                    astfel de mesaje, obiectivul nostru este să vă trimitem cele mai relevante reclame și oferte
                    posibile, adaptate nevoilor dvs. sau care sunt susceptibile de a vă interesa efectiv.<br><br>


                    Aceste cookie-uri pot prelucra date referitoare la preferințele dumneavoastră personale, interesele
                    dumneavoastră în produsele și/sau serviciile noastre și obiceiurile dumneavoastră de consum.<br><br>


                    Prin urmare, această prelucrare automată a datelor contribuie la o experiență online mai
                    bună:<br><br>

                    - arătându-vă ofertele relevante la momentul potrivit,<br><br>

                    - renunțandu-se la oferte care, cel mai probabil, nu vă vor interesa;<br><br>

                    - limitand numărului de afișări ale aceleiași reclame.<br><br>


                    De exemplu, aceste operațiuni de prelucrare ne pot determina să vă propunem informații legate de
                    segmentul auto pe care este posibil să îl preferați (un SUV, mai degrabă decât o mașină de oraș sau
                    invers).<br><br>


                    Este posibil ca datele prelucrate în acest context să fi fost colectate din diferite surse și să fie
                    interconectate: date din fișierele clienților noștri, date de navigație, date de la terți, date
                    privind utilizarea vehiculului dvs., etc. Utilizarea de astfel de instrumente este definita ca
                    profilare prin reglementări.<br><br>


                    Utilizarea și stocarea acestor cookie-uri este condiționată de consimțământul dumneavoastră.<br><br>


                    Cookie-uri de analiză<br><br>


                    Aceste cookie-uri sunt destinate să analizeze traficul in site și sa îmbunătateasca experiența
                    dumneavoastră de navigare.<br><br>


                    Aceste cookie-uri pot prelucra date referitoare la paginile vizitate, timpul petrecut pe paginile
                    vizitate, browser-ul pe care îl utilizați pentru a vizita aceste pagini etc.<br><br>


                    Datele colectate de aceste cookie-uri pot fi, de asemenea, utilizate pentru a crea algoritmi și
                    profiluri pentru a analiza interesele și preferințele dumneavoastră potențiale pentru produsele și
                    serviciile noastre. Putem combina aceste date cu alte informații din baza noastră de date (pentru
                    mai multe informații despre profilare ori daca doriți să obiectați la o astfel de profilare, vă
                    rugăm să consultați Informațiile privind protecția datelor cu caracter personal)<br><br>


                    Utilizarea și stocarea acestor cookie-uri este, în general, supusă consimțământului dumneavoastră.
                    În anumite condiții, aceste cookie-uri pot fi plasate/citite fără consimțământul dumneavoastră, de
                    exemplu dacă scopul cookie-urilor este doar de a analiza conținutul pe care l-ați vizitat.</p>
            </mat-expansion-panel>

            <mat-expansion-panel (opened)="panelOpenState.set(true)" (closed)="panelOpenState.set(false)">
                <mat-expansion-panel-header>
                    <mat-panel-title>CE COOKIE-URI SUNT UTILIZATE PE ACEST SITE?</mat-panel-title>
                </mat-expansion-panel-header>
                <p>Tabelul atașat conține lista tuturor cookie-urilor stocate și/sau utilizate de acest site web și
                    principalele caracteristicile acestora. Ori de câte ori este posibil, aceste cookie-uri sunt
                    găzduite pe servere situate în Spațiul Economic European (SEE). Cu toate acestea, deoarece unii
                    dintre furnizorii noștri de servicii se află în țări din afara SEE, datele dumneavoastră cu caracter
                    personal sunt prelucrate în aceste țări, care pot avea reglementări diferite în ceea ce privește
                    datele cu caracter personal decât Uniunea Europeană. Într-un astfel de caz (i) acordăm o atenție
                    deosebită asigurării faptului că acest transfer se efectuează în conformitate cu reglementările
                    aplicabile și (ii) instituim garanții care asigură un nivel adecvat de protecție a datelor
                    dumneavoastră (în special prin utilizarea clauzelor contractuale standard ale Comisiei
                    Europene).<br><br>


                    Site-ul nostru web poate conține cookie-uri ale terților (agenție de comunicare, servicii de
                    analiză, etc.) care le permit, pe durata de viață a cookie-urilor, să colecteze informații de
                    navigare referitoare la site-ul nostru web. Stocarea și utilizarea acestor cookie-uri de către terți
                    se supune politicilor de confidențialitate ale acestor terțe părți. Vă informăm cu privire la scopul
                    oricăror cookie-uri terțe de pe site-ul nostru web, precum și cu privire la mijloacele pe care le
                    aveți la dispoziție pentru a face alegeri cu privire la aceste cookie-uri.

                </p>
            </mat-expansion-panel>

            <mat-expansion-panel (opened)="panelOpenState.set(true)" (closed)="panelOpenState.set(false)">
                <mat-expansion-panel-header>
                    <mat-panel-title>SE OBȚINE CONSIMȚĂMÂNTUL DVS.?</mat-panel-title>
                </mat-expansion-panel-header>
                <p>Când vizitați pentru prima dată site-ul nostru web, vi se solicită să fiți de acord, să refuzați sau
                    să faceți anumite alegeri cu privire la utilizarea cookie-urilor, prin afișarea unui banner dedicat.<br><br>


                    Vă puteți retrage consimțământul în orice moment sau vă puteți schimba opțiunile (de exemplu,
                    acceptând doar anumite tipuri de cookie-uri) cu instrumentul nostru dedicat.<br><br>


                    Vă rugăm să rețineți că, în conformitate cu reglementările, cookie-urile care sunt necesare pentru
                    funcționarea site-ului web nu sunt supuse consimțământului dumneavoastră și, prin urmare, nu pot fi
                    dezactivate în instrumentul nostru de gestionare a cookie-urilor. Puteți împiedica utilizarea
                    acestora prin modificarea setărilor browser-ului (urmând secțiunea să refuzați cookie-urile de mai
                    jos), dar calitatea experienței dumneavoastră pe site-ul web poate avea de suferit.</p>
            </mat-expansion-panel>

            <mat-expansion-panel (opened)="panelOpenState.set(true)" (closed)="panelOpenState.set(false)">
                <mat-expansion-panel-header>
                    <mat-panel-title>CÂT TIMP ESTE STOCAT CONSIMȚĂMÂNTUL DVS.?</mat-panel-title>
                </mat-expansion-panel-header>
                <p>Atunci când acceptați sau refuzați toate sau o parte din cookie-urile de pe site-ul nostru, alegerea
                    dumneavoastră este salvată și stocată (și vom păstra o evidență a preferințelor dvs.) pentru o
                    perioadă de cel mult 6 luni. Aceasta înseamnă că, atunci când consultați site-ul nostru web
                    ulterior, în această perioadă, nu vom solicita nicio acțiune în numele dvs. cu privire la
                    cookie-uri, deoarece vom avea o înregistrare a preferințelor dvs. stocate.<br><br>


                    După încheierea acestei perioade, vă vom cere din nou să vă dați consimțământul, să refuzați sau să
                    faceți anumite alegeri cu privire la utilizarea cookie-urilor.</p>
            </mat-expansion-panel>

            <mat-expansion-panel (opened)="panelOpenState.set(true)" (closed)="panelOpenState.set(false)">
                <mat-expansion-panel-header>
                    <mat-panel-title>CUM PUTEȚI GESTIONA COOKIE-URI?</mat-panel-title>
                </mat-expansion-panel-header>
                <p>Cum pot fi refuzate cookie-urile?<br><br>


                    Dacă nu doriți ca modulele cookie să fie stocate sau citite pe dispozitivul dvs., un cookie de refuz
                    va fi stocat pe echipamentul dvs., pentru a ne permite să înregistreze faptul că ați obiectat la
                    utilizarea cookie-urilor. Dacă ștergeți acest refuz de cookie-uri, nu vom mai putea recunoaște că
                    ați respins utilizarea și stocarea cookie-urilor (ceea ce înseamnă că va trebui să vă setați din nou
                    preferințele data viitoare când vizitați site-ul nostru).<br><br>


                    Aveți mai multe opțiuni pentru gestionarea cookie-urilor, inclusiv blocarea utilizării și /sau
                    ștergerea acestora.
                    <br><br>

                    Instrument de gestionare a cookie-urilor<br><br>


                    Este furnizat un instrument de tip cookie care vă permite să gestionați preferințele privind
                    modulele cookie pentru a vă permite să gestionați modulele cookie stocate și/sau utilizate pe acest
                    site web. În orice moment, făcând clic pe acest link de mai jos, puteți accesa configuratorul și vă
                    puteți schimba preferințele, în funcție de tipul de cookie în cauză.<br><br>


                    GESTIONAREA COOKIE-URILOR<br>
                    Setări browser<br><br>


                    De asemenea, puteți gestiona modulele cookie prin intermediul browserului dvs.<br><br>


                    Majoritatea browserelor sunt setate în mod implicit să accepte cookie-uri, dar dacă doriți, puteți
                    alege să acceptați toate cookie-urile, să refuzați sistematic cookie-urile sau să alegeți
                    cookie-urile pe care le acceptați în funcție de locul de unde provin. De asemenea, puteți șterge în
                    mod regulat cookie-urile de pe dispozitiv folosind browserul dvs.<br><br>


                    Cu toate acestea, nu uitați să configurați toate browserele de pe diverse dispozitive (tablete,
                    smartphone-uri, computere etc.).<br><br>


                    Fiecare browser are o configurație diferită pentru gestionarea cookie-urilor și a alegerilor dvs.
                    Acesta este descris în meniul de ajutor al browserului dvs., care vă va spune să vă schimbați
                    preferințele privind modulele cookie. De exemplu:<br><br>

                    • pentru Internet Explorer™: http://windows.microsoft.com/fr-FR/windows-vista/Block-or-allow-cookies<br><br>
                    ;

                    • pentru Safari™: https://support.apple.com/fr-fr/safari ;<br><br>

                    • pentru Chrome™: http://support.google.com/chrome/bin/answer.py?hl=fr&hlrm=en&answer=95647
                    ;<br><br>

                    • pentru Firefox™: http://support.mozilla.org/fr/kb/Activer%20et%20d%C3%A9sactiver%20les%20cookies ;<br><br>

                    • pentru Opera™: http://help.opera.com/Windows/10.20/fr/cookies.html<br><br>


                    Module de editare pentru formularea de obiecții<br><br>

                    Puteți alege să dezactivați unele cookie-uri terțe mergând direct la pagina emitentului cookie-ului.
                    În acest fel:<br><br>


                    - pentru a dezactiva cookie-urile Google Analytics, accesați pagina
                    https://tools.google.com/dlpage/gaoptout?hl=en<br><br>

                    - pentru a dezactiva cookie-urile intelliAd, accesați pagina: https://login.intelliad.com/optout.php<br><br>

                    - pentru a dezactiva modulele cookie Adobe, accesați pagina :<br><br>
                    https://www.adobe.com/uk/privacy/opt-out.html</p>
            </mat-expansion-panel>

            <mat-expansion-panel (opened)="panelOpenState.set(true)" (closed)="panelOpenState.set(false)">
                <mat-expansion-panel-header>
                    <mat-panel-title>CUM VĂ PUTEȚI EXERCITA CELELALTE DREPTURI?</mat-panel-title>
                </mat-expansion-panel-header>
                <p></p>
            </mat-expansion-panel>

            <mat-expansion-panel (opened)="panelOpenState.set(true)" (closed)="panelOpenState.set(false)">
                <mat-expansion-panel-header>
                    <mat-panel-title>CUM VĂ PUTEȚI EXERCITA CELELALTE DREPTURI?</mat-panel-title>
                </mat-expansion-panel-header>
                <p>În conformitate cu legislația aplicabilă privind protecția datelor cu caracter personal, aveți
                    dreptul de a accesa, rectifica, obiecta, restricționa și șterge datele dumneavoastră cu caracter
                    personal. Pentru a vă exercita celelalte drepturi, vă rugăm să consultați informațiile noastre
                    privind protecția datelor dumneavoastră cu caracter personal făcând clic pe următorul link</p>
            </mat-expansion-panel>

            <mat-expansion-panel (opened)="panelOpenState.set(true)" (closed)="panelOpenState.set(false)">
                <mat-expansion-panel-header>
                    <mat-panel-title>MODIFICĂRI</mat-panel-title>
                </mat-expansion-panel-header>
                <p>Este posibil să schimbăm această politică din când în când. Vă vom informa și/sau vă vom solicita
                    consimțământul ori de câte ori este necesar sau necesar. Prin urmare, vă recomandăm să consultați
                    această politică de fiecare dată când vizitați site-ul nostru web pentru a revizui cea mai recentă
                    versiune.<br><br>


                    Versiunea 1 : 27/05/2021</p>
            </mat-expansion-panel>
        </mat-accordion>
    </div>
</div>
