import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root', // Makes it available application-wide
})
export class ProformaDownloadService {
    constructor() {}

    public downloadFile(response: any, defaultFilename: string = 'default.pdf'): void {
        // Extract file name from Content-Disposition header if available
        const contentDisposition = response.headers.get('Content-Disposition');
        let filename = defaultFilename;

        if (contentDisposition) {
            const match = contentDisposition.match(/filename="?(.+)"?/);
            if (match && match[1]) {
                filename = match[1];
            }
        }

        // Create and download file using Blob
        const blob = new Blob([response.body], { type: 'application/pdf' });
        const downloadURL = window.URL.createObjectURL(blob);
        const documentLink = document.createElement('a');
        documentLink.href = downloadURL;
        documentLink.download = filename;

        // Trigger download
        documentLink.click();

        // Clean up
        window.URL.revokeObjectURL(downloadURL);
        documentLink.remove();
    }
}
