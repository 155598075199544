<div class="contracts-list__item contract-{{ contract.state }}">
    <div class="short-info">
        <div class="collapsed-view">
            <span>Contract {{ contract.contract_number }}
                @if (contract.contract_objects[0].registration_number) {
                    {{ '/ ' + contract.contract_objects[0].registration_number }}
                }
            </span>
            <div class="item-extend"
                 (click)="toggleContractState()"><span
                class="icon-mobilize-chevron-down" [@rotateChevron]="extendContract ? 'open' : 'closed'"></span>
            </div>
        </div>

        <div class="extended-view" [@slideToggle]="extendContract ? 'open' : 'closed'">
            <div class="contract-object">
                <span class="label-name">bun finantat:</span>
                <span
                    class="label-value">{{ contract.contract_objects[0].vehicle_brand }} {{ contract.contract_objects[0].vehicle_model }}</span>
            </div>
            <div class="contract-object" *ngIf="contract.state !== 'ready'">
                <span class="label-name">capital achitat:</span>
                <span class="label-value">
                                            <span
                                                [innerHTML]="formatAmount(contract.totalPayed, contract.currency_code)"></span>
                                            <mobilize-installments-slider
                                                [showInstallments]="true"
                                                [maxInstallments]="contract.maximumInstallments"
                                                [payedInstallments]="contract.previous_instalments"></mobilize-installments-slider>
                                        </span>
            </div>
        </div>
    </div>
    @if (!paymentPhase) {
        <div class="payment-process-steps" [@slideToggle]="extendContract ? 'open' : 'closed'">
            @if (showPartial) {
                <div class="payment-process-steps__left">
                    <div class="title">Fac o plata partiala</div>
                    <div class="steps">
                        <div class="step step-1" *ngIf="currentStep === 1">
                            <div class="step-title">Suma pe care vrei sa o achiti
                                ({{ contract.currency_code }}):
                            </div>
                            <input type="text" [(ngModel)]="contract.amount"
                                   placeholder="scrie valoarea">
                            <button class="mobilize-btn btn-orange" (click)="nextStep(contract)">
                                continua
                            </button>
                        </div>

                        <div class="step step-2" *ngIf="currentStep === 2">
                            <div class="step-subtitle">Suma pe care vrei sa o achiti: <span
                                [innerHTML]="formatAmount(contract.amount, contract.currency_code)"></span>
                            </div>
                            <button class="mobilize-btn btn-orange"
                                    [mobilizeButtonLoader]="isProcessing"
                                    originalContent="vreau sa reduc rata"
                                    (click)="nextStep(contract, 'installment')">
                                vreau sa reduc rata
                            </button>
                            @if (contract.contract_type === 'Credit' && allowPeriodReduction) {
                                <button class="mobilize-btn btn-orange"
                                        [mobilizeButtonLoader]="isProcessing"
                                        originalContent="vreau sa reduc perioada"
                                        (click)="nextStep(contract, 'period')">vreau
                                    sa reduc perioada
                                </button>
                            }
                            <button class="mobilize-btn btn-clear" (click)="previousStep()">inapoi
                            </button>
                        </div>

                        <div class="step step-3" *ngIf="currentStep === 3">
                            <div class="step-subtitle">Suma pe care vrei sa o achiti: <span
                                [innerHTML]="formatAmount(contract.amount, contract.currency_code)"></span>
                            </div>
                            @if (choice === 'installment') {
                                <p>Ai ales sa reduci: <span>rata</span></p>
                                <p>Valoarea noii rate: <span class="bg-orange-light"
                                                             [innerHTML]="formatAmount(newMonthlyRate, contract.currency_code)"></span>
                                </p>
                                <p>Numar rate ramase: <span
                                    class="bg-orange-light">{{ monthsRemaining }}</span></p>
                            }

                            @if (choice === 'period') {
                                <p>Vrei sa reduci: <span>perioada</span></p>

                                <p>Valoarea noii rate: <span class="bg-orange-light"
                                                             [innerHTML]="formatAmount(newMonthlyRate, contract.currency_code)"></span>
                                </p>

                                <p>Numar de rate ramase: <span
                                    class="bg-orange-light">{{ monthsRemaining }}</span>
                                </p>
                            }
                            <div class="buttons">
                                <button class="mobilize-btn btn-orange"
                                        (click)="finalizePayment(contract)">
                                    plateste partial
                                </button>
                                <button class="mobilize-btn btn-clear" (click)="previousStep()">
                                    inapoi
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="steps-bullets">
                        <div class="bullet" [ngClass]="bulletStateClass[0]"></div>
                        <div class="bullet" [ngClass]="bulletStateClass[1]"></div>
                        <div class="bullet" [ngClass]="bulletStateClass[2]"></div>
                    </div>
                </div>

                <div class="choice-info">sau</div>
            }

            <div class="payment-process-steps__right">
                @if (!contract.proforma_invoices.length || showEarlyRepaymentOptions) {
                    <div class="title">Fac plata integrala</div>

                    @if (noReimbursement) {
                        <p class="text-center">Nu puteti efectua o plata anticipata totala. <br>Trebuie sa aveti cel
                            putin 12 rate platite pana in momentul rambursarii.</p>
                    }
                    <div class="amount-to-pay">
                        <b>TOTAL DE PLATA:</b>
                        @if (totalAmountLoaded) {
                            <span [innerHTML]="formatAmount(totalFullReimbursement, contract.currency_code)"></span>
                        } @else {
                            <mat-progress-spinner class="orange-spinner" diameter="20" strokeWidth="2"
                                                  mode="indeterminate"></mat-progress-spinner>
                        }
                        <i class="icon-mobilize-info" [matTooltip]="'Valoare fara TVA'" matTooltipPosition="above"></i>
                    </div>
                    @if (totalAmountLoaded) {
                        <button class="mobilize-btn btn-clear" (click)="fullReimbursement()">plateste integral</button>
                    }
                } @else {
                    <div class="pending-proforma">
                        Pentru acest contract ai deja o cerere de rambursare in lucru
                        <button class="mobilize-btn btn-orange" (click)="downloadProforma()"
                                [mobilizeButtonLoader]="isProcessing" originalContent="descarca proforma"></button>
                        <div>sau vezi statusul cererii in <span class="orange-link cursor-pointer"
                                                                (click)="goToHistory()">istoric</span></div>
                    </div>
                }
            </div>
        </div>
    }

    @if (!fullReimbursementActive) {
        <div class="payment-process-summary" *ngIf="contract.state === 'ready'">
            <div class="title">Faci o plata anticipata partiala</div>

            <p>Suma pe care vrei sa o achiti: <span class="font-orange"
                                                    [innerHTML]="formatAmount(contract.amount, contract.currency_code)"></span>
            </p>
            <p>Noua rata lunara va fi: <span class="bg-orange-light"
                                             [innerHTML]="formatAmount(newMonthlyRate, contract.currency_code)"></span>
            </p>
            <p>Numarul de rate de achitat: <strong>{{ monthsRemaining }}</strong></p>
        </div>
    } @else {
        <div class="payment-process-summary" *ngIf="contract.state === 'ready'">
            <div class="title">Faci o plata anticipata integrala</div>

            @if (fullReimbursementData.valoareReziduala.faraTva > 0) {
                <p>Valoare reziduala in avans: <span
                    [innerHTML]="formatAmount(fullReimbursementData.valoareReziduala.faraTva, contract.currency_code)"></span>
                </p>
            }
            <p>Comision de administrare lunara: <span
                [innerHTML]="formatAmount(fullReimbursementData.comision.faraTva, contract.currency_code)"></span>
            </p>
            <p>Comision de plata anticipata: <span
                [innerHTML]="formatAmount(fullReimbursementData.comisionRambursare.faraTva, contract.currency_code)"></span>
            </p>
            <p>Rata - Dobanda: <span
                [innerHTML]="formatAmount(fullReimbursementData.dobanda.faraTva, contract.currency_code)"></span></p>
            <p>Rata - Principal: <span
                [innerHTML]="formatAmount(fullReimbursementData.capital.faraTva, contract.currency_code)"></span>
            </p>
            @if (fullReimbursementData.casco.total > 0) {
                <p>Asigurare CASCO:
                    <span
                        [innerHTML]="formatAmount(fullReimbursementData.casco.faraTva, contract.currency_code)"></span>
                </p>
            }

            @if (fullReimbursementData['Asistenta Rutiera']) {
                <p>Asistenta Rutiera: <span
                    [innerHTML]="formatAmount(fullReimbursementData['Asistenta Rutiera'].faraTva, contract.currency_code)"></span>
                </p>
            }

            @if (fullReimbursementData.Intretinere) {
                <p>Intretinere: <span
                    [innerHTML]="formatAmount(fullReimbursementData.Intretinere.faraTva, contract.currency_code)"></span>
                </p>
            }

            @if (contract.contract_type === 'Credit') {
                <p>Taxa Ministerul de Justitie: <span [innerHTML]="formatAmount(20, 'RON')"></span></p>
                <p>Aviz initial - radiere arhiva electronica: <span [innerHTML]="formatAmount(23.8, 'RON')"></span></p>
            }

            <p>Total: <span
                [innerHTML]="formatAmount(fullReimbursementData.grand_total.faraTva, contract.currency_code)"></span>
            </p>

            @if (contract.currency_code === 'EUR') {
                <p>Total de plata (inclusiv TVA): <span
                    [innerHTML]="formatAmount(fullReimbursementData.grand_total.total, contract.currency_code)"></span>
                </p>
                @if (selectedPaymentMethod === 1) {
                    <p>Curs de schimb: <span
                        [innerHTML]="finalExchangeRate + ' RON'"></span>
                    </p>
                    <p>Total de plata RON (inclusiv TVA): <span
                        [innerHTML]="formatAmount(fullReimbursementData.grand_total.total * finalExchangeRate, 'RON')"></span>
                    </p>
                }
            } @else {
                <p>Total de plata (inclusiv TVA): <span
                    [innerHTML]="formatAmount(fullReimbursementData.grand_total.total, contract.currency_code)"></span>
                </p>
            }
            <p>Sold zi anterioara: <span [innerHTML]="formatAmount(contract.unpaid_invoices_ron, 'RON')"></span></p>
            <p>Plati in avans: <span [innerHTML]="formatAmount(fullReimbursementData.total_advance, 'RON')"></span></p>
        </div>
    }
</div>
