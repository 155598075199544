import { ChangeDetectorRef, Component, ViewRef } from '@angular/core';
import { Invoice } from '../interfaces/invoice';
import moment from 'moment';

@Component({
	selector: 'mobilize-base',
	standalone: true,
	template: '',
    providers: []
})
export class BaseComponent {

    protected readonly changeDetectorRef!: ChangeDetectorRef;

	formatAmount(amount: any, currency: string, hideLabel?: boolean) {
		return Intl.NumberFormat('us-US', {
			style: 'currency',
			notation: 'standard',
			currency: currency || 'RON'
		}).format(parseFloat(amount)).replace('€', '').replace(currency, '') + ' <span>' + (!hideLabel ? currency : '') + '</span>'
	}

    scrollToTop() {
        window.scrollTo({ top: 0, behavior: 'smooth' }); // Smooth scrolling to the top
    }

    parseDateToDisplay(dateString: string): string {
        const date = new Date(dateString);
        let day = date.getDate();
        const month = new Intl.DateTimeFormat('ro-RO', { month: 'short' }).format(date);
        const paddedDay = day < 10 ? `0${day}` : day.toString();

        return `${paddedDay} ${month.replace('.', '')}`;
    }

    invoiceStatus(invoice: Invoice): string {
        const today = moment();
        console.log(today.isAfter(moment(invoice.data_scadenta, 'DD/MM/YYYY')))
        if (today.isAfter(moment(invoice.data_scadenta, 'DD/MM/YYYY')) && parseFloat(invoice.rest_amount) > 0) {
            return '<span class="invoice-status status-outstanding">restant</span>'
        }

        if (parseFloat(invoice.rest_amount) === 0) {
            return '<span class="invoice-status status-cashed">incasat</span>'
        } else {
            return '<span class="invoice-status status-not-cashed">neachitat</span>'
        }
    }

    invoiceStatusText(invoice: Invoice): string {
        const today = moment();
        if (moment(invoice.data_scadenta, 'DD/MM/YYYY').isBefore(today) && parseFloat(invoice.rest_amount) > 0) {
            return 'restant'
        }

        if (parseFloat(invoice.rest_amount) === 0) {
            return 'incasat'
        } else {
            return 'neachitat'
        }
    }

    public detectChanges(): void {
        if (!this.changeDetectorRef || (this.changeDetectorRef as ViewRef).destroyed) {
            return;
        }
        this.changeDetectorRef.detectChanges();
    }

    isValid(createdAt: string, expireAt: string) {
        const formatDate = (date: string) => {
            const [day, month, year] = date.split('/');
            return new Date(`${year}-${month}-${day}`);
        };

        const createdDate = formatDate(createdAt);
        const expireDate = formatDate(expireAt);
        const currentDate = new Date(); // Current date

        return currentDate >= createdDate && currentDate <= expireDate;
    }
}
