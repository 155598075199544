import { Component, inject, Input, OnInit } from '@angular/core';
import { DatePipe, NgClass, NgForOf } from '@angular/common';
import { PadZeroPipe } from '../../../pipes/pad-zero.pipe';
import { ButtonLoaderDirective } from '../../../directives/button-loader.directive';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { finalize } from 'rxjs';
import { RequestService } from '../../../services/request.service';
import { LoadingService } from '../../../services/loading.service';
import { SocketService } from '../../../services/socket.service';
import { MatDialog } from '@angular/material/dialog';
import { EscalateUsersModalComponent } from '../escalate-users-modal/escalate-users-modal.component';
import { DownloadService } from '../../../services/download.service';
import { MatFormField } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { MatLabel } from '@angular/material/select';
import { FileItem, FileLikeObject, FileUploader, FileUploadModule } from 'ng2-file-upload';
import { environment } from '../../../../environments/environment';
import { KeycloakService } from 'keycloak-angular';
import { MatSnackBar } from '@angular/material/snack-bar';

const MAX_SIZE = 5 * 1024 * 1024; // 5MB in bytes

@Component({
    selector: 'mobilize-bo-request',
    standalone: true,
    imports: [
        DatePipe,
        PadZeroPipe,
        NgClass,
        ButtonLoaderDirective,
        MatLabel,
        ReactiveFormsModule,
        MatFormField,
        MatInput,
        FileUploadModule,
        NgForOf
    ],
    templateUrl: './bo-request.component.html',
    styleUrl: './bo-request.component.scss'
})
export class BoRequestComponent implements OnInit {

    private _fb = inject(FormBuilder);
    private _kc = inject(KeycloakService);
    private _request = inject(RequestService);
    private _snackbar = inject(MatSnackBar);
    private _dialog = inject(MatDialog);
    private _download = inject(DownloadService);

    @Input() request: any;
    @Input() detailsPage: boolean = false;
    @Input() reportPage: boolean = false;

    loading = false;
    showReplyForm = false;
    replyForm!: FormGroup;
    hasBaseDropZoneOver: any;
    tokenLoaded = false;
    uploader!: FileUploader;
    uploadUrl: any = 'upload';
    newToken: string = '';
    changing = false;
    uploadedFiles: any[] = [];

    private totalSize: number = 0;

    constructor() {
        this.replyForm = this._fb.group({
            subject: ['', Validators.required],
            message: ['', Validators.required],
            //files: [null]
        });
    }

    ngOnInit() {
        this._kc.getToken().then(rsp => {
            this.tokenLoaded = true;
            this.uploader = new FileUploader({
                url: environment.apiUrl + this.uploadUrl,
                allowedMimeType: [
                    'image/jpeg',
                    'image/JPEG',
                    'image/JPG',
                    'image/jpg',
                    'application/pdf',
                    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                    'application/vnd.ms-excel', // .xls files
                    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' // .xlsx files
                ],
                method: 'POST',
                autoUpload: false,
                headers: [
                    {
                        name: 'Authorization',
                        value: `Bearer ${this.newToken !== '' ? this.newToken : rsp}`
                    }
                ],
            });
            this.totalSize = this.calculateTotalSize();
            this.uploader.onErrorItem = (item: FileItem, response: string, status: number): any => {
                // error
                this.uploader.removeFromQueue(item);
            };

            this.uploader.onWhenAddingFileFailed = (item: FileLikeObject): any => {
                // error
                this._snackbar.open(`Tipul fisierului nu este permis! (${item.name})`, 'Ok', {
                    panelClass: 'error-snack'
                })
            };

            this.uploader.onBeforeUploadItem = () => {
                if (this.uploader.queue && this.uploader.queue.length > 1) {
                    if (this.uploader.queue.length === 0) {
                        return;
                    }
                    const item = this.uploader.queue[0];
                    if (item.isUploading) {
                        this.uploader.cancelItem(item);
                    }
                }
            };

            this.uploader.onCancelItem = (file) => {
                this.totalSize -= file.file.size;
            };

            this.uploader.onAfterAddingFile = (file) => {
                file.withCredentials = false; // Disable credentials flag

                const newTotalSize = this.totalSize + file.file.size;
                if (newTotalSize > MAX_SIZE) {
                    this.uploader.removeFromQueue(file);
                    this._snackbar.open(`Dimensiunea totala a fisierelor depaseste 5MB.`, 'Ok', {
                        panelClass: 'snack-error'
                    });
                } else {
                    this.totalSize = newTotalSize;
                }
            };

            this.uploader.response.subscribe(res => {
                console.log(res)
                this.uploadedFiles.push(res);
            });
        });
    }

    async refreshTokenIfNeeded(): Promise<string> {
        if (!this.newToken) {
            this.newToken = await this._kc.getToken(); // Or call your Keycloak refresh logic
        }
        return this.newToken;
    }

    async execUpload() {
        this.changing = true;
        if (this.uploader.queue.length) {
            this.uploader.options.headers = [
                {
                    name: 'Authorization',
                    value: `Bearer ${await this.refreshTokenIfNeeded()}`,
                },
            ];
            this.uploader.uploadAll();
            this.uploader.onCompleteAll = () => {
                this.sendReply();
            };
        } else {
            this.sendReply();
        }
    }

    removeFile(item: FileItem) {
        this.uploader.removeFromQueue(item);
    }

    get statusClass(): string {
        switch (this.request.bo_status) {
            case 'Primita':
                return 'received';

            case 'Citita':
                return 'read'

            case 'Escaladata':
                return 'escalated'

            case 'Rezolvata - Raspuns trimis':
                return 'resolved'

            case 'Rezolvata - Citita':
                return 'closed'
        }
        return '';
    }

    replyRequest() {
        this.showReplyForm = true;
        this.replyForm.controls['subject'].setValue(`Re: ${this.request.request_subject}`);
    }

    fileOverBase(hasBaseDropZoneOver: any): void {
        this.hasBaseDropZoneOver = hasBaseDropZoneOver;
    }

    private calculateTotalSize(): number {
        return this.uploader.queue.reduce((total, file) => total + file.file.size, 0);
    }

    sendReply() {
        if (this.replyForm.invalid) {
            this.replyForm.markAllAsTouched();
            return;
        }
        this.loading = true;
        this._request.sendReply({
            message: this.replyForm.controls['message'].value,
            request_uid: this.request.client_request_uid,
            files: this.uploadedFiles,
        })
            .pipe(
                finalize(() => {
                    this.loading = false;
                })
            )
            .subscribe(rsp => {
                if (rsp.success) {
                    this.request.reply = rsp.reply;

                    this.request.bo_status = 'Rezolvata - Raspuns trimis';

                    this.showReplyForm = false;
                    this.replyForm.reset();
                }
            });
    }

    cancelReply() {
        this.showReplyForm = false;
        this.replyForm.reset();
    }

    escalateRequest() {
        this._dialog.open(EscalateUsersModalComponent, {
            width: '740px',
            panelClass: 'admin-list-modal'
        }).afterClosed().subscribe(rsp => {
            if (rsp.escalate) {
                this._request.escalate({request_uid: this.request.client_request_uid, escalate_to: rsp.uid, escalate_to_name: rsp.name}).subscribe(response => {
                    if (response.success) {
                        this.request.escalate_at = new Date();
                    }
                })
            }
        });
    }

    downloadFile(key: string) {
        const filename: any = key.split('/').pop();
        this._download.downloadFile(filename).subscribe(
            (rsp) => {
                const a = document.createElement('a');
                a.href = rsp.url;
                a.download = filename;
                a.click();
                window.URL.revokeObjectURL(rsp.url);
            }
        );
    }
}
