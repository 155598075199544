<div class="head" style="display: flex; align-items: center;">
    <img src="assets/images/mobilize-logo-small.png" alt="logo" width="150px">
    <a href="javascript: window.close()" class="mobilize-btn btn-orange">inchide</a>
</div>
<div class="static-page-wrapper">
    <img src="assets/images/logo.png">
    <div class="static-page-title">Politica de confidentialitate</div>
    <br><br>

    <div class="static-page-content">
        RCI LEASING ROMÂNIA IFN SA, RCI FINANTARE ROMANIA SRL si RCI BROKER DE ASIGURARE SRL socieatati ale caror date
        de regasiti in sectiunea DATE DE CONTACT din acest site, s-au angajat să respecte viața privată a utilizatorilor
        site-ului proprietatea noastră, www.my.mobilize-fs.ro. Politica de protecție a persoanelor fizice în ceea ce
        privește prelucrarea datelor cu caracter personal se bazează pe <a
        href="https://commission.europa.eu/strategy/justice-and-fundamental-rights/data-protection_en" target="_blank">Regulamentul
        general privind protejarea datelor</a>
        (GDPR), care a intrat în vigoare de la 25 mai 2018 si care creează reguli consecvente privind protejarea datelor
        cu caracter personal în Uniunea Europeană, precum si pe legislatia nationala aplicabila.<br><br>

        Această politică generală se aplică pe site-ul web pe care va aflati www.my.mobilize-fs.ro. administrat de
        <strong>RCI
            Leasing Romania IFN SA</strong><br>
        Continutul acestui site poate fi accesat prin furnizarea unor informatii personale necesare pentru a vă putea
        oferi serviciile online pe care le solicitați, conform scopurilor declarate respectiv, in vederea inregistrarii
        la servicii online sau prin e-mail, formularii de comentarii, desfasurarii corespondentei si accesarii spatiului
        personal de cont client.<br><br><br>


        Mai jos va rugam sa regasiti nota de informare privind prelucrarea datelor cu caracter personal de catre
        societatile RCI ROMANIA ale persoanelor vizate:<br><br>

        <strong>2. NOTA DE INFORMARE, DATE CU CARACTER PERSONAL, DESTINATARI, DREPTURI</strong><br><br>

        Având în vedere faptul că datele dvs. cu caracter personal urmează să facă obiectul prelucrărilor de date,
        importanța respectării obligațiilor de informare stabilite prin art. 12 și 13 din Regulamentul (UE) 2016/679 al
        Parlamentului European și al Consiliului din 27 aprilie 2016 privind protecția persoanelor fizice în ceea ce
        privește prelucrarea datelor cu caracter personal și privind libera circulație a acestor date și de abrogare a
        Directivei 95/46/CE (Regulamentul general privind protecția datelor), în continuare
        <strong>“Regulamentul”</strong> sau <strong>„GDPR”</strong>,
        vă aducem la cunoștință următoarele informații cu privire la prelucrarea datelor dvs. personale de către RCI
        LEASING ROMÂNIA IFN SA, RCI FINANTARE ROMANIA SRL si RCI BROKER DE ASIGURARE SRL in calitate de Operatori.<br>

        <strong>1. Categoriile de date cu caracter personal ce fac obiectul prelucrării</strong><br>
        Date cu caracter personal înseamnă orice informații privind o persoană fizică identificată sau identificabilă,
        precum: numele, număr de identificare, date de localizare etc.<br>
        Prelucrarea înseamnă orice operațiune privind datele cu caracter personal, precum: colectarea, înregistrarea,
        organizarea, structurarea, stocarea, adaptarea, modificarea, extragerea, consultarea, utilizarea, divulgarea
        prin transmitere, diseminare sau punerea la dispoziție în orice alt mod, alinierea sau combinarea,
        restricționarea, ștergerea sau distrugerea.<br><br>

        in calitate de Operatori, vor prelucra exclusiv acele categorii de date cu caracter personal care sunt adecvate,
        relevante și necesare scopurilor prelucrării, după cum urmează:<br>
        1.1. Datele dvs. de identificare – nume și prenume, pseudonim, sexul, data și locul nașterii, cetățenia,
        naționalitatea, codul numeric personal, fotografia, data de emitere / expirare, seria și numărul actului de
        identitate/pașaportului;<br>
        1.2. Datele dvs. de contact – domiciliul, adresa de corespondență, telefon/fax, e-mail;<br>
        1.3. Datele dvs. familiale – stare civilă, numărul de persoane aflate în întreținere<br>
        1.4. Datele dvs. profesionale – profesie/ocupație, domeniul de activitate, locul de muncă, vechimea în muncă,
        nivelul studiilor;<br>
        1.5. Date privind situația dvs. financiară – venitul net, credite (auto, imobiliar, nevoi personale, carduri de
        credite, contracte de leasing financiar etc), conturi bancare;<br>
        1.6. Date privind desfășurarea contractului de credit – tipul de produs, termenul de acordare, data acordării,
        data scadenței, sumele datorate, sumele restante, data scadentă a restanței, numărul de zile de întârziere,
        starea contului, frecvența plăților, suma plătită, rata lunară;<br>
        1.7. Date privind bonitatea dvs. și orice informații de risc bancar înregistrare pe numele dvs.;<br>
        1.8. Alte informatii de natura financiara (de ex. modaliate de detinere a locuintei - chirie/proprietate);<br>
        1.9. Informatii referitoare la activitatea frauduloasa/potential frauduloasa, constand in date referitoare la
        acuzatii si condamnari legate de infractiuni precum frauda, spalarea de bani si finantarea actelor de
        terorism;<br>
        1.10. Informatii rezultate in urma inregistrarii audio a convorbirilor telefonice sau video daca este cazul,
        semnătura olografă sau electronică, date video sau audio si localizare in cazul incheierii contractelor la
        distanta sau prin mijloace electronice sau utilizarea call center-ului, în vedere complinirii obligației de
        cunoastere a clientelei; date video sau audio in cazul prezentei la Biroul de Relatii cu clientii;<br>
        1.11. Informatii privind bunurile si persoanele asigurabile, persoanele responsabile de producerea daunelor,
        date telematice si istoricul de daune - dupa caz;<br>
        1.12. Date privind navigarea GPS in cazul contractarii unui serviciu de localizare al autovehiculului;<br>
        1.13. Date de identificare privind sotul/sotia și codebitorii.<br><br>

        <strong>2. Scopurile prelucrării</strong><br>
        RCI LEASING ROMÂNIA IFN SA, RCI FINANTARE ROMANIA SRL si RCI BROKER DE ASIGURARE SRL vă prelucrează datele
        personale în vederea îndeplinirii unor scopuri determinate, explicite și legitime, după cum urmează: încheierea,
        executarea și derularea în bune condiții a contractelor de finanțare și după caz asigurare, întreținere auto,
        pneuri, ori alte servicii conexe aflate in oferta comerciala;<br>
        (i) Evaluarea în vederea acordării finanțării prin cuantificarea riscului aferent unei eventuale imposibilității
        de rambursare a ratelor de credit, primelor de asigurare;<br>
        (ii) Prestarea serviciilor de intermediere in asigurari - ofertare produse de asigurare, emitere contracte de
        asigurare, informare privind scadentele de plata a primelor, informare privind expirarea contractelor de
        asigurare, gestionarea contractelor de asigurare pe intreaga perioada a derularii acestora, asistenta la
        solutionarea daunelor, analiza nevoilor de asigurare si propunerea de solutii personalizate de asigurare,
        activitati care rezulta din interesul legitim al Societatii precum si asigurarea masurilor de securitate
        necesare (supraveghere video) în sediile Societății;<br>
        (iii) Îndeplinirea obligațiilor legale conform art. 6(1)(c) din Regulament, Interogări si raportări către
        autoritățile și instituțiile competente (Agenția Națională de Administrare Fiscală, Banca Națională a României),
        transmiterea de informații la solicitarea autorităților statului, instanțelor de judecată;<br>
        (iv) Interogări si raportări către bazele de date ale Biroului de Credit S.A., Centralei Riscului de Credit
        pentru ținerea evidenței rău-platnicilor și evaluarea solvabilității clienților;<br>
        (v) îndeplinirea unei sarcini care servește unui interes public;<br>
        (vi) marketing direct și publicitate, cu consimțământul Clientului, conform Regulamentului.<br><br>

        <strong>3. Temeiul juridic</strong><br>
        (i) Necesitatea executării contractului de credit, de asigurare sau alte contracte accesorii dupa caz, conform
        art. 6(1)(b) din Regulament (ex. furnizarea serviciilor financiare, furnizarea de servicii suport pentru
        solicitarile Clientului, trimiterea de notificari/informari cu privire la produsul detinut) sau pentru a face
        demersuri pre-contractuale la cererea Clientului de incheiere a unui contract (sau pentru a furniza Clientului
        informatii despre produsele detinute sau suport pentru utilizarea acestora pe toata durata de valabilitate a
        contractului, necesitatea executării contractelor de asigurare si intermediere in asigurari, consultanță
        acordata clientilor pe durata acestora, asistenta la daune, rezolvarea unor solicitari ale clientilor);<br>
        (ii) Îndeplinirea obligațiilor legale conform art. 6(1)(c) din Regulament ( ex. verificare riguroasă a
        capacității de rambursare a creditului - art. 11 1 din Regulamentul nr. 17 din 12 decembrie 2012 privind unele
        condiții de creditare -, indeplinirea operatiunilor curente pentru desfasurarea activitatii financiare,
        analizarea si minimizarea riscurilor financiare, reputationale la care se expune RCI in legatura cu furnizarea
        serviciilor si produselor financiare; acumularea unui nivel ridicat de cunoastere al pietei financiare;
        mentinerea stabilitatii sistemului financiar, in special in ceea ce priveste descoperirea si minimizarea
        riscurilor de frauda, informarea cu privire la expirarea contractului RCA, raportări catre autorități,
        respectarea Legii nr.129/2019, Regulamentului nr.2/2019 al BNR, Regulamentul ASF nr.13/2019).<br>
        (iii) Interesul legitim al Finanțatorului de a obține și deține informații pertinente și actualizate despre
        bonitate și conduita de plată a clienților, conform art. 6(1)(f) din Regulament (ex. evaluare preliminara si
        acceptare a Clientului prin interogare la Biroul de Credit SA, ).<br>
        (iv) Consimțământul dvs., conform art. 6(1)(a) din Regulament (in caz de marketing direct sau luarea de decizii
        automate, interogare si raportare in baza de date a ANAF, interogare in baza de date a Centralei Riscului de
        Credit)<br>
        (v) Prelucrarea este necesară pentru îndeplinirea unei sarcini care servește unui interes public conform art.
        6(1)(e) din Regulament (ex. acțiuni si investigații ale Politiei, Parchetului, adrese ale instanțelor
        judecătorești, orice solicitări ale autorităților investite cu atribuții administrative, fiscale sau de ancheta,
        solicitări ale oricăror entități de drept public ori privat care sunt investite prin lege cu competenta de a
        solicita date cu caracter personal)<br>
        Pentru prelucrările care au ca temei exclusiv consimțământul dvs., vă informăm că aveți dreptul de a vă retrage
        consimțământul în orice moment, fără a fi afectate însă prelucrările efectuate în baza consimțământului anterior
        retragerii sale.<br>
        Furnizarea datelor cu caracter personal prevăzute la punctele 3 (i), (ii), (iii) de mai sus reprezintă o
        obligație contractuală a dvs., nerespectarea acesteia având drept consecință imposibilitatea executării
        contractului.<br><br>

        <strong>4. Durata prelucrării</strong><br>
        4.1. Prelucrarea datelor personale ale Clienților se va desfășura pe toată durata derulării contractului dvs, cu
        următoarele excepții:<br>
        - datelor transmise către Biroul de Credit S.A. – a se vedea secțiunea de informare a Biroului de Credit SA;<br>
        - datele transmise către societatea de asigurare vor putea fi păstrate pana la 10 ani după încetarea
        contractului de asigurare/reasigurare sau pe perioada in care se pot solicita drepturi rezultate din
        asigurare.<br>
        - pentru scop de marketing 5 ani de la la finalul relatiei contractuale sau 6 luni de la data acordarii
        consimtamantului pentru prospecti.<br>
        - obligatii legale;<br>
        Datele cu caracter personal ale clientilor se vor pastra de catre Finantator pe o perioada de 5 ani de la
        incetarea contractului de credit ori pe alte durate reglementate legal. Pentru motive legitime, proceduri
        judiciare ori necesitați de protejare a intereselor legale ale Operatorilor aceste datele sunt păstrate pana la
        finalizarea acestor proceduri sau atât timp cat interesele Operatorilor necesita protecție.<br><br>

        <strong>5. Destinatarii datelor cu caracter personal</strong><br>
        5.1. Datele dvs. personale vor putea fi dezvăluite următorilor destinatari, cu respectarea cerințelor legale de
        confidențialitate și securitate:<br>
        - Partenerilor ( Agentii Autorizati Dacia / Renault / Nissan / Renault Selection, Marsh Broker de
        Asigurare-Reasigurare SRL) și societăților din Grupul Renault Romania sau băncii mama, RCI Banque SA, în scopuri
        administrative, sau marketing (daca exista un consimtamant in acest sens);<br>
        - Societăților de asigurare si reasigurare, pentru întocmirea polițelor de asigurare și buna executare a
        contractelor de asigurare/reasigurare,<br>
        - Biroului de Credit S.A., Centralei Riscului de Credit, Agenției Națională de Administrare Fiscală, în scopul
        evaluării bonității, centralizării/monitorizării conduitei dvs. de plată, preîntâmpinării activităților
        frauduloase, Oficiul National de Prevenire si Combatere a Spălării Banilor, Autoritatea de Supraveghere
        Financiara;<br>
        - agențiilor de colectare a debitelor/ recuperare a creanțelor, avocaților, executorilor judecătorești angajați
        de Finanțator în scopul colectării eventualelor creanțe și recuperării bunurilor sau daunelor-interese,
        agenților Registrului National de Publicitate Mobiliara;<br>
        - societăți partenere de expediție plicuri, curierat, mesagerie, furnizori de servicii IT&C (mentenanta,
        dezvoltare software, furnizori de servicii online), arhivare in format fizic si/sau electronic;<br>
        - furnizorilor de servicii conexe activităților de creditare (ex: contracte de întreținere auto, asistenta
        rutiera etc );<br>
        - Autorităților publice centrale sau locale, in conditiile legii.<br>
        5.2. Divulgarea datelor către parteneri și alte societăți din Grupul Renault Romania ori catre societatea mama,
        RCI BANQUE SA se realizează în scopuri administrative, reprezentând așadar un interes legitim al Operatorului in
        legătura cu executarea contractului/elor.<br>
        5.3. Transmiterea datelor personale către sisteme de evidență de tipul birourilor de credit se va realiza cu
        respectarea formalităților legale.<br><br>

        <strong>6. Transferul datelor</strong><br>
        6.1. Datele personale pot face obiectul unor transferuri către entități care fac parte din grupul
        Renault-Romania sau organisme care luptă împotriva fraudelor și spălării de bani, cu sediul în state sin Spatiul
        Economic European (SEE) ori, cel mult, limitat la statele terțe cu privire la care Comisia Europeană a emis
        decizii cu privire la caracterul adecvat al nivelului de protecție, cu respectarea garanțiilor adecvate și
        nivelului adecvat de protecție/în baza regulilor corporatiste obligatorii aprobate de autoritatea competentă/ în
        baza consimțământului dvs. expres. In cazul in care datele ar fi transferate in afara SEE, Operatorii se vor
        asigura ca acesta date vor face obiectul unor clauze standard stabilite prin Regulament.<br><br>

        <strong>7. Drepturile dumneavoastra in calitate de persoană vizată:</strong><br>
        7.1 <strong>Dreptul de a fi informat</strong> cu privire la toate aspectele esențiale ale prelucrării de
        date;<br>
        7.2 <strong>Dreptul de acces</strong> la datele prelucrate și la următoarele informații:<br>
        a) scopurile prelucrării;<br>
        b) categoriile de date cu caracter personal vizate;<br>
        c) destinatarii sau categoriile de destinatari cărora datele cu caracter personal le-au fost sau urmează să le
        fie divulgate, în special destinatari din țări terțe sau organizații internaționale;<br>
        d) acolo unde este posibil, perioada pentru care se preconizează că vor fi stocate datele cu caracter personal
        sau, dacă acest lucru nu este posibil, criteriile utilizate pentru a stabili această perioadă;<br>
        e) existența dreptului de a solicita Operatorilor rectificarea sau ștergerea datelor cu caracter personal ori
        restricționarea prelucrării datelor cu caracter personal referitoare la persoana vizată sau a dreptului de a se
        opune prelucrării;<br>
        f) dreptul de a depune o plângere în fața unei autorități de supraveghere;<br>
        g) existența unui proces decizional automatizat incluzând crearea de profiluri, precum și, cel puțin în cazurile
        respective, informații pertinente privind logica utilizată și privind importanța și consecințele preconizate ale
        unei astfel de prelucrări pentru dvs.<br>
        h) garanțiile adecvate stabilite de Operatori în cazul transferului datelor personale către o țară terță sau o
        organizație internațională.<br>
        <strong>7.3 Dreptul de a obține rectificarea și/sau completarea datelor personale</strong>, fără întârzieri
        nejustificate;<br>
        <strong>7.4 Dreptul de a obține ștergerea datelor personale</strong>, fără întârzieri nejustificate, atunci
        când:<br>
        a) datele cu caracter personal nu mai sunt necesare pentru îndeplinirea scopurilor pentru care au fost colectate
        sau prelucrate;<br>
        b) vă retrageți consimțământul pe baza căruia are loc prelucrarea, atunci când pe acest consimțământ se
        întemeiază legalitatea prelucrării și nu există niciun alt temei juridic pentru prelucrare;<br>
        c) vă opuneți prelucrării în temeiul existenței unui interes public, exercitării autorității publice cu care
        este învestit Operatorul sau în temeiul urmăririi scopurilor legitime ale Operatorului și nu există motive
        legitime care să prevaleze în ceea ce privește prelucrarea sau vă opuneți prelucrării în scopuri de marketing
        direct;<br>
        d) datele cu caracter personal trebuie șterse pentru respectarea unei obligații legale care revine
        Operatoruluiîn temeiul dreptului Uniunii sau al dreptului intern sub incidența căruia se află Operatorul, în
        măsura în care nu este aplicabilă niciuna dintre excepțiile prevăzute de art. 17(3) din Regulament.<br>
        <strong>7.5 Dreptul de a obține restricționarea prelucrării atunci când:</strong><br>
        a) contestați exactitatea datelor, pentru o perioadă care permite Operatorului să verifice exactitatea
        datelor;<br>
        b) nu mai avem nevoie de datele cu caracter personal în scopurile prelucrării, dar dvs. le solicitați pentru
        constatarea, exercitarea sau apărarea unui drept în instanță;<br>
        c) v-ați opus prelucrării în temeiul intereselor legitime ale Operatorului, pentru intervalul de timp în care se
        verifică dacă drepturile legitime ale Operatorului prevalează asupra drepturilor dvs.
        Cu excepția stocării, datele a căror prelucrare a fost restricționată pot fi prelucrate numai cu consimțământul
        dvs. pentru constatarea, exercitarea sau apărarea unui drept în instanță sau pentru protecția drepturilor unei
        alte persoane fizice sau juridice sau din motive de interes public important al Uniunii sau al unui stat membru.
        În măsura în care ați obținut restricția prelucrării, vă vom informa înainte de ridicarea restricției de
        prelucrare.<br>
        <strong>7.6 Dreptul de a obține notificarea destinatarilor</strong> privind rectificarea sau ștergerea datelor
        personale sau cu
        privire la restricționarea prelucrării, cu excepția cazului în care acest lucru se dovedește imposibil sau
        presupune eforturi disproporționate pentru Operator.<br>
        <strong>7.7 Dreptul la portabilitatea datelor</strong><br>
        Aveți dreptul de a obține date personale prelucrate într-un mod structurat, utilizat în mod curent și care poate
        fi citit automat în scopul de a transmite aceste date altui operator, fără obstacole din partea Operatorului, în
        cazurile prevăzute de art. 20 din Regulament.<br>
        <strong>7.8 Dreptul la opoziție</strong><br>
        Aveți dreptul de a vă opune, din motive legate de situația dvs. particulară, prelucrării în temeiul interesului
        public sau al interesului legitim al Operatorului sau în scopuri de marketing direct, inclusiv creării de
        profiluri pe baza respectivelor justificări, cu excepția prevăzute de art. 21 din Regulament.<br>
        <strong>7.9 Dreptul de a nu face obiectul unei decizii bazate exclusiv pe prelucrarea automată</strong>, în
        afara excepțiilor
        prevăzute de art. 22 din Regulament. Aveți dreptul sa cereți si sa obțineți intervenția umana cu privire la
        respectiva prelucrare, va puteți exprima punctul de vedere cu privire la respectiva prelucrare si puteți
        contesta decizia.<br>
        <strong>7.10 Dreptul de a depune o plângere</strong> în fața Autorității Naționale de Supraveghere a Prelucrării
        Datelor cu
        Caracter Personal (ANSPDCP) sau instanțelor de judecată din România.<br><br>

        <strong>8. Exercitarea drepturilor</strong><br>
        a) Pentru a vă exercita drepturile anterior indicate, vă rugăm să formulați o solicitare scrisă, cu indicarea
        calității dvs. de persoană vizată și obiectul solicitării, pe care să o adresați Operatorului folosind datele de
        contact de mai jos:<br>
        În atenția: Responsabilului cu protecția datelor<br>
        Adresa: București, str. Preciziei, nr. 3G, Clădirea A et.1, camera AE1 20, sector 6, cod 062203<br>
        E-mail: <a href="mailto: dataprotectionofficer-romania@mobilize-fs.com">dataprotectionofficer-romania&#64;mobilize-fs.com</a><br>
        b) În măsura în care solicitați o copie a datelor cu caracter personal care fac obiectul prelucrării de catre
        Operator, aceasta vă va fi furnizată în format material sau electronic, conform opțiunii dvs.<br>
        c) Operatorii vor analiza temeinicia solicitărilor dvs. și vă vor furniza un răspuns fără întârzieri
        nejustificate și în orice caz în cel mult o lună de la primirea cererii. Această perioadă poate fi prelungită cu
        două luni atunci când complexitatea și numărul cererilor o impune. Pentru solicitările transmise de către dvs.
        în format electronic, răspunsurile se vor transmite tot în format electronic, cu excepția cazului în care
        solicitați expres furnizarea lor în alt format.<br>
        d) În calitate de persoană vizată, vă revin următoarele obligații:<br>
        (i) De a furniza date corecte;<br>
        (ii) De a informa, fără întârzieri nejustificate, Operatorul despre orice modificare survenită cu privire la
        datele prelucrate.<br>
        <strong>9. Securitatea prelucrării</strong><br>
        a) Operatorii vor asigura securitatea prelucrării datelor dvs. începând cu momentul conceperii acestora prin
        implementarea unor măsuri tehnice și organizatorice adecvate.<br>
        b) Operatorii vă vor informa, fără întârzieri nejustificate, în cazul în care o eventuală încălcare a
        securității datelor este susceptibilă să genereze un risc ridicat pentru drepturile și libertățile dvs., cu
        excepția cazurilor indicate în art. 34 din Regulament.<br><br>

        <strong>10. Procese decizionale automate</strong><br>
        operatorul poate utiliza procese automate de prelucrare a datelor cu caracter personal in vederea interogării /
        raportării in bazele de date menționate (Biroul de Credit, CRC, ANAF, alte baze de date publice – Ministerul de
        Justiție) urmărind crearea unui profil de risc al solicitantului in vederea realizării relației contractuale de
        creditare.<br><br>


        Continuarea accesului dumneavoastra la conținutul site-ului <strong>www.my.mobilize-fs.ro</strong> constituie un accept al
        politicii noastre de confidențialitate. Pentru orice sugestii sau reclamații cu privire la conținutul politicii
        de confidențialitate vă rugăm sa ne contacți la adresa de e-mail <a
        href="mailto: dataprotectionofficer-romania@mobilize-fs.com">dataprotectionofficer-romania&#64;mobilize-fs.com</a><br><br>


        Mobilize Finacial Services este denumirea comerciala sub care actioneaza societatile RCI LEASING ROMÂNIA IFN SA,
        RCI FINANTARE ROMANIA SRL si RCI BROKER DE ASIGURARE SRL<br><br>


        * versiune 22.03.2023

    </div>
</div>
