<div class="head" style="display: flex; align-items: center;">
    <img src="assets/images/mobilize-logo-small.png" alt="logo" width="150px">
    <a href="javascript: window.close()" class="mobilize-btn btn-orange">inchide</a>
</div>

<table border=0 cellpadding=0 cellspacing=0 width=2753 style='border-collapse:
 collapse;table-layout:fixed;width:2066pt'>
    <col width=251 style='mso-width-source:userset;mso-width-alt:9179;width:188pt'>
    <col width=371 style='mso-width-source:userset;mso-width-alt:13568;width:278pt'>
    <col width=150 style='mso-width-source:userset;mso-width-alt:5485;width:113pt'>
    <col width=123 style='mso-width-source:userset;mso-width-alt:4498;width:92pt'>
    <col width=251 style='mso-width-source:userset;mso-width-alt:9179;width:188pt'>
    <col width=505 style='mso-width-source:userset;mso-width-alt:18468;width:379pt'>
    <col width=193 style='mso-width-source:userset;mso-width-alt:7058;width:145pt'>
    <col width=162 style='mso-width-source:userset;mso-width-alt:5924;width:122pt'>
    <col width=140 style='mso-width-source:userset;mso-width-alt:5120;width:105pt'>
    <col width=84 style='mso-width-source:userset;mso-width-alt:3072;width:63pt'>
    <col width=96 style='mso-width-source:userset;mso-width-alt:3510;width:72pt'>
    <col width=234 style='mso-width-source:userset;mso-width-alt:8557;width:176pt'>
    <col width=193 style='mso-width-source:userset;mso-width-alt:7058;width:145pt'>
    <tr height=20 style='height:15.0pt'>
        <td colspan=8 height=20 class=xl90 width=2006 style='height:15.0pt;
  width:1505pt'>&nbsp;
        </td>
        <td class=xl70 width=140 style='width:105pt'>Marca</td>
        <td colspan=4 class=xl91 width=607 style='width:456pt'>Activitati punct de
            lucru
        </td>
    </tr>
    <tr class=xl89 height=20 style='mso-height-source:userset;height:15.0pt'>
        <td height=20 class=xl87 width=251 style='height:15.0pt;width:188pt'>Denumire
            Societate
        </td>
        <td class=xl87 width=371 style='border-left:none;width:278pt'>Adresa Sediu
            social
        </td>
        <td class=xl88 width=150 style='border-left:none;width:113pt'>Localitate
            Sediu social
        </td>
        <td class=xl88 width=123 style='border-left:none;width:92pt'>Judet Sediu
            social
        </td>
        <td class=xl88 width=251 style='border-left:none;width:188pt'>Denumire Punct
            de lucru
        </td>
        <td class=xl88 width=505 style='border-left:none;width:379pt'>Adresa Punct de
            lucru
        </td>
        <td class=xl88 width=193 style='border-left:none;width:145pt'>Localitate
            Punct de lucru
        </td>
        <td class=xl88 width=162 style='border-left:none;width:122pt'>Judet Punct de
            lucru
        </td>
        <td class=xl88 width=140 style='border-left:none;width:105pt'>Marca</td>
        <td class=xl88 width=84 style='border-left:none;width:63pt'>Mecanica</td>
        <td class=xl88 width=96 style='border-left:none;width:72pt'>Caroserie</td>
        <td class=xl88 width=234 style='border-left:none;width:176pt'>Reparatii
            vehicule electrice
        </td>
        <td class=xl88 width=193 style='border-left:none;width:145pt'>Reparatii
            baterii de tractiune
        </td>
    </tr>
    <tr class=xl83 height=20 style='mso-height-source:userset;height:15.0pt'>
        <td height=20 class=xl69 style='height:15.0pt;border-top:none'>Amat S.A.</td>
        <td class=xl69 style='border-top:none;border-left:none'>Bd. N. B&#259;lcescu
            nr. 204
        </td>
        <td class=xl68 width=150 style='border-top:none;border-left:none;width:113pt'>Pite&#351;ti</td>
        <td class=xl68 width=123 style='border-top:none;border-left:none;width:92pt'>Arge&#351;</td>
        <td class=xl69 style='border-top:none;border-left:none'>Amat S.A.</td>
        <td class=xl69 style='border-top:none;border-left:none'>Bd. N. B&#259;lcescu
            nr. 204
        </td>
        <td class=xl68 width=193 style='border-top:none;border-left:none;width:145pt'>Pite&#351;ti</td>
        <td class=xl68 width=162 style='border-top:none;border-left:none;width:122pt'>Arge&#351;</td>
        <td class=xl68 width=140 style='border-top:none;border-left:none;width:105pt'>DACIA</td>
        <td class=xl83 style='border-top:none;border-left:none'>Da</td>
        <td class=xl83 style='border-top:none;border-left:none'>Da</td>
        <td class=xl83 style='border-top:none;border-left:none'>Full Expert VE (cu
            demontare baterie)
        </td>
        <td class=xl83 style='border-top:none;border-left:none'>Nu</td>
    </tr>
    <tr class=xl84 height=20 style='mso-height-source:userset;height:15.0pt'>
        <td height=20 class=xl72 style='height:15.0pt;border-top:none'>Amat S.A.</td>
        <td class=xl72 style='border-top:none;border-left:none'>Bd. N. B<font
            class="font5">&#259;lcescu nr. 204</font></td>
        <td class=xl71 width=150 style='border-top:none;border-left:none;width:113pt'>Pite<font
            class="font5">&#351;ti</font></td>
        <td class=xl71 width=123 style='border-top:none;border-left:none;width:92pt'>Arge<font
            class="font5">&#351;</font></td>
        <td class=xl72 style='border-top:none;border-left:none'>Amat S.A.</td>
        <td class=xl72 style='border-top:none;border-left:none'>Bd. N. B<font
            class="font5">&#259;lcescu nr. 204</font></td>
        <td class=xl71 width=193 style='border-top:none;border-left:none;width:145pt'>Pite<font
            class="font5">&#351;ti</font></td>
        <td class=xl71 width=162 style='border-top:none;border-left:none;width:122pt'>Arge<font
            class="font5">&#351;</font></td>
        <td class=xl71 width=140 style='border-top:none;border-left:none;width:105pt'>RENAULT</td>
        <td class=xl83 style='border-left:none'>Da</td>
        <td class=xl83 style='border-left:none'>Da</td>
        <td class=xl83 style='border-left:none'>Full Expert E-TECH (cu demontare
            baterie)
        </td>
        <td class=xl83 style='border-left:none'>Nu</td>
    </tr>
    <tr class=xl84 height=20 style='mso-height-source:userset;height:15.0pt'>
        <td height=20 class=xl69 style='height:15.0pt'>Allmas Dacia Service SRL</td>
        <td class=xl69 style='border-left:none'>Str.&#350;tefan cel Mare nr.69 A</td>
        <td class=xl73 width=150 style='border-left:none;width:113pt'>V&#259;lenii de
            Munte
        </td>
        <td class=xl73 width=123 style='border-left:none;width:92pt'>Prahova</td>
        <td class=xl69 style='border-left:none'>Allmas Dacia Service SRL</td>
        <td class=xl69 style='border-left:none'>Str.&#350;tefan cel Mare nr.69 A</td>
        <td class=xl73 width=193 style='border-left:none;width:145pt'>V&#259;lenii de
            Munte
        </td>
        <td class=xl73 width=162 style='border-left:none;width:122pt'>Prahova</td>
        <td class=xl71 width=140 style='border-left:none;width:105pt'>DACIA</td>
        <td class=xl83 style='border-left:none'>Da</td>
        <td class=xl83 style='border-left:none'>Da</td>
        <td class=xl83 style='border-left:none'>Expert VE (fara demontare baterie)</td>
        <td class=xl83 style='border-left:none'>Nu</td>
    </tr>
    <tr class=xl84 height=20 style='mso-height-source:userset;height:15.0pt'>
        <td height=20 class=xl69 style='height:15.0pt;border-top:none'>Ana Autocenter
            SRL<span style='mso-spacerun:yes'> </span></td>
        <td class=xl69 style='border-top:none;border-left:none'>Str. Tecuciului nr.1</td>
        <td class=xl73 width=150 style='border-top:none;border-left:none;width:113pt'>Bârlad</td>
        <td class=xl73 width=123 style='border-top:none;border-left:none;width:92pt'>Vaslui</td>
        <td class=xl69 style='border-top:none;border-left:none'>Ana Autocenter
            SRL<span style='mso-spacerun:yes'> </span></td>
        <td class=xl69 style='border-top:none;border-left:none'>Str. Tecuciului nr.1</td>
        <td class=xl73 width=193 style='border-top:none;border-left:none;width:145pt'>Bârlad</td>
        <td class=xl73 width=162 style='border-top:none;border-left:none;width:122pt'>Vaslui</td>
        <td class=xl71 width=140 style='border-left:none;width:105pt'>DACIA</td>
        <td class=xl83 style='border-left:none'>Da</td>
        <td class=xl83 style='border-left:none'>Da</td>
        <td class=xl83 style='border-left:none'>Expert VE (fara demontare baterie)</td>
        <td class=xl83 style='border-left:none'>Nu</td>
    </tr>
    <tr class=xl84 height=20 style='mso-height-source:userset;height:15.0pt'>
        <td height=20 class=xl72 style='height:15.0pt;border-top:none'>Apan SRL</td>
        <td class=xl72 style='border-top:none;border-left:none'>D.N. 22 B, km. 4</td>
        <td class=xl74 width=150 style='border-left:none;width:113pt'>Br&#259;ila</td>
        <td class=xl74 width=123 style='border-left:none;width:92pt'>Br&#259;ila</td>
        <td class=xl72 style='border-top:none;border-left:none'>Apan SRL</td>
        <td class=xl72 style='border-top:none;border-left:none'>D.N. 22 B, km. 4</td>
        <td class=xl74 width=193 style='border-left:none;width:145pt'>Br&#259;ila</td>
        <td class=xl74 width=162 style='border-left:none;width:122pt'>Br&#259;ila</td>
        <td class=xl71 width=140 style='border-left:none;width:105pt'>DACIA</td>
        <td class=xl83 style='border-left:none'>Da</td>
        <td class=xl83 style='border-left:none'>Da</td>
        <td class=xl83 style='border-left:none'>Full Expert VE (cu demontare baterie)</td>
        <td class=xl83 style='border-left:none'>Nu</td>
    </tr>
    <tr class=xl84 height=20 style='mso-height-source:userset;height:15.0pt'>
        <td height=20 class=xl72 style='height:15.0pt'>Apan SRL</td>
        <td class=xl72 style='border-left:none'>D.N. 22 B, km. 4</td>
        <td class=xl71 width=150 style='border-top:none;border-left:none;width:113pt'>Br&#259;ila</td>
        <td class=xl71 width=123 style='border-top:none;border-left:none;width:92pt'>Br&#259;ila</td>
        <td class=xl72 style='border-left:none'>Apan SRL</td>
        <td class=xl72 style='border-left:none'>D.N. 22 B, km. 4</td>
        <td class=xl71 width=193 style='border-top:none;border-left:none;width:145pt'>Br&#259;ila</td>
        <td class=xl71 width=162 style='border-top:none;border-left:none;width:122pt'>Br&#259;ila</td>
        <td class=xl71 width=140 style='border-left:none;width:105pt'>RENAULT</td>
        <td class=xl83 style='border-left:none'>Da</td>
        <td class=xl83 style='border-left:none'>Da</td>
        <td class=xl83 style='border-left:none'>Full Expert E-TECH (cu demontare
            baterie
        </td>
        <td class=xl83 style='border-left:none'>Nu</td>
    </tr>
    <tr class=xl84 height=20 style='mso-height-source:userset;height:15.0pt'>
        <td height=20 class=xl72 style='height:15.0pt'>Apan SRL</td>
        <td class=xl72 style='border-left:none'>D.N. 22 B, km. 4</td>
        <td class=xl71 width=150 style='border-left:none;width:113pt'>Br&#259;ila</td>
        <td class=xl71 width=123 style='border-left:none;width:92pt'>Br&#259;ila</td>
        <td class=xl72 style='border-left:none'>Apan SRL</td>
        <td class=xl72 style='border-left:none'>D.N. 22 B, km. 4</td>
        <td class=xl71 width=193 style='border-left:none;width:145pt'>Br&#259;ila</td>
        <td class=xl71 width=162 style='border-left:none;width:122pt'>Br&#259;ila</td>
        <td class=xl71 width=140 style='border-left:none;width:105pt'>NISSAN</td>
        <td class=xl83 style='border-left:none'>Da</td>
        <td class=xl83 style='border-left:none'>Da</td>
        <td class=xl83 style='border-left:none'>Da</td>
        <td class=xl83 style='border-left:none'>Nu</td>
    </tr>
    <tr class=xl84 height=20 style='mso-height-source:userset;height:15.0pt'>
        <td height=20 class=xl69 style='height:15.0pt'>Apan SRL</td>
        <td class=xl69 style='border-left:none'>D.N. 22 B, km. 4</td>
        <td class=xl68 width=150 style='border-left:none;width:113pt'>Br&#259;ila</td>
        <td class=xl68 width=123 style='border-left:none;width:92pt'>Br&#259;ila</td>
        <td class=xl72 style='border-left:none'>Apan SRL</td>
        <td class=xl69 style='border-left:none'>Str. George Co&#351;buc nr. 148</td>
        <td class=xl69 style='border-left:none'>Gala&#355;i</td>
        <td class=xl69 style='border-left:none'>Gala&#355;i</td>
        <td class=xl68 width=140 style='border-left:none;width:105pt'>DACIA</td>
        <td class=xl85 style='border-left:none'>Nu</td>
        <td class=xl85 style='border-left:none'>Nu</td>
        <td class=xl85 style='border-left:none'>Nu</td>
        <td class=xl85 style='border-left:none'>Nu</td>
    </tr>
    <tr class=xl84 height=20 style='mso-height-source:userset;height:15.0pt'>
        <td height=20 class=xl72 style='height:15.0pt;border-top:none'>Apan SRL</td>
        <td class=xl72 style='border-top:none;border-left:none'>D.N. 22 B, km. 4</td>
        <td class=xl71 width=150 style='border-top:none;border-left:none;width:113pt'>Br&#259;ila</td>
        <td class=xl71 width=123 style='border-top:none;border-left:none;width:92pt'>Br&#259;ila</td>
        <td class=xl72 style='border-left:none'>Apan SRL</td>
        <td class=xl69 style='border-top:none;border-left:none'>Str. George
            Co&#351;buc nr. 148
        </td>
        <td class=xl69 style='border-top:none;border-left:none'>Gala&#355;i</td>
        <td class=xl69 style='border-top:none;border-left:none'>Gala&#355;i</td>
        <td class=xl71 width=140 style='border-top:none;border-left:none;width:105pt'>RENAULT</td>
        <td class=xl83 style='border-top:none;border-left:none'>Da</td>
        <td class=xl83 style='border-top:none;border-left:none'>Nu</td>
        <td class=xl83 style='border-top:none;border-left:none'>Nu</td>
        <td class=xl83 style='border-top:none;border-left:none'>Nu</td>
    </tr>
    <tr class=xl84 height=20 style='mso-height-source:userset;height:15.0pt'>
        <td height=20 class=xl69 style='height:15.0pt'>Apan SRL</td>
        <td class=xl69 style='border-left:none'>D.N. 22 B, km. 4</td>
        <td class=xl68 width=150 style='border-left:none;width:113pt'>Br&#259;ila</td>
        <td class=xl68 width=123 style='border-left:none;width:92pt'>Br&#259;ila</td>
        <td class=xl72 style='border-left:none'>Apan SRL</td>
        <td class=xl69 style='border-top:none;border-left:none'>Str. Drumul Viilor
            nr. 17 - 19
        </td>
        <td class=xl69 style='border-top:none;border-left:none'>Gala&#355;i</td>
        <td class=xl69 style='border-top:none;border-left:none'>Gala&#355;i</td>
        <td class=xl68 width=140 style='border-left:none;width:105pt'>DACIA</td>
        <td class=xl85 style='border-left:none'>Da</td>
        <td class=xl85 style='border-left:none'>Da</td>
        <td class=xl85 style='border-left:none'>Nu</td>
        <td class=xl85 style='border-left:none'>Nu</td>
    </tr>
    <tr class=xl84 height=20 style='mso-height-source:userset;height:15.0pt'>
        <td height=20 class=xl69 style='height:15.0pt;border-top:none'>Apan SRL</td>
        <td class=xl69 style='border-top:none;border-left:none'>D.N. 22 B, km. 4</td>
        <td class=xl68 width=150 style='border-top:none;border-left:none;width:113pt'>Br&#259;ila</td>
        <td class=xl68 width=123 style='border-top:none;border-left:none;width:92pt'>Br&#259;ila</td>
        <td class=xl72 style='border-left:none'>Apan SRL</td>
        <td class=xl69 style='border-top:none;border-left:none'>Str. Drumul Viilor
            nr. 17 - 19
        </td>
        <td class=xl69 style='border-top:none;border-left:none'>Gala&#355;i</td>
        <td class=xl69 style='border-top:none;border-left:none'>Gala&#355;i</td>
        <td class=xl68 width=140 style='border-top:none;border-left:none;width:105pt'>RENAULT</td>
        <td class=xl85 style='border-top:none;border-left:none'>Nu</td>
        <td class=xl85 style='border-top:none;border-left:none'>Da</td>
        <td class=xl85 style='border-top:none;border-left:none'>Nu</td>
        <td class=xl85 style='border-top:none;border-left:none'>Nu</td>
    </tr>
    <tr class=xl84 height=20 style='mso-height-source:userset;height:15.0pt'>
        <td height=20 class=xl72 style='height:15.0pt;border-top:none'>Apan SRL</td>
        <td class=xl72 style='border-top:none;border-left:none'>D.N. 22 B, km. 4</td>
        <td class=xl71 width=150 style='border-top:none;border-left:none;width:113pt'>Br&#259;ila</td>
        <td class=xl71 width=123 style='border-top:none;border-left:none;width:92pt'>Br&#259;ila</td>
        <td class=xl72 style='border-left:none'>Apan SRL</td>
        <td class=xl69 style='border-top:none;border-left:none'>Str. Drumul Viilor
            nr. 17 - 19
        </td>
        <td class=xl69 style='border-top:none;border-left:none'>Gala&#355;i</td>
        <td class=xl69 style='border-top:none;border-left:none'>Gala&#355;i</td>
        <td class=xl71 width=140 style='border-top:none;border-left:none;width:105pt'>NISSAN</td>
        <td class=xl83 style='border-top:none;border-left:none'>Da</td>
        <td class=xl83 style='border-top:none;border-left:none'>Da</td>
        <td class=xl83 style='border-top:none;border-left:none'>Nu</td>
        <td class=xl83 style='border-top:none;border-left:none'>Nu</td>
    </tr>
    <tr class=xl84 height=20 style='mso-height-source:userset;height:15.0pt'>
        <td height=20 class=xl72 style='height:15.0pt'>Aurocar 2002 SRL</td>
        <td class=xl72 style='border-left:none'>Str. Alexandru Ioan Cuza, Nr.31E</td>
        <td class=xl74 width=150 style='border-left:none;width:113pt'>Alba Iulia</td>
        <td class=xl74 width=123 style='border-left:none;width:92pt'>Alba</td>
        <td class=xl72 style='border-left:none'>Aurocar 2002 SRL</td>
        <td class=xl72 style='border-top:none;border-left:none'>Str. Alexandru Ioan
            Cuza, Nr.31E
        </td>
        <td class=xl74 width=193 style='border-left:none;width:145pt'>Alba Iulia</td>
        <td class=xl74 width=162 style='border-left:none;width:122pt'>Alba</td>
        <td class=xl71 width=140 style='border-left:none;width:105pt'>DACIA</td>
        <td class=xl83 style='border-left:none'>Da</td>
        <td class=xl83 style='border-left:none'>Da</td>
        <td class=xl83 style='border-left:none'>Full Expert VE (cu demontare baterie)</td>
        <td class=xl83 style='border-left:none'>Nu</td>
    </tr>
    <tr class=xl84 height=20 style='mso-height-source:userset;height:15.0pt'>
        <td height=20 class=xl72 style='height:15.0pt'>Aurocar 2002 SRL</td>
        <td class=xl72 style='border-left:none'>Str. Alexandru Ioan Cuza, Nr.31E</td>
        <td class=xl71 width=150 style='border-top:none;border-left:none;width:113pt'>Alba
            Iulia
        </td>
        <td class=xl71 width=123 style='border-top:none;border-left:none;width:92pt'>Alba</td>
        <td class=xl72 style='border-left:none'>Aurocar 2002 SRL</td>
        <td class=xl72 style='border-left:none'>Str. Alexandru Ioan Cuza, Nr.31E</td>
        <td class=xl71 width=193 style='border-top:none;border-left:none;width:145pt'>Alba
            Iulia
        </td>
        <td class=xl71 width=162 style='border-top:none;border-left:none;width:122pt'>Alba</td>
        <td class=xl71 width=140 style='border-left:none;width:105pt'>RENAULT</td>
        <td class=xl83 style='border-left:none'>Da</td>
        <td class=xl83 style='border-left:none'>Da</td>
        <td class=xl83 style='border-left:none'>Full Expert E-TECH (cu demontare
            baterie)
        </td>
        <td class=xl83 style='border-left:none'>Nu</td>
    </tr>
    <tr class=xl84 height=20 style='mso-height-source:userset;height:15.0pt'>
        <td height=20 class=xl72 style='height:15.0pt'>Aurocar 2002 SRL</td>
        <td class=xl72 style='border-left:none'>Str. Alexandru Ioan Cuza, Nr.31E</td>
        <td class=xl71 width=150 style='border-left:none;width:113pt'>Alba Iulia</td>
        <td class=xl71 width=123 style='border-left:none;width:92pt'>Alba</td>
        <td class=xl72 style='border-left:none'>Aurocar 2002 SRL</td>
        <td class=xl75 style='border-left:none'>Str. Unirii, nr.30</td>
        <td class=xl74 width=193 style='border-left:none;width:145pt'>Ora&#351;tie</td>
        <td class=xl74 width=162 style='border-left:none;width:122pt'>Hunedoara</td>
        <td class=xl71 width=140 style='border-left:none;width:105pt'>DACIA</td>
        <td class=xl83 style='border-left:none'>Nu</td>
        <td class=xl83 style='border-left:none'>Nu</td>
        <td class=xl83 style='border-left:none'>Nu</td>
        <td class=xl83 style='border-left:none'>Nu</td>
    </tr>
    <tr class=xl84 height=20 style='mso-height-source:userset;height:15.0pt'>
        <td height=20 class=xl72 style='height:15.0pt'>Aurocar Serv SRL</td>
        <td class=xl72 style='border-left:none'>Str. Unirii, nr.30</td>
        <td class=xl73 width=150 style='border-left:none;width:113pt'>Or&#259;&#351;tie</td>
        <td class=xl73 width=123 style='border-left:none;width:92pt'>Hunedoara</td>
        <td class=xl72 style='border-left:none'>Aurocar Serv SRL</td>
        <td class=xl72 style='border-top:none;border-left:none'>Str. Unirii, nr.30</td>
        <td class=xl73 width=193 style='border-top:none;border-left:none;width:145pt'>Or&#259;&#351;tie</td>
        <td class=xl73 width=162 style='border-top:none;border-left:none;width:122pt'>Hunedoara</td>
        <td class=xl71 width=140 style='border-left:none;width:105pt'>DACIA</td>
        <td class=xl83 style='border-left:none'>Da</td>
        <td class=xl83 style='border-left:none'>Da</td>
        <td class=xl83 style='border-left:none'>Expert VE (fara demontare baterie)</td>
        <td class=xl83 style='border-left:none'>Nu</td>
    </tr>
    <tr class=xl84 height=20 style='mso-height-source:userset;height:15.0pt'>
        <td height=20 class=xl72 style='height:15.0pt'>Aurocar Serv SRL</td>
        <td class=xl72 style='border-left:none'>Str. Unirii, nr.30</td>
        <td class=xl71 width=150 style='border-top:none;border-left:none;width:113pt'>Or&#259;&#351;tie</td>
        <td class=xl71 width=123 style='border-top:none;border-left:none;width:92pt'>Hunedoara</td>
        <td class=xl72 style='border-left:none'>Aurocar Serv SRL</td>
        <td class=xl69 style='border-left:none'>Santuhalm, Calea Hunedoarei, nr. 3</td>
        <td class=xl73 width=193 style='border-top:none;border-left:none;width:145pt'>Deva</td>
        <td class=xl73 width=162 style='border-top:none;border-left:none;width:122pt'>Hunedoara</td>
        <td class=xl71 width=140 style='border-left:none;width:105pt'>DACIA</td>
        <td class=xl83 style='border-left:none'>Da</td>
        <td class=xl83 style='border-left:none'>Nu</td>
        <td class=xl83 style='border-left:none'>Nu</td>
        <td class=xl83 style='border-left:none'>Nu</td>
    </tr>
    <tr class=xl84 height=20 style='mso-height-source:userset;height:15.0pt'>
        <td height=20 class=xl72 style='height:15.0pt'>Auto Bara &amp; Co SRL</td>
        <td class=xl72 style='border-left:none'>&#350;oseaua Bor&#351;ului, nr. 22</td>
        <td class=xl73 width=150 style='border-left:none;width:113pt'>Oradea</td>
        <td class=xl73 width=123 style='border-left:none;width:92pt'>Bihor</td>
        <td class=xl72 style='border-left:none'>Auto Bara &amp; Co SRL</td>
        <td class=xl72 style='border-top:none;border-left:none'>&#350;oseaua
            Bor&#351;ului, nr. 22
        </td>
        <td class=xl73 width=193 style='border-top:none;border-left:none;width:145pt'>Oradea</td>
        <td class=xl73 width=162 style='border-top:none;border-left:none;width:122pt'>Bihor</td>
        <td class=xl71 width=140 style='border-left:none;width:105pt'>DACIA</td>
        <td class=xl83 style='border-left:none'>Da</td>
        <td class=xl83 style='border-left:none'>Da</td>
        <td class=xl83 style='border-left:none'>Full Expert VE (cu demontare baterie)</td>
        <td class=xl83 style='border-left:none'>Nu</td>
    </tr>
    <tr class=xl84 height=20 style='mso-height-source:userset;height:15.0pt'>
        <td height=20 class=xl72 style='height:15.0pt'>Auto Bara &amp; Co SRL</td>
        <td class=xl72 style='border-left:none'>&#350;oseaua Bor&#351;ului, nr. 22</td>
        <td class=xl71 width=150 style='border-top:none;border-left:none;width:113pt'>Oradea</td>
        <td class=xl71 width=123 style='border-top:none;border-left:none;width:92pt'>Bihor</td>
        <td class=xl72 style='border-left:none'>Auto Bara &amp; Co SRL</td>
        <td class=xl72 style='border-left:none'>&#350;oseaua Bor&#351;ului, nr. 22</td>
        <td class=xl71 width=193 style='border-top:none;border-left:none;width:145pt'>Oradea</td>
        <td class=xl71 width=162 style='border-top:none;border-left:none;width:122pt'>Bihor</td>
        <td class=xl71 width=140 style='border-left:none;width:105pt'>RENAULT</td>
        <td class=xl83 style='border-left:none'>Da</td>
        <td class=xl83 style='border-left:none'>Da</td>
        <td class=xl83 style='border-left:none'>Full Expert E-TECH (cu demontare
            baterie)
        </td>
        <td class=xl83 style='border-left:none'>Nu</td>
    </tr>
    <tr class=xl84 height=20 style='mso-height-source:userset;height:15.0pt'>
        <td height=20 class=xl72 style='height:15.0pt'>Auto Bara &amp; Co SRL</td>
        <td class=xl72 style='border-left:none'>&#350;oseaua Bor&#351;ului, nr. 22</td>
        <td class=xl71 width=150 style='border-left:none;width:113pt'>Oradea</td>
        <td class=xl71 width=123 style='border-left:none;width:92pt'>Bihor</td>
        <td class=xl72 style='border-left:none'>Auto Bara &amp; Co SRL</td>
        <td class=xl72 style='border-left:none'>&#350;oseaua Bor&#351;ului, nr. 22</td>
        <td class=xl71 width=193 style='border-left:none;width:145pt'>Oradea</td>
        <td class=xl71 width=162 style='border-left:none;width:122pt'>Bihor</td>
        <td class=xl71 width=140 style='border-left:none;width:105pt'>NISSAN</td>
        <td class=xl83 style='border-left:none'>Da</td>
        <td class=xl83 style='border-left:none'>Da</td>
        <td class=xl83 style='border-left:none'>Da</td>
        <td class=xl83 style='border-left:none'>Nu</td>
    </tr>
    <tr class=xl84 height=20 style='mso-height-source:userset;height:15.0pt'>
        <td height=20 class=xl72 style='height:15.0pt'>Auto Becoro SRL</td>
        <td class=xl72 style='border-left:none'>Bd. Independen&#355;ei nr.32</td>
        <td class=xl74 width=150 style='border-left:none;width:113pt'>Baia Mare</td>
        <td class=xl74 width=123 style='border-left:none;width:92pt'>Maramure&#351;</td>
        <td class=xl72 style='border-left:none'>Auto Becoro SRL</td>
        <td class=xl72 style='border-left:none'>Bd. Independen&#355;ei nr.32</td>
        <td class=xl74 width=193 style='border-left:none;width:145pt'>Baia Mare</td>
        <td class=xl74 width=162 style='border-left:none;width:122pt'>Maramure&#351;</td>
        <td class=xl71 width=140 style='border-left:none;width:105pt'>DACIA</td>
        <td class=xl83 style='border-left:none'>Da</td>
        <td class=xl83 style='border-left:none'>Da</td>
        <td class=xl83 style='border-left:none'>Full Expert VE (cu demontare baterie)</td>
        <td class=xl83 style='border-left:none'>Nu</td>
    </tr>
    <tr class=xl84 height=20 style='mso-height-source:userset;height:15.0pt'>
        <td height=20 class=xl72 style='height:15.0pt'>Auto Becoro SRL</td>
        <td class=xl72 style='border-left:none'>Bd. Independen&#355;ei nr.32</td>
        <td class=xl71 width=150 style='border-top:none;border-left:none;width:113pt'>Baia
            Mare
        </td>
        <td class=xl71 width=123 style='border-top:none;border-left:none;width:92pt'>Maramure&#351;</td>
        <td class=xl72 style='border-left:none'>Auto Becoro SRL</td>
        <td class=xl72 style='border-left:none'>Bd. Independen&#355;ei nr.32</td>
        <td class=xl71 width=193 style='border-top:none;border-left:none;width:145pt'>Baia
            Mare
        </td>
        <td class=xl71 width=162 style='border-top:none;border-left:none;width:122pt'>Maramure&#351;</td>
        <td class=xl71 width=140 style='border-left:none;width:105pt'>RENAULT</td>
        <td class=xl83 style='border-left:none'>Da</td>
        <td class=xl83 style='border-left:none'>Da</td>
        <td class=xl83 style='border-left:none'>Full Expert E-TECH (cu demontare
            baterie)
        </td>
        <td class=xl83 style='border-left:none'>Nu</td>
    </tr>
    <tr class=xl84 height=20 style='mso-height-source:userset;height:15.0pt'>
        <td height=20 class=xl72 style='height:15.0pt'>Auto Becoro SRL</td>
        <td class=xl72 style='border-left:none'>Bd. Independen&#355;ei nr.32</td>
        <td class=xl71 width=150 style='border-left:none;width:113pt'>Baia Mare</td>
        <td class=xl71 width=123 style='border-left:none;width:92pt'>Maramure&#351;</td>
        <td class=xl72 style='border-left:none'>Auto Becoro SRL</td>
        <td class=xl72 style='border-left:none'>Bd. Independen&#355;ei nr.32</td>
        <td class=xl71 width=193 style='border-left:none;width:145pt'>Baia Mare</td>
        <td class=xl71 width=162 style='border-left:none;width:122pt'>Maramure&#351;</td>
        <td class=xl71 width=140 style='border-left:none;width:105pt'>NISSAN</td>
        <td class=xl83 style='border-left:none'>Da</td>
        <td class=xl83 style='border-left:none'>Da</td>
        <td class=xl83 style='border-left:none'>Da</td>
        <td class=xl83 style='border-left:none'>Nu</td>
    </tr>
    <tr class=xl84 height=20 style='mso-height-source:userset;height:15.0pt'>
        <td height=20 class=xl72 style='height:15.0pt'>Auto Becoro SRL</td>
        <td class=xl72 style='border-left:none'>Bd. Independen&#355;ei nr.32</td>
        <td class=xl71 width=150 style='border-left:none;width:113pt'>Baia Mare</td>
        <td class=xl71 width=123 style='border-left:none;width:92pt'>Maramure&#351;</td>
        <td class=xl72 style='border-left:none'>Auto Becoro SRL</td>
        <td class=xl69 style='border-left:none'>Sat Tisa nr. 383B</td>
        <td class=xl73 width=193 style='border-left:none;width:145pt'>Com. Bocicoiu
            Mare
        </td>
        <td class=xl73 width=162 style='border-left:none;width:122pt'>Maramure&#351;</td>
        <td class=xl71 width=140 style='border-left:none;width:105pt'>DACIA</td>
        <td class=xl83 style='border-left:none'>Da</td>
        <td class=xl83 style='border-left:none'>Da</td>
        <td class=xl83 style='border-left:none'>Expert VE (fara demontare baterie)</td>
        <td class=xl83 style='border-left:none'>Nu</td>
    </tr>
    <tr class=xl84 height=20 style='mso-height-source:userset;height:15.0pt'>
        <td height=20 class=xl72 style='height:15.0pt'>Auto Class S.A.</td>
        <td class=xl72 style='border-left:none'>Str. Botizului nr. 43/A</td>
        <td class=xl75 style='border-left:none'>Satu Mare</td>
        <td class=xl75 style='border-left:none'>Satu Mare</td>
        <td class=xl72 style='border-left:none'>Auto Class S.A.</td>
        <td class=xl72 style='border-top:none;border-left:none'>Str. Botizului nr.
            43/A
        </td>
        <td class=xl75 style='border-left:none'>Satu Mare</td>
        <td class=xl75 style='border-left:none'>Satu Mare</td>
        <td class=xl71 width=140 style='border-left:none;width:105pt'>DACIA</td>
        <td class=xl83 style='border-left:none'>Da</td>
        <td class=xl83 style='border-left:none'>Da</td>
        <td class=xl83 style='border-left:none'>Expert VE (fara demontare baterie)</td>
        <td class=xl83 style='border-left:none'>Nu</td>
    </tr>
    <tr class=xl84 height=20 style='mso-height-source:userset;height:15.0pt'>
        <td height=20 class=xl72 style='height:15.0pt'>Auto Class S.A.</td>
        <td class=xl72 style='border-left:none'>Str. Botizului nr. 43/A</td>
        <td class=xl71 width=150 style='border-top:none;border-left:none;width:113pt'>Satu
            Mare
        </td>
        <td class=xl71 width=123 style='border-top:none;border-left:none;width:92pt'>Satu
            Mare
        </td>
        <td class=xl72 style='border-left:none'>Auto Class S.A.</td>
        <td class=xl72 style='border-left:none'>Str. Botizului nr. 43/A</td>
        <td class=xl71 width=193 style='border-top:none;border-left:none;width:145pt'>Satu
            Mare
        </td>
        <td class=xl71 width=162 style='border-top:none;border-left:none;width:122pt'>Satu
            Mare
        </td>
        <td class=xl71 width=140 style='border-left:none;width:105pt'>RENAULT</td>
        <td class=xl83 style='border-left:none'>Da</td>
        <td class=xl83 style='border-left:none'>Da</td>
        <td class=xl83 style='border-left:none'>Expert E-TECH (fara demontare
            baterie)
        </td>
        <td class=xl83 style='border-left:none'>Nu</td>
    </tr>
    <tr class=xl84 height=20 style='mso-height-source:userset;height:15.0pt'>
        <td height=20 class=xl72 style='height:15.0pt'>Auto Cob&#259;lcescu SRL</td>
        <td class=xl72 style='border-left:none'>Str. Splaiul Unirii, nr. 311, sector
            3
        </td>
        <td class=xl74 width=150 style='border-left:none;width:113pt'>Bucure&#351;ti</td>
        <td class=xl74 width=123 style='border-left:none;width:92pt'>Bucure&#351;ti</td>
        <td class=xl72 style='border-left:none'>Auto Cob&#259;lcescu SRL</td>
        <td class=xl72 style='border-left:none'>Str. Splaiul Unirii, nr. 311, sector
            3
        </td>
        <td class=xl74 width=193 style='border-left:none;width:145pt'>Bucure&#351;ti</td>
        <td class=xl74 width=162 style='border-left:none;width:122pt'>Bucure&#351;ti</td>
        <td class=xl71 width=140 style='border-left:none;width:105pt'>DACIA</td>
        <td class=xl83 style='border-left:none'>Da</td>
        <td class=xl83 style='border-left:none'>Da</td>
        <td class=xl83 style='border-left:none'>Full Expert VE (cu demontare baterie)</td>
        <td class=xl83 style='border-left:none'>Da</td>
    </tr>
    <tr class=xl84 height=20 style='mso-height-source:userset;height:15.0pt'>
        <td height=20 class=xl72 style='height:15.0pt'>Auto Cob&#259;lcescu SRL</td>
        <td class=xl72 style='border-left:none'>Str. Splaiul Unirii, nr. 311, sector
            3
        </td>
        <td class=xl71 width=150 style='border-top:none;border-left:none;width:113pt'>Bucure&#351;ti</td>
        <td class=xl71 width=123 style='border-top:none;border-left:none;width:92pt'>Bucure&#351;ti</td>
        <td class=xl72 style='border-left:none'>Auto Cob&#259;lcescu SRL</td>
        <td class=xl72 style='border-left:none'>Str. Splaiul Unirii, nr. 311, sector
            3
        </td>
        <td class=xl71 width=193 style='border-top:none;border-left:none;width:145pt'>Bucure&#351;ti</td>
        <td class=xl71 width=162 style='border-top:none;border-left:none;width:122pt'>Bucure&#351;ti</td>
        <td class=xl71 width=140 style='border-left:none;width:105pt'>RENAULT</td>
        <td class=xl83 style='border-left:none'>Da</td>
        <td class=xl83 style='border-left:none'>Da</td>
        <td class=xl83 style='border-left:none'>Full Expert E-TECH (cu demontare
            baterie)
        </td>
        <td class=xl83 style='border-left:none'>Da</td>
    </tr>
    <tr class=xl84 height=20 style='mso-height-source:userset;height:15.0pt'>
        <td height=20 class=xl72 style='height:15.0pt'>Auto Cob&#259;lcescu SRL</td>
        <td class=xl72 style='border-left:none'>Str. Splaiul Unirii, nr. 311, sector
            3
        </td>
        <td class=xl71 width=150 style='border-left:none;width:113pt'>Bucure&#351;ti</td>
        <td class=xl71 width=123 style='border-left:none;width:92pt'>Bucure&#351;ti</td>
        <td class=xl72 style='border-left:none'>Auto Cob&#259;lcescu SRL</td>
        <td class=xl72 style='border-left:none'>Str. Splaiul Unirii, nr. 311, sector
            3
        </td>
        <td class=xl71 width=193 style='border-left:none;width:145pt'>Bucure&#351;ti</td>
        <td class=xl71 width=162 style='border-left:none;width:122pt'>Bucure&#351;ti</td>
        <td class=xl71 width=140 style='border-left:none;width:105pt'>NISSAN</td>
        <td class=xl83 style='border-left:none'>Da</td>
        <td class=xl83 style='border-left:none'>Da</td>
        <td class=xl83 style='border-left:none'>Da</td>
        <td class=xl83 style='border-left:none'>Da</td>
    </tr>
    <tr class=xl84 height=20 style='mso-height-source:userset;height:15.0pt'>
        <td height=20 class=xl72 style='height:15.0pt'>Auto Europa SRL</td>
        <td class=xl72 style='border-left:none'>Calea &#350;agului nr.142/A</td>
        <td class=xl73 width=150 style='border-left:none;width:113pt'>Timi&#351;oara</td>
        <td class=xl73 width=123 style='border-left:none;width:92pt'>Timi&#351;</td>
        <td class=xl72 style='border-left:none'>Auto Europa SRL</td>
        <td class=xl72 style='border-left:none'>Calea &#350;agului nr.142/A</td>
        <td class=xl73 width=193 style='border-left:none;width:145pt'>Timi&#351;oara</td>
        <td class=xl73 width=162 style='border-left:none;width:122pt'>Timi&#351;</td>
        <td class=xl71 width=140 style='border-left:none;width:105pt'>DACIA</td>
        <td class=xl83 style='border-left:none'>Da</td>
        <td class=xl83 style='border-left:none'>Da</td>
        <td class=xl83 style='border-left:none'>Full Expert VE (cu demontare baterie)</td>
        <td class=xl83 style='border-left:none'>Nu</td>
    </tr>
    <tr class=xl84 height=20 style='mso-height-source:userset;height:15.0pt'>
        <td height=20 class=xl72 style='height:15.0pt'>Auto Europa SRL</td>
        <td class=xl72 style='border-left:none'>Calea &#350;agului nr.142/A</td>
        <td class=xl71 width=150 style='border-top:none;border-left:none;width:113pt'>Timi&#351;oara</td>
        <td class=xl71 width=123 style='border-top:none;border-left:none;width:92pt'>Timi&#351;</td>
        <td class=xl72 style='border-left:none'>Auto Europa SRL</td>
        <td class=xl72 style='border-left:none'>Calea &#350;agului nr.142/A</td>
        <td class=xl71 width=193 style='border-top:none;border-left:none;width:145pt'>Timi&#351;oara</td>
        <td class=xl71 width=162 style='border-top:none;border-left:none;width:122pt'>Timi&#351;</td>
        <td class=xl71 width=140 style='border-left:none;width:105pt'>RENAULT</td>
        <td class=xl83 style='border-left:none'>Da</td>
        <td class=xl83 style='border-left:none'>Da</td>
        <td class=xl83 style='border-left:none'>Full Expert E-TECH (cu demontare
            baterie)
        </td>
        <td class=xl83 style='border-left:none'>Nu</td>
    </tr>
    <tr class=xl84 height=20 style='mso-height-source:userset;height:15.0pt'>
        <td height=20 class=xl72 style='height:15.0pt'>Auto Europa SRL</td>
        <td class=xl72 style='border-left:none'>Calea &#350;agului nr.142/A</td>
        <td class=xl71 width=150 style='border-left:none;width:113pt'>Timi&#351;oara</td>
        <td class=xl71 width=123 style='border-left:none;width:92pt'>Timi&#351;</td>
        <td class=xl72 style='border-left:none'>Auto Europa SRL</td>
        <td class=xl72 style='border-left:none'>Calea &#350;agului nr.142/A</td>
        <td class=xl71 width=193 style='border-left:none;width:145pt'>Timi&#351;oara</td>
        <td class=xl71 width=162 style='border-left:none;width:122pt'>Timi&#351;</td>
        <td class=xl71 width=140 style='border-left:none;width:105pt'>NISSAN</td>
        <td class=xl83 style='border-left:none'>Nu</td>
        <td class=xl83 style='border-left:none'>Da</td>
        <td class=xl83 style='border-left:none'>Nu</td>
        <td class=xl83 style='border-left:none'>Nu</td>
    </tr>
    <tr class=xl84 height=20 style='mso-height-source:userset;height:15.0pt'>
        <td height=20 class=xl72 style='height:15.0pt'>Auto Europa SRL</td>
        <td class=xl72 style='border-left:none'>Calea &#350;agului nr.142/A</td>
        <td class=xl71 width=150 style='border-left:none;width:113pt'>Timi&#351;oara</td>
        <td class=xl71 width=123 style='border-left:none;width:92pt'>Timi&#351;</td>
        <td class=xl72 style='border-left:none'>Auto Europa SRL</td>
        <td class=xl75 style='border-left:none'>Str. Miresei Nr 1</td>
        <td class=xl71 width=193 style='border-left:none;width:145pt'>Timi&#351;oara</td>
        <td class=xl71 width=162 style='border-left:none;width:122pt'>Timi&#351;</td>
        <td class=xl71 width=140 style='border-left:none;width:105pt'>DACIA</td>
        <td class=xl83 style='border-left:none'>Da</td>
        <td class=xl83 style='border-left:none'>Nu</td>
        <td class=xl83 style='border-left:none'>Full Expert VE (cu demontare baterie)</td>
        <td class=xl83 style='border-left:none'>Nu</td>
    </tr>
    <tr class=xl84 height=20 style='mso-height-source:userset;height:15.0pt'>
        <td height=20 class=xl72 style='height:15.0pt'>Auto Europa SRL</td>
        <td class=xl72 style='border-left:none'>Calea &#350;agului nr.142/A</td>
        <td class=xl71 width=150 style='border-left:none;width:113pt'>Timi&#351;oara</td>
        <td class=xl71 width=123 style='border-left:none;width:92pt'>Timi&#351;</td>
        <td class=xl72 style='border-left:none'>Auto Europa SRL</td>
        <td class=xl75 style='border-left:none'>Str. Miresei Nr 1</td>
        <td class=xl71 width=193 style='border-left:none;width:145pt'>Timi&#351;oara</td>
        <td class=xl71 width=162 style='border-left:none;width:122pt'>Timi&#351;</td>
        <td class=xl71 width=140 style='border-left:none;width:105pt'>NISSAN</td>
        <td class=xl83 style='border-left:none'>Da</td>
        <td class=xl83 style='border-left:none'>Nu</td>
        <td class=xl83 style='border-left:none'>Da</td>
        <td class=xl83 style='border-left:none'>Da</td>
    </tr>
    <tr class=xl84 height=20 style='mso-height-source:userset;height:15.0pt'>
        <td height=20 class=xl72 style='height:15.0pt'>Auto Group SRL<span
            style='mso-spacerun:yes'> </span></td>
        <td class=xl72 style='border-left:none'>Calea Caransebe&#351;ului nr 21</td>
        <td class=xl74 width=150 style='border-left:none;width:113pt'>Resita</td>
        <td class=xl74 width=123 style='border-left:none;width:92pt'>Caras-Severin</td>
        <td class=xl72 style='border-left:none'>Auto Group SRL<span
            style='mso-spacerun:yes'> </span></td>
        <td class=xl72 style='border-top:none;border-left:none'>Calea
            Caransebe&#351;ului nr 21
        </td>
        <td class=xl74 width=193 style='border-left:none;width:145pt'>Resita</td>
        <td class=xl74 width=162 style='border-left:none;width:122pt'>Caras-Severin</td>
        <td class=xl71 width=140 style='border-left:none;width:105pt'>DACIA</td>
        <td class=xl83 style='border-left:none'>Da</td>
        <td class=xl83 style='border-left:none'>Da</td>
        <td class=xl83 style='border-left:none'>Expert VE (fara demontare baterie)</td>
        <td class=xl83 style='border-left:none'>Nu</td>
    </tr>
    <tr class=xl84 height=20 style='mso-height-source:userset;height:15.0pt'>
        <td height=20 class=xl72 style='height:15.0pt'>Auto Haus Tudor S.R.L.</td>
        <td class=xl72 style='border-left:none'>Soseaua de Centura, DN 2A - Centura
            de Vest
        </td>
        <td class=xl74 width=150 style='border-left:none;width:113pt'>Constan&#355;a</td>
        <td class=xl74 width=123 style='border-left:none;width:92pt'>Constan&#355;a</td>
        <td class=xl72 style='border-left:none'>Auto Haus Tudor S.R.L.</td>
        <td class=xl72 style='border-left:none'>Soseaua de Centura, DN 2A - Centura
            de Vest
        </td>
        <td class=xl74 width=193 style='border-left:none;width:145pt'>Constan&#355;a</td>
        <td class=xl74 width=162 style='border-left:none;width:122pt'>Constan&#355;a</td>
        <td class=xl71 width=140 style='border-left:none;width:105pt'>DACIA</td>
        <td class=xl83 style='border-left:none'>Da</td>
        <td class=xl83 style='border-left:none'>Da</td>
        <td class=xl83 style='border-left:none'>Expert VE (fara demontare baterie)</td>
        <td class=xl83 style='border-left:none'>Nu</td>
    </tr>
    <tr class=xl84 height=20 style='mso-height-source:userset;height:15.0pt'>
        <td height=20 class=xl72 style='height:15.0pt'>Auto Haus Tudor S.R.L.</td>
        <td class=xl72 style='border-left:none'>Soseaua de Centura, DN 2A - Centura
            de Vest
        </td>
        <td class=xl71 width=150 style='border-top:none;border-left:none;width:113pt'>Constan&#355;a</td>
        <td class=xl71 width=123 style='border-top:none;border-left:none;width:92pt'>Constan&#355;a</td>
        <td class=xl72 style='border-left:none'>Auto Haus Tudor S.R.L.</td>
        <td class=xl72 style='border-left:none'>Soseaua de Centura, DN 2A - Centura
            de Vest
        </td>
        <td class=xl71 width=193 style='border-top:none;border-left:none;width:145pt'>Constan&#355;a</td>
        <td class=xl71 width=162 style='border-top:none;border-left:none;width:122pt'>Constan&#355;a</td>
        <td class=xl71 width=140 style='border-left:none;width:105pt'>RENAULT</td>
        <td class=xl83 style='border-left:none'>Da</td>
        <td class=xl83 style='border-left:none'>Da</td>
        <td class=xl83 style='border-left:none'>Expert E-TECH (fara demontare
            baterie)
        </td>
        <td class=xl83 style='border-left:none'>Nu</td>
    </tr>
    <tr class=xl84 height=20 style='mso-height-source:userset;height:15.0pt'>
        <td height=20 class=xl72 style='height:15.0pt'>Auto Marcu's Grup SA</td>
        <td class=xl72 style='border-left:none'>&#350;os. Pantelimon, Nr 450, sector
            2
        </td>
        <td class=xl74 width=150 style='border-left:none;width:113pt'>Bucure&#351;ti</td>
        <td class=xl74 width=123 style='border-left:none;width:92pt'>Bucure&#351;ti</td>
        <td class=xl72 style='border-left:none'>Auto Marcu's Grup SA</td>
        <td class=xl72 style='border-left:none'>&#350;os. Pantelimon, Nr 450, sector
            2
        </td>
        <td class=xl74 width=193 style='border-left:none;width:145pt'>Bucure&#351;ti</td>
        <td class=xl74 width=162 style='border-left:none;width:122pt'>Bucure&#351;ti</td>
        <td class=xl71 width=140 style='border-left:none;width:105pt'>DACIA</td>
        <td class=xl83 style='border-left:none'>Da</td>
        <td class=xl83 style='border-left:none'>Da</td>
        <td class=xl83 style='border-left:none'>Expert VE (fara demontare baterie)</td>
        <td class=xl83 style='border-left:none'>Nu</td>
    </tr>
    <tr class=xl84 height=20 style='mso-height-source:userset;height:15.0pt'>
        <td height=20 class=xl72 style='height:15.0pt'>Auto Marcu's Grup SA</td>
        <td class=xl72 style='border-left:none'>&#350;os. Pantelimon, Nr 450, sector
            2
        </td>
        <td class=xl71 width=150 style='border-top:none;border-left:none;width:113pt'>Bucure&#351;ti</td>
        <td class=xl71 width=123 style='border-top:none;border-left:none;width:92pt'>Bucure&#351;ti</td>
        <td class=xl72 style='border-left:none'>Auto Marcu's Grup SA</td>
        <td class=xl72 style='border-left:none'>&#350;os. Pantelimon, Nr 450, sector
            2
        </td>
        <td class=xl71 width=193 style='border-top:none;border-left:none;width:145pt'>Bucure&#351;ti</td>
        <td class=xl71 width=162 style='border-top:none;border-left:none;width:122pt'>Bucure&#351;ti</td>
        <td class=xl71 width=140 style='border-left:none;width:105pt'>RENAULT</td>
        <td class=xl83 style='border-left:none'>Da</td>
        <td class=xl83 style='border-left:none'>Da</td>
        <td class=xl83 style='border-left:none'>Expert E-TECH (fara demontare
            baterie)
        </td>
        <td class=xl83 style='border-left:none'>Nu</td>
    </tr>
    <tr class=xl84 height=20 style='mso-height-source:userset;height:15.0pt'>
        <td height=20 class=xl72 style='height:15.0pt'>Auto Moldova SA</td>
        <td class=xl72 style='border-left:none'>Bd. General Nicolae
            D&#259;sc&#259;lescu, Nr 492B
        </td>
        <td class=xl74 width=150 style='border-left:none;width:113pt'>Piatra
            Neam&#355;
        </td>
        <td class=xl74 width=123 style='border-left:none;width:92pt'>Neam&#355;</td>
        <td class=xl72 style='border-left:none'>Auto Moldova SA</td>
        <td class=xl72 style='border-left:none'>Bd. General Nicolae
            D&#259;sc&#259;lescu, Nr 492B
        </td>
        <td class=xl74 width=193 style='border-left:none;width:145pt'>Piatra
            Neam&#355;
        </td>
        <td class=xl74 width=162 style='border-left:none;width:122pt'>Neam&#355;</td>
        <td class=xl71 width=140 style='border-left:none;width:105pt'>DACIA</td>
        <td class=xl83 style='border-left:none'>Da</td>
        <td class=xl83 style='border-left:none'>Da</td>
        <td class=xl83 style='border-left:none'>Expert VE (fara demontare baterie)</td>
        <td class=xl83 style='border-left:none'>Nu</td>
    </tr>
    <tr class=xl84 height=20 style='mso-height-source:userset;height:15.0pt'>
        <td height=20 class=xl72 style='height:15.0pt'>Auto Moldova SA</td>
        <td class=xl72 style='border-left:none'>Bd. General Nicolae
            D&#259;sc&#259;lescu, Nr 492B
        </td>
        <td class=xl71 width=150 style='border-top:none;border-left:none;width:113pt'>Piatra
            Neam&#355;
        </td>
        <td class=xl71 width=123 style='border-top:none;border-left:none;width:92pt'>Neam&#355;</td>
        <td class=xl72 style='border-left:none'>Auto Moldova SA</td>
        <td class=xl72 style='border-left:none'>Bd. General Nicolae
            D&#259;sc&#259;lescu, Nr 492B
        </td>
        <td class=xl71 width=193 style='border-top:none;border-left:none;width:145pt'>Piatra
            Neam&#355;
        </td>
        <td class=xl71 width=162 style='border-top:none;border-left:none;width:122pt'>Neam&#355;</td>
        <td class=xl71 width=140 style='border-left:none;width:105pt'>RENAULT</td>
        <td class=xl83 style='border-left:none'>Da</td>
        <td class=xl83 style='border-left:none'>Da</td>
        <td class=xl83 style='border-left:none'>Full Expert E-TECH (cu demontare
            baterie)
        </td>
        <td class=xl83 style='border-left:none'>Nu</td>
    </tr>
    <tr class=xl84 height=20 style='mso-height-source:userset;height:15.0pt'>
        <td height=20 class=xl72 style='height:15.0pt'>Auto Moldova SA</td>
        <td class=xl72 style='border-left:none'>Bd. General Nicolae
            D&#259;sc&#259;lescu, Nr 492B
        </td>
        <td class=xl71 width=150 style='border-left:none;width:113pt'>Piatra
            Neam&#355;
        </td>
        <td class=xl71 width=123 style='border-left:none;width:92pt'>Neam&#355;</td>
        <td class=xl72 style='border-left:none'>Auto Moldova SA</td>
        <td class=xl72 style='border-left:none'>Bd. General Nicolae
            D&#259;sc&#259;lescu, Nr 492B
        </td>
        <td class=xl71 width=193 style='border-left:none;width:145pt'>Piatra
            Neam&#355;
        </td>
        <td class=xl71 width=162 style='border-left:none;width:122pt'>Neam&#355;</td>
        <td class=xl71 width=140 style='border-left:none;width:105pt'>NISSAN</td>
        <td class=xl83 style='border-left:none'>Da</td>
        <td class=xl83 style='border-left:none'>Da</td>
        <td class=xl83 style='border-left:none'>Da</td>
        <td class=xl83 style='border-left:none'>Nu</td>
    </tr>
    <tr class=xl84 height=20 style='mso-height-source:userset;height:15.0pt'>
        <td height=20 class=xl72 style='height:15.0pt'>Auto Moldova SA</td>
        <td class=xl72 style='border-left:none'>Bd. General Nicolae
            D&#259;sc&#259;lescu, Nr 492B
        </td>
        <td class=xl71 width=150 style='border-left:none;width:113pt'>Piatra
            Neam&#355;
        </td>
        <td class=xl71 width=123 style='border-left:none;width:92pt'>Neam&#355;</td>
        <td class=xl72 style='border-left:none'>Auto Moldova SA</td>
        <td class=xl75 style='border-left:none'>Str. &#350;tefan cel Mare, Nr. 256
            BIS
        </td>
        <td class=xl74 width=193 style='border-left:none;width:145pt'>Roman</td>
        <td class=xl71 width=162 style='border-left:none;width:122pt'>Neam&#355;</td>
        <td class=xl71 width=140 style='border-left:none;width:105pt'>DACIA</td>
        <td class=xl83 style='border-left:none'>Da</td>
        <td class=xl83 style='border-left:none'>Da</td>
        <td class=xl83 style='border-left:none'>Expert VE (fara demontare baterie)</td>
        <td class=xl83 style='border-left:none'>Nu</td>
    </tr>
    <tr class=xl84 height=20 style='mso-height-source:userset;height:15.0pt'>
        <td height=20 class=xl72 style='height:15.0pt'>Auto Moldova SA</td>
        <td class=xl72 style='border-left:none'>Bd. General Nicolae
            D&#259;sc&#259;lescu, Nr 492B
        </td>
        <td class=xl71 width=150 style='border-left:none;width:113pt'>Piatra
            Neam&#355;
        </td>
        <td class=xl71 width=123 style='border-left:none;width:92pt'>Neam&#355;</td>
        <td class=xl72 style='border-left:none'>Auto Moldova SA</td>
        <td class=xl75 style='border-left:none'>Str. &#350;tefan cel Mare, Nr. 256
            BIS
        </td>
        <td class=xl74 width=193 style='border-left:none;width:145pt'>Roman</td>
        <td class=xl71 width=162 style='border-left:none;width:122pt'>Neam&#355;</td>
        <td class=xl71 width=140 style='border-left:none;width:105pt'>RENAULT</td>
        <td class=xl83 style='border-left:none'>Da</td>
        <td class=xl83 style='border-left:none'>Da</td>
        <td class=xl83 style='border-left:none'>Expert E-TECH (fara demontare
            baterie)
        </td>
        <td class=xl83 style='border-left:none'>Nu</td>
    </tr>
    <tr class=xl84 height=20 style='mso-height-source:userset;height:15.0pt'>
        <td height=20 class=xl72 style='height:15.0pt'>Auto Press Sighi&#351;oara SRL</td>
        <td class=xl72 style='border-left:none'>Str. Mihai Viteazu, Nr. 119/A</td>
        <td class=xl74 width=150 style='border-left:none;width:113pt'>Sighi<font
            class="font9">&#351;</font><font class="font6">oara</font></td>
        <td class=xl74 width=123 style='border-left:none;width:92pt'>Mure&#351;</td>
        <td class=xl72 style='border-left:none'>Auto Press Sighi&#351;oara SRL</td>
        <td class=xl72 style='border-top:none;border-left:none'>Str. Mihai Viteazu,
            Nr. 119/A
        </td>
        <td class=xl74 width=193 style='border-left:none;width:145pt'>Sighi<font
            class="font9">&#351;</font><font class="font6">oara</font></td>
        <td class=xl74 width=162 style='border-left:none;width:122pt'>Mure&#351;</td>
        <td class=xl71 width=140 style='border-left:none;width:105pt'>DACIA</td>
        <td class=xl83 style='border-left:none'>Da</td>
        <td class=xl83 style='border-left:none'>Da</td>
        <td class=xl83 style='border-left:none'>Expert VE (fara demontare baterie)</td>
        <td class=xl83 style='border-left:none'>Nu</td>
    </tr>
    <tr class=xl84 height=20 style='mso-height-source:userset;height:15.0pt'>
        <td height=20 class=xl72 style='height:15.0pt'>Auto Sima Beyer Import Export
            SRL
        </td>
        <td class=xl72 style='border-left:none'>Calea Munteniei nr. 11</td>
        <td class=xl73 width=150 style='border-top:none;border-left:none;width:113pt'>Foc&#351;ani</td>
        <td class=xl73 width=123 style='border-top:none;border-left:none;width:92pt'>Vrancea</td>
        <td class=xl72 style='border-left:none'>Auto Sima Beyer Import Export SRL</td>
        <td class=xl72 style='border-left:none'>Calea Munteniei nr. 11</td>
        <td class=xl73 width=193 style='border-top:none;border-left:none;width:145pt'>Foc&#351;ani</td>
        <td class=xl73 width=162 style='border-top:none;border-left:none;width:122pt'>Vrancea</td>
        <td class=xl71 width=140 style='border-left:none;width:105pt'>DACIA</td>
        <td class=xl83 style='border-left:none'>Da</td>
        <td class=xl83 style='border-left:none'>Da</td>
        <td class=xl83 style='border-left:none'>Nu</td>
        <td class=xl83 style='border-left:none'>Nu</td>
    </tr>
    <tr class=xl84 height=20 style='mso-height-source:userset;height:15.0pt'>
        <td height=20 class=xl72 style='height:15.0pt'>Autoglobus 2000 SRL</td>
        <td class=xl72 style='border-left:none'>Calea &#350;agului, nr. 201</td>
        <td class=xl73 width=150 style='border-top:none;border-left:none;width:113pt'>Timi&#351;oara</td>
        <td class=xl73 width=123 style='border-top:none;border-left:none;width:92pt'>Timi&#351;</td>
        <td class=xl72 style='border-left:none'>Autoglobus 2000 SRL</td>
        <td class=xl72 style='border-left:none'>Calea &#350;agului, nr. 201</td>
        <td class=xl73 width=193 style='border-top:none;border-left:none;width:145pt'>Timi&#351;oara</td>
        <td class=xl73 width=162 style='border-top:none;border-left:none;width:122pt'>Timi&#351;</td>
        <td class=xl71 width=140 style='border-left:none;width:105pt'>DACIA</td>
        <td class=xl83 style='border-left:none'>Da</td>
        <td class=xl83 style='border-left:none'>Da</td>
        <td class=xl83 style='border-left:none'>Expert VE (fara demontare baterie)</td>
        <td class=xl83 style='border-left:none'>Nu</td>
    </tr>
    <tr class=xl84 height=20 style='mso-height-source:userset;height:15.0pt'>
        <td height=20 class=xl72 style='height:15.0pt'>Autoglobus 2000 SRL</td>
        <td class=xl72 style='border-left:none'>Calea &#350;agului, nr. 201</td>
        <td class=xl71 width=150 style='border-top:none;border-left:none;width:113pt'>Timi&#351;oara</td>
        <td class=xl71 width=123 style='border-top:none;border-left:none;width:92pt'>Timi&#351;</td>
        <td class=xl72 style='border-left:none'>Autoglobus 2000 SRL</td>
        <td class=xl72 style='border-left:none'>Calea &#350;agului, nr. 201</td>
        <td class=xl71 width=193 style='border-top:none;border-left:none;width:145pt'>Timi&#351;oara</td>
        <td class=xl71 width=162 style='border-top:none;border-left:none;width:122pt'>Timi&#351;</td>
        <td class=xl71 width=140 style='border-left:none;width:105pt'>RENAULT</td>
        <td class=xl83 style='border-left:none'>Da</td>
        <td class=xl83 style='border-left:none'>Da</td>
        <td class=xl83 style='border-left:none'>Expert E-TECH (fara demontare
            baterie)
        </td>
        <td class=xl83 style='border-left:none'>Nu</td>
    </tr>
    <tr class=xl84 height=20 style='mso-height-source:userset;height:15.0pt'>
        <td height=20 class=xl72 style='height:15.0pt'>Autogroup-Simo S.R.L.</td>
        <td class=xl72 style='border-left:none'>Str. Beclean nr. 298</td>
        <td class=xl75 style='border-left:none'>Od. Secuiesc</td>
        <td class=xl75 style='border-left:none'>Harghita</td>
        <td class=xl72 style='border-left:none'>Autogroup-Simo S.R.L.</td>
        <td class=xl72 style='border-left:none'>Str. Beclean nr. 298</td>
        <td class=xl75 style='border-left:none'>Od. Secuiesc</td>
        <td class=xl75 style='border-left:none'>Harghita</td>
        <td class=xl71 width=140 style='border-left:none;width:105pt'>DACIA</td>
        <td class=xl83 style='border-left:none'>Da</td>
        <td class=xl83 style='border-left:none'>Da</td>
        <td class=xl83 style='border-left:none'>Expert VE (fara demontare baterie)</td>
        <td class=xl83 style='border-left:none'>Nu</td>
    </tr>
    <tr class=xl84 height=20 style='mso-height-source:userset;height:15.0pt'>
        <td height=20 class=xl72 style='height:15.0pt'>Autogroup-Simo S.R.L.</td>
        <td class=xl72 style='border-left:none'>Str. Beclean nr. 298</td>
        <td class=xl71 width=150 style='border-top:none;border-left:none;width:113pt'>Od.
            Secuiesc
        </td>
        <td class=xl71 width=123 style='border-top:none;border-left:none;width:92pt'>Harghita</td>
        <td class=xl72 style='border-left:none'>Autogroup-Simo S.R.L.</td>
        <td class=xl72 style='border-left:none'>Str. Beclean nr. 298</td>
        <td class=xl71 width=193 style='border-top:none;border-left:none;width:145pt'>Od.
            Secuiesc
        </td>
        <td class=xl71 width=162 style='border-top:none;border-left:none;width:122pt'>Harghita</td>
        <td class=xl71 width=140 style='border-left:none;width:105pt'>RENAULT</td>
        <td class=xl83 style='border-left:none'>Da</td>
        <td class=xl83 style='border-left:none'>Da</td>
        <td class=xl83 style='border-left:none'>Expert E-TECH (fara demontare
            baterie)
        </td>
        <td class=xl83 style='border-left:none'>Nu</td>
    </tr>
    <tr class=xl84 height=20 style='mso-height-source:userset;height:15.0pt'>
        <td height=20 class=xl72 style='height:15.0pt'>Automobile Service SRL<span
            style='mso-spacerun:yes'> </span></td>
        <td class=xl72 style='border-left:none'>Calea Moldovei, Nr 22</td>
        <td class=xl74 width=150 style='border-left:none;width:113pt'>Bistrita</td>
        <td class=xl74 width=123 style='border-left:none;width:92pt'>Bistri&#355;a
            N&#259;s&#259;ud
        </td>
        <td class=xl72 style='border-left:none'>Automobile Service SRL<span
            style='mso-spacerun:yes'> </span></td>
        <td class=xl72 style='border-left:none'>Calea Moldovei, Nr 22</td>
        <td class=xl74 width=193 style='border-left:none;width:145pt'>Bistrita</td>
        <td class=xl74 width=162 style='border-left:none;width:122pt'>Bistri&#355;a
            N&#259;s&#259;ud
        </td>
        <td class=xl71 width=140 style='border-left:none;width:105pt'>DACIA</td>
        <td class=xl83 style='border-left:none'>Da</td>
        <td class=xl83 style='border-left:none'>Da</td>
        <td class=xl83 style='border-left:none'>Expert VE (fara demontare baterie)</td>
        <td class=xl83 style='border-left:none'>Nu</td>
    </tr>
    <tr class=xl84 height=20 style='mso-height-source:userset;height:15.0pt'>
        <td height=20 class=xl72 style='height:15.0pt'>Automobile Service SRL<span
            style='mso-spacerun:yes'> </span></td>
        <td class=xl72 style='border-left:none'>Calea Moldovei, Nr 22</td>
        <td class=xl71 width=150 style='border-top:none;border-left:none;width:113pt'>Bistrita</td>
        <td class=xl71 width=123 style='border-top:none;border-left:none;width:92pt'>Bistri&#355;a
            N&#259;s&#259;ud
        </td>
        <td class=xl72 style='border-left:none'>Automobile Service SRL<span
            style='mso-spacerun:yes'> </span></td>
        <td class=xl72 style='border-left:none'>Calea Moldovei, Nr 22</td>
        <td class=xl71 width=193 style='border-top:none;border-left:none;width:145pt'>Bistrita</td>
        <td class=xl71 width=162 style='border-top:none;border-left:none;width:122pt'>Bistri&#355;a
            N&#259;s&#259;ud
        </td>
        <td class=xl71 width=140 style='border-left:none;width:105pt'>RENAULT</td>
        <td class=xl83 style='border-left:none'>Da</td>
        <td class=xl83 style='border-left:none'>Da</td>
        <td class=xl83 style='border-left:none'>Expert E-TECH (fara demontare
            baterie)
        </td>
        <td class=xl83 style='border-left:none'>Nu</td>
    </tr>
    <tr class=xl84 height=20 style='mso-height-source:userset;height:15.0pt'>
        <td height=20 class=xl72 style='height:15.0pt'>Automotor SRL</td>
        <td class=xl72 style='border-left:none'>Calea Bucure&#351;ti, nr.112 bis</td>
        <td class=xl73 width=150 style='border-left:none;width:113pt'>Craiova</td>
        <td class=xl73 width=123 style='border-left:none;width:92pt'>Dolj</td>
        <td class=xl72 style='border-left:none'>Automotor SRL</td>
        <td class=xl72 style='border-left:none'>Calea Bucure&#351;ti, nr.112 bis</td>
        <td class=xl73 width=193 style='border-left:none;width:145pt'>Craiova</td>
        <td class=xl73 width=162 style='border-left:none;width:122pt'>Dolj</td>
        <td class=xl71 width=140 style='border-left:none;width:105pt'>DACIA</td>
        <td class=xl83 style='border-left:none'>Da</td>
        <td class=xl83 style='border-left:none'>Da</td>
        <td class=xl83 style='border-left:none'>Expert VE (fara demontare baterie)</td>
        <td class=xl83 style='border-left:none'>Nu</td>
    </tr>
    <tr class=xl84 height=20 style='mso-height-source:userset;height:15.0pt'>
        <td height=20 class=xl69 style='height:15.0pt'>BMG PIESE AUTO SRL</td>
        <td class=xl69 style='border-left:none'>Sat Valea Mare - Podgoria, Calea
            Bucuresti, nr. 159
        </td>
        <td class=xl68 width=150 style='border-top:none;border-left:none;width:113pt'>Ors.
            Stef&#259;ne&#351;ti
        </td>
        <td class=xl71 width=123 style='border-top:none;border-left:none;width:92pt'>Arge<font
            class="font5">&#351;</font></td>
        <td class=xl69 style='border-left:none'>BMG PIESE AUTO SRL</td>
        <td class=xl69 style='border-left:none'>Calea Bucuresti, nr. 81</td>
        <td class=xl68 width=193 style='border-top:none;border-left:none;width:145pt'>Ors.
            Stef&#259;ne&#351;ti
        </td>
        <td class=xl71 width=162 style='border-top:none;border-left:none;width:122pt'>Arge<font
            class="font5">&#351;</font></td>
        <td class=xl68 width=140 style='border-left:none;width:105pt'>DACIA</td>
        <td class=xl85 style='border-left:none'>Da</td>
        <td class=xl85 style='border-left:none'>Da</td>
        <td class=xl83 style='border-left:none'>Expert VE (fara demontare baterie)</td>
        <td class=xl85 style='border-left:none'>Nu</td>
    </tr>
    <tr class=xl84 height=20 style='mso-height-source:userset;height:15.0pt'>
        <td height=20 class=xl72 style='height:15.0pt;border-top:none'>Bras S.R.L.</td>
        <td class=xl72 style='border-top:none;border-left:none'>Str. Silvestru nr. 4</td>
        <td class=xl73 width=150 style='border-top:none;border-left:none;width:113pt'>Ia&#351;i</td>
        <td class=xl73 width=123 style='border-left:none;width:92pt'>Ia&#351;i</td>
        <td class=xl72 style='border-top:none;border-left:none'>Bras S.R.L.</td>
        <td class=xl69 style='border-top:none;border-left:none'>Str. Silvestru, nr.36</td>
        <td class=xl73 width=193 style='border-top:none;border-left:none;width:145pt'>Ia&#351;i</td>
        <td class=xl73 width=162 style='border-left:none;width:122pt'>Ia&#351;i</td>
        <td class=xl71 width=140 style='border-top:none;border-left:none;width:105pt'>DACIA</td>
        <td class=xl83 style='border-top:none;border-left:none'>Da</td>
        <td class=xl83 style='border-top:none;border-left:none'>Nu</td>
        <td class=xl83 style='border-left:none'>Full Expert VE (cu demontare baterie)</td>
        <td class=xl83 style='border-top:none;border-left:none'>Nu</td>
    </tr>
    <tr class=xl84 height=20 style='mso-height-source:userset;height:15.0pt'>
        <td height=20 class=xl72 style='height:15.0pt'>Bras S.R.L.</td>
        <td class=xl72 style='border-left:none'>Str. Silvestru nr. 4</td>
        <td class=xl71 width=150 style='border-top:none;border-left:none;width:113pt'>Ia&#351;i</td>
        <td class=xl71 width=123 style='border-top:none;border-left:none;width:92pt'>Ia&#351;i</td>
        <td class=xl72 style='border-left:none'>Bras S.R.L.</td>
        <td class=xl69 style='border-top:none;border-left:none'>DN28, Soseaua IASI
            -Targul Frumos, KM 10
        </td>
        <td class=xl73 width=193 style='border-top:none;border-left:none;width:145pt'>Ia&#351;i</td>
        <td class=xl73 width=162 style='border-top:none;border-left:none;width:122pt'>Ia&#351;i</td>
        <td class=xl71 width=140 style='border-left:none;width:105pt'>DACIA</td>
        <td class=xl83 style='border-left:none'>Nu</td>
        <td class=xl83 style='border-left:none'>Da</td>
        <td class=xl83 style='border-left:none'>Nu</td>
        <td class=xl83 style='border-left:none'>Nu</td>
    </tr>
    <tr class=xl84 height=20 style='mso-height-source:userset;height:15.0pt'>
        <td height=20 class=xl72 style='height:15.0pt'>Bras S.R.L.</td>
        <td class=xl72 style='border-left:none'>Str. Silvestru nr. 4</td>
        <td class=xl71 width=150 style='border-left:none;width:113pt'>Ia&#351;i</td>
        <td class=xl71 width=123 style='border-left:none;width:92pt'>Ia&#351;i</td>
        <td class=xl72 style='border-left:none'>Bras S.R.L.</td>
        <td class=xl69 style='border-top:none;border-left:none'>DN28, Soseaua IASI
            -Targul Frumos, KM 10
        </td>
        <td class=xl73 width=193 style='border-top:none;border-left:none;width:145pt'>Ia&#351;i</td>
        <td class=xl73 width=162 style='border-top:none;border-left:none;width:122pt'>Ia&#351;i</td>
        <td class=xl71 width=140 style='border-left:none;width:105pt'>RENAULT</td>
        <td class=xl83 style='border-left:none'>Da</td>
        <td class=xl83 style='border-left:none'>Da</td>
        <td class=xl83 style='border-left:none'>Expert E-TECH (fara demontare
            baterie)
        </td>
        <td class=xl83 style='border-left:none'>Nu</td>
    </tr>
    <tr class=xl84 height=20 style='mso-height-source:userset;height:15.0pt'>
        <td height=20 class=xl72 style='height:15.0pt'>Badsi Impex S.R.L.</td>
        <td class=xl72 style='border-left:none'>&#350;os. Pipera nr. 50A, Sector 2</td>
        <td class=xl82 style='border-left:none'>Bucure&#351;ti</td>
        <td class=xl82 style='border-left:none'>Bucure&#351;ti</td>
        <td class=xl72 style='border-left:none'>Badsi Impex S.R.L.</td>
        <td class=xl72 style='border-top:none;border-left:none'>&#350;os. Pipera nr.
            50A, Sector 2
        </td>
        <td class=xl82 style='border-top:none;border-left:none'>Bucure&#351;ti</td>
        <td class=xl82 style='border-top:none;border-left:none'>Bucure&#351;ti</td>
        <td class=xl71 width=140 style='border-left:none;width:105pt'>NISSAN</td>
        <td class=xl83 style='border-left:none'>Nu</td>
        <td class=xl83 style='border-left:none'>Nu</td>
        <td class=xl83 style='border-left:none'>Nu</td>
        <td class=xl83 style='border-left:none'>Nu</td>
    </tr>
    <tr class=xl84 height=20 style='mso-height-source:userset;height:15.0pt'>
        <td height=20 class=xl72 style='height:15.0pt'>Casa Auto Valea Lupului SRL</td>
        <td class=xl72 style='border-left:none'>Sat Valea Lupului nr.10, DN 28</td>
        <td class=xl74 width=150 style='border-left:none;width:113pt'>Comuna Valea
            Lupului
        </td>
        <td class=xl71 width=123 style='border-top:none;border-left:none;width:92pt'>Ia&#351;i</td>
        <td class=xl72 style='border-left:none'>Casa Auto Valea Lupului SRL</td>
        <td class=xl72 style='border-left:none'>Sat Valea Lupului nr.10, DN 28</td>
        <td class=xl74 width=193 style='border-left:none;width:145pt'>Comuna Valea
            Lupului
        </td>
        <td class=xl71 width=162 style='border-top:none;border-left:none;width:122pt'>Ia&#351;i</td>
        <td class=xl71 width=140 style='border-left:none;width:105pt'>DACIA</td>
        <td class=xl83 style='border-left:none'>Da</td>
        <td class=xl83 style='border-left:none'>Da</td>
        <td class=xl83 style='border-left:none'>Expert VE (fara demontare baterie)</td>
        <td class=xl83 style='border-left:none'>Nu</td>
    </tr>
    <tr class=xl84 height=20 style='mso-height-source:userset;height:15.0pt'>
        <td height=20 class=xl72 style='height:15.0pt'>Casa Auto Valea Lupului SRL</td>
        <td class=xl72 style='border-left:none'>Sat Valea Lupului nr.10, DN 28</td>
        <td class=xl71 width=150 style='border-top:none;border-left:none;width:113pt'>Comuna
            Valea Lupului
        </td>
        <td class=xl71 width=123 style='border-left:none;width:92pt'>Ia&#351;i</td>
        <td class=xl72 style='border-left:none'>Casa Auto Valea Lupului SRL</td>
        <td class=xl72 style='border-left:none'>Sat Valea Lupului nr.10, DN 28</td>
        <td class=xl71 width=193 style='border-top:none;border-left:none;width:145pt'>Comuna
            Valea Lupului
        </td>
        <td class=xl71 width=162 style='border-left:none;width:122pt'>Ia&#351;i</td>
        <td class=xl71 width=140 style='border-left:none;width:105pt'>RENAULT</td>
        <td class=xl83 style='border-left:none'>Da</td>
        <td class=xl83 style='border-left:none'>Da</td>
        <td class=xl83 style='border-left:none'>Expert E-TECH (fara demontare
            baterie)
        </td>
        <td class=xl83 style='border-left:none'>Nu</td>
    </tr>
    <tr class=xl84 height=20 style='mso-height-source:userset;height:15.0pt'>
        <td height=20 class=xl72 style='height:15.0pt'>Casa Auto Valea Lupului SRL</td>
        <td class=xl72 style='border-left:none'>Sat Valea Lupului nr.10, DN 28</td>
        <td class=xl71 width=150 style='border-left:none;width:113pt'>Comuna Valea
            Lupului
        </td>
        <td class=xl71 width=123 style='border-left:none;width:92pt'>Ia&#351;i</td>
        <td class=xl72 style='border-left:none'>Casa Auto Valea Lupului SRL</td>
        <td class=xl72 style='border-left:none'>Sat Valea Lupului nr.10, DN 28</td>
        <td class=xl71 width=193 style='border-left:none;width:145pt'>Comuna Valea
            Lupului
        </td>
        <td class=xl71 width=162 style='border-left:none;width:122pt'>Ia&#351;i</td>
        <td class=xl71 width=140 style='border-left:none;width:105pt'>NISSAN</td>
        <td class=xl83 style='border-left:none'>Da</td>
        <td class=xl83 style='border-left:none'>Da</td>
        <td class=xl83 style='border-left:none'>Da</td>
        <td class=xl83 style='border-left:none'>Nu</td>
    </tr>
    <tr class=xl84 height=20 style='mso-height-source:userset;height:15.0pt'>
        <td height=20 class=xl69 style='height:15.0pt'>Casa Auto Valea Lupului SRL</td>
        <td class=xl69 style='border-left:none'>Sat Valea Lupului nr.10, DN 28</td>
        <td class=xl68 width=150 style='border-left:none;width:113pt'>Comuna Valea
            Lupului
        </td>
        <td class=xl68 width=123 style='border-left:none;width:92pt'>Ia&#351;i</td>
        <td class=xl72 style='border-left:none'>Casa Auto Valea Lupului SRL</td>
        <td class=xl76 width=505 style='border-left:none;width:379pt'>Calea
            Chi&#537;in&#259;ului, nr. 23, Spa&#539;iul P1, <br>
            în incinta Cl&#259;dirii de birouri Centro 2, parter
        </td>
        <td class=xl71 width=193 style='border-left:none;width:145pt'>Ia&#351;i</td>
        <td class=xl71 width=162 style='border-left:none;width:122pt'>Ia&#351;i</td>
        <td class=xl68 width=140 style='border-left:none;width:105pt'>DACIA</td>
        <td class=xl85 style='border-left:none'>Nu</td>
        <td class=xl85 style='border-left:none'>Nu</td>
        <td class=xl85 style='border-left:none'>Nu</td>
        <td class=xl85 style='border-left:none'>Nu</td>
    </tr>
    <tr class=xl84 height=20 style='mso-height-source:userset;height:15.0pt'>
        <td height=20 class=xl72 style='height:15.0pt;border-top:none'>Casa Auto
            Valea Lupului SRL
        </td>
        <td class=xl72 style='border-top:none;border-left:none'>Sat Valea Lupului
            nr.10, DN 28
        </td>
        <td class=xl71 width=150 style='border-top:none;border-left:none;width:113pt'>Comuna
            Valea Lupului
        </td>
        <td class=xl71 width=123 style='border-top:none;border-left:none;width:92pt'>Ia&#351;i</td>
        <td class=xl72 style='border-left:none'>Casa Auto Valea Lupului SRL</td>
        <td class=xl76 width=505 style='border-left:none;width:379pt'>Calea
            Chi&#537;in&#259;ului, nr. 23, Spa&#539;iul P1, <br>
            în incinta Cl&#259;dirii de birouri Centro 2, parter
        </td>
        <td class=xl71 width=193 style='border-left:none;width:145pt'>Ia&#351;i</td>
        <td class=xl71 width=162 style='border-left:none;width:122pt'>Ia&#351;i</td>
        <td class=xl71 width=140 style='border-top:none;border-left:none;width:105pt'>RENAULT</td>
        <td class=xl83 style='border-top:none;border-left:none'>Nu</td>
        <td class=xl83 style='border-top:none;border-left:none'>Nu</td>
        <td class=xl83 style='border-top:none;border-left:none'>Nu</td>
        <td class=xl83 style='border-top:none;border-left:none'>Nu</td>
    </tr>
    <tr class=xl84 height=20 style='mso-height-source:userset;height:15.0pt'>
        <td height=20 class=xl72 style='height:15.0pt'>Condorul SA</td>
        <td class=xl72 style='border-left:none'>Str. Bucuresti&nbsp; Nr 401</td>
        <td class=xl74 width=150 style='border-left:none;width:113pt'>C&#259;l&#259;ra&#351;i</td>
        <td class=xl74 width=123 style='border-left:none;width:92pt'>C&#259;l&#259;ra&#351;i</td>
        <td class=xl72 style='border-left:none'>Condorul SA</td>
        <td class=xl72 style='border-top:none;border-left:none'>Str. Bucuresti&nbsp;
            Nr 401
        </td>
        <td class=xl74 width=193 style='border-left:none;width:145pt'>C&#259;l&#259;ra&#351;i</td>
        <td class=xl74 width=162 style='border-left:none;width:122pt'>C&#259;l&#259;ra&#351;i</td>
        <td class=xl71 width=140 style='border-left:none;width:105pt'>DACIA</td>
        <td class=xl83 style='border-left:none'>Nu</td>
        <td class=xl83 style='border-left:none'>Nu</td>
        <td class=xl83 style='border-left:none'>Nu</td>
        <td class=xl83 style='border-left:none'>Nu</td>
    </tr>
    <tr class=xl84 height=20 style='mso-height-source:userset;height:15.0pt'>
        <td height=20 class=xl72 style='height:15.0pt'>Condorul SA</td>
        <td class=xl72 style='border-left:none'>Str. Bucuresti&nbsp; Nr 401</td>
        <td class=xl71 width=150 style='border-top:none;border-left:none;width:113pt'>C&#259;l&#259;ra&#351;i</td>
        <td class=xl71 width=123 style='border-top:none;border-left:none;width:92pt'>C&#259;l&#259;ra&#351;i</td>
        <td class=xl72 style='border-left:none'>Condorul SA</td>
        <td class=xl72 style='border-left:none'>Str. Bucuresti&nbsp; Nr 401</td>
        <td class=xl71 width=193 style='border-top:none;border-left:none;width:145pt'>C&#259;l&#259;ra&#351;i</td>
        <td class=xl71 width=162 style='border-top:none;border-left:none;width:122pt'>C&#259;l&#259;ra&#351;i</td>
        <td class=xl71 width=140 style='border-left:none;width:105pt'>RENAULT</td>
        <td class=xl83 style='border-left:none'>Nu</td>
        <td class=xl83 style='border-left:none'>Nu</td>
        <td class=xl83 style='border-left:none'>Nu</td>
        <td class=xl83 style='border-left:none'>Nu</td>
    </tr>
    <tr class=xl84 height=20 style='mso-height-source:userset;height:15.0pt'>
        <td height=20 class=xl72 style='height:15.0pt'>CONDORUL AUTOMOBILE S.R.L.</td>
        <td class=xl72 style='border-left:none'>Str. Bucuresti, nr.401 C9</td>
        <td class=xl71 width=150 style='border-left:none;width:113pt'>C&#259;l&#259;ra&#351;i</td>
        <td class=xl71 width=123 style='border-left:none;width:92pt'>C&#259;l&#259;ra&#351;i</td>
        <td class=xl72 style='border-left:none'>CONDORUL AUTOMOBILE S.R.L.</td>
        <td class=xl72 style='border-left:none'>Str. Bucuresti&nbsp; Nr 401</td>
        <td class=xl71 width=193 style='border-left:none;width:145pt'>C&#259;l&#259;ra&#351;i</td>
        <td class=xl71 width=162 style='border-left:none;width:122pt'>C&#259;l&#259;ra&#351;i</td>
        <td class=xl71 width=140 style='border-left:none;width:105pt'>DACIA</td>
        <td class=xl83 style='border-left:none'>Da</td>
        <td class=xl83 style='border-left:none'>Da</td>
        <td class=xl83 style='border-left:none'>Expert VE (fara demontare baterie)</td>
        <td class=xl83 style='border-left:none'>Nu</td>
    </tr>
    <tr class=xl84 height=20 style='mso-height-source:userset;height:15.0pt'>
        <td height=20 class=xl72 style='height:15.0pt'>CONDORUL AUTOMOBILE S.R.L.</td>
        <td class=xl72 style='border-left:none'>Str. Bucuresti, nr.401 C9</td>
        <td class=xl71 width=150 style='border-left:none;width:113pt'>C&#259;l&#259;ra&#351;i</td>
        <td class=xl71 width=123 style='border-left:none;width:92pt'>C&#259;l&#259;ra&#351;i</td>
        <td class=xl72 style='border-left:none'>CONDORUL AUTOMOBILE S.R.L.</td>
        <td class=xl72 style='border-left:none'>Str. Bucuresti&nbsp; Nr 401</td>
        <td class=xl71 width=193 style='border-left:none;width:145pt'>C&#259;l&#259;ra&#351;i</td>
        <td class=xl71 width=162 style='border-left:none;width:122pt'>C&#259;l&#259;ra&#351;i</td>
        <td class=xl71 width=140 style='border-left:none;width:105pt'>RENAULT</td>
        <td class=xl83 style='border-left:none'>Da</td>
        <td class=xl83 style='border-left:none'>Da</td>
        <td class=xl83 style='border-left:none'>Expert E-TECH (fara demontare
            baterie)
        </td>
        <td class=xl83 style='border-left:none'>Nu</td>
    </tr>
    <tr class=xl84 height=20 style='mso-height-source:userset;height:15.0pt'>
        <td height=20 class=xl72 style='height:15.0pt'>Coramex SRL</td>
        <td class=xl72 style='border-left:none'>Bd. 22 Decembrie nr. 127A</td>
        <td class=xl74 width=150 style='border-left:none;width:113pt'>Deva</td>
        <td class=xl74 width=123 style='border-left:none;width:92pt'>Hunedoara</td>
        <td class=xl72 style='border-left:none'>Coramex SRL</td>
        <td class=xl72 style='border-left:none'>Bd. 22 Decembrie nr. 127A</td>
        <td class=xl74 width=193 style='border-left:none;width:145pt'>Deva</td>
        <td class=xl74 width=162 style='border-left:none;width:122pt'>Hunedoara</td>
        <td class=xl71 width=140 style='border-left:none;width:105pt'>RENAULT</td>
        <td class=xl83 style='border-left:none'>Da</td>
        <td class=xl83 style='border-left:none'>Subcontractata</td>
        <td class=xl83 style='border-left:none'>Expert E-TECH (fara demontare
            baterie)
        </td>
        <td class=xl83 style='border-left:none'>Nu</td>
    </tr>
    <tr class=xl84 height=20 style='mso-height-source:userset;height:15.0pt'>
        <td height=20 class=xl72 style='height:15.0pt'>Dacia Service Cluj SRL</td>
        <td class=xl72 style='border-left:none'>Calea Turzii nr.253-255</td>
        <td class=xl74 width=150 style='border-left:none;width:113pt'>Cluj Napoca</td>
        <td class=xl71 width=123 style='border-top:none;border-left:none;width:92pt'>Cluj</td>
        <td class=xl72 style='border-left:none'>Dacia Service Cluj SRL</td>
        <td class=xl72 style='border-left:none'>Calea Turzii nr.253-255</td>
        <td class=xl74 width=193 style='border-left:none;width:145pt'>Cluj Napoca</td>
        <td class=xl71 width=162 style='border-top:none;border-left:none;width:122pt'>Cluj</td>
        <td class=xl71 width=140 style='border-left:none;width:105pt'>DACIA</td>
        <td class=xl83 style='border-left:none'>Da</td>
        <td class=xl83 style='border-left:none'>Da</td>
        <td class=xl83 style='border-left:none'>Full Expert VE (cu demontare baterie)</td>
        <td class=xl83 style='border-left:none'>Nu</td>
    </tr>
    <tr class=xl84 height=20 style='mso-height-source:userset;height:15.0pt'>
        <td height=20 class=xl72 style='height:15.0pt'>Dacia Service Cluj SRL</td>
        <td class=xl72 style='border-left:none'>Calea Turzii nr.253-255</td>
        <td class=xl71 width=150 style='border-top:none;border-left:none;width:113pt'>Cluj
            Napoca
        </td>
        <td class=xl71 width=123 style='border-left:none;width:92pt'>Cluj</td>
        <td class=xl72 style='border-left:none'>Dacia Service Cluj SRL</td>
        <td class=xl72 style='border-left:none'>Calea Turzii nr.253-255</td>
        <td class=xl71 width=193 style='border-top:none;border-left:none;width:145pt'>Cluj
            Napoca
        </td>
        <td class=xl71 width=162 style='border-left:none;width:122pt'>Cluj</td>
        <td class=xl71 width=140 style='border-left:none;width:105pt'>RENAULT</td>
        <td class=xl83 style='border-left:none'>Da</td>
        <td class=xl83 style='border-left:none'>Da</td>
        <td class=xl83 style='border-left:none'>Full Expert E-TECH (cu demontare
            baterie)
        </td>
        <td class=xl83 style='border-left:none'>Nu</td>
    </tr>
    <tr class=xl84 height=20 style='mso-height-source:userset;height:15.0pt'>
        <td height=20 class=xl72 style='height:15.0pt'>Dacia Service Cluj SRL</td>
        <td class=xl72 style='border-left:none'>Calea Turzii nr.253-255</td>
        <td class=xl71 width=150 style='border-left:none;width:113pt'>Cluj Napoca</td>
        <td class=xl71 width=123 style='border-left:none;width:92pt'>Cluj</td>
        <td class=xl72 style='border-left:none'>Dacia Service Cluj SRL</td>
        <td class=xl72 style='border-left:none'>Calea Turzii nr.253-255</td>
        <td class=xl71 width=193 style='border-left:none;width:145pt'>Cluj Napoca</td>
        <td class=xl71 width=162 style='border-left:none;width:122pt'>Cluj</td>
        <td class=xl71 width=140 style='border-left:none;width:105pt'>NISSAN</td>
        <td class=xl83 style='border-left:none'>Da</td>
        <td class=xl83 style='border-left:none'>Nu</td>
        <td class=xl83 style='border-left:none'>Da</td>
        <td class=xl83 style='border-left:none'>Nu</td>
    </tr>
    <tr class=xl84 height=20 style='mso-height-source:userset;height:15.0pt'>
        <td height=20 class=xl72 style='height:15.0pt'>Dacoserv SA</td>
        <td class=xl72 style='border-left:none'>Str. Preciziei nr. 4, Sector 6</td>
        <td class=xl74 width=150 style='border-left:none;width:113pt'>Bucure&#351;ti</td>
        <td class=xl74 width=123 style='border-left:none;width:92pt'>Bucure&#351;ti</td>
        <td class=xl72 style='border-left:none'>Dacoserv SA</td>
        <td class=xl72 style='border-left:none'>Str. Preciziei nr. 4, Sector 6</td>
        <td class=xl74 width=193 style='border-left:none;width:145pt'>Bucure&#351;ti</td>
        <td class=xl74 width=162 style='border-left:none;width:122pt'>Bucure&#351;ti</td>
        <td class=xl71 width=140 style='border-left:none;width:105pt'>DACIA</td>
        <td class=xl83 style='border-left:none'>Da</td>
        <td class=xl83 style='border-left:none'>Da</td>
        <td class=xl83 style='border-left:none'>Full Expert VE (cu demontare baterie)</td>
        <td class=xl83 style='border-left:none'>Nu</td>
    </tr>
    <tr class=xl84 height=20 style='mso-height-source:userset;height:15.0pt'>
        <td height=20 class=xl72 style='height:15.0pt'>Dacoserv SA</td>
        <td class=xl72 style='border-left:none'>Str. Preciziei nr. 4, Sector 6</td>
        <td class=xl71 width=150 style='border-top:none;border-left:none;width:113pt'>Bucure&#351;ti</td>
        <td class=xl71 width=123 style='border-top:none;border-left:none;width:92pt'>Bucure&#351;ti</td>
        <td class=xl72 style='border-left:none'>Dacoserv SA</td>
        <td class=xl72 style='border-left:none'>Str. Preciziei nr. 4, Sector 6</td>
        <td class=xl71 width=193 style='border-top:none;border-left:none;width:145pt'>Bucure&#351;ti</td>
        <td class=xl71 width=162 style='border-top:none;border-left:none;width:122pt'>Bucure&#351;ti</td>
        <td class=xl71 width=140 style='border-left:none;width:105pt'>RENAULT</td>
        <td class=xl83 style='border-left:none'>Da</td>
        <td class=xl83 style='border-left:none'>Da</td>
        <td class=xl83 style='border-left:none'>Expert E-TECH (fara demontare
            baterie)
        </td>
        <td class=xl83 style='border-left:none'>Nu</td>
    </tr>
    <tr class=xl84 height=20 style='mso-height-source:userset;height:15.0pt'>
        <td height=20 class=xl72 style='height:15.0pt'>Dacoserv SA</td>
        <td class=xl72 style='border-left:none'>Str. Preciziei nr. 4, Sector 6</td>
        <td class=xl71 width=150 style='border-left:none;width:113pt'>Bucure&#351;ti</td>
        <td class=xl71 width=123 style='border-left:none;width:92pt'>Bucure&#351;ti</td>
        <td class=xl72 style='border-left:none'>Dacoserv SA</td>
        <td class=xl75 style='border-left:none'>&#350;os. S&#259;laj, nr. 225, sector
            5
        </td>
        <td class=xl71 width=193 style='border-left:none;width:145pt'>Bucure&#351;ti</td>
        <td class=xl71 width=162 style='border-left:none;width:122pt'>Bucure&#351;ti</td>
        <td class=xl71 width=140 style='border-left:none;width:105pt'>DACIA</td>
        <td class=xl83 style='border-left:none'>Da</td>
        <td class=xl83 style='border-left:none'>Nu</td>
        <td class=xl83 style='border-left:none'>Nu</td>
        <td class=xl83 style='border-left:none'>Nu</td>
    </tr>
    <tr class=xl84 height=20 style='mso-height-source:userset;height:15.0pt'>
        <td height=20 class=xl72 style='height:15.0pt'>Daperom Grup Auto SRL<span
            style='mso-spacerun:yes'> </span></td>
        <td class=xl72 style='border-left:none'>Str. Târgul din Vale nr.3A</td>
        <td class=xl73 width=150 style='border-left:none;width:113pt'>Pite&#351;ti</td>
        <td class=xl73 width=123 style='border-left:none;width:92pt'>Arge&#351;</td>
        <td class=xl72 style='border-left:none'>Daperom Grup Auto SRL<span
            style='mso-spacerun:yes'> </span></td>
        <td class=xl72 style='border-top:none;border-left:none'>Str. Târgul din Vale
            nr.3A
        </td>
        <td class=xl73 width=193 style='border-left:none;width:145pt'>Pite&#351;ti</td>
        <td class=xl73 width=162 style='border-left:none;width:122pt'>Arge&#351;</td>
        <td class=xl71 width=140 style='border-left:none;width:105pt'>DACIA</td>
        <td class=xl83 style='border-left:none'>Da</td>
        <td class=xl83 style='border-left:none'>Da</td>
        <td class=xl83 style='border-left:none'>Full Expert VE (cu demontare baterie)</td>
        <td class=xl83 style='border-left:none'>Nu</td>
    </tr>
    <tr class=xl84 height=20 style='mso-height-source:userset;height:15.0pt'>
        <td height=20 class=xl72 style='height:15.0pt'>Daperom Grup Auto SRL<span
            style='mso-spacerun:yes'> </span></td>
        <td class=xl72 style='border-left:none'>Str. Târgul din Vale nr.3A</td>
        <td class=xl71 width=150 style='border-top:none;border-left:none;width:113pt'>Pite&#351;ti</td>
        <td class=xl71 width=123 style='border-top:none;border-left:none;width:92pt'>Arge&#351;</td>
        <td class=xl72 style='border-left:none'>Daperom Grup Auto SRL<span
            style='mso-spacerun:yes'> </span></td>
        <td class=xl72 style='border-left:none'>Str. Târgul din Vale nr.3A</td>
        <td class=xl73 width=193 style='border-top:none;border-left:none;width:145pt'>Pite&#351;ti</td>
        <td class=xl73 width=162 style='border-top:none;border-left:none;width:122pt'>Arge&#351;</td>
        <td class=xl71 width=140 style='border-left:none;width:105pt'>NISSAN</td>
        <td class=xl83 style='border-left:none'>Nu</td>
        <td class=xl83 style='border-left:none'>Da</td>
        <td class=xl83 style='border-left:none'>Nu</td>
        <td class=xl83 style='border-left:none'>Nu</td>
    </tr>
    <tr class=xl84 height=20 style='mso-height-source:userset;height:15.0pt'>
        <td height=20 class=xl72 style='height:15.0pt'>Daperom Grup Auto SRL<span
            style='mso-spacerun:yes'> </span></td>
        <td class=xl72 style='border-left:none'>Str. Târgul din Vale nr.3A</td>
        <td class=xl71 width=150 style='border-left:none;width:113pt'>Pite&#351;ti</td>
        <td class=xl71 width=123 style='border-left:none;width:92pt'>Arge&#351;</td>
        <td class=xl72 style='border-left:none'>Daperom Grup Auto SRL<span
            style='mso-spacerun:yes'> </span></td>
        <td class=xl72 style='border-left:none'>Str. IOAN S.GHEORGHE, nr.1</td>
        <td class=xl73 width=193 style='border-top:none;border-left:none;width:145pt'>Pite&#351;ti</td>
        <td class=xl73 width=162 style='border-top:none;border-left:none;width:122pt'>Arge&#351;</td>
        <td class=xl71 width=140 style='border-left:none;width:105pt'>NISSAN</td>
        <td class=xl83 style='border-left:none'>Da</td>
        <td class=xl83 style='border-left:none'>Nu</td>
        <td class=xl83 style='border-left:none'>Da</td>
        <td class=xl83 style='border-left:none'>Nu</td>
    </tr>
    <tr class=xl84 height=20 style='mso-height-source:userset;height:15.0pt'>
        <td height=20 class=xl72 style='height:15.0pt'>Daren Automobile SRL</td>
        <td class=xl72 style='border-left:none'>Str. Republicii, Nr 185</td>
        <td class=xl74 width=150 style='border-left:none;width:113pt'>Bac&#259;u</td>
        <td class=xl74 width=123 style='border-left:none;width:92pt'>Bac&#259;u</td>
        <td class=xl72 style='border-left:none'>Daren Automobile SRL</td>
        <td class=xl72 style='border-left:none'>Str. Republicii, Nr 185</td>
        <td class=xl74 width=193 style='border-left:none;width:145pt'>Bac&#259;u</td>
        <td class=xl74 width=162 style='border-left:none;width:122pt'>Bac&#259;u</td>
        <td class=xl71 width=140 style='border-left:none;width:105pt'>DACIA</td>
        <td class=xl83 style='border-left:none'>Da</td>
        <td class=xl83 style='border-left:none'>Da</td>
        <td class=xl83 style='border-left:none'>Expert VE (fara demontare baterie)</td>
        <td class=xl83 style='border-left:none'>Nu</td>
    </tr>
    <tr class=xl84 height=20 style='mso-height-source:userset;height:15.0pt'>
        <td height=20 class=xl72 style='height:15.0pt'>Daren Automobile SRL</td>
        <td class=xl72 style='border-left:none'>Str. Republicii, Nr 185</td>
        <td class=xl71 width=150 style='border-top:none;border-left:none;width:113pt'>Bac&#259;u</td>
        <td class=xl71 width=123 style='border-top:none;border-left:none;width:92pt'>Bac&#259;u</td>
        <td class=xl72 style='border-left:none'>Daren Automobile SRL</td>
        <td class=xl72 style='border-left:none'>Str. Republicii, Nr 185</td>
        <td class=xl71 width=193 style='border-top:none;border-left:none;width:145pt'>Bac&#259;u</td>
        <td class=xl71 width=162 style='border-top:none;border-left:none;width:122pt'>Bac&#259;u</td>
        <td class=xl71 width=140 style='border-left:none;width:105pt'>RENAULT</td>
        <td class=xl83 style='border-left:none'>Da</td>
        <td class=xl83 style='border-left:none'>Da</td>
        <td class=xl83 style='border-left:none'>Expert E-TECH (fara demontare
            baterie)
        </td>
        <td class=xl83 style='border-left:none'>Nu</td>
    </tr>
    <tr class=xl84 height=20 style='mso-height-source:userset;height:15.0pt'>
        <td height=20 class=xl72 style='height:15.0pt'>Daren Automobile SRL</td>
        <td class=xl72 style='border-left:none'>Str. Republicii, Nr 185</td>
        <td class=xl71 width=150 style='border-left:none;width:113pt'>Bac&#259;u</td>
        <td class=xl71 width=123 style='border-left:none;width:92pt'>Bac&#259;u</td>
        <td class=xl72 style='border-left:none'>Daren Automobile SRL</td>
        <td class=xl72 style='border-left:none'>Str. Republicii, Nr 185</td>
        <td class=xl71 width=193 style='border-left:none;width:145pt'>Bac&#259;u</td>
        <td class=xl71 width=162 style='border-left:none;width:122pt'>Bac&#259;u</td>
        <td class=xl71 width=140 style='border-left:none;width:105pt'>NISSAN</td>
        <td class=xl83 style='border-left:none'>Da</td>
        <td class=xl83 style='border-left:none'>Da</td>
        <td class=xl83 style='border-left:none'>Da</td>
        <td class=xl83 style='border-left:none'>Nu</td>
    </tr>
    <tr class=xl84 height=20 style='mso-height-source:userset;height:15.0pt'>
        <td height=20 class=xl69 style='height:15.0pt'>Daren Automobile SRL</td>
        <td class=xl69 style='border-left:none'>Str. Republicii, Nr 185</td>
        <td class=xl68 width=150 style='border-left:none;width:113pt'>Bac&#259;u</td>
        <td class=xl68 width=123 style='border-left:none;width:92pt'>Bac&#259;u</td>
        <td class=xl72 style='border-left:none'>Daren Automobile SRL</td>
        <td class=xl69 style='border-left:none'>Calea Slanicului nr.10A</td>
        <td class=xl73 width=193 style='border-left:none;width:145pt'>One&#351;ti</td>
        <td class=xl71 width=162 style='border-left:none;width:122pt'>Bac&#259;u</td>
        <td class=xl68 width=140 style='border-left:none;width:105pt'>DACIA</td>
        <td class=xl83 style='border-left:none'>Da</td>
        <td class=xl83 style='border-left:none'>Da</td>
        <td class=xl83 style='border-left:none'>Expert VE (fara demontare baterie)</td>
        <td class=xl83 style='border-left:none'>Nu</td>
    </tr>
    <tr class=xl84 height=20 style='mso-height-source:userset;height:15.0pt'>
        <td height=20 class=xl72 style='height:15.0pt;border-top:none'>Daren
            Automobile SRL
        </td>
        <td class=xl72 style='border-top:none;border-left:none'>Str. Republicii, Nr
            185
        </td>
        <td class=xl71 width=150 style='border-top:none;border-left:none;width:113pt'>Bac&#259;u</td>
        <td class=xl71 width=123 style='border-top:none;border-left:none;width:92pt'>Bac&#259;u</td>
        <td class=xl72 style='border-left:none'>Daren Automobile SRL</td>
        <td class=xl69 style='border-top:none;border-left:none'>Calea Slanicului
            nr.10A
        </td>
        <td class=xl73 width=193 style='border-top:none;border-left:none;width:145pt'>One&#351;ti</td>
        <td class=xl71 width=162 style='border-left:none;width:122pt'>Bac&#259;u</td>
        <td class=xl71 width=140 style='border-top:none;border-left:none;width:105pt'>RENAULT</td>
        <td class=xl83 style='border-left:none'>Da</td>
        <td class=xl83 style='border-left:none'>Da</td>
        <td class=xl83 style='border-left:none'>Expert E-TECH (fara demontare
            baterie)
        </td>
        <td class=xl83 style='border-left:none'>Nu</td>
    </tr>
    <tr class=xl84 height=20 style='mso-height-source:userset;height:15.0pt'>
        <td height=20 class=xl72 style='height:15.0pt'>Darex Auto SRL</td>
        <td class=xl72 style='border-left:none'>Str. Humorului, nr. 96, Etaj 1,
            camera 4, sat &#350;cheia
        </td>
        <td class=xl74 width=150 style='border-left:none;width:113pt'>Comuna
            &#350;cheia
        </td>
        <td class=xl74 width=123 style='border-left:none;width:92pt'>Suceava</td>
        <td class=xl72 style='border-left:none'>Darex Auto SRL</td>
        <td class=xl72 style='border-top:none;border-left:none'>Str. Humorului, nr.
            96, sat &#350;cheia
        </td>
        <td class=xl74 width=193 style='border-left:none;width:145pt'>Comuna
            &#350;cheia
        </td>
        <td class=xl74 width=162 style='border-left:none;width:122pt'>Suceava</td>
        <td class=xl71 width=140 style='border-left:none;width:105pt'>DACIA</td>
        <td class=xl83 style='border-left:none'>Da</td>
        <td class=xl83 style='border-left:none'>Da</td>
        <td class=xl83 style='border-left:none'>Expert VE (fara demontare baterie)</td>
        <td class=xl83 style='border-left:none'>Nu</td>
    </tr>
    <tr class=xl84 height=20 style='mso-height-source:userset;height:15.0pt'>
        <td height=20 class=xl72 style='height:15.0pt'>Darex Auto SRL</td>
        <td class=xl72 style='border-left:none'>Str. Humorului, nr. 96, Etaj 1,
            camera 4, sat &#350;cheia
        </td>
        <td class=xl71 width=150 style='border-top:none;border-left:none;width:113pt'>Comuna
            &#350;cheia
        </td>
        <td class=xl71 width=123 style='border-top:none;border-left:none;width:92pt'>Suceava</td>
        <td class=xl72 style='border-left:none'>Darex Auto SRL</td>
        <td class=xl72 style='border-left:none'>Str. Humorului, nr. 96, sat
            &#350;cheia
        </td>
        <td class=xl71 width=193 style='border-top:none;border-left:none;width:145pt'>Comuna
            &#350;cheia
        </td>
        <td class=xl71 width=162 style='border-top:none;border-left:none;width:122pt'>Suceava</td>
        <td class=xl71 width=140 style='border-left:none;width:105pt'>RENAULT</td>
        <td class=xl83 style='border-left:none'>Da</td>
        <td class=xl83 style='border-left:none'>Da</td>
        <td class=xl83 style='border-left:none'>Expert E-TECH (fara demontare
            baterie)
        </td>
        <td class=xl83 style='border-left:none'>Nu</td>
    </tr>
    <tr class=xl84 height=20 style='mso-height-source:userset;height:15.0pt'>
        <td height=20 class=xl72 style='height:15.0pt'>Darex Auto SRL</td>
        <td class=xl72 style='border-left:none'>Str. Humorului, nr. 96, Etaj 1,
            camera 4, sat &#350;cheia
        </td>
        <td class=xl71 width=150 style='border-left:none;width:113pt'>Comuna
            &#350;cheia
        </td>
        <td class=xl71 width=123 style='border-left:none;width:92pt'>Suceava</td>
        <td class=xl72 style='border-left:none'>Darex Auto SRL</td>
        <td class=xl72 style='border-left:none'>Str. Humorului, nr. 96, sat
            &#350;cheia
        </td>
        <td class=xl71 width=193 style='border-left:none;width:145pt'>Comuna
            &#350;cheia
        </td>
        <td class=xl71 width=162 style='border-left:none;width:122pt'>Suceava</td>
        <td class=xl71 width=140 style='border-left:none;width:105pt'>NISSAN</td>
        <td class=xl83 style='border-left:none'>Da</td>
        <td class=xl83 style='border-left:none'>Da</td>
        <td class=xl83 style='border-left:none'>Da</td>
        <td class=xl83 style='border-left:none'>Nu</td>
    </tr>
    <tr class=xl84 height=20 style='mso-height-source:userset;height:15.0pt'>
        <td height=20 class=xl69 style='height:15.0pt'>Darex Auto SRL</td>
        <td class=xl69 style='border-left:none'>Str. Humorului, nr. 96, Etaj 1,
            camera 4, sat &#350;cheia
        </td>
        <td class=xl68 width=150 style='border-left:none;width:113pt'>Comuna
            &#350;cheia
        </td>
        <td class=xl68 width=123 style='border-left:none;width:92pt'>Suceava</td>
        <td class=xl72 style='border-left:none'>Darex Auto SRL</td>
        <td class=xl69 style='border-left:none'>Calea Bucovinei nr. 62</td>
        <td class=xl73 width=193 style='border-left:none;width:145pt'>R&#259;d&#259;uti</td>
        <td class=xl73 width=162 style='border-left:none;width:122pt'>Suceava</td>
        <td class=xl68 width=140 style='border-left:none;width:105pt'>DACIA</td>
        <td class=xl85 style='border-left:none'>Da</td>
        <td class=xl85 style='border-left:none'>Da</td>
        <td class=xl83 style='border-left:none'>Expert VE (fara demontare baterie)</td>
        <td class=xl85 style='border-left:none'>Nu</td>
    </tr>
    <tr class=xl84 height=20 style='mso-height-source:userset;height:15.0pt'>
        <td height=20 class=xl72 style='height:15.0pt;border-top:none'>Darex Auto SRL</td>
        <td class=xl72 style='border-top:none;border-left:none'>Str. Humorului, nr.
            96, Etaj 1, camera 4, sat &#350;cheia
        </td>
        <td class=xl71 width=150 style='border-top:none;border-left:none;width:113pt'>Comuna
            &#350;cheia
        </td>
        <td class=xl71 width=123 style='border-top:none;border-left:none;width:92pt'>Suceava</td>
        <td class=xl72 style='border-left:none'>Darex Auto SRL</td>
        <td class=xl75 style='border-left:none'>Bd. Mihai Eminescu, nr. 2</td>
        <td class=xl74 width=193 style='border-left:none;width:145pt'>Boto&#351;ani</td>
        <td class=xl74 width=162 style='border-left:none;width:122pt'>Boto&#351;ani</td>
        <td class=xl71 width=140 style='border-top:none;border-left:none;width:105pt'>RENAULT</td>
        <td class=xl83 style='border-top:none;border-left:none'>Nu</td>
        <td class=xl83 style='border-top:none;border-left:none'>Nu</td>
        <td class=xl83 style='border-left:none'>Nu</td>
        <td class=xl83 style='border-top:none;border-left:none'>Nu</td>
    </tr>
    <tr class=xl84 height=20 style='mso-height-source:userset;height:15.0pt'>
        <td height=20 class=xl72 style='height:15.0pt'>Delta Plus Trading SRL</td>
        <td class=xl72 style='border-left:none'>Autostrada
            Pite&#351;ti-Bucure&#351;ti, DN 65B, km. 3 + 750
        </td>
        <td class=xl74 width=150 style='border-left:none;width:113pt'>Pite&#351;ti</td>
        <td class=xl71 width=123 style='border-left:none;width:92pt'>Arge<font
            class="font5">&#351;</font></td>
        <td class=xl72 style='border-left:none'>Delta Plus Trading SRL</td>
        <td class=xl72 style='border-top:none;border-left:none'>Autostrada
            Pite&#351;ti-Bucure&#351;ti, DN 65B, km. 3 + 750
        </td>
        <td class=xl74 width=193 style='border-left:none;width:145pt'>Pite&#351;ti</td>
        <td class=xl71 width=162 style='border-top:none;border-left:none;width:122pt'>Arge<font
            class="font5">&#351;</font></td>
        <td class=xl71 width=140 style='border-left:none;width:105pt'>DACIA</td>
        <td class=xl83 style='border-left:none'>Da</td>
        <td class=xl83 style='border-left:none'>Da</td>
        <td class=xl83 style='border-left:none'>Expert VE (fara demontare baterie)</td>
        <td class=xl83 style='border-left:none'>Nu</td>
    </tr>
    <tr class=xl84 height=20 style='mso-height-source:userset;height:15.0pt'>
        <td height=20 class=xl72 style='height:15.0pt'>DI-BAS AUTO SRL</td>
        <td class=xl72 style='border-left:none'>Soseaua Fundeni, nr. 260-262, Sector
            2
        </td>
        <td class=xl73 width=150 style='border-top:none;border-left:none;width:113pt'>Bucure&#351;ti</td>
        <td class=xl73 width=123 style='border-left:none;width:92pt'>Bucure&#351;ti</td>
        <td class=xl72 style='border-left:none'>DI-BAS AUTO SRL</td>
        <td class=xl72 style='border-left:none'>Soseaua Fundeni, nr. 260-262, Sector
            2
        </td>
        <td class=xl73 width=193 style='border-top:none;border-left:none;width:145pt'>Bucure&#351;ti</td>
        <td class=xl73 width=162 style='border-left:none;width:122pt'>Bucure&#351;ti</td>
        <td class=xl71 width=140 style='border-left:none;width:105pt'>DACIA</td>
        <td class=xl83 style='border-left:none'>Da</td>
        <td class=xl83 style='border-left:none'>Da</td>
        <td class=xl83 style='border-left:none'>Full Expert VE (cu demontare baterie)</td>
        <td class=xl83 style='border-left:none'>Nu</td>
    </tr>
    <tr class=xl84 height=20 style='mso-height-source:userset;height:15.0pt'>
        <td height=20 class=xl72 style='height:15.0pt'>DI-BAS AUTO SRL</td>
        <td class=xl72 style='border-left:none'>Soseaua Fundeni, nr. 260-262, Sector
            2
        </td>
        <td class=xl71 width=150 style='border-top:none;border-left:none;width:113pt'>Bucure&#351;ti</td>
        <td class=xl71 width=123 style='border-top:none;border-left:none;width:92pt'>Bucure&#351;ti</td>
        <td class=xl72 style='border-left:none'>DI-BAS AUTO SRL</td>
        <td class=xl72 style='border-left:none'>Soseaua Fundeni, nr. 260-262, Sector
            2
        </td>
        <td class=xl71 width=193 style='border-top:none;border-left:none;width:145pt'>Bucure&#351;ti</td>
        <td class=xl71 width=162 style='border-top:none;border-left:none;width:122pt'>Bucure&#351;ti</td>
        <td class=xl71 width=140 style='border-left:none;width:105pt'>RENAULT</td>
        <td class=xl83 style='border-left:none'>Da</td>
        <td class=xl83 style='border-left:none'>Da</td>
        <td class=xl83 style='border-left:none'>Full Expert E-TECH (cu demontare
            baterie)
        </td>
        <td class=xl83 style='border-left:none'>Nu</td>
    </tr>
    <tr class=xl84 height=20 style='mso-height-source:userset;height:15.0pt'>
        <td height=20 class=xl72 style='height:15.0pt'>Emilian SRL</td>
        <td class=xl72 style='border-left:none'>Drumul DN1 km 302 + 545</td>
        <td class=xl74 width=150 style='border-left:none;width:113pt'>&#350;elimbar</td>
        <td class=xl74 width=123 style='border-left:none;width:92pt'>Sibiu</td>
        <td class=xl72 style='border-left:none'>Emilian SRL</td>
        <td class=xl72 style='border-left:none'>Drumul DN1 km 302 + 545</td>
        <td class=xl74 width=193 style='border-left:none;width:145pt'>&#350;elimbar</td>
        <td class=xl74 width=162 style='border-left:none;width:122pt'>Sibiu</td>
        <td class=xl71 width=140 style='border-left:none;width:105pt'>DACIA</td>
        <td class=xl83 style='border-left:none'>Da</td>
        <td class=xl83 style='border-left:none'>Da</td>
        <td class=xl83 style='border-left:none'>Expert VE (fara demontare baterie)</td>
        <td class=xl83 style='border-left:none'>Nu</td>
    </tr>
    <tr class=xl84 height=20 style='mso-height-source:userset;height:15.0pt'>
        <td height=20 class=xl72 style='height:15.0pt'>Eurocar Sud SA</td>
        <td class=xl72 style='border-left:none'>&#350;os. Bucure&#351;ti, km 60</td>
        <td class=xl74 width=150 style='border-left:none;width:113pt'>Giurgiu</td>
        <td class=xl74 width=123 style='border-left:none;width:92pt'>Giurgiu</td>
        <td class=xl72 style='border-left:none'>Eurocar Sud SA</td>
        <td class=xl72 style='border-left:none'>&#350;os. Bucure&#351;ti, km 60</td>
        <td class=xl74 width=193 style='border-left:none;width:145pt'>Giurgiu</td>
        <td class=xl74 width=162 style='border-left:none;width:122pt'>Giurgiu</td>
        <td class=xl71 width=140 style='border-left:none;width:105pt'>DACIA</td>
        <td class=xl83 style='border-left:none'>Da</td>
        <td class=xl83 style='border-left:none'>Da</td>
        <td class=xl83 style='border-left:none'>Full Expert VE (cu demontare baterie)</td>
        <td class=xl83 style='border-left:none'>Nu</td>
    </tr>
    <tr class=xl84 height=20 style='mso-height-source:userset;height:15.0pt'>
        <td height=20 class=xl72 style='height:15.0pt'>Eurocar Service Teleorman SRL</td>
        <td class=xl72 style='border-left:none'>Str. Bucure&#351;ti nr.187</td>
        <td class=xl74 width=150 style='border-left:none;width:113pt'>Alexandria</td>
        <td class=xl74 width=123 style='border-left:none;width:92pt'>Teleorman</td>
        <td class=xl72 style='border-left:none'>Eurocar Service Teleorman SRL</td>
        <td class=xl72 style='border-left:none'>Str. Bucure&#351;ti nr.187</td>
        <td class=xl74 width=193 style='border-left:none;width:145pt'>Alexandria</td>
        <td class=xl74 width=162 style='border-left:none;width:122pt'>Teleorman</td>
        <td class=xl71 width=140 style='border-left:none;width:105pt'>DACIA</td>
        <td class=xl83 style='border-left:none'>Da</td>
        <td class=xl83 style='border-left:none'>Da</td>
        <td class=xl83 style='border-left:none'>Expert VE (fara demontare baterie)</td>
        <td class=xl83 style='border-left:none'>Nu</td>
    </tr>
    <tr class=xl84 height=20 style='mso-height-source:userset;height:15.0pt'>
        <td height=20 class=xl72 style='height:15.0pt'>Eurocar Service Teleorman SRL</td>
        <td class=xl72 style='border-left:none'>Str. Bucure&#351;ti nr.187</td>
        <td class=xl71 width=150 style='border-top:none;border-left:none;width:113pt'>Alexandria</td>
        <td class=xl71 width=123 style='border-top:none;border-left:none;width:92pt'>Teleorman</td>
        <td class=xl72 style='border-left:none'>Eurocar Service Teleorman SRL</td>
        <td class=xl72 style='border-left:none'>Str. Bucure&#351;ti nr.187</td>
        <td class=xl71 width=193 style='border-top:none;border-left:none;width:145pt'>Alexandria</td>
        <td class=xl71 width=162 style='border-top:none;border-left:none;width:122pt'>Teleorman</td>
        <td class=xl71 width=140 style='border-left:none;width:105pt'>RENAULT</td>
        <td class=xl83 style='border-left:none'>Da</td>
        <td class=xl83 style='border-left:none'>Da</td>
        <td class=xl83 style='border-left:none'>Expert E-TECH (fara demontare
            baterie)
        </td>
        <td class=xl83 style='border-left:none'>Nu</td>
    </tr>
    <tr class=xl84 height=20 style='mso-height-source:userset;height:15.0pt'>
        <td height=20 class=xl72 style='height:15.0pt'>Euro-Motor SRL</td>
        <td class=xl72 style='border-left:none'>Calea Bucure&#351;ti, nr. 132</td>
        <td class=xl73 width=150 style='border-left:none;width:113pt'>Bra&#351;ov</td>
        <td class=xl73 width=123 style='border-left:none;width:92pt'>Bra&#351;ov</td>
        <td class=xl72 style='border-left:none'>Euro-Motor SRL</td>
        <td class=xl72 style='border-left:none'>Calea Bucure&#351;ti, nr. 132</td>
        <td class=xl73 width=193 style='border-left:none;width:145pt'>Bra&#351;ov</td>
        <td class=xl73 width=162 style='border-left:none;width:122pt'>Bra&#351;ov</td>
        <td class=xl71 width=140 style='border-left:none;width:105pt'>DACIA</td>
        <td class=xl83 style='border-left:none'>Da</td>
        <td class=xl83 style='border-left:none'>Da</td>
        <td class=xl83 style='border-left:none'>Expert VE (fara demontare baterie)</td>
        <td class=xl83 style='border-left:none'>Nu</td>
    </tr>
    <tr class=xl84 height=20 style='mso-height-source:userset;height:15.0pt'>
        <td height=20 class=xl72 style='height:15.0pt'>Euro-Motor SRL</td>
        <td class=xl72 style='border-left:none'>Calea Bucure&#351;ti, nr. 132</td>
        <td class=xl71 width=150 style='border-top:none;border-left:none;width:113pt'>Bra&#351;ov</td>
        <td class=xl71 width=123 style='border-top:none;border-left:none;width:92pt'>Bra&#351;ov</td>
        <td class=xl72 style='border-left:none'>Euro-Motor SRL</td>
        <td class=xl72 style='border-left:none'>Calea Bucure&#351;ti, nr. 132</td>
        <td class=xl71 width=193 style='border-top:none;border-left:none;width:145pt'>Bra&#351;ov</td>
        <td class=xl71 width=162 style='border-top:none;border-left:none;width:122pt'>Bra&#351;ov</td>
        <td class=xl71 width=140 style='border-left:none;width:105pt'>RENAULT</td>
        <td class=xl83 style='border-left:none'>Da</td>
        <td class=xl83 style='border-left:none'>Da</td>
        <td class=xl83 style='border-left:none'>Expert E-TECH (fara demontare
            baterie)
        </td>
        <td class=xl83 style='border-left:none'>Nu</td>
    </tr>
    <tr class=xl84 height=20 style='mso-height-source:userset;height:15.0pt'>
        <td height=20 class=xl72 style='height:15.0pt'>Euro-Motor SRL</td>
        <td class=xl72 style='border-left:none'>Calea Bucure&#351;ti, nr. 132</td>
        <td class=xl71 width=150 style='border-left:none;width:113pt'>Bra&#351;ov</td>
        <td class=xl71 width=123 style='border-left:none;width:92pt'>Bra&#351;ov</td>
        <td class=xl72 style='border-left:none'>Euro-Motor SRL</td>
        <td class=xl72 style='border-left:none'>Calea Bucure&#351;ti, nr. 132</td>
        <td class=xl71 width=193 style='border-left:none;width:145pt'>Bra&#351;ov</td>
        <td class=xl71 width=162 style='border-left:none;width:122pt'>Bra&#351;ov</td>
        <td class=xl71 width=140 style='border-left:none;width:105pt'>NISSAN</td>
        <td class=xl83 style='border-left:none'>Da</td>
        <td class=xl83 style='border-left:none'>Da</td>
        <td class=xl83 style='border-left:none'>Da</td>
        <td class=xl83 style='border-left:none'>Nu</td>
    </tr>
    <tr class=xl84 height=20 style='mso-height-source:userset;height:15.0pt'>
        <td height=20 class=xl72 style='height:15.0pt'>Euro-Motor SRL</td>
        <td class=xl72 style='border-left:none'>Calea Bucure&#351;ti, nr. 132</td>
        <td class=xl71 width=150 style='border-left:none;width:113pt'>Bra&#351;ov</td>
        <td class=xl71 width=123 style='border-left:none;width:92pt'>Bra&#351;ov</td>
        <td class=xl72 style='border-left:none'>Euro-Motor SRL</td>
        <td class=xl82 style='border-left:none'>Calea Bucure&#351;ti nr. 240</td>
        <td class=xl71 width=193 style='border-left:none;width:145pt'>Bra&#351;ov</td>
        <td class=xl71 width=162 style='border-left:none;width:122pt'>Bra&#351;ov</td>
        <td class=xl71 width=140 style='border-left:none;width:105pt'>NISSAN</td>
        <td class=xl83 style='border-left:none'>Nu</td>
        <td class=xl83 style='border-left:none'>Nu</td>
        <td class=xl83 style='border-left:none'>Nu</td>
        <td class=xl83 style='border-left:none'>Nu</td>
    </tr>
    <tr class=xl84 height=20 style='mso-height-source:userset;height:15.0pt'>
        <td height=20 class=xl72 style='height:15.0pt'>Flamicom Impex S.R.L.</td>
        <td class=xl72 style='border-left:none'>Str. R<font class="font5">&#259;zboieni
            nr. 37</font></td>
        <td class=xl76 width=150 style='border-left:none;width:113pt'>Pite&#351;ti</td>
        <td class=xl76 width=123 style='border-left:none;width:92pt'>Arge&#351;</td>
        <td class=xl72 style='border-left:none'>Flamicom Impex S.R.L.</td>
        <td class=xl77 style='border-left:none'>Str. Barajului nr. 10</td>
        <td class=xl76 width=193 style='border-left:none;width:145pt'>Râmnicu Vâlcea</td>
        <td class=xl76 width=162 style='border-left:none;width:122pt'>Vâlcea</td>
        <td class=xl71 width=140 style='border-left:none;width:105pt'>DACIA</td>
        <td class=xl83 style='border-left:none'>Da</td>
        <td class=xl83 style='border-left:none'>Da</td>
        <td class=xl83 style='border-left:none'>Full Expert VE (cu demontare baterie)</td>
        <td class=xl83 style='border-left:none'>Nu</td>
    </tr>
    <tr class=xl84 height=20 style='mso-height-source:userset;height:15.0pt'>
        <td height=20 class=xl72 style='height:15.0pt'>Flamicom Impex S.R.L.</td>
        <td class=xl72 style='border-left:none'>Str. R<font class="font5">&#259;zboieni
            nr. 37</font></td>
        <td class=xl71 width=150 style='border-top:none;border-left:none;width:113pt'>Pite<font
            class="font5">&#351;ti</font></td>
        <td class=xl71 width=123 style='border-top:none;border-left:none;width:92pt'>Arge<font
            class="font5">&#351;</font></td>
        <td class=xl72 style='border-left:none'>Flamicom Impex S.R.L.</td>
        <td class=xl72 style='border-top:none;border-left:none'>Str. Barajului nr. 10</td>
        <td class=xl71 width=193 style='border-top:none;border-left:none;width:145pt'>R<font
            class="font5">âmnicu Vâlcea</font></td>
        <td class=xl71 width=162 style='border-top:none;border-left:none;width:122pt'>V<font
            class="font5">âlcea</font></td>
        <td class=xl71 width=140 style='border-left:none;width:105pt'>RENAULT</td>
        <td class=xl83 style='border-left:none'>Da</td>
        <td class=xl83 style='border-left:none'>Da</td>
        <td class=xl83 style='border-left:none'>Full Expert E-TECH (cu demontare
            baterie)
        </td>
        <td class=xl83 style='border-left:none'>Nu</td>
    </tr>
    <tr class=xl84 height=20 style='mso-height-source:userset;height:15.0pt'>
        <td height=20 class=xl72 style='height:15.0pt'>Flamicom Impex S.R.L.</td>
        <td class=xl72 style='border-left:none'>Str. R<font class="font5">&#259;zboieni
            nr. 37</font></td>
        <td class=xl71 width=150 style='border-left:none;width:113pt'>Pite<font
            class="font5">&#351;ti</font></td>
        <td class=xl71 width=123 style='border-left:none;width:92pt'>Arge<font
            class="font5">&#351;</font></td>
        <td class=xl72 style='border-left:none'>Flamicom Impex S.R.L.</td>
        <td class=xl72 style='border-left:none'>Str. Barajului nr. 10</td>
        <td class=xl71 width=193 style='border-left:none;width:145pt'>R<font
            class="font5">âmnicu Vâlcea</font></td>
        <td class=xl71 width=162 style='border-left:none;width:122pt'>V<font
            class="font5">âlcea</font></td>
        <td class=xl71 width=140 style='border-left:none;width:105pt'>NISSAN</td>
        <td class=xl83 style='border-left:none'>Da</td>
        <td class=xl83 style='border-left:none'>Da</td>
        <td class=xl83 style='border-left:none'>Da</td>
        <td class=xl83 style='border-left:none'>Nu</td>
    </tr>
    <tr class=xl84 height=20 style='mso-height-source:userset;height:15.0pt'>
        <td height=20 class=xl72 style='height:15.0pt'>Gammis SRL</td>
        <td class=xl72 style='border-left:none'>Str. Brateiului nr. 35</td>
        <td class=xl74 width=150 style='border-left:none;width:113pt'>Medias</td>
        <td class=xl75 style='border-left:none'>Sibiu</td>
        <td class=xl72 style='border-left:none'>Gammis SRL</td>
        <td class=xl72 style='border-left:none'>Str. Brateiului nr. 35</td>
        <td class=xl74 width=193 style='border-left:none;width:145pt'>Medias</td>
        <td class=xl75 style='border-left:none'>Sibiu</td>
        <td class=xl71 width=140 style='border-left:none;width:105pt'>DACIA</td>
        <td class=xl83 style='border-left:none'>Da</td>
        <td class=xl83 style='border-left:none'>Subcontractata</td>
        <td class=xl83 style='border-left:none'>Expert VE (fara demontare baterie)</td>
        <td class=xl83 style='border-left:none'>Nu</td>
    </tr>
    <tr class=xl84 height=20 style='mso-height-source:userset;height:15.0pt'>
        <td height=20 class=xl72 style='height:15.0pt'>General Autocom SRL<span
            style='mso-spacerun:yes'> </span></td>
        <td class=xl72 style='border-left:none'>Str. Euro 85 nr. 160, Sat
            M&#259;r&#259;cineni
        </td>
        <td class=xl68 width=150 style='border-top:none;border-left:none;width:113pt'>Comuna
            M&#259;r&#259;cineni
        </td>
        <td class=xl68 width=123 style='border-top:none;border-left:none;width:92pt'>Buz&#259;u</td>
        <td class=xl72 style='border-left:none'>General Autocom SRL<span
            style='mso-spacerun:yes'> </span></td>
        <td class=xl72 style='border-left:none'>Str. Euro 85 nr. 160, Sat
            M&#259;r&#259;cineni
        </td>
        <td class=xl68 width=193 style='border-top:none;border-left:none;width:145pt'>Comuna
            M&#259;r&#259;cineni
        </td>
        <td class=xl68 width=162 style='border-top:none;border-left:none;width:122pt'>Buz&#259;u</td>
        <td class=xl71 width=140 style='border-left:none;width:105pt'>DACIA</td>
        <td class=xl83 style='border-left:none'>Da</td>
        <td class=xl83 style='border-left:none'>Da</td>
        <td class=xl83 style='border-left:none'>Expert VE (fara demontare baterie)</td>
        <td class=xl83 style='border-left:none'>Nu</td>
    </tr>
    <tr class=xl84 height=20 style='mso-height-source:userset;height:15.0pt'>
        <td height=20 class=xl72 style='height:15.0pt'>General Autocom SRL<span
            style='mso-spacerun:yes'> </span></td>
        <td class=xl72 style='border-left:none'>Str. Euro 85 nr. 160, Sat
            M&#259;r&#259;cineni
        </td>
        <td class=xl71 width=150 style='border-top:none;border-left:none;width:113pt'>Comuna
            M&#259;r&#259;cineni
        </td>
        <td class=xl71 width=123 style='border-top:none;border-left:none;width:92pt'>Buz&#259;u</td>
        <td class=xl72 style='border-left:none'>General Autocom SRL<span
            style='mso-spacerun:yes'> </span></td>
        <td class=xl72 style='border-left:none'>Str. Euro 85 nr. 160, Sat
            M&#259;r&#259;cineni
        </td>
        <td class=xl71 width=193 style='border-top:none;border-left:none;width:145pt'>Comuna
            M&#259;r&#259;cineni
        </td>
        <td class=xl71 width=162 style='border-top:none;border-left:none;width:122pt'>Buz&#259;u</td>
        <td class=xl71 width=140 style='border-left:none;width:105pt'>NISSAN</td>
        <td class=xl83 style='border-left:none'>Da</td>
        <td class=xl83 style='border-left:none'>Da</td>
        <td class=xl83 style='border-left:none'>Da</td>
        <td class=xl83 style='border-left:none'>Nu</td>
    </tr>
    <tr class=xl84 height=20 style='mso-height-source:userset;height:15.0pt'>
        <td height=20 class=xl72 style='height:15.0pt'>General Autocom SRL<span
            style='mso-spacerun:yes'> </span></td>
        <td class=xl72 style='border-left:none'>Str. Euro 85 nr. 160, Sat
            M&#259;r&#259;cineni
        </td>
        <td class=xl71 width=150 style='border-left:none;width:113pt'>Comuna
            M&#259;r&#259;cineni
        </td>
        <td class=xl71 width=123 style='border-left:none;width:92pt'>Buz&#259;u</td>
        <td class=xl72 style='border-left:none'>General Autocom SRL<span
            style='mso-spacerun:yes'> </span></td>
        <td class=xl79 style='border-left:none'>Str. EURO 85 nr. 39-41, Sat
            M&#259;r&#259;cineni
        </td>
        <td class=xl68 width=193 style='border-left:none;width:145pt'>Comuna
            M&#259;r&#259;cineni
        </td>
        <td class=xl71 width=162 style='border-left:none;width:122pt'>Buz&#259;u</td>
        <td class=xl71 width=140 style='border-left:none;width:105pt'>RENAULT</td>
        <td class=xl83 style='border-left:none'>Da</td>
        <td class=xl83 style='border-left:none'>Da</td>
        <td class=xl83 style='border-left:none'>Expert E-TECH (fara demontare
            baterie)
        </td>
        <td class=xl83 style='border-left:none'>Nu</td>
    </tr>
    <tr class=xl84 height=20 style='mso-height-source:userset;height:15.0pt'>
        <td height=20 class=xl72 style='height:15.0pt'>General Autocom SRL<span
            style='mso-spacerun:yes'> </span></td>
        <td class=xl72 style='border-left:none'>Str. Euro 85 nr. 160, Sat
            M&#259;r&#259;cineni
        </td>
        <td class=xl71 width=150 style='border-left:none;width:113pt'>Comuna
            M&#259;r&#259;cineni
        </td>
        <td class=xl71 width=123 style='border-left:none;width:92pt'>Buz&#259;u</td>
        <td class=xl72 style='border-left:none'>General Autocom SRL<span
            style='mso-spacerun:yes'> </span></td>
        <td class=xl69 style='border-top:none;border-left:none'>Calea Munteniei, DN2,
            E85 nr. 51
        </td>
        <td class=xl73 width=193 style='border-top:none;border-left:none;width:145pt'>Foc&#351;ani</td>
        <td class=xl73 width=162 style='border-left:none;width:122pt'>Vrancea</td>
        <td class=xl71 width=140 style='border-left:none;width:105pt'>DACIA</td>
        <td class=xl83 style='border-left:none'>Da</td>
        <td class=xl83 style='border-left:none'>Da</td>
        <td class=xl83 style='border-left:none'>Expert VE (fara demontare baterie)</td>
        <td class=xl83 style='border-left:none'>Nu</td>
    </tr>
    <tr class=xl84 height=20 style='mso-height-source:userset;height:15.0pt'>
        <td height=20 class=xl72 style='height:15.0pt'>General Autocom SRL<span
            style='mso-spacerun:yes'> </span></td>
        <td class=xl72 style='border-left:none'>Str. Euro 85 nr. 160, Sat
            M&#259;r&#259;cineni
        </td>
        <td class=xl71 width=150 style='border-left:none;width:113pt'>Comuna
            M&#259;r&#259;cineni
        </td>
        <td class=xl71 width=123 style='border-left:none;width:92pt'>Buz&#259;u</td>
        <td class=xl72 style='border-left:none'>General Autocom SRL<span
            style='mso-spacerun:yes'> </span></td>
        <td class=xl69 style='border-top:none;border-left:none'>Calea Munteniei, DN2,
            E85 nr. 51
        </td>
        <td class=xl73 width=193 style='border-top:none;border-left:none;width:145pt'>Foc&#351;ani</td>
        <td class=xl73 width=162 style='border-top:none;border-left:none;width:122pt'>Vrancea</td>
        <td class=xl71 width=140 style='border-left:none;width:105pt'>RENAULT</td>
        <td class=xl83 style='border-left:none'>Da</td>
        <td class=xl83 style='border-left:none'>Da</td>
        <td class=xl83 style='border-left:none'>Expert E-TECH (fara demontare
            baterie)
        </td>
        <td class=xl83 style='border-left:none'>Nu</td>
    </tr>
    <tr class=xl84 height=20 style='mso-height-source:userset;height:15.0pt'>
        <td height=20 class=xl72 style='height:15.0pt'>General Autocom SRL<span
            style='mso-spacerun:yes'> </span></td>
        <td class=xl72 style='border-left:none'>Str. Euro 85 nr. 160, Sat
            M&#259;r&#259;cineni
        </td>
        <td class=xl71 width=150 style='border-left:none;width:113pt'>Comuna
            M&#259;r&#259;cineni
        </td>
        <td class=xl71 width=123 style='border-left:none;width:92pt'>Buz&#259;u</td>
        <td class=xl72 style='border-left:none'>General Autocom SRL<span
            style='mso-spacerun:yes'> </span></td>
        <td class=xl69 style='border-top:none;border-left:none'>Calea Munteniei, DN2,
            E85 nr. 51
        </td>
        <td class=xl73 width=193 style='border-top:none;border-left:none;width:145pt'>Foc&#351;ani</td>
        <td class=xl73 width=162 style='border-top:none;border-left:none;width:122pt'>Vrancea</td>
        <td class=xl71 width=140 style='border-left:none;width:105pt'>NISSAN</td>
        <td class=xl83 style='border-left:none'>Da</td>
        <td class=xl83 style='border-left:none'>Da</td>
        <td class=xl83 style='border-left:none'>Nu</td>
        <td class=xl83 style='border-left:none'>Nu</td>
    </tr>
    <tr class=xl84 height=20 style='mso-height-source:userset;height:15.0pt'>
        <td height=20 class=xl72 style='height:15.0pt'>Grig Auto SRL</td>
        <td class=xl72 style='border-left:none'>Str. Regele Carol, nr. 54</td>
        <td class=xl73 width=150 style='border-left:none;width:113pt'>Dr&#259;g&#259;&#351;ani</td>
        <td class=xl73 width=123 style='border-left:none;width:92pt'>Vâlcea</td>
        <td class=xl72 style='border-left:none'>Grig Auto SRL</td>
        <td class=xl72 style='border-top:none;border-left:none'>Str. Regele Carol,
            nr. 54
        </td>
        <td class=xl73 width=193 style='border-top:none;border-left:none;width:145pt'>Dr&#259;g&#259;&#351;ani</td>
        <td class=xl73 width=162 style='border-top:none;border-left:none;width:122pt'>Vâlcea</td>
        <td class=xl71 width=140 style='border-left:none;width:105pt'>DACIA</td>
        <td class=xl83 style='border-left:none'>Da</td>
        <td class=xl83 style='border-left:none'>Da</td>
        <td class=xl83 style='border-left:none'>Expert VE (fara demontare baterie)</td>
        <td class=xl83 style='border-left:none'>Nu</td>
    </tr>
    <tr class=xl84 height=20 style='mso-height-source:userset;height:15.0pt'>
        <td height=20 class=xl72 style='height:15.0pt'>Grig Auto SRL</td>
        <td class=xl72 style='border-left:none'>Str. Regele Carol, nr. 54</td>
        <td class=xl71 width=150 style='border-top:none;border-left:none;width:113pt'>Dr&#259;g&#259;&#351;ani</td>
        <td class=xl71 width=123 style='border-top:none;border-left:none;width:92pt'>Vâlcea</td>
        <td class=xl72 style='border-left:none'>Grig Auto SRL</td>
        <td class=xl75 style='border-left:none'>Str. Profesor Nicolita Sanda, nr. 3</td>
        <td class=xl73 width=193 style='border-top:none;border-left:none;width:145pt'>Dr&#259;g&#259;&#351;ani</td>
        <td class=xl73 width=162 style='border-top:none;border-left:none;width:122pt'>Vâlcea</td>
        <td class=xl71 width=140 style='border-left:none;width:105pt'>DACIA</td>
        <td class=xl83 style='border-left:none'>Nu</td>
        <td class=xl83 style='border-left:none'>Nu</td>
        <td class=xl83 style='border-left:none'>Nu</td>
        <td class=xl83 style='border-left:none'>Nu</td>
    </tr>
    <tr class=xl84 height=20 style='mso-height-source:userset;height:15.0pt'>
        <td height=20 class=xl72 style='height:15.0pt'>IATSA Gala&#355;i SA<span
            style='mso-spacerun:yes'> </span></td>
        <td class=xl72 style='border-left:none'>Str. Drumul Viilor nr.6</td>
        <td class=xl73 width=150 style='border-left:none;width:113pt'>Gala&#355;i</td>
        <td class=xl73 width=123 style='border-left:none;width:92pt'>Gala&#355;i</td>
        <td class=xl72 style='border-left:none'>IATSA Gala&#355;i SA<span
            style='mso-spacerun:yes'> </span></td>
        <td class=xl72 style='border-top:none;border-left:none'>Str. Drumul Viilor
            nr.6
        </td>
        <td class=xl73 width=193 style='border-top:none;border-left:none;width:145pt'>Gala&#355;i</td>
        <td class=xl73 width=162 style='border-top:none;border-left:none;width:122pt'>Gala&#355;i</td>
        <td class=xl71 width=140 style='border-left:none;width:105pt'>DACIA</td>
        <td class=xl83 style='border-left:none'>Da</td>
        <td class=xl83 style='border-left:none'>Da</td>
        <td class=xl83 style='border-left:none'>Expert VE (fara demontare baterie)</td>
        <td class=xl83 style='border-left:none'>Nu</td>
    </tr>
    <tr class=xl84 height=20 style='mso-height-source:userset;height:15.0pt'>
        <td height=20 class=xl72 style='height:15.0pt'>International Service Slobozia
            SA
        </td>
        <td class=xl72 style='border-left:none'>&#350;os.
            Bucure&#351;ti-Constan&#355;a Km110
        </td>
        <td class=xl73 width=150 style='border-top:none;border-left:none;width:113pt'>Slobozia</td>
        <td class=xl73 width=123 style='border-top:none;border-left:none;width:92pt'>Ialomita</td>
        <td class=xl72 style='border-left:none'>International Service Slobozia SA</td>
        <td class=xl72 style='border-left:none'>&#350;os.
            Bucure&#351;ti-Constan&#355;a Km110
        </td>
        <td class=xl73 width=193 style='border-top:none;border-left:none;width:145pt'>Slobozia</td>
        <td class=xl73 width=162 style='border-top:none;border-left:none;width:122pt'>Ialomita</td>
        <td class=xl71 width=140 style='border-left:none;width:105pt'>DACIA</td>
        <td class=xl83 style='border-left:none'>Da</td>
        <td class=xl83 style='border-left:none'>Da</td>
        <td class=xl83 style='border-left:none'>Expert VE (fara demontare baterie)</td>
        <td class=xl83 style='border-left:none'>Nu</td>
    </tr>
    <tr class=xl84 height=20 style='mso-height-source:userset;height:15.0pt'>
        <td height=20 class=xl72 style='height:15.0pt'>International Service Slobozia
            SA
        </td>
        <td class=xl72 style='border-left:none'>&#350;os.
            Bucure&#351;ti-Constan&#355;a Km110
        </td>
        <td class=xl71 width=150 style='border-top:none;border-left:none;width:113pt'>Slobozia</td>
        <td class=xl71 width=123 style='border-top:none;border-left:none;width:92pt'>Ialomita</td>
        <td class=xl72 style='border-left:none'>International Service Slobozia SA</td>
        <td class=xl72 style='border-left:none'>&#350;os.
            Bucure&#351;ti-Constan&#355;a Km110
        </td>
        <td class=xl71 width=193 style='border-top:none;border-left:none;width:145pt'>Slobozia</td>
        <td class=xl71 width=162 style='border-top:none;border-left:none;width:122pt'>Ialomita</td>
        <td class=xl71 width=140 style='border-left:none;width:105pt'>RENAULT</td>
        <td class=xl83 style='border-left:none'>Da</td>
        <td class=xl83 style='border-left:none'>Da</td>
        <td class=xl83 style='border-left:none'>Expert E-TECH (fara demontare
            baterie)
        </td>
        <td class=xl83 style='border-left:none'>Nu</td>
    </tr>
    <tr class=xl84 height=20 style='mso-height-source:userset;height:15.0pt'>
        <td height=20 class=xl72 style='height:15.0pt'>Laz&#259;r Service Com S.R.L.</td>
        <td class=xl72 style='border-left:none'>Str. Victoriei nr. 332</td>
        <td class=xl68 width=150 style='border-left:none;width:113pt'>Târgu Jiu</td>
        <td class=xl69 style='border-left:none'>Gorj</td>
        <td class=xl72 style='border-left:none'>Laz&#259;r Service Com S.R.L.</td>
        <td class=xl79 style='border-left:none'>Aleea Victoriei nr. 1</td>
        <td class=xl68 width=193 style='border-left:none;width:145pt'>Târgu Jiu</td>
        <td class=xl69 style='border-left:none'>Gorj</td>
        <td class=xl71 width=140 style='border-left:none;width:105pt'>DACIA</td>
        <td class=xl83 style='border-left:none'>Da</td>
        <td class=xl83 style='border-left:none'>Da</td>
        <td class=xl83 style='border-left:none'>Full Expert VE (cu demontare baterie)</td>
        <td class=xl83 style='border-left:none'>Nu</td>
    </tr>
    <tr class=xl84 height=20 style='mso-height-source:userset;height:15.0pt'>
        <td height=20 class=xl72 style='height:15.0pt'>Laz&#259;r Service Com S.R.L.</td>
        <td class=xl72 style='border-left:none'>Str. Victoriei nr. 332</td>
        <td class=xl71 width=150 style='border-top:none;border-left:none;width:113pt'>T<font
            class="font5">ârgu Jiu</font></td>
        <td class=xl71 width=123 style='border-top:none;border-left:none;width:92pt'>Gorj</td>
        <td class=xl72 style='border-left:none'>Laz&#259;r Service Com S.R.L.</td>
        <td class=xl72 style='border-top:none;border-left:none'>Aleea Victoriei nr. 1</td>
        <td class=xl71 width=193 style='border-top:none;border-left:none;width:145pt'>T<font
            class="font5">ârgu Jiu</font></td>
        <td class=xl71 width=162 style='border-top:none;border-left:none;width:122pt'>Gorj</td>
        <td class=xl71 width=140 style='border-left:none;width:105pt'>RENAULT</td>
        <td class=xl83 style='border-left:none'>Da</td>
        <td class=xl83 style='border-left:none'>Da</td>
        <td class=xl83 style='border-left:none'>Full Expert E-TECH (cu demontare
            baterie)
        </td>
        <td class=xl83 style='border-left:none'>Nu</td>
    </tr>
    <tr class=xl84 height=20 style='mso-height-source:userset;height:15.0pt'>
        <td height=20 class=xl72 style='height:15.0pt'>Laz&#259;r Service Com S.R.L.</td>
        <td class=xl72 style='border-left:none'>Str. Victoriei nr. 332</td>
        <td class=xl71 width=150 style='border-left:none;width:113pt'>T<font
            class="font5">ârgu Jiu</font></td>
        <td class=xl71 width=123 style='border-left:none;width:92pt'>Gorj</td>
        <td class=xl72 style='border-left:none'>Laz&#259;r Service Com S.R.L.</td>
        <td class=xl72 style='border-left:none'>Aleea Victoriei nr. 1</td>
        <td class=xl71 width=193 style='border-left:none;width:145pt'>T<font
            class="font5">ârgu Jiu</font></td>
        <td class=xl71 width=162 style='border-left:none;width:122pt'>Gorj</td>
        <td class=xl71 width=140 style='border-left:none;width:105pt'>NISSAN</td>
        <td class=xl83 style='border-left:none'>Da</td>
        <td class=xl83 style='border-left:none'>Da</td>
        <td class=xl83 style='border-left:none'>Da</td>
        <td class=xl83 style='border-left:none'>Nu</td>
    </tr>
    <tr class=xl84 height=20 style='mso-height-source:userset;height:15.0pt'>
        <td height=20 class=xl69 style='height:15.0pt'>Laz&#259;r Service Com S.R.L.</td>
        <td class=xl69 style='border-left:none'>Str. Victoriei nr. 332</td>
        <td class=xl68 width=150 style='border-left:none;width:113pt'>T<font
            class="font5">ârgu Jiu</font></td>
        <td class=xl68 width=123 style='border-left:none;width:92pt'>Gorj</td>
        <td class=xl72 style='border-left:none'>Laz&#259;r Service Com S.R.L.</td>
        <td class=xl79 style='border-left:none'>Calea T<font class="font9">â</font><font
            class="font6">rgu Jiului nr. 128</font></td>
        <td class=xl69 style='border-left:none'>Drobeta-Turnu Severin</td>
        <td class=xl73 width=162 style='border-left:none;width:122pt'>Mehedin&#355;i</td>
        <td class=xl68 width=140 style='border-left:none;width:105pt'>DACIA</td>
        <td class=xl83 style='border-left:none'>Da</td>
        <td class=xl83 style='border-left:none'>Da</td>
        <td class=xl83 style='border-left:none'>Expert VE (fara demontare baterie)</td>
        <td class=xl83 style='border-left:none'>Nu</td>
    </tr>
    <tr class=xl84 height=20 style='mso-height-source:userset;height:15.0pt'>
        <td height=20 class=xl72 style='height:15.0pt;border-top:none'>Laz&#259;r
            Service Com S.R.L.
        </td>
        <td class=xl72 style='border-top:none;border-left:none'>Str. Victoriei nr.
            332
        </td>
        <td class=xl71 width=150 style='border-top:none;border-left:none;width:113pt'>T<font
            class="font5">ârgu Jiu</font></td>
        <td class=xl71 width=123 style='border-top:none;border-left:none;width:92pt'>Gorj</td>
        <td class=xl72 style='border-left:none'>Laz&#259;r Service Com S.R.L.</td>
        <td class=xl79 style='border-top:none;border-left:none'>Calea T<font
            class="font9">â</font><font class="font6">rgu Jiului nr. 128</font></td>
        <td class=xl69 style='border-top:none;border-left:none'>Drobeta-Turnu Severin</td>
        <td class=xl73 width=162 style='border-top:none;border-left:none;width:122pt'>Mehedin&#355;i</td>
        <td class=xl71 width=140 style='border-top:none;border-left:none;width:105pt'>RENAULT</td>
        <td class=xl83 style='border-left:none'>Da</td>
        <td class=xl83 style='border-left:none'>Da</td>
        <td class=xl83 style='border-left:none'>Expert E-TECH (fara demontare
            baterie)
        </td>
        <td class=xl83 style='border-left:none'>Nu</td>
    </tr>
    <tr class=xl84 height=20 style='mso-height-source:userset;height:15.0pt'>
        <td height=20 class=xl72 style='height:15.0pt'>Mac Auto S.R.L.</td>
        <td class=xl72 style='border-left:none'>Str. Tunari nr. 22</td>
        <td class=xl75 style='border-left:none'>Slatina</td>
        <td class=xl75 style='border-left:none'>Olt</td>
        <td class=xl72 style='border-left:none'>Mac Auto S.R.L.</td>
        <td class=xl72 style='border-top:none;border-left:none'>Str. Tunari nr. 22</td>
        <td class=xl75 style='border-left:none'>Slatina</td>
        <td class=xl75 style='border-left:none'>Olt</td>
        <td class=xl71 width=140 style='border-left:none;width:105pt'>DACIA</td>
        <td class=xl83 style='border-left:none'>Nu</td>
        <td class=xl83 style='border-left:none'>Nu</td>
        <td class=xl83 style='border-left:none'>N/A</td>
        <td class=xl83 style='border-left:none'>Nu</td>
    </tr>
    <tr class=xl84 height=20 style='mso-height-source:userset;height:15.0pt'>
        <td height=20 class=xl72 style='height:15.0pt'>Materom Auto Expert S.R.L.</td>
        <td class=xl72 style='border-left:none'>Str. Viilor, Nr 799, (biroul nr. 2),
            etaj 1
        </td>
        <td class=xl73 width=150 style='border-top:none;border-left:none;width:113pt'>Criste&#351;ti</td>
        <td class=xl73 width=123 style='border-top:none;border-left:none;width:92pt'>Mure&#351;</td>
        <td class=xl72 style='border-left:none'>Materom Auto Expert S.R.L.</td>
        <td class=xl86 width=505 style='border-left:none;width:379pt'>Str. Budiului
            nr. 2/A<span style='mso-spacerun:yes'> </span></td>
        <td class=xl78 width=193 style='border-top:none;border-left:none;width:145pt'>Tg.
            Mure&#351;
        </td>
        <td class=xl78 width=162 style='border-top:none;border-left:none;width:122pt'>Mure&#351;</td>
        <td class=xl71 width=140 style='border-left:none;width:105pt'>NISSAN</td>
        <td class=xl83 style='border-left:none'>Da</td>
        <td class=xl83 style='border-left:none'>Subcontractata</td>
        <td class=xl83 style='border-left:none'>Da</td>
        <td class=xl83 style='border-left:none'>Nu</td>
    </tr>
    <tr class=xl84 height=20 style='mso-height-source:userset;height:15.0pt'>
        <td height=20 class=xl72 style='height:15.0pt'>Materom Auto Expert S.R.L.</td>
        <td class=xl72 style='border-left:none'>Str. Viilor, Nr 799, (biroul nr. 2),
            etaj 1
        </td>
        <td class=xl71 width=150 style='border-top:none;border-left:none;width:113pt'>Criste&#351;ti</td>
        <td class=xl71 width=123 style='border-top:none;border-left:none;width:92pt'>Mure&#351;</td>
        <td class=xl72 style='border-left:none'>Materom Auto Expert S.R.L.</td>
        <td class=xl69 style='border-top:none;border-left:none'>&#350;oseaua
            Sighi&#351;oarei, Nr 411
        </td>
        <td class=xl73 width=193 style='border-top:none;border-left:none;width:145pt'>Corunca</td>
        <td class=xl78 width=162 style='border-top:none;border-left:none;width:122pt'>Mure&#351;</td>
        <td class=xl71 width=140 style='border-left:none;width:105pt'>DACIA</td>
        <td class=xl83 style='border-left:none'>Da</td>
        <td class=xl83 style='border-left:none'>Subcontractata</td>
        <td class=xl83 style='border-left:none'>Expert VE (fara demontare baterie)</td>
        <td class=xl83 style='border-left:none'>Nu</td>
    </tr>
    <tr class=xl84 height=20 style='mso-height-source:userset;height:15.0pt'>
        <td height=20 class=xl72 style='height:15.0pt'>Materom Auto Expert S.R.L.</td>
        <td class=xl72 style='border-left:none'>Str. Viilor, Nr 799, (biroul nr. 2),
            etaj 1
        </td>
        <td class=xl71 width=150 style='border-left:none;width:113pt'>Criste&#351;ti</td>
        <td class=xl71 width=123 style='border-left:none;width:92pt'>Mure&#351;</td>
        <td class=xl72 style='border-left:none'>Materom Auto Expert S.R.L.</td>
        <td class=xl69 style='border-top:none;border-left:none'>&#350;oseaua
            Sighi&#351;oarei, Nr 411
        </td>
        <td class=xl73 width=193 style='border-top:none;border-left:none;width:145pt'>Corunca</td>
        <td class=xl78 width=162 style='border-top:none;border-left:none;width:122pt'>Mure&#351;</td>
        <td class=xl71 width=140 style='border-left:none;width:105pt'>RENAULT</td>
        <td class=xl83 style='border-left:none'>Da</td>
        <td class=xl83 style='border-left:none'>Subcontractata</td>
        <td class=xl83 style='border-left:none'>Expert E-TECH (fara demontare
            baterie)
        </td>
        <td class=xl83 style='border-left:none'>Nu</td>
    </tr>
    <tr class=xl84 height=20 style='mso-height-source:userset;height:15.0pt'>
        <td height=20 class=xl72 style='height:15.0pt'>Mavexim SRL</td>
        <td class=xl72 style='border-left:none'>Bd. Petrochimi&#351;tilor, nr 5, bl
            32, parter
        </td>
        <td class=xl74 width=150 style='border-left:none;width:113pt'>Pite&#351;ti</td>
        <td class=xl68 width=123 style='border-left:none;width:92pt'>Arge&#351;</td>
        <td class=xl72 style='border-left:none'>Mavexim SRL</td>
        <td class=xl75 style='border-left:none'>Aleea Mân&#259;stirea Dealu nr. 197,
            Sat Valea Voievozilor
        </td>
        <td class=xl74 width=193 style='border-left:none;width:145pt'>Targoviste</td>
        <td class=xl74 width=162 style='border-left:none;width:122pt'>Dâmbovi&#355;a</td>
        <td class=xl71 width=140 style='border-left:none;width:105pt'>DACIA</td>
        <td class=xl83 style='border-left:none'>Da</td>
        <td class=xl83 style='border-left:none'>Da</td>
        <td class=xl83 style='border-left:none'>Expert VE (fara demontare baterie)</td>
        <td class=xl83 style='border-left:none'>Nu</td>
    </tr>
    <tr class=xl84 height=20 style='mso-height-source:userset;height:15.0pt'>
        <td height=20 class=xl72 style='height:15.0pt'>Mavexim SRL</td>
        <td class=xl72 style='border-left:none'>Bd. Petrochimi&#351;tilor, nr 5, bl
            32, parter
        </td>
        <td class=xl71 width=150 style='border-top:none;border-left:none;width:113pt'>Pite&#351;ti</td>
        <td class=xl71 width=123 style='border-top:none;border-left:none;width:92pt'>Arge<font
            class="font5">&#351;</font></td>
        <td class=xl72 style='border-left:none'>Mavexim SRL</td>
        <td class=xl72 style='border-top:none;border-left:none'>Aleea Mân&#259;stirea
            Dealu nr. 197, Sat Valea Voievozilor
        </td>
        <td class=xl71 width=193 style='border-top:none;border-left:none;width:145pt'>Targoviste</td>
        <td class=xl71 width=162 style='border-top:none;border-left:none;width:122pt'>Dâmbovi&#355;a</td>
        <td class=xl71 width=140 style='border-left:none;width:105pt'>RENAULT</td>
        <td class=xl83 style='border-left:none'>Da</td>
        <td class=xl83 style='border-left:none'>Da</td>
        <td class=xl83 style='border-left:none'>Expert E-TECH (fara demontare
            baterie)
        </td>
        <td class=xl83 style='border-left:none'>Nu</td>
    </tr>
    <tr class=xl84 height=20 style='mso-height-source:userset;height:15.0pt'>
        <td height=20 class=xl72 style='height:15.0pt'>Meridian Nord SRL</td>
        <td class=xl72 style='border-left:none'>Str. Turnu M&#259;gurele nr. 52-62,
            Sector 4
        </td>
        <td class=xl74 width=150 style='border-left:none;width:113pt'>Bucure&#351;ti</td>
        <td class=xl74 width=123 style='border-left:none;width:92pt'>Bucure&#351;ti</td>
        <td class=xl72 style='border-left:none'>Meridian Nord SRL</td>
        <td class=xl77 style='border-left:none'>Bd. Pipera nr. 1/IX</td>
        <td class=xl74 width=193 style='border-left:none;width:145pt'>Voluntari</td>
        <td class=xl74 width=162 style='border-left:none;width:122pt'>Ilfov</td>
        <td class=xl71 width=140 style='border-left:none;width:105pt'>DACIA</td>
        <td class=xl83 style='border-left:none'>Da</td>
        <td class=xl83 style='border-left:none'>Da</td>
        <td class=xl83 style='border-left:none'>Expert VE (fara demontare baterie)</td>
        <td class=xl83 style='border-left:none'>Nu</td>
    </tr>
    <tr class=xl84 height=20 style='mso-height-source:userset;height:15.0pt'>
        <td height=20 class=xl72 style='height:15.0pt'>Meridian Nord SRL</td>
        <td class=xl72 style='border-left:none'>Str. Turnu M&#259;gurele nr. 52-62,
            Sector 4
        </td>
        <td class=xl74 width=150 style='border-left:none;width:113pt'>Bucure&#351;ti</td>
        <td class=xl74 width=123 style='border-left:none;width:92pt'>Bucure&#351;ti</td>
        <td class=xl72 style='border-left:none'>Meridian Nord SRL</td>
        <td class=xl77 style='border-left:none'>Bd. Pipera nr. 1/IX</td>
        <td class=xl74 width=193 style='border-left:none;width:145pt'>Voluntari</td>
        <td class=xl74 width=162 style='border-left:none;width:122pt'>Ilfov</td>
        <td class=xl71 width=140 style='border-left:none;width:105pt'>RENAULT</td>
        <td class=xl83 style='border-left:none'>Da</td>
        <td class=xl83 style='border-left:none'>Da</td>
        <td class=xl83 style='border-left:none'>Expert E-TECH (fara demontare
            baterie)
        </td>
        <td class=xl83 style='border-left:none'>Nu</td>
    </tr>
    <tr class=xl84 height=20 style='mso-height-source:userset;height:15.0pt'>
        <td height=20 class=xl72 style='height:15.0pt'>Meridian Sud Invest SRL</td>
        <td class=xl72 style='border-left:none'>Str. Turnu M&#259;gurele Nr 52-62,
            sector 4
        </td>
        <td class=xl74 width=150 style='border-left:none;width:113pt'>Bucure&#351;ti</td>
        <td class=xl74 width=123 style='border-left:none;width:92pt'>Bucure&#351;ti</td>
        <td class=xl72 style='border-left:none'>Meridian Sud Invest SRL</td>
        <td class=xl75 style='border-left:none'>Str. Turnu M&#259;gurele Nr 58-60,
            sector 4<span style='mso-spacerun:yes'> </span></td>
        <td class=xl74 width=193 style='border-left:none;width:145pt'>Bucure&#351;ti</td>
        <td class=xl74 width=162 style='border-left:none;width:122pt'>Bucure&#351;ti</td>
        <td class=xl71 width=140 style='border-left:none;width:105pt'>DACIA</td>
        <td class=xl83 style='border-left:none'>Da</td>
        <td class=xl83 style='border-left:none'>Da</td>
        <td class=xl83 style='border-left:none'>Expert VE (fara demontare baterie)</td>
        <td class=xl83 style='border-left:none'>Nu</td>
    </tr>
    <tr class=xl84 height=20 style='mso-height-source:userset;height:15.0pt'>
        <td height=20 class=xl72 style='height:15.0pt'>Meridian Sud Invest SRL</td>
        <td class=xl72 style='border-left:none'>Str. Turnu M&#259;gurele Nr 52-62,
            sector 4
        </td>
        <td class=xl74 width=150 style='border-left:none;width:113pt'>Bucure&#351;ti</td>
        <td class=xl74 width=123 style='border-left:none;width:92pt'>Bucure&#351;ti</td>
        <td class=xl72 style='border-left:none'>Meridian Sud Invest SRL</td>
        <td class=xl75 style='border-left:none'>Str. Turnu M&#259;gurele Nr 58-60,
            sector 4<span style='mso-spacerun:yes'> </span></td>
        <td class=xl74 width=193 style='border-left:none;width:145pt'>Bucure&#351;ti</td>
        <td class=xl74 width=162 style='border-left:none;width:122pt'>Bucure&#351;ti</td>
        <td class=xl71 width=140 style='border-left:none;width:105pt'>RENAULT</td>
        <td class=xl83 style='border-left:none'>Da</td>
        <td class=xl83 style='border-left:none'>Da</td>
        <td class=xl83 style='border-left:none'>Expert E-TECH (fara demontare
            baterie)
        </td>
        <td class=xl83 style='border-left:none'>Nu</td>
    </tr>
    <tr class=xl84 height=20 style='mso-height-source:userset;height:15.0pt'>
        <td height=20 class=xl72 style='height:15.0pt'>Meridian Vest S.R.L.</td>
        <td class=xl72 style='border-left:none'>Str. Turnu M<font class="font5">&#259;gurele
            nr. 52-62, Sector 4</font></td>
        <td class=xl74 width=150 style='border-left:none;width:113pt'>Bucure&#351;ti</td>
        <td class=xl74 width=123 style='border-left:none;width:92pt'>Bucure&#351;ti</td>
        <td class=xl72 style='border-left:none'>Meridian Vest S.R.L.</td>
        <td class=xl79 style='border-top:none;border-left:none'>Bd. Iuliu Maniu nr.
            319-329, Sector 6
        </td>
        <td class=xl74 width=193 style='border-left:none;width:145pt'>Bucure&#351;ti</td>
        <td class=xl74 width=162 style='border-left:none;width:122pt'>Bucure&#351;ti</td>
        <td class=xl71 width=140 style='border-left:none;width:105pt'>DACIA</td>
        <td class=xl83 style='border-left:none'>Da</td>
        <td class=xl83 style='border-left:none'>Da</td>
        <td class=xl83 style='border-left:none'>Expert VE (fara demontare baterie)</td>
        <td class=xl83 style='border-left:none'>Nu</td>
    </tr>
    <tr class=xl84 height=20 style='mso-height-source:userset;height:15.0pt'>
        <td height=20 class=xl72 style='height:15.0pt'>Meridian Vest S.R.L.</td>
        <td class=xl72 style='border-left:none'>Str. Turnu M<font class="font5">&#259;gurele
            nr. 52-62, Sector 4</font></td>
        <td class=xl74 width=150 style='border-left:none;width:113pt'>Bucure&#351;ti</td>
        <td class=xl74 width=123 style='border-left:none;width:92pt'>Bucure&#351;ti</td>
        <td class=xl72 style='border-left:none'>Meridian Vest S.R.L.</td>
        <td class=xl79 style='border-top:none;border-left:none'>Bd. Iuliu Maniu nr.
            319-329, Sector 6
        </td>
        <td class=xl74 width=193 style='border-left:none;width:145pt'>Bucure&#351;ti</td>
        <td class=xl74 width=162 style='border-left:none;width:122pt'>Bucure&#351;ti</td>
        <td class=xl71 width=140 style='border-left:none;width:105pt'>RENAULT</td>
        <td class=xl83 style='border-left:none'>Da</td>
        <td class=xl83 style='border-left:none'>Da</td>
        <td class=xl83 style='border-left:none'>Expert E-TECH (fara demontare
            baterie)
        </td>
        <td class=xl83 style='border-left:none'>Nu</td>
    </tr>
    <tr class=xl84 height=20 style='mso-height-source:userset;height:15.0pt'>
        <td height=20 class=xl72 style='height:15.0pt'>Mida SRL</td>
        <td class=xl72 style='border-left:none'>Str. Bra&#351;ovului, Nr 67</td>
        <td class=xl74 width=150 style='border-left:none;width:113pt'>Miercurea-Ciuc</td>
        <td class=xl74 width=123 style='border-left:none;width:92pt'>Harghita</td>
        <td class=xl72 style='border-left:none'>Mida SRL</td>
        <td class=xl72 style='border-top:none;border-left:none'>Str. Bra&#351;ovului,
            Nr 67
        </td>
        <td class=xl74 width=193 style='border-left:none;width:145pt'>Miercurea-Ciuc</td>
        <td class=xl74 width=162 style='border-left:none;width:122pt'>Harghita</td>
        <td class=xl71 width=140 style='border-left:none;width:105pt'>DACIA</td>
        <td class=xl83 style='border-left:none'>Da</td>
        <td class=xl83 style='border-left:none'>Da</td>
        <td class=xl83 style='border-left:none'>Expert VE (fara demontare baterie)</td>
        <td class=xl83 style='border-left:none'>Nu</td>
    </tr>
    <tr class=xl84 height=20 style='mso-height-source:userset;height:15.0pt'>
        <td height=20 class=xl72 style='height:15.0pt'>Mida SRL</td>
        <td class=xl72 style='border-left:none'>Str. Bra&#351;ovului, Nr 67</td>
        <td class=xl74 width=150 style='border-left:none;width:113pt'>Miercurea-Ciuc</td>
        <td class=xl74 width=123 style='border-left:none;width:92pt'>Harghita</td>
        <td class=xl72 style='border-left:none'>Mida SRL</td>
        <td class=xl72 style='border-left:none'>Str. Bra&#351;ovului, Nr 67</td>
        <td class=xl74 width=193 style='border-left:none;width:145pt'>Miercurea-Ciuc</td>
        <td class=xl74 width=162 style='border-left:none;width:122pt'>Harghita</td>
        <td class=xl71 width=140 style='border-left:none;width:105pt'>RENAULT</td>
        <td class=xl83 style='border-left:none'>Da</td>
        <td class=xl83 style='border-left:none'>Da</td>
        <td class=xl83 style='border-left:none'>Expert E-TECH (fara demontare
            baterie)
        </td>
        <td class=xl83 style='border-left:none'>Nu</td>
    </tr>
    <tr class=xl84 height=20 style='mso-height-source:userset;height:15.0pt'>
        <td height=20 class=xl72 style='height:15.0pt'>Mida SRL</td>
        <td class=xl72 style='border-left:none'>Str. Bra&#351;ovului, Nr 67</td>
        <td class=xl74 width=150 style='border-left:none;width:113pt'>Miercurea-Ciuc</td>
        <td class=xl74 width=123 style='border-left:none;width:92pt'>Harghita</td>
        <td class=xl72 style='border-left:none'>Mida SRL</td>
        <td class=xl72 style='border-left:none'>Str. Bra&#351;ovului, Nr 67</td>
        <td class=xl74 width=193 style='border-left:none;width:145pt'>Miercurea-Ciuc</td>
        <td class=xl74 width=162 style='border-left:none;width:122pt'>Harghita</td>
        <td class=xl71 width=140 style='border-left:none;width:105pt'>NISSAN</td>
        <td class=xl83 style='border-left:none'>Da</td>
        <td class=xl83 style='border-left:none'>Da</td>
        <td class=xl83 style='border-left:none'>Da</td>
        <td class=xl83 style='border-left:none'>Nu</td>
    </tr>
    <tr class=xl84 height=20 style='mso-height-source:userset;height:15.0pt'>
        <td height=20 class=xl72 style='height:15.0pt'>Mida SRL</td>
        <td class=xl72 style='border-left:none'>Str. Bra&#351;ovului, Nr 67</td>
        <td class=xl74 width=150 style='border-left:none;width:113pt'>Miercurea-Ciuc</td>
        <td class=xl74 width=123 style='border-left:none;width:92pt'>Harghita</td>
        <td class=xl72 style='border-left:none'>Mida SRL</td>
        <td class=xl75 style='border-left:none'>DN 12, km 8, F.N.</td>
        <td class=xl74 width=193 style='border-left:none;width:145pt'>Sfântu Gheorghe</td>
        <td class=xl74 width=162 style='border-left:none;width:122pt'>Covasna</td>
        <td class=xl71 width=140 style='border-left:none;width:105pt'>DACIA</td>
        <td class=xl83 style='border-left:none'>Da</td>
        <td class=xl83 style='border-left:none'>Da</td>
        <td class=xl83 style='border-left:none'>Expert VE (fara demontare baterie)</td>
        <td class=xl83 style='border-left:none'>Nu</td>
    </tr>
    <tr class=xl84 height=20 style='mso-height-source:userset;height:15.0pt'>
        <td height=20 class=xl72 style='height:15.0pt'>Mida SRL</td>
        <td class=xl72 style='border-left:none'>Str. Bra&#351;ovului, Nr 67</td>
        <td class=xl74 width=150 style='border-left:none;width:113pt'>Miercurea-Ciuc</td>
        <td class=xl74 width=123 style='border-left:none;width:92pt'>Harghita</td>
        <td class=xl72 style='border-left:none'>Mida SRL</td>
        <td class=xl75 style='border-left:none'>DN 12, km 8, F.N.</td>
        <td class=xl74 width=193 style='border-left:none;width:145pt'>Sfântu Gheorghe</td>
        <td class=xl74 width=162 style='border-left:none;width:122pt'>Covasna</td>
        <td class=xl71 width=140 style='border-left:none;width:105pt'>RENAULT</td>
        <td class=xl83 style='border-left:none'>Da</td>
        <td class=xl83 style='border-left:none'>Da</td>
        <td class=xl83 style='border-left:none'>Expert E-TECH (fara demontare
            baterie)
        </td>
        <td class=xl83 style='border-left:none'>Nu</td>
    </tr>
    <tr class=xl84 height=20 style='mso-height-source:userset;height:15.0pt'>
        <td height=20 class=xl72 style='height:15.0pt'>Mival Class S.R.L.</td>
        <td class=xl72 style='border-left:none'>Str. Nicolae Racota, Nr.17, bloc 80P,
            Scara 2, Ap 16, Sector 1
        </td>
        <td class=xl74 width=150 style='border-left:none;width:113pt'>Bucure&#351;ti</td>
        <td class=xl74 width=123 style='border-left:none;width:92pt'>Bucure&#351;ti</td>
        <td class=xl72 style='border-left:none'>Mival Class S.R.L.</td>
        <td class=xl79 style='border-top:none;border-left:none'>P-&#355;a Montreal,
            Nr. 10, Cl&#259;direa Principal&#259; WTCB, Spatiul Garage, Parking 1, Sector
            1
        </td>
        <td class=xl74 width=193 style='border-left:none;width:145pt'>Bucure&#351;ti</td>
        <td class=xl74 width=162 style='border-left:none;width:122pt'>Bucure&#351;ti</td>
        <td class=xl71 width=140 style='border-left:none;width:105pt'>RENAULT</td>
        <td class=xl83 style='border-left:none'>Da</td>
        <td class=xl83 style='border-left:none'>Subcontractata</td>
        <td class=xl83 style='border-left:none'>Expert E-TECH (fara demontare
            baterie)
        </td>
        <td class=xl83 style='border-left:none'>Nu</td>
    </tr>
    <tr class=xl84 height=20 style='mso-height-source:userset;height:15.0pt'>
        <td height=20 class=xl72 style='height:15.0pt'>MATEROM AUTOMOBILE SRL</td>
        <td class=xl72 style='border-left:none'>Str. Viilor, Nr 799, (biroul nr. 1),
            etaj 1
        </td>
        <td class=xl74 width=150 style='border-left:none;width:113pt'>Comuna
            Criste&#351;ti,
        </td>
        <td class=xl74 width=123 style='border-left:none;width:92pt'>Mure&#351;</td>
        <td class=xl72 style='border-left:none'>MTR AUTO SERVICE SRL</td>
        <td class=xl69 style='border-top:none;border-left:none'>Strada
            H&#258;RMANULUI, Nr. 49A
        </td>
        <td class=xl74 width=193 style='border-left:none;width:145pt'>Bra&#351;ov</td>
        <td class=xl74 width=162 style='border-left:none;width:122pt'>Bra&#351;ov</td>
        <td class=xl71 width=140 style='border-left:none;width:105pt'>DACIA</td>
        <td class=xl83 style='border-left:none'>Da</td>
        <td class=xl83 style='border-left:none'>Da</td>
        <td class=xl83 style='border-left:none'>Nu</td>
        <td class=xl83 style='border-left:none'>Nu</td>
    </tr>
    <tr class=xl84 height=20 style='mso-height-source:userset;height:15.0pt'>
        <td height=20 class=xl72 style='height:15.0pt'>MATEROM AUTOMOBILE SRL</td>
        <td class=xl72 style='border-left:none'>Str. Viilor, Nr 799, (biroul nr. 1),
            etaj 1
        </td>
        <td class=xl74 width=150 style='border-left:none;width:113pt'>Comuna
            Criste&#351;ti,
        </td>
        <td class=xl74 width=123 style='border-left:none;width:92pt'>Mure&#351;</td>
        <td class=xl72 style='border-left:none'>MTR AUTO SERVICE SRL</td>
        <td class=xl69 style='border-top:none;border-left:none'>Strada
            H&#258;RMANULUI, Nr. 49A
        </td>
        <td class=xl74 width=193 style='border-left:none;width:145pt'>Bra&#351;ov</td>
        <td class=xl74 width=162 style='border-left:none;width:122pt'>Bra&#351;ov</td>
        <td class=xl71 width=140 style='border-left:none;width:105pt'>RENAULT</td>
        <td class=xl83 style='border-left:none'>Da</td>
        <td class=xl83 style='border-left:none'>Da</td>
        <td class=xl83 style='border-left:none'>Nu</td>
        <td class=xl83 style='border-left:none'>Nu</td>
    </tr>
    <tr class=xl84 height=20 style='mso-height-source:userset;height:15.0pt'>
        <td height=20 class=xl72 style='height:15.0pt'>Nesty Auto Service SRL</td>
        <td class=xl72 style='border-left:none'>Sos Berceni nr.80, sector 4</td>
        <td class=xl74 width=150 style='border-left:none;width:113pt'>Bucure&#351;ti</td>
        <td class=xl74 width=123 style='border-left:none;width:92pt'>Bucure&#351;ti</td>
        <td class=xl72 style='border-left:none'>Nesty Auto Service SRL</td>
        <td class=xl72 style='border-top:none;border-left:none'>Sos Berceni nr.80,
            sector 4
        </td>
        <td class=xl74 width=193 style='border-left:none;width:145pt'>Bucure&#351;ti</td>
        <td class=xl74 width=162 style='border-left:none;width:122pt'>Bucure&#351;ti</td>
        <td class=xl71 width=140 style='border-left:none;width:105pt'>DACIA</td>
        <td class=xl83 style='border-left:none'>Da</td>
        <td class=xl83 style='border-left:none'>Da</td>
        <td class=xl83 style='border-left:none'>Expert VE (fara demontare baterie)</td>
        <td class=xl83 style='border-left:none'>Nu</td>
    </tr>
    <tr class=xl84 height=20 style='mso-height-source:userset;height:15.0pt'>
        <td height=20 class=xl72 style='height:15.0pt'>Nicogel Serv S.R.L.</td>
        <td class=xl72 style='border-left:none'>Str. Democra&#355;iei nr.10B</td>
        <td class=xl73 width=150 style='border-top:none;border-left:none;width:113pt'>Plopeni</td>
        <td class=xl73 width=123 style='border-top:none;border-left:none;width:92pt'>Prahova</td>
        <td class=xl72 style='border-left:none'>Nicogel Serv S.R.L.</td>
        <td class=xl79 style='border-left:none'>Str. Poligonului nr. 1</td>
        <td class=xl73 width=193 style='border-top:none;border-left:none;width:145pt'>Ploie&#351;ti</td>
        <td class=xl73 width=162 style='border-top:none;border-left:none;width:122pt'>Prahova</td>
        <td class=xl71 width=140 style='border-left:none;width:105pt'>DACIA</td>
        <td class=xl83 style='border-left:none'>Da</td>
        <td class=xl83 style='border-left:none'>Da</td>
        <td class=xl83 style='border-left:none'>Expert VE (fara demontare baterie)</td>
        <td class=xl83 style='border-left:none'>Nu</td>
    </tr>
    <tr class=xl84 height=20 style='mso-height-source:userset;height:15.0pt'>
        <td height=20 class=xl72 style='height:15.0pt'>Nicogel Serv S.R.L.</td>
        <td class=xl72 style='border-left:none'>Str. Democra&#355;iei nr.10B</td>
        <td class=xl73 width=150 style='border-top:none;border-left:none;width:113pt'>Plopeni</td>
        <td class=xl73 width=123 style='border-top:none;border-left:none;width:92pt'>Prahova</td>
        <td class=xl72 style='border-left:none'>Nicogel Serv S.R.L.</td>
        <td class=xl79 style='border-top:none;border-left:none'>Str. Poligonului nr.
            1
        </td>
        <td class=xl73 width=193 style='border-top:none;border-left:none;width:145pt'>Ploie&#351;ti</td>
        <td class=xl73 width=162 style='border-top:none;border-left:none;width:122pt'>Prahova</td>
        <td class=xl71 width=140 style='border-left:none;width:105pt'>RENAULT</td>
        <td class=xl83 style='border-left:none'>Da</td>
        <td class=xl83 style='border-left:none'>Da</td>
        <td class=xl83 style='border-left:none'>Expert E-TECH (fara demontare
            baterie)
        </td>
        <td class=xl83 style='border-left:none'>Nu</td>
    </tr>
    <tr class=xl84 height=20 style='mso-height-source:userset;height:15.0pt'>
        <td height=20 class=xl72 style='height:15.0pt'>Poliserv JG (PJG) S.R.L.</td>
        <td class=xl72 style='border-left:none'>Str. Veronica Micle nr.12B</td>
        <td class=xl74 width=150 style='border-left:none;width:113pt'>Ploie&#351;ti</td>
        <td class=xl73 width=123 style='border-top:none;border-left:none;width:92pt'>Prahova</td>
        <td class=xl72 style='border-left:none'>Poliserv JG (PJG) S.R.L.</td>
        <td class=xl75 style='border-left:none'>Str. Strandului, 146</td>
        <td class=xl74 width=193 style='border-left:none;width:145pt'>Ploie&#351;ti</td>
        <td class=xl73 width=162 style='border-top:none;border-left:none;width:122pt'>Prahova</td>
        <td class=xl71 width=140 style='border-left:none;width:105pt'>DACIA</td>
        <td class=xl83 style='border-left:none'>Da</td>
        <td class=xl83 style='border-left:none'>Nu</td>
        <td class=xl83 style='border-left:none'>Expert VE (fara demontare baterie)</td>
        <td class=xl83 style='border-left:none'>Nu</td>
    </tr>
    <tr class=xl84 height=20 style='mso-height-source:userset;height:15.0pt'>
        <td height=20 class=xl72 style='height:15.0pt'>Poliserv JG (PJG) S.R.L.</td>
        <td class=xl72 style='border-left:none'>Str. Veronica Micle nr.12B</td>
        <td class=xl74 width=150 style='border-left:none;width:113pt'>Ploie&#351;ti</td>
        <td class=xl73 width=123 style='border-top:none;border-left:none;width:92pt'>Prahova</td>
        <td class=xl72 style='border-left:none'>Poliserv JG (PJG) S.R.L.</td>
        <td class=xl75 style='border-left:none'>Str. Strandului, 146</td>
        <td class=xl74 width=193 style='border-left:none;width:145pt'>Ploie&#351;ti</td>
        <td class=xl73 width=162 style='border-top:none;border-left:none;width:122pt'>Prahova</td>
        <td class=xl71 width=140 style='border-left:none;width:105pt'>RENAULT</td>
        <td class=xl83 style='border-left:none'>Da</td>
        <td class=xl83 style='border-left:none'>Nu</td>
        <td class=xl83 style='border-left:none'>Expert E-TECH (fara demontare
            baterie)
        </td>
        <td class=xl83 style='border-left:none'>Nu</td>
    </tr>
    <tr class=xl84 height=20 style='mso-height-source:userset;height:15.0pt'>
        <td height=20 class=xl69 style='height:15.0pt'>Poliserv JG (PJG) S.R.L.</td>
        <td class=xl69 style='border-left:none'>Str. Veronica Micle nr.12B</td>
        <td class=xl68 width=150 style='border-top:none;border-left:none;width:113pt'>Ploie&#351;ti</td>
        <td class=xl68 width=123 style='border-top:none;border-left:none;width:92pt'>Prahova</td>
        <td class=xl72 style='border-left:none'>Poliserv JG (PJG) S.R.L.</td>
        <td class=xl69 style='border-top:none;border-left:none'>Centura de Est, nr.21</td>
        <td class=xl74 width=193 style='border-left:none;width:145pt'>Ploie&#351;ti</td>
        <td class=xl73 width=162 style='border-top:none;border-left:none;width:122pt'>Prahova</td>
        <td class=xl68 width=140 style='border-left:none;width:105pt'>DACIA</td>
        <td class=xl85 style='border-left:none'>Nu</td>
        <td class=xl85 style='border-left:none'>Da</td>
        <td class=xl85 style='border-left:none'>Nu</td>
        <td class=xl85 style='border-left:none'>Nu</td>
    </tr>
    <tr class=xl84 height=20 style='mso-height-source:userset;height:15.0pt'>
        <td height=20 class=xl72 style='height:15.0pt;border-top:none'>Poliserv JG
            (PJG) S.R.L.
        </td>
        <td class=xl72 style='border-top:none;border-left:none'>Str. Veronica Micle
            nr.12B
        </td>
        <td class=xl71 width=150 style='border-top:none;border-left:none;width:113pt'>Ploie&#351;ti</td>
        <td class=xl71 width=123 style='border-top:none;border-left:none;width:92pt'>Prahova</td>
        <td class=xl72 style='border-left:none'>Poliserv JG (PJG) S.R.L.</td>
        <td class=xl69 style='border-top:none;border-left:none'>Centura de Est, nr.21</td>
        <td class=xl74 width=193 style='border-left:none;width:145pt'>Ploie&#351;ti</td>
        <td class=xl73 width=162 style='border-top:none;border-left:none;width:122pt'>Prahova</td>
        <td class=xl71 width=140 style='border-top:none;border-left:none;width:105pt'>RENAULT</td>
        <td class=xl83 style='border-top:none;border-left:none'>Nu</td>
        <td class=xl83 style='border-top:none;border-left:none'>Da</td>
        <td class=xl83 style='border-top:none;border-left:none'>Nu</td>
        <td class=xl83 style='border-top:none;border-left:none'>Nu</td>
    </tr>
    <tr class=xl84 height=20 style='mso-height-source:userset;height:15.0pt'>
        <td height=20 class=xl72 style='height:15.0pt'>Redac S.R.L.</td>
        <td class=xl72 style='border-left:none'>Str. R<font class="font5">âului nr.
            387</font></td>
        <td class=xl69 style='border-left:none'>Craiova</td>
        <td class=xl69 style='border-left:none'>Dolj</td>
        <td class=xl72 style='border-left:none'>Redac S.R.L.</td>
        <td class=xl72 style='border-top:none;border-left:none'>Str. R<font
            class="font5">âului nr. 387</font></td>
        <td class=xl69 style='border-top:none;border-left:none'>Craiova</td>
        <td class=xl69 style='border-top:none;border-left:none'>Dolj</td>
        <td class=xl71 width=140 style='border-left:none;width:105pt'>DACIA</td>
        <td class=xl83 style='border-left:none'>Da</td>
        <td class=xl83 style='border-left:none'>Da</td>
        <td class=xl83 style='border-left:none'>Full Expert VE (cu demontare baterie)</td>
        <td class=xl83 style='border-left:none'>Nu</td>
    </tr>
    <tr class=xl84 height=20 style='mso-height-source:userset;height:15.0pt'>
        <td height=20 class=xl72 style='height:15.0pt'>Redac S.R.L.</td>
        <td class=xl72 style='border-left:none'>Str. R<font class="font5">âului nr.
            387</font></td>
        <td class=xl71 width=150 style='border-top:none;border-left:none;width:113pt'>Craiova</td>
        <td class=xl71 width=123 style='border-top:none;border-left:none;width:92pt'>Dolj</td>
        <td class=xl72 style='border-left:none'>Redac S.R.L.</td>
        <td class=xl72 style='border-left:none'>Str. R<font class="font5">âului nr.
            387</font></td>
        <td class=xl71 width=193 style='border-top:none;border-left:none;width:145pt'>Craiova</td>
        <td class=xl71 width=162 style='border-top:none;border-left:none;width:122pt'>Dolj</td>
        <td class=xl71 width=140 style='border-left:none;width:105pt'>RENAULT</td>
        <td class=xl83 style='border-left:none'>Da</td>
        <td class=xl83 style='border-left:none'>Da</td>
        <td class=xl83 style='border-left:none'>Full Expert E-TECH (cu demontare
            baterie)
        </td>
        <td class=xl83 style='border-left:none'>Nu</td>
    </tr>
    <tr class=xl84 height=20 style='mso-height-source:userset;height:15.0pt'>
        <td height=20 class=xl72 style='height:15.0pt'>Redac S.R.L.</td>
        <td class=xl72 style='border-left:none'>Str. R<font class="font5">âului nr.
            387</font></td>
        <td class=xl71 width=150 style='border-left:none;width:113pt'>Craiova</td>
        <td class=xl71 width=123 style='border-left:none;width:92pt'>Dolj</td>
        <td class=xl72 style='border-left:none'>Redac S.R.L.</td>
        <td class=xl72 style='border-left:none'>Str. R<font class="font5">âului nr.
            387</font></td>
        <td class=xl71 width=193 style='border-left:none;width:145pt'>Craiova</td>
        <td class=xl71 width=162 style='border-left:none;width:122pt'>Dolj</td>
        <td class=xl71 width=140 style='border-left:none;width:105pt'>NISSAN</td>
        <td class=xl83 style='border-left:none'>Da</td>
        <td class=xl83 style='border-left:none'>Da</td>
        <td class=xl83 style='border-left:none'>Da</td>
        <td class=xl83 style='border-left:none'>Nu</td>
    </tr>
    <tr class=xl84 height=20 style='mso-height-source:userset;height:15.0pt'>
        <td height=20 class=xl72 style='height:15.0pt'>Rent Car Service Litoral
            SRL<span style='mso-spacerun:yes'> </span></td>
        <td class=xl72 style='border-left:none'>Str. I. C. Br&#259;tianu, nr. 251</td>
        <td class=xl73 width=150 style='border-left:none;width:113pt'>Constan&#355;a</td>
        <td class=xl73 width=123 style='border-left:none;width:92pt'>Constan&#355;a</td>
        <td class=xl72 style='border-left:none'>Rent Car Service Litoral SRL<span
            style='mso-spacerun:yes'> </span></td>
        <td class=xl72 style='border-left:none'>Str. I. C. Br&#259;tianu, nr. 251</td>
        <td class=xl73 width=193 style='border-left:none;width:145pt'>Constan&#355;a</td>
        <td class=xl73 width=162 style='border-left:none;width:122pt'>Constan&#355;a</td>
        <td class=xl71 width=140 style='border-left:none;width:105pt'>DACIA</td>
        <td class=xl83 style='border-left:none'>Da</td>
        <td class=xl83 style='border-left:none'>Da</td>
        <td class=xl83 style='border-left:none'>Expert VE (fara demontare baterie)</td>
        <td class=xl83 style='border-left:none'>Nu</td>
    </tr>
    <tr class=xl84 height=20 style='mso-height-source:userset;height:15.0pt'>
        <td height=20 class=xl72 style='height:15.0pt'>Rent Car Service Litoral
            SRL<span style='mso-spacerun:yes'> </span></td>
        <td class=xl72 style='border-left:none'>Str. I. C. Br&#259;tianu, nr. 251</td>
        <td class=xl71 width=150 style='border-top:none;border-left:none;width:113pt'>Constan&#355;a</td>
        <td class=xl71 width=123 style='border-top:none;border-left:none;width:92pt'>Constan&#355;a</td>
        <td class=xl72 style='border-left:none'>Rent Car Service Litoral SRL<span
            style='mso-spacerun:yes'> </span></td>
        <td class=xl69 style='border-left:none'>&#350;oseaua Constan&#355;ei, Nr.
            57A-57B
        </td>
        <td class=xl73 width=193 style='border-top:none;border-left:none;width:145pt'>Mangalia</td>
        <td class=xl73 width=162 style='border-top:none;border-left:none;width:122pt'>Constan&#355;a</td>
        <td class=xl71 width=140 style='border-left:none;width:105pt'>DACIA</td>
        <td class=xl83 style='border-left:none'>Da</td>
        <td class=xl83 style='border-left:none'>Da</td>
        <td class=xl83 style='border-left:none'>Nu</td>
        <td class=xl83 style='border-left:none'>Nu</td>
    </tr>
    <tr class=xl84 height=20 style='mso-height-source:userset;height:15.0pt'>
        <td height=20 class=xl72 style='height:15.0pt'>Rent Car Service Litoral
            SRL<span style='mso-spacerun:yes'> </span></td>
        <td class=xl72 style='border-left:none'>Str. I. C. Br&#259;tianu, nr. 251</td>
        <td class=xl71 width=150 style='border-left:none;width:113pt'>Constan&#355;a</td>
        <td class=xl71 width=123 style='border-left:none;width:92pt'>Constan&#355;a</td>
        <td class=xl72 style='border-left:none'>Rent Car Service Litoral SRL<span
            style='mso-spacerun:yes'> </span></td>
        <td class=xl69 style='border-top:none;border-left:none'>B-dul Tomis, nr. 393</td>
        <td class=xl73 width=193 style='border-top:none;border-left:none;width:145pt'>Constan&#355;a</td>
        <td class=xl73 width=162 style='border-top:none;border-left:none;width:122pt'>Constan&#355;a</td>
        <td class=xl71 width=140 style='border-left:none;width:105pt'>DACIA</td>
        <td class=xl83 style='border-left:none'>Da</td>
        <td class=xl83 style='border-left:none'>Da</td>
        <td class=xl83 style='border-left:none'>Expert VE (fara demontare baterie)</td>
        <td class=xl83 style='border-left:none'>Nu</td>
    </tr>
    <tr class=xl84 height=20 style='mso-height-source:userset;height:15.0pt'>
        <td height=20 class=xl72 style='height:15.0pt'>Seda-Service SRL</td>
        <td class=xl72 style='border-left:none'>Str. Buze&#351;ti, nr 8</td>
        <td class=xl73 width=150 style='border-left:none;width:113pt'>Caracal</td>
        <td class=xl73 width=123 style='border-left:none;width:92pt'>Olt</td>
        <td class=xl72 style='border-left:none'>Seda-Service SRL</td>
        <td class=xl72 style='border-top:none;border-left:none'>Str. Buze&#351;ti, nr
            8
        </td>
        <td class=xl73 width=193 style='border-top:none;border-left:none;width:145pt'>Caracal</td>
        <td class=xl73 width=162 style='border-top:none;border-left:none;width:122pt'>Olt</td>
        <td class=xl71 width=140 style='border-left:none;width:105pt'>DACIA</td>
        <td class=xl83 style='border-left:none'>Da</td>
        <td class=xl83 style='border-left:none'>Da</td>
        <td class=xl83 style='border-left:none'>Expert VE (fara demontare baterie)</td>
        <td class=xl83 style='border-left:none'>Nu</td>
    </tr>
    <tr class=xl84 height=20 style='mso-height-source:userset;height:15.0pt'>
        <td height=20 class=xl72 style='height:15.0pt'>Service Auto Serus S.R.L.</td>
        <td class=xl72 style='border-left:none'>B-dul Timi<font class="font5">&#351;oara
            nr. 60E, Sector 6</font></td>
        <td class=xl68 width=150 style='border-top:none;border-left:none;width:113pt'>Bucure&#351;ti</td>
        <td class=xl68 width=123 style='border-top:none;border-left:none;width:92pt'>Bucure&#351;ti</td>
        <td class=xl72 style='border-left:none'>Service Auto Serus S.R.L.</td>
        <td class=xl72 style='border-left:none'>B-dul Timi<font class="font5">&#351;oara
            nr. 60E, Sector 6</font></td>
        <td class=xl68 width=193 style='border-top:none;border-left:none;width:145pt'>Bucure&#351;ti</td>
        <td class=xl68 width=162 style='border-top:none;border-left:none;width:122pt'>Bucure&#351;ti</td>
        <td class=xl71 width=140 style='border-left:none;width:105pt'>DACIA</td>
        <td class=xl83 style='border-left:none'>Da</td>
        <td class=xl83 style='border-left:none'>Nu</td>
        <td class=xl83 style='border-left:none'>Expert VE (fara demontare baterie)</td>
        <td class=xl83 style='border-left:none'>Nu</td>
    </tr>
    <tr class=xl84 height=20 style='mso-height-source:userset;height:15.0pt'>
        <td height=20 class=xl72 style='height:15.0pt'>Service Auto Serus S.R.L.</td>
        <td class=xl72 style='border-left:none'>B-dul Timi<font class="font5">&#351;oara
            nr. 60E, Sector 6</font></td>
        <td class=xl71 width=150 style='border-top:none;border-left:none;width:113pt'>Bucure<font
            class="font5">&#351;ti</font></td>
        <td class=xl71 width=123 style='border-top:none;border-left:none;width:92pt'>Bucure<font
            class="font5">&#351;ti</font></td>
        <td class=xl72 style='border-left:none'>Service Auto Serus S.R.L.</td>
        <td class=xl72 style='border-left:none'>B-dul Timi<font class="font5">&#351;oara
            nr. 60E, Sector 6</font></td>
        <td class=xl68 width=193 style='border-top:none;border-left:none;width:145pt'>Bucure&#351;ti</td>
        <td class=xl68 width=162 style='border-top:none;border-left:none;width:122pt'>Bucure&#351;ti</td>
        <td class=xl71 width=140 style='border-left:none;width:105pt'>RENAULT</td>
        <td class=xl83 style='border-left:none'>Da</td>
        <td class=xl83 style='border-left:none'>Nu</td>
        <td class=xl83 style='border-left:none'>Expert E-TECH (fara demontare
            baterie)
        </td>
        <td class=xl83 style='border-left:none'>Nu</td>
    </tr>
    <tr class=xl84 height=20 style='mso-height-source:userset;height:15.0pt'>
        <td height=20 class=xl72 style='height:15.0pt'>Service Auto Serus S.R.L.</td>
        <td class=xl72 style='border-left:none'>B-dul Timi<font class="font5">&#351;oara
            nr. 60E, Sector 6</font></td>
        <td class=xl71 width=150 style='border-left:none;width:113pt'>Bucure<font
            class="font5">&#351;ti</font></td>
        <td class=xl71 width=123 style='border-left:none;width:92pt'>Bucure<font
            class="font5">&#351;ti</font></td>
        <td class=xl72 style='border-left:none'>Service Auto Serus S.R.L.</td>
        <td class=xl82 style='border-left:none'>B-dul Timi&#351;oara nr. 87, Sector 6</td>
        <td class=xl68 width=193 style='border-top:none;border-left:none;width:145pt'>Bucure&#351;ti</td>
        <td class=xl68 width=162 style='border-top:none;border-left:none;width:122pt'>Bucure&#351;ti</td>
        <td class=xl71 width=140 style='border-left:none;width:105pt'>NISSAN</td>
        <td class=xl83 style='border-left:none'>Da</td>
        <td class=xl83 style='border-left:none'>Nu</td>
        <td class=xl83 style='border-left:none'>Da</td>
        <td class=xl83 style='border-left:none'>Nu</td>
    </tr>
    <tr class=xl84 height=20 style='mso-height-source:userset;height:15.0pt'>
        <td height=20 class=xl72 style='height:15.0pt'>Service Auto Serus S.R.L.</td>
        <td class=xl72 style='border-left:none'>B-dul Timi<font class="font5">&#351;oara
            nr. 60E, Sector 6</font></td>
        <td class=xl71 width=150 style='border-left:none;width:113pt'>Bucure<font
            class="font5">&#351;ti</font></td>
        <td class=xl71 width=123 style='border-left:none;width:92pt'>Bucure<font
            class="font5">&#351;ti</font></td>
        <td class=xl72 style='border-left:none'>Service Auto Serus S.R.L.</td>
        <td class=xl69 style='border-top:none;border-left:none'>&#350;os. de
            Centur&#259; nr. 17, T57/6,P8,9,10,11,12/2
        </td>
        <td class=xl69 style='border-top:none;border-left:none'>Comuna Chiajna</td>
        <td class=xl69 style='border-top:none;border-left:none'>Ilfov</td>
        <td class=xl71 width=140 style='border-left:none;width:105pt'>DACIA</td>
        <td class=xl83 style='border-left:none'>Da</td>
        <td class=xl83 style='border-left:none'>Da</td>
        <td class=xl83 style='border-left:none'>Expert VE (fara demontare baterie)</td>
        <td class=xl83 style='border-left:none'>Nu</td>
    </tr>
    <tr class=xl84 height=20 style='mso-height-source:userset;height:15.0pt'>
        <td height=20 class=xl72 style='height:15.0pt'>Service Auto Serus S.R.L.</td>
        <td class=xl72 style='border-left:none'>B-dul Timi<font class="font5">&#351;oara
            nr. 60E, Sector 6</font></td>
        <td class=xl71 width=150 style='border-left:none;width:113pt'>Bucure<font
            class="font5">&#351;ti</font></td>
        <td class=xl71 width=123 style='border-left:none;width:92pt'>Bucure<font
            class="font5">&#351;ti</font></td>
        <td class=xl72 style='border-left:none'>Service Auto Serus S.R.L.</td>
        <td class=xl69 style='border-top:none;border-left:none'>&#350;os. de
            Centur&#259; nr. 17, T57/6,P8,9,10,11,12/2
        </td>
        <td class=xl69 style='border-top:none;border-left:none'>Comuna Chiajna</td>
        <td class=xl69 style='border-top:none;border-left:none'>Ilfov</td>
        <td class=xl71 width=140 style='border-left:none;width:105pt'>RENAULT</td>
        <td class=xl83 style='border-left:none'>Da</td>
        <td class=xl83 style='border-left:none'>Da</td>
        <td class=xl83 style='border-left:none'>Expert E-TECH (fara demontare
            baterie)
        </td>
        <td class=xl83 style='border-left:none'>Nu</td>
    </tr>
    <tr class=xl84 height=20 style='mso-height-source:userset;height:15.0pt'>
        <td height=20 class=xl72 style='height:15.0pt'>Service Auto Serus S.R.L.</td>
        <td class=xl72 style='border-left:none'>B-dul Timi<font class="font5">&#351;oara
            nr. 60E, Sector 6</font></td>
        <td class=xl71 width=150 style='border-left:none;width:113pt'>Bucure<font
            class="font5">&#351;ti</font></td>
        <td class=xl71 width=123 style='border-left:none;width:92pt'>Bucure<font
            class="font5">&#351;ti</font></td>
        <td class=xl72 style='border-left:none'>Service Auto Serus S.R.L.</td>
        <td class=xl69 style='border-top:none;border-left:none'>&#350;os. de
            Centur&#259; nr. 17, T57/6,P8,9,10,11,12/2
        </td>
        <td class=xl69 style='border-top:none;border-left:none'>Comuna Chiajna</td>
        <td class=xl69 style='border-top:none;border-left:none'>Ilfov</td>
        <td class=xl71 width=140 style='border-left:none;width:105pt'>NISSAN</td>
        <td class=xl83 style='border-left:none'>Nu</td>
        <td class=xl83 style='border-left:none'>Da</td>
        <td class=xl83 style='border-left:none'>Nu</td>
        <td class=xl83 style='border-left:none'>Nu</td>
    </tr>
    <tr class=xl84 height=20 style='mso-height-source:userset;height:15.0pt'>
        <td height=20 class=xl72 style='height:15.0pt'>Service Auto Serus S.R.L.</td>
        <td class=xl72 style='border-left:none'>B-dul Timi<font class="font5">&#351;oara
            nr. 60E, Sector 6</font></td>
        <td class=xl71 width=150 style='border-left:none;width:113pt'>Bucure<font
            class="font5">&#351;ti</font></td>
        <td class=xl71 width=123 style='border-left:none;width:92pt'>Bucure<font
            class="font5">&#351;ti</font></td>
        <td class=xl72 style='border-left:none'>Service Auto Serus S.R.L.</td>
        <td class=xl69 style='border-top:none;border-left:none'>Tarlaua 12, Parcelele
            80/1-80/43
        </td>
        <td class=xl68 width=193 style='border-top:none;border-left:none;width:145pt'>Comuna
            Blejoi
        </td>
        <td class=xl73 width=162 style='border-top:none;border-left:none;width:122pt'>Prahova</td>
        <td class=xl71 width=140 style='border-left:none;width:105pt'>DACIA</td>
        <td class=xl83 style='border-left:none'>Da</td>
        <td class=xl83 style='border-left:none'>Da</td>
        <td class=xl83 style='border-left:none'>Expert VE (fara demontare baterie)</td>
        <td class=xl83 style='border-left:none'>Nu</td>
    </tr>
    <tr class=xl84 height=20 style='mso-height-source:userset;height:15.0pt'>
        <td height=20 class=xl72 style='height:15.0pt'>Service Auto Serus S.R.L.</td>
        <td class=xl72 style='border-left:none'>B-dul Timi<font class="font5">&#351;oara
            nr. 60E, Sector 6</font></td>
        <td class=xl71 width=150 style='border-left:none;width:113pt'>Bucure<font
            class="font5">&#351;ti</font></td>
        <td class=xl71 width=123 style='border-left:none;width:92pt'>Bucure<font
            class="font5">&#351;ti</font></td>
        <td class=xl72 style='border-left:none'>Service Auto Serus S.R.L.</td>
        <td class=xl69 style='border-top:none;border-left:none'>Tarlaua 12, Parcelele
            80/1-80/43
        </td>
        <td class=xl68 width=193 style='border-top:none;border-left:none;width:145pt'>Comuna
            Blejoi
        </td>
        <td class=xl73 width=162 style='border-top:none;border-left:none;width:122pt'>Prahova</td>
        <td class=xl71 width=140 style='border-left:none;width:105pt'>RENAULT</td>
        <td class=xl83 style='border-left:none'>Da</td>
        <td class=xl83 style='border-left:none'>Da</td>
        <td class=xl83 style='border-left:none'>Full Expert E-TECH (cu demontare
            baterie)
        </td>
        <td class=xl83 style='border-left:none'>Nu</td>
    </tr>
    <tr class=xl84 height=20 style='mso-height-source:userset;height:15.0pt'>
        <td height=20 class=xl72 style='height:15.0pt'>Service Auto Serus S.R.L.</td>
        <td class=xl72 style='border-left:none'>B-dul Timi<font class="font5">&#351;oara
            nr. 60E, Sector 6</font></td>
        <td class=xl71 width=150 style='border-left:none;width:113pt'>Bucure<font
            class="font5">&#351;ti</font></td>
        <td class=xl71 width=123 style='border-left:none;width:92pt'>Bucure<font
            class="font5">&#351;ti</font></td>
        <td class=xl72 style='border-left:none'>Service Auto Serus S.R.L.</td>
        <td class=xl69 style='border-top:none;border-left:none'>Tarlaua 12, Parcelele
            80/1-80/43
        </td>
        <td class=xl68 width=193 style='border-top:none;border-left:none;width:145pt'>Comuna
            Blejoi
        </td>
        <td class=xl73 width=162 style='border-top:none;border-left:none;width:122pt'>Prahova</td>
        <td class=xl71 width=140 style='border-left:none;width:105pt'>NISSAN</td>
        <td class=xl83 style='border-left:none'>Da</td>
        <td class=xl83 style='border-left:none'>Da</td>
        <td class=xl83 style='border-left:none'>Nu</td>
        <td class=xl83 style='border-left:none'>Nu</td>
    </tr>
    <tr class=xl84 height=20 style='mso-height-source:userset;height:15.0pt'>
        <td height=20 class=xl72 style='height:15.0pt'>Service Automobile SA</td>
        <td class=xl72 style='border-left:none'>Bd. 22 Decembrie, nr. 129</td>
        <td class=xl73 width=150 style='border-left:none;width:113pt'>Deva</td>
        <td class=xl73 width=123 style='border-left:none;width:92pt'>Hunedoara</td>
        <td class=xl72 style='border-left:none'>Service Automobile SA</td>
        <td class=xl72 style='border-top:none;border-left:none'>Bd. 22 Decembrie, nr.
            129
        </td>
        <td class=xl73 width=193 style='border-top:none;border-left:none;width:145pt'>Deva</td>
        <td class=xl73 width=162 style='border-top:none;border-left:none;width:122pt'>Hunedoara</td>
        <td class=xl71 width=140 style='border-left:none;width:105pt'>DACIA</td>
        <td class=xl83 style='border-left:none'>Da</td>
        <td class=xl83 style='border-left:none'>Da</td>
        <td class=xl83 style='border-left:none'>Expert VE (fara demontare baterie)</td>
        <td class=xl83 style='border-left:none'>Nu</td>
    </tr>
    <tr class=xl84 height=20 style='mso-height-source:userset;height:15.0pt'>
        <td height=20 class=xl72 style='height:15.0pt'>Service Automobile SA</td>
        <td class=xl72 style='border-left:none'>Bd. 22 Decembrie, nr. 129</td>
        <td class=xl71 width=150 style='border-top:none;border-left:none;width:113pt'>Deva</td>
        <td class=xl71 width=123 style='border-top:none;border-left:none;width:92pt'>Hunedoara</td>
        <td class=xl72 style='border-left:none'>Service Automobile SA</td>
        <td class=xl72 style='border-left:none'>Bd. 22 Decembrie, nr. 129</td>
        <td class=xl73 width=193 style='border-top:none;border-left:none;width:145pt'>Deva</td>
        <td class=xl73 width=162 style='border-top:none;border-left:none;width:122pt'>Hunedoara</td>
        <td class=xl71 width=140 style='border-left:none;width:105pt'>NISSAN</td>
        <td class=xl83 style='border-left:none'>Da</td>
        <td class=xl83 style='border-left:none'>Da</td>
        <td class=xl83 style='border-left:none'>Da</td>
        <td class=xl83 style='border-left:none'>Nu</td>
    </tr>
    <tr class=xl84 height=20 style='mso-height-source:userset;height:15.0pt'>
        <td height=20 class=xl72 style='height:15.0pt'>Service Automobile SA</td>
        <td class=xl72 style='border-left:none'>Bd. 22 Decembrie, nr. 129</td>
        <td class=xl71 width=150 style='border-left:none;width:113pt'>Deva</td>
        <td class=xl71 width=123 style='border-left:none;width:92pt'>Hunedoara</td>
        <td class=xl72 style='border-left:none'>Service Automobile SA</td>
        <td class=xl69 style='border-left:none'>Calea Timisoarei FN</td>
        <td class=xl73 width=193 style='border-top:none;border-left:none;width:145pt'>Caransebe&#351;</td>
        <td class=xl73 width=162 style='border-top:none;border-left:none;width:122pt'>Cara&#351;-Severin</td>
        <td class=xl71 width=140 style='border-left:none;width:105pt'>DACIA</td>
        <td class=xl83 style='border-left:none'>Nu</td>
        <td class=xl83 style='border-left:none'>Nu</td>
        <td class=xl83 style='border-left:none'>Nu</td>
        <td class=xl83 style='border-left:none'>Nu</td>
    </tr>
    <tr class=xl84 height=20 style='mso-height-source:userset;height:15.0pt'>
        <td height=20 class=xl72 style='height:15.0pt'>Service Automobile SA<span
            style='mso-spacerun:yes'> </span></td>
        <td class=xl72 style='border-left:none'>Str. Calea Transilvaniei nr.60</td>
        <td class=xl80 width=150 style='border-left:none;width:113pt'>Câmpulung
            Moldovenesc
        </td>
        <td class=xl80 width=123 style='border-left:none;width:92pt'>Suceava</td>
        <td class=xl72 style='border-left:none'>Service Automobile SA<span
            style='mso-spacerun:yes'> </span></td>
        <td class=xl72 style='border-top:none;border-left:none'>Str. Calea
            Transilvaniei nr.60
        </td>
        <td class=xl80 width=193 style='border-top:none;border-left:none;width:145pt'>Câmpulung
            Moldovenesc
        </td>
        <td class=xl80 width=162 style='border-top:none;border-left:none;width:122pt'>Suceava</td>
        <td class=xl71 width=140 style='border-left:none;width:105pt'>DACIA</td>
        <td class=xl83 style='border-left:none'>Da</td>
        <td class=xl83 style='border-left:none'>Da</td>
        <td class=xl83 style='border-left:none'>Expert VE (fara demontare baterie)</td>
        <td class=xl83 style='border-left:none'>Nu</td>
    </tr>
    <tr class=xl84 height=20 style='mso-height-source:userset;height:15.0pt'>
        <td height=20 class=xl72 style='height:15.0pt'>Service Automobile SRL<span
            style='mso-spacerun:yes'> </span></td>
        <td class=xl72 style='border-left:none'>Sat R&#259;carii de Sus, Str.
            Prim&#259;verii, Nr.5
        </td>
        <td class=xl80 width=150 style='border-left:none;width:113pt'>Filia&#351;i</td>
        <td class=xl80 width=123 style='border-left:none;width:92pt'>Dolj</td>
        <td class=xl72 style='border-left:none'>Service Automobile SRL<span
            style='mso-spacerun:yes'> </span></td>
        <td class=xl72 style='border-left:none'>Sat R&#259;carii de Sus, Str.
            Prim&#259;verii, Nr.5
        </td>
        <td class=xl80 width=193 style='border-left:none;width:145pt'>Filia&#351;i</td>
        <td class=xl80 width=162 style='border-left:none;width:122pt'>Dolj</td>
        <td class=xl71 width=140 style='border-left:none;width:105pt'>DACIA</td>
        <td class=xl83 style='border-left:none'>Da</td>
        <td class=xl83 style='border-left:none'>Da</td>
        <td class=xl83 style='border-left:none'>Expert VE (fara demontare baterie)</td>
        <td class=xl83 style='border-left:none'>Nu</td>
    </tr>
    <tr class=xl84 height=20 style='mso-height-source:userset;height:15.0pt'>
        <td height=20 class=xl72 style='height:15.0pt'>Service Automobile SRL<span
            style='mso-spacerun:yes'> </span></td>
        <td class=xl72 style='border-left:none'>Sat R&#259;carii de Sus, Str.
            Prim&#259;verii, Nr.5
        </td>
        <td class=xl71 width=150 style='border-left:none;width:113pt'>Filia&#351;i</td>
        <td class=xl71 width=123 style='border-left:none;width:92pt'>Dolj</td>
        <td class=xl72 style='border-left:none'>Service Automobile SRL<span
            style='mso-spacerun:yes'> </span></td>
        <td class=xl69 style='border-left:none'>Calea Severinului, Nr.225</td>
        <td class=xl73 width=193 style='border-left:none;width:145pt'>Craiova</td>
        <td class=xl80 width=162 style='border-left:none;width:122pt'>Dolj</td>
        <td class=xl71 width=140 style='border-left:none;width:105pt'>DACIA</td>
        <td class=xl83 style='border-left:none'>Da</td>
        <td class=xl83 style='border-left:none'>Da</td>
        <td class=xl83 style='border-left:none'>Expert VE (fara demontare baterie)</td>
        <td class=xl83 style='border-left:none'>Nu</td>
    </tr>
    <tr class=xl84 height=20 style='mso-height-source:userset;height:15.0pt'>
        <td height=20 class=xl72 style='height:15.0pt'>Service Automobile SRL<span
            style='mso-spacerun:yes'> </span></td>
        <td class=xl72 style='border-left:none'>Sat R&#259;carii de Sus, Str.
            Prim&#259;verii, Nr.5
        </td>
        <td class=xl71 width=150 style='border-left:none;width:113pt'>Filia&#351;i</td>
        <td class=xl71 width=123 style='border-left:none;width:92pt'>Dolj</td>
        <td class=xl72 style='border-left:none'>Service Automobile SRL<span
            style='mso-spacerun:yes'> </span></td>
        <td class=xl72 style='border-top:none;border-left:none'>Calea Severinului,
            Nr.225
        </td>
        <td class=xl71 width=193 style='border-top:none;border-left:none;width:145pt'>Craiova</td>
        <td class=xl71 width=162 style='border-left:none;width:122pt'>Dolj</td>
        <td class=xl71 width=140 style='border-left:none;width:105pt'>RENAULT</td>
        <td class=xl83 style='border-left:none'>Da</td>
        <td class=xl83 style='border-left:none'>Da</td>
        <td class=xl83 style='border-left:none'>Expert E-TECH (fara demontare
            baterie)
        </td>
        <td class=xl83 style='border-left:none'>Nu</td>
    </tr>
    <tr class=xl84 height=20 style='mso-height-source:userset;height:15.0pt'>
        <td height=20 class=xl72 style='height:15.0pt'>Service Automobile Turda SA</td>
        <td class=xl72 style='border-left:none'>Str. Clujului D.e. 15, FN</td>
        <td class=xl73 width=150 style='border-left:none;width:113pt'>Turda</td>
        <td class=xl73 width=123 style='border-left:none;width:92pt'>Cluj</td>
        <td class=xl72 style='border-left:none'>Service Automobile Turda SA</td>
        <td class=xl72 style='border-left:none'>Str. Clujului D.e. 15, FN</td>
        <td class=xl73 width=193 style='border-left:none;width:145pt'>Turda</td>
        <td class=xl73 width=162 style='border-left:none;width:122pt'>Cluj</td>
        <td class=xl71 width=140 style='border-left:none;width:105pt'>DACIA</td>
        <td class=xl83 style='border-left:none'>Da</td>
        <td class=xl83 style='border-left:none'>Da</td>
        <td class=xl83 style='border-left:none'>Expert VE (fara demontare baterie)</td>
        <td class=xl83 style='border-left:none'>Nu</td>
    </tr>
    <tr class=xl84 height=20 style='mso-height-source:userset;height:15.0pt'>
        <td height=20 class=xl72 style='height:15.0pt'>Sicaro SA</td>
        <td class=xl72 style='border-left:none'>Str. Harmanului, nr. 49</td>
        <td class=xl73 width=150 style='border-top:none;border-left:none;width:113pt'>Bra&#351;ov</td>
        <td class=xl73 width=123 style='border-top:none;border-left:none;width:92pt'>Bra&#351;ov</td>
        <td class=xl72 style='border-left:none'>Sicaro SA</td>
        <td class=xl72 style='border-left:none'>Str. Harmanului, nr. 49</td>
        <td class=xl73 width=193 style='border-top:none;border-left:none;width:145pt'>Bra&#351;ov</td>
        <td class=xl73 width=162 style='border-top:none;border-left:none;width:122pt'>Bra&#351;ov</td>
        <td class=xl71 width=140 style='border-left:none;width:105pt'>DACIA</td>
        <td class=xl83 style='border-left:none'>Da</td>
        <td class=xl83 style='border-left:none'>Da</td>
        <td class=xl83 style='border-left:none'>Expert VE (fara demontare baterie)</td>
        <td class=xl83 style='border-left:none'>Nu</td>
    </tr>
    <tr class=xl84 height=20 style='mso-height-source:userset;height:15.0pt'>
        <td height=20 class=xl72 style='height:15.0pt'>Sicaro SA</td>
        <td class=xl72 style='border-left:none'>Str. Harmanului, nr. 49</td>
        <td class=xl71 width=150 style='border-top:none;border-left:none;width:113pt'>Bra&#351;ov</td>
        <td class=xl71 width=123 style='border-top:none;border-left:none;width:92pt'>Bra&#351;ov</td>
        <td class=xl72 style='border-left:none'>Sicaro SA</td>
        <td class=xl72 style='border-left:none'>Str. Harmanului, nr. 49</td>
        <td class=xl71 width=193 style='border-top:none;border-left:none;width:145pt'>Bra&#351;ov</td>
        <td class=xl71 width=162 style='border-top:none;border-left:none;width:122pt'>Bra&#351;ov</td>
        <td class=xl71 width=140 style='border-left:none;width:105pt'>RENAULT</td>
        <td class=xl83 style='border-left:none'>Da</td>
        <td class=xl83 style='border-left:none'>Da</td>
        <td class=xl83 style='border-left:none'>Expert E-TECH (fara demontare
            baterie)
        </td>
        <td class=xl83 style='border-left:none'>Nu</td>
    </tr>
    <tr class=xl84 height=20 style='mso-height-source:userset;height:15.0pt'>
        <td height=20 class=xl72 style='height:15.0pt'>Simpo Trans Service SRL</td>
        <td class=xl72 style='border-left:none'>Sos. Bucure&#351;ti-Urziceni nr. 21,
            DN2, km21
        </td>
        <td class=xl73 width=150 style='border-left:none;width:113pt'>Afuma&#355;i</td>
        <td class=xl80 width=123 style='border-left:none;width:92pt'>Ilfov</td>
        <td class=xl72 style='border-left:none'>Simpo Trans Service SRL</td>
        <td class=xl72 style='border-left:none'>Sos. Bucure&#351;ti-Urziceni nr. 21,
            DN2, km21
        </td>
        <td class=xl73 width=193 style='border-left:none;width:145pt'>Afuma&#355;i</td>
        <td class=xl80 width=162 style='border-left:none;width:122pt'>Ilfov</td>
        <td class=xl71 width=140 style='border-left:none;width:105pt'>DACIA</td>
        <td class=xl83 style='border-left:none'>Da</td>
        <td class=xl83 style='border-left:none'>Da</td>
        <td class=xl83 style='border-left:none'>Expert VE (fara demontare baterie)</td>
        <td class=xl83 style='border-left:none'>Nu</td>
    </tr>
    <tr class=xl84 height=20 style='mso-height-source:userset;height:15.0pt'>
        <td height=20 class=xl72 style='height:15.0pt'>Simpo Trans Service SRL</td>
        <td class=xl72 style='border-left:none'>Sos. Bucure&#351;ti-Urziceni nr. 21,
            DN2, km21
        </td>
        <td class=xl71 width=150 style='border-top:none;border-left:none;width:113pt'>Afuma&#355;i</td>
        <td class=xl71 width=123 style='border-left:none;width:92pt'>Ilfov</td>
        <td class=xl72 style='border-left:none'>Simpo Trans Service SRL</td>
        <td class=xl69 style='border-left:none'>&#350;os. Colentina 345, Sector 2</td>
        <td class=xl73 width=193 style='border-top:none;border-left:none;width:145pt'>Bucure&#351;ti</td>
        <td class=xl73 width=162 style='border-left:none;width:122pt'>Bucure&#351;ti</td>
        <td class=xl71 width=140 style='border-left:none;width:105pt'>DACIA</td>
        <td class=xl83 style='border-left:none'>Da</td>
        <td class=xl83 style='border-left:none'>Nu</td>
        <td class=xl83 style='border-left:none'>Expert VE (fara demontare baterie)</td>
        <td class=xl83 style='border-left:none'>Nu</td>
    </tr>
    <tr class=xl84 height=20 style='mso-height-source:userset;height:15.0pt'>
        <td height=20 class=xl72 style='height:15.0pt'>Star Service S.A.<span
            style='mso-spacerun:yes'> </span></td>
        <td class=xl72 style='border-left:none'>Str. Barajului, FN</td>
        <td class=xl69 style='border-left:none'>Tulcea</td>
        <td class=xl69 style='border-left:none'>Tulcea</td>
        <td class=xl72 style='border-left:none'>Star Service S.A.<span
            style='mso-spacerun:yes'> </span></td>
        <td class=xl72 style='border-top:none;border-left:none'>Str. Barajului, FN</td>
        <td class=xl69 style='border-top:none;border-left:none'>Tulcea</td>
        <td class=xl69 style='border-top:none;border-left:none'>Tulcea</td>
        <td class=xl71 width=140 style='border-left:none;width:105pt'>DACIA</td>
        <td class=xl83 style='border-left:none'>Da</td>
        <td class=xl83 style='border-left:none'>Da</td>
        <td class=xl83 style='border-left:none'>Expert VE (fara demontare baterie)</td>
        <td class=xl83 style='border-left:none'>Nu</td>
    </tr>
    <tr class=xl84 height=20 style='mso-height-source:userset;height:15.0pt'>
        <td height=20 class=xl72 style='height:15.0pt'>Star Service S.A.<span
            style='mso-spacerun:yes'> </span></td>
        <td class=xl72 style='border-left:none'>Str. Barajului, FN</td>
        <td class=xl69 style='border-top:none;border-left:none'>Tulcea</td>
        <td class=xl69 style='border-top:none;border-left:none'>Tulcea</td>
        <td class=xl72 style='border-left:none'>Star Service S.A.<span
            style='mso-spacerun:yes'> </span></td>
        <td class=xl72 style='border-left:none'>Str. Barajului, FN</td>
        <td class=xl71 width=193 style='border-top:none;border-left:none;width:145pt'>Tulcea</td>
        <td class=xl71 width=162 style='border-top:none;border-left:none;width:122pt'>Tulcea</td>
        <td class=xl71 width=140 style='border-left:none;width:105pt'>RENAULT</td>
        <td class=xl83 style='border-left:none'>Da</td>
        <td class=xl83 style='border-left:none'>Da</td>
        <td class=xl83 style='border-left:none'>Expert E-TECH (fara demontare
            baterie)
        </td>
        <td class=xl83 style='border-left:none'>Nu</td>
    </tr>
    <tr class=xl84 height=20 style='mso-height-source:userset;height:15.0pt'>
        <td height=20 class=xl72 style='height:15.0pt'>Ti Suerom Auto SRL</td>
        <td class=xl72 style='border-left:none'>Str. Ha&#351;deu nr. 33</td>
        <td class=xl74 width=150 style='border-left:none;width:113pt'>Cluj Napoca</td>
        <td class=xl74 width=123 style='border-left:none;width:92pt'>Cluj</td>
        <td class=xl72 style='border-left:none'>Ti Suerom Auto SRL</td>
        <td class=xl75 style='border-left:none'>Calea Turzii nr. 253-255</td>
        <td class=xl74 width=193 style='border-left:none;width:145pt'>Cluj Napoca</td>
        <td class=xl74 width=162 style='border-left:none;width:122pt'>Cluj</td>
        <td class=xl71 width=140 style='border-left:none;width:105pt'>RENAULT</td>
        <td class=xl83 style='border-left:none'>Nu</td>
        <td class=xl83 style='border-left:none'>Nu</td>
        <td class=xl83 style='border-left:none'>Nu</td>
        <td class=xl83 style='border-left:none'>Nu</td>
    </tr>
    <tr class=xl84 height=20 style='mso-height-source:userset;height:15.0pt'>
        <td height=20 class=xl72 style='height:15.0pt'>Ti Suerom Auto SRL</td>
        <td class=xl72 style='border-left:none'>Str. Ha&#351;deu nr. 33</td>
        <td class=xl71 width=150 style='border-top:none;border-left:none;width:113pt'>Cluj
            Napoca
        </td>
        <td class=xl71 width=123 style='border-top:none;border-left:none;width:92pt'>Cluj</td>
        <td class=xl72 style='border-left:none'>Ti Suerom Auto SRL</td>
        <td class=xl72 style='border-top:none;border-left:none'>Calea Turzii nr.
            253-255
        </td>
        <td class=xl71 width=193 style='border-top:none;border-left:none;width:145pt'>Cluj
            Napoca
        </td>
        <td class=xl71 width=162 style='border-top:none;border-left:none;width:122pt'>Cluj</td>
        <td class=xl71 width=140 style='border-left:none;width:105pt'>NISSAN</td>
        <td class=xl83 style='border-left:none'>Nu</td>
        <td class=xl83 style='border-left:none'>Nu</td>
        <td class=xl83 style='border-left:none'>Nu</td>
        <td class=xl83 style='border-left:none'>Nu</td>
    </tr>
    <tr class=xl84 height=20 style='mso-height-source:userset;height:15.0pt'>
        <td height=20 class=xl72 style='height:15.0pt'>Ti Suerom Auto SRL</td>
        <td class=xl72 style='border-left:none'>Str. Ha&#351;deu nr. 33</td>
        <td class=xl71 width=150 style='border-left:none;width:113pt'>Cluj Napoca</td>
        <td class=xl71 width=123 style='border-left:none;width:92pt'>Cluj</td>
        <td class=xl72 style='border-left:none'>Ti Suerom Auto SRL</td>
        <td class=xl75 style='border-left:none'>Str. Clujului D.E. 15, FN</td>
        <td class=xl74 width=193 style='border-left:none;width:145pt'>Turda</td>
        <td class=xl71 width=162 style='border-left:none;width:122pt'>Cluj</td>
        <td class=xl71 width=140 style='border-left:none;width:105pt'>RENAULT</td>
        <td class=xl83 style='border-left:none'>Nu</td>
        <td class=xl83 style='border-left:none'>Nu</td>
        <td class=xl83 style='border-left:none'>Nu</td>
        <td class=xl83 style='border-left:none'>Nu</td>
    </tr>
    <tr class=xl84 height=20 style='mso-height-source:userset;height:15.0pt'>
        <td height=20 class=xl69 style='height:15.0pt'>TARGOVISTE PREMIUM AUTO 2012
            SRL
        </td>
        <td class=xl69 style='border-left:none'>Sat Viforata, Str. Aleea Sinaia, nr.
            21-23
        </td>
        <td class=xl68 width=150 style='border-left:none;width:113pt'>Comuna Aninoasa</td>
        <td class=xl68 width=123 style='border-left:none;width:92pt'>Dâmbovi&#355;a</td>
        <td class=xl69 style='border-left:none'>TARGOVISTE PREMIUM AUTO 2012 SRL</td>
        <td class=xl69 style='border-top:none;border-left:none'>Sat Viforata, Str.
            Aleea Sinaia, nr. 21-23
        </td>
        <td class=xl68 width=193 style='border-top:none;border-left:none;width:145pt'>Comuna
            Aninoasa
        </td>
        <td class=xl68 width=162 style='border-left:none;width:122pt'>Dâmbovi&#355;a</td>
        <td class=xl68 width=140 style='border-left:none;width:105pt'>DACIA</td>
        <td class=xl85 style='border-left:none'>Da</td>
        <td class=xl85 style='border-left:none'>Da</td>
        <td class=xl83 style='border-left:none'>Expert VE (fara demontare baterie)</td>
        <td class=xl85 style='border-left:none'>Nu</td>
    </tr>
    <tr class=xl84 height=20 style='mso-height-source:userset;height:15.0pt'>
        <td height=20 class=xl72 style='height:15.0pt;border-top:none'>Total Service
            Interna<font class="font5">&#355;ional S.R.L.</font></td>
        <td class=xl72 style='border-top:none;border-left:none'>B-dul Tomis nr. 393</td>
        <td class=xl71 width=150 style='border-top:none;border-left:none;width:113pt'>Constan&#355;a</td>
        <td class=xl71 width=123 style='border-top:none;border-left:none;width:92pt'>Constan&#355;a</td>
        <td class=xl72 style='border-top:none;border-left:none'>Total Service Interna<font
            class="font5">&#355;ional S.R.L.</font></td>
        <td class=xl72 style='border-top:none;border-left:none'>B-dul Tomis nr. 393</td>
        <td class=xl71 width=193 style='border-top:none;border-left:none;width:145pt'>Constan&#355;a</td>
        <td class=xl71 width=162 style='border-top:none;border-left:none;width:122pt'>Constan&#355;a</td>
        <td class=xl71 width=140 style='border-top:none;border-left:none;width:105pt'>RENAULT</td>
        <td class=xl83 style='border-top:none;border-left:none'>Da</td>
        <td class=xl83 style='border-top:none;border-left:none'>Da</td>
        <td class=xl83 style='border-left:none'>Expert E-TECH (fara demontare
            baterie)
        </td>
        <td class=xl83 style='border-top:none;border-left:none'>Nu</td>
    </tr>
    <tr class=xl84 height=20 style='mso-height-source:userset;height:15.0pt'>
        <td height=20 class=xl72 style='height:15.0pt'>Total Service Interna<font
            class="font5">&#355;ional S.R.L.</font></td>
        <td class=xl72 style='border-left:none'>B-dul Tomis nr. 393</td>
        <td class=xl71 width=150 style='border-left:none;width:113pt'>Constan<font
            class="font5">&#355;a</font></td>
        <td class=xl71 width=123 style='border-left:none;width:92pt'>Constan<font
            class="font5">&#355;a</font></td>
        <td class=xl72 style='border-left:none'>Total Service Interna<font
            class="font5">&#355;ional S.R.L.</font></td>
        <td class=xl72 style='border-left:none'>B-dul Tomis nr. 393</td>
        <td class=xl71 width=193 style='border-left:none;width:145pt'>Constan&#355;a</td>
        <td class=xl71 width=162 style='border-left:none;width:122pt'>Constan&#355;a</td>
        <td class=xl71 width=140 style='border-left:none;width:105pt'>NISSAN</td>
        <td class=xl83 style='border-left:none'>Da</td>
        <td class=xl83 style='border-left:none'>Da</td>
        <td class=xl83 style='border-left:none'>Da</td>
        <td class=xl83 style='border-left:none'>Nu</td>
    </tr>
    <tr class=xl84 height=20 style='mso-height-source:userset;height:15.0pt'>
        <td height=20 class=xl72 style='height:15.0pt'>Total Service Interna<font
            class="font5">&#355;ional S.R.L.</font></td>
        <td class=xl72 style='border-left:none'>B-dul Tomis nr. 393</td>
        <td class=xl71 width=150 style='border-left:none;width:113pt'>Constan<font
            class="font5">&#355;a</font></td>
        <td class=xl71 width=123 style='border-left:none;width:92pt'>Constan<font
            class="font5">&#355;a</font></td>
        <td class=xl72 style='border-left:none'>Total Service Interna<font
            class="font5">&#355;ional S.R.L.</font></td>
        <td class=xl72 style='border-left:none'>B-dul I. C. Br&#259;tianu nr. 251</td>
        <td class=xl71 width=193 style='border-left:none;width:145pt'>Constan&#355;a</td>
        <td class=xl71 width=162 style='border-left:none;width:122pt'>Constan&#355;a</td>
        <td class=xl71 width=140 style='border-left:none;width:105pt'>RENAULT</td>
        <td class=xl83 style='border-left:none'>Da</td>
        <td class=xl83 style='border-left:none'>Da</td>
        <td class=xl83 style='border-left:none'>Expert E-TECH (fara demontare
            baterie)
        </td>
        <td class=xl83 style='border-left:none'>Nu</td>
    </tr>
    <tr class=xl84 height=20 style='mso-height-source:userset;height:15.0pt'>
        <td height=20 class=xl72 style='height:15.0pt'>Total Service Interna<font
            class="font5">&#355;ional S.R.L.</font></td>
        <td class=xl72 style='border-left:none'>B-dul Tomis nr. 393</td>
        <td class=xl71 width=150 style='border-left:none;width:113pt'>Constan<font
            class="font5">&#355;a</font></td>
        <td class=xl71 width=123 style='border-left:none;width:92pt'>Constan<font
            class="font5">&#355;a</font></td>
        <td class=xl72 style='border-left:none'>Total Service Interna<font
            class="font5">&#355;ional S.R.L.</font></td>
        <td class=xl72 style='border-left:none'>B-dul I. C. Br&#259;tianu nr. 251</td>
        <td class=xl71 width=193 style='border-left:none;width:145pt'>Constan&#355;a</td>
        <td class=xl71 width=162 style='border-left:none;width:122pt'>Constan&#355;a</td>
        <td class=xl71 width=140 style='border-left:none;width:105pt'>NISSAN</td>
        <td class=xl83 style='border-left:none'>Nu</td>
        <td class=xl83 style='border-left:none'>Da</td>
        <td class=xl83 style='border-left:none'>Nu</td>
        <td class=xl83 style='border-left:none'>Nu</td>
    </tr>
    <tr class=xl84 height=20 style='mso-height-source:userset;height:15.0pt'>
        <td height=20 class=xl72 style='height:15.0pt'>Transilvania Automobile S.R.L.</td>
        <td class=xl72 style='border-left:none'>DN1, km. 303+505, FN, Sat
            &#350;elimb&#259;r
        </td>
        <td class=xl69 style='border-left:none'>Comuna &#350;elimb&#259;r</td>
        <td class=xl69 style='border-left:none'>Sibiu</td>
        <td class=xl72 style='border-left:none'>Transilvania Automobile S.R.L.</td>
        <td class=xl72 style='border-left:none'>DN1, km. 303+505, FN, Sat
            &#350;elimb&#259;r
        </td>
        <td class=xl69 style='border-left:none'>Comuna &#350;elimb&#259;r</td>
        <td class=xl69 style='border-left:none'>Sibiu</td>
        <td class=xl71 width=140 style='border-left:none;width:105pt'>DACIA</td>
        <td class=xl83 style='border-left:none'>Da</td>
        <td class=xl83 style='border-left:none'>Da</td>
        <td class=xl83 style='border-left:none'>Expert VE (fara demontare baterie)</td>
        <td class=xl83 style='border-left:none'>Nu</td>
    </tr>
    <tr class=xl84 height=20 style='mso-height-source:userset;height:15.0pt'>
        <td height=20 class=xl72 style='height:15.0pt'>Transilvania Automobile S.R.L.</td>
        <td class=xl72 style='border-left:none'>DN1, km. 303+505, FN, Sat
            &#350;elimb&#259;r
        </td>
        <td class=xl71 width=150 style='border-top:none;border-left:none;width:113pt'>Comuna
            &#350;elimb&#259;r
        </td>
        <td class=xl71 width=123 style='border-top:none;border-left:none;width:92pt'>Sibiu</td>
        <td class=xl72 style='border-left:none'>Transilvania Automobile S.R.L.</td>
        <td class=xl72 style='border-left:none'>DN1, km. 303+505, FN, Sat
            &#350;elimb&#259;r
        </td>
        <td class=xl71 width=193 style='border-top:none;border-left:none;width:145pt'>Comuna
            &#350;elimb&#259;r
        </td>
        <td class=xl71 width=162 style='border-top:none;border-left:none;width:122pt'>Sibiu</td>
        <td class=xl71 width=140 style='border-left:none;width:105pt'>RENAULT</td>
        <td class=xl83 style='border-left:none'>Da</td>
        <td class=xl83 style='border-left:none'>Da</td>
        <td class=xl83 style='border-left:none'>Expert E-TECH (fara demontare
            baterie)
        </td>
        <td class=xl83 style='border-left:none'>Nu</td>
    </tr>
    <tr class=xl84 height=20 style='mso-height-source:userset;height:15.0pt'>
        <td height=20 class=xl72 style='height:15.0pt'>Transilvania Automobile S.R.L.</td>
        <td class=xl72 style='border-left:none'>DN1, km. 303+505, FN, Sat
            &#350;elimb&#259;r
        </td>
        <td class=xl71 width=150 style='border-left:none;width:113pt'>Comuna
            &#350;elimb&#259;r
        </td>
        <td class=xl71 width=123 style='border-left:none;width:92pt'>Sibiu</td>
        <td class=xl72 style='border-left:none'>Transilvania Automobile S.R.L.</td>
        <td class=xl72 style='border-left:none'>DN1, km. 303+505, FN, Sat
            &#350;elimb&#259;r
        </td>
        <td class=xl71 width=193 style='border-left:none;width:145pt'>Comuna
            &#350;elimb&#259;r
        </td>
        <td class=xl71 width=162 style='border-left:none;width:122pt'>Sibiu</td>
        <td class=xl71 width=140 style='border-left:none;width:105pt'>NISSAN</td>
        <td class=xl83 style='border-left:none'>Da</td>
        <td class=xl83 style='border-left:none'>Da</td>
        <td class=xl83 style='border-left:none'>Da</td>
        <td class=xl83 style='border-left:none'>Nu</td>
    </tr>
    <tr class=xl84 height=20 style='mso-height-source:userset;height:15.0pt'>
        <td height=20 class=xl72 style='height:15.0pt'>Tridex Service S.R.L.</td>
        <td class=xl72 style='border-left:none'>Str. Mihai Eminescu, nr.8</td>
        <td class=xl69 style='border-left:none'>C&#259;t&#259;m&#259;r&#259;&#537;ti
            Deal
        </td>
        <td class=xl73 width=123 style='border-left:none;width:92pt'>Boto&#351;ani</td>
        <td class=xl72 style='border-left:none'>Tridex Service S.R.L.</td>
        <td class=xl72 style='border-left:none'>Str. Mihai Eminescu, nr.8</td>
        <td class=xl69 style='border-left:none'>C&#259;t&#259;m&#259;r&#259;&#537;ti
            Deal
        </td>
        <td class=xl73 width=162 style='border-left:none;width:122pt'>Boto&#351;ani</td>
        <td class=xl71 width=140 style='border-left:none;width:105pt'>DACIA</td>
        <td class=xl83 style='border-left:none'>Da</td>
        <td class=xl83 style='border-left:none'>Da</td>
        <td class=xl83 style='border-left:none'>Expert VE (fara demontare baterie)</td>
        <td class=xl83 style='border-left:none'>Nu</td>
    </tr>
    <tr class=xl84 height=20 style='mso-height-source:userset;height:15.0pt'>
        <td height=20 class=xl72 style='height:15.0pt'>Tridex Service S.R.L.</td>
        <td class=xl72 style='border-left:none'>Str. Mihai Eminescu, nr.8</td>
        <td class=xl69 style='border-top:none;border-left:none'>C&#259;t&#259;m&#259;r&#259;&#537;ti
            Deal
        </td>
        <td class=xl73 width=123 style='border-top:none;border-left:none;width:92pt'>Boto&#351;ani</td>
        <td class=xl72 style='border-left:none'>Tridex Service S.R.L.</td>
        <td class=xl72 style='border-left:none'>Str. Mihai Eminescu, nr.8</td>
        <td class=xl71 width=193 style='border-top:none;border-left:none;width:145pt'>C&#259;t&#259;m&#259;r&#259;&#537;ti
            Deal
        </td>
        <td class=xl71 width=162 style='border-top:none;border-left:none;width:122pt'>Boto&#351;ani</td>
        <td class=xl71 width=140 style='border-left:none;width:105pt'>RENAULT</td>
        <td class=xl83 style='border-left:none'>Da</td>
        <td class=xl83 style='border-left:none'>Da</td>
        <td class=xl83 style='border-left:none'>Expert E-TECH (fara demontare
            baterie)
        </td>
        <td class=xl83 style='border-left:none'>Nu</td>
    </tr>
    <tr class=xl84 height=20 style='mso-height-source:userset;height:15.0pt'>
        <td height=20 class=xl72 style='height:15.0pt'>Union Motors Car Sales S.R.L.</td>
        <td class=xl72 style='border-left:none'>Calea Bucure&#351;tilor nr. 201-203,
            parter, biroul nr. 3
        </td>
        <td class=xl85 style='border-top:none;border-left:none'>Otopeni</td>
        <td class=xl85 style='border-top:none;border-left:none'>Ilfov</td>
        <td class=xl72 style='border-left:none'>Union Motors Car Sales S.R.L.</td>
        <td class=xl69 style='border-left:none'>&#350;os.
            Bucure&#351;ti-Ploie&#351;ti nr. 145, Sector 1
        </td>
        <td class=xl71 width=193 style='border-left:none;width:145pt'>Bucure&#351;ti</td>
        <td class=xl71 width=162 style='border-left:none;width:122pt'>Bucure&#351;ti</td>
        <td class=xl71 width=140 style='border-left:none;width:105pt'>DACIA</td>
        <td class=xl83 style='border-left:none'>Da</td>
        <td class=xl83 style='border-left:none'>Da</td>
        <td class=xl83 style='border-left:none'>Full Expert VE (cu demontare baterie)</td>
        <td class=xl83 style='border-left:none'>Nu</td>
    </tr>
    <tr class=xl84 height=20 style='mso-height-source:userset;height:15.0pt'>
        <td height=20 class=xl72 style='height:15.0pt'>Union Motors Car Sales S.R.L.</td>
        <td class=xl72 style='border-left:none'>Calea Bucure&#351;tilor nr. 201-203,
            parter, biroul nr. 3
        </td>
        <td class=xl71 width=150 style='border-top:none;border-left:none;width:113pt'>Otopeni</td>
        <td class=xl71 width=123 style='border-top:none;border-left:none;width:92pt'>Ilfov</td>
        <td class=xl72 style='border-left:none'>Union Motors Car Sales S.R.L.</td>
        <td class=xl72 style='border-top:none;border-left:none'>&#350;<font
            class="font5">os. Bucure&#351;ti-Ploie&#351;ti nr. 145, Sector 1</font></td>
        <td class=xl71 width=193 style='border-left:none;width:145pt'>Bucure&#351;ti</td>
        <td class=xl71 width=162 style='border-left:none;width:122pt'>Bucure&#351;ti</td>
        <td class=xl71 width=140 style='border-left:none;width:105pt'>RENAULT</td>
        <td class=xl83 style='border-left:none'>Da</td>
        <td class=xl83 style='border-left:none'>Da</td>
        <td class=xl83 style='border-left:none'>Full Expert E-TECH (cu demontare
            baterie)
        </td>
        <td class=xl83 style='border-left:none'>Nu</td>
    </tr>
    <tr class=xl84 height=20 style='mso-height-source:userset;height:15.0pt'>
        <td height=20 class=xl72 style='height:15.0pt'>Verbi<font class="font5">&#355;&#259;
            S.R.L.</font></td>
        <td class=xl72 style='border-left:none'>Calea Aurel Vlaicu nr. 282/1</td>
        <td class=xl85 style='border-left:none'>Arad</td>
        <td class=xl85 style='border-left:none'>Arad</td>
        <td class=xl72 style='border-left:none'>Verbi<font class="font5">&#355;&#259;
            S.R.L.</font></td>
        <td class=xl72 style='border-left:none'>Calea Aurel Vlaicu nr. 282/1</td>
        <td class=xl85 style='border-left:none'>Arad</td>
        <td class=xl85 style='border-left:none'>Arad</td>
        <td class=xl71 width=140 style='border-left:none;width:105pt'>DACIA</td>
        <td class=xl83 style='border-left:none'>Da</td>
        <td class=xl83 style='border-left:none'>Da</td>
        <td class=xl83 style='border-left:none'>Full Expert VE (cu demontare baterie)</td>
        <td class=xl83 style='border-left:none'>Nu</td>
    </tr>
    <tr class=xl84 height=20 style='mso-height-source:userset;height:15.0pt'>
        <td height=20 class=xl72 style='height:15.0pt'>Verbi<font class="font5">&#355;&#259;
            S.R.L.</font></td>
        <td class=xl72 style='border-left:none'>Calea Aurel Vlaicu nr. 282/1</td>
        <td class=xl71 width=150 style='border-top:none;border-left:none;width:113pt'>Arad</td>
        <td class=xl71 width=123 style='border-top:none;border-left:none;width:92pt'>Arad</td>
        <td class=xl72 style='border-left:none'>Verbi<font class="font5">&#355;&#259;
            S.R.L.</font></td>
        <td class=xl72 style='border-left:none'>Calea Aurel Vlaicu nr. 282/1</td>
        <td class=xl85 style='border-top:none;border-left:none'>Arad</td>
        <td class=xl85 style='border-top:none;border-left:none'>Arad</td>
        <td class=xl71 width=140 style='border-left:none;width:105pt'>RENAULT</td>
        <td class=xl83 style='border-left:none'>Da</td>
        <td class=xl83 style='border-left:none'>Da</td>
        <td class=xl83 style='border-left:none'>Full Expert E-TECH (cu demontare
            baterie)
        </td>
        <td class=xl83 style='border-left:none'>Nu</td>
    </tr>
    <tr class=xl84 height=20 style='mso-height-source:userset;height:15.0pt'>
        <td height=20 class=xl72 style='height:15.0pt'>Verbi<font class="font5">&#355;&#259;
            S.R.L.</font></td>
        <td class=xl72 style='border-left:none'>Calea Aurel Vlaicu nr. 282/1</td>
        <td class=xl71 width=150 style='border-left:none;width:113pt'>Arad</td>
        <td class=xl71 width=123 style='border-left:none;width:92pt'>Arad</td>
        <td class=xl72 style='border-left:none'>Verbi<font class="font5">&#355;&#259;
            S.R.L.</font></td>
        <td class=xl72 style='border-left:none'>Calea Aurel Vlaicu nr. 282/1</td>
        <td class=xl85 style='border-top:none;border-left:none'>Arad</td>
        <td class=xl85 style='border-top:none;border-left:none'>Arad</td>
        <td class=xl71 width=140 style='border-left:none;width:105pt'>NISSAN</td>
        <td class=xl83 style='border-left:none'>Da</td>
        <td class=xl83 style='border-left:none'>Da</td>
        <td class=xl83 style='border-left:none'>Da</td>
        <td class=xl83 style='border-left:none'>Nu</td>
    </tr>
    <tr class=xl84 height=20 style='mso-height-source:userset;height:15.0pt'>
        <td height=20 class=xl72 style='height:15.0pt'>Xandor Grup SRL</td>
        <td class=xl72 style='border-left:none'>Str. Manole&#351;ti<span
            style='mso-spacerun:yes'>  </span>Deal, nr. 33
        </td>
        <td class=xl81 width=150 style='width:113pt'>Boto&#351;ani</td>
        <td class=xl81 width=123 style='width:92pt'>Boto&#351;ani</td>
        <td class=xl72>Xandor Grup SRL</td>
        <td class=xl72 style='border-left:none'>Str. Manole&#351;ti<span
            style='mso-spacerun:yes'>  </span>Deal, nr. 33
        </td>
        <td class=xl81 width=193 style='width:145pt'>Boto&#351;ani</td>
        <td class=xl81 width=162 style='width:122pt'>Boto&#351;ani</td>
        <td class=xl71 width=140 style='width:105pt'>DACIA</td>
        <td class=xl83 style='border-left:none'>Da</td>
        <td class=xl83 style='border-left:none'>Da</td>
        <td class=xl83 style='border-left:none'>Expert VE (fara demontare baterie)</td>
        <td class=xl83 style='border-left:none'>Nu</td>
    </tr>
</table>

