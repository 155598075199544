<div class="request-box" [ngClass]="{'mobilize-card': !detailsPage}">
    <div class="request-box__header">
        <div class="title">{{ request.request_subject }}</div>
        <div class="status" [ngClass]="statusClass">
            <span class="bullet"></span>
            {{ request.bo_status }}
        </div>
    </div>

    <div class="request-box__content">
        <div class="details">
            <div class="details__row">
                Client: <span>{{ request.partner.partner_name }}</span>
            </div>
            <div class="details__row">
                Utilizator: <span>{{ request.sender_name }}</span>
            </div>
            <div class="details__row">
                Contract: <span>{{ request.contract_number }}</span>
            </div>
            <div class="details__row">
                Data solicitarii: <span>{{ request.created_at | date: 'dd/MM/YYYY, HH:mm' }}</span>
            </div>
            <div class="details__row">
                Termen de rezolvare: <span>{{ request.deadline_at | date: 'dd/MM/YYYY, HH:mm' }}</span>
            </div>
            <div class="details__row">
                Nr. solicitare: <span>{{ request.request_number | padZero: 7 }}</span>
            </div>
        </div>
    </div>

    @if (detailsPage) {
        @if (!request.reply) {
            @if (!reportPage) {
                @if (!showReplyForm) {
                    <div class="request-box__actions">
                        <button class="mobilize-btn btn-orange" (click)="replyRequest()">
                            Raspunde
                        </button>
                        @if (!request.escalated_to) {
                            <button class="mobilize-btn btn-clear" (click)="escalateRequest()">Escalez</button>
                        }
                    </div>
                } @else {
                    <div class="request-box__form">
                        <div class="reply-form-wrapper">
                            <div class="reply-form-wrapper__header">
                                <div class="title">Raspunde</div>
                            </div>
                            <form [formGroup]="replyForm">
                                <div class="form-group">
                                    <div class="form-control single-control">
                                        <mat-form-field>
                                            <mat-label>Subiect</mat-label>
                                            <input type="text" matInput formControlName="subject"
                                                   placeholder="scrie subiectul"
                                                   readonly>
                                        </mat-form-field>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <div class="form-control single-control">
                                        <mat-form-field>
                                            <mat-label>Mesaj</mat-label>
                                            <textarea matInput type="text" formControlName="message"
                                                      placeholder="scrie mesajul"></textarea>
                                        </mat-form-field>
                                    </div>
                                </div>
                            </form>

                            <div class="upload-area" ng2FileDrop (fileOver)="fileOverBase($event)"
                                 [ngClass]="{'file-over': hasBaseDropZoneOver}">
                                <label>Adauga fisiere:</label>
                                <div class="uploaded-files">
                                    <div *ngFor="let item of uploader.queue">
                                        {{ item?.file?.name }} <span class="icon-mobilize-close" (click)="removeFile(item)"></span>
                                    </div>
                                </div>
                                <label class="file-upload">
                                    <i class="options-icon-upload-cloud-02"></i>
                                    <input type="file" ng2FileSelect [multiple]="true" [uploader]="uploader" #file>
                                </label>
                                <div class="upload-action">
                                    <button class="" (click)="file.click()">
                                        adauga fisier
                                    </button>
                                </div>
                            </div>

                            <div class="reply-form-wrapper__actions">
                                <button class="mobilize-btn btn-orange" (click)="execUpload()"
                                        [mobilizeButtonLoader]="loading" originalContent="Raspunde">Raspunde
                                </button>
                                <button class="mobilize-btn btn-clear" (click)="cancelReply()">Cancel</button>
                            </div>
                        </div>
                    </div>
                }
            }
        } @else {
            @if (reportPage && request.client_read_at) {
                <div class="activity-row">
                    <div class="description">Cerere citita de {{ request.sender_name }}</div>
                    <div class="date">{{ request.client_read_at | date: 'dd/MM/YYYY HH:mm' }}</div>
                </div>
            }
            <div class="request-box__message">
                <div class="request-box__message-header">
                    <span>De la: Mobilize FS Romania</span>
                    <span class="request-date">{{ request.reply.created_at | date: 'dd/MM/YYYY HH:mm' }}</span>
                </div>
                <div class="request-box__message-content">
                    {{ request.reply.reply_body }}
                </div>
            </div>

            <div class="request-box__documents">
                @for (file of request.reply.request_files; track file) {
                    <div class="download-document" (click)="downloadFile(file)">
                        <span class="icon-mobilize-export"></span> {{ file }}
                    </div>
                }
            </div>
        }

        @if (reportPage && request.escalated_to) {
            <div class="activity-row">
                <div class="description">Cerere escaladata de {{ request.bo_read_by }} (Mobilize FS)
                    catre {{ request.escalated_to_name }} (Mobilize FS)
                </div>
                <div class="date">{{ request.bo_read_at | date: 'dd/MM/YYYY HH:mm' }}</div>
            </div>
        }

        @if (reportPage && request.bo_status !== 'Primita') {
            <div class="activity-row">
                <div class="description">Cerere citita de {{ request.bo_read_by }} (Mobilize FS)</div>
                <div class="date">{{ request.bo_read_at | date: 'dd/MM/YYYY HH:mm' }}</div>
            </div>
        }

        <div class="request-box__message">
            <div class="request-box__message-header">
                <span>De la: {{ request.sender_name }}</span>
                <span class="request-date">{{ request.created_at | date: 'dd/MM/YYYY HH:mm' }}</span>
            </div>
            <div class="request-box__message-content">
                {{ request.request_body }}
            </div>
        </div>

        <div class="request-box__documents">
            @for (file of request.request_files; track file) {
                <div class="download-document" (click)="downloadFile(file)">
                    <span class="icon-mobilize-export"></span> {{ file }}
                </div>
            }
        </div>

    }
</div>
