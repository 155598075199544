import { EventEmitter, inject, Injectable } from '@angular/core';
import { Schedule } from '../interfaces/schedule';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { FeedbackComponent } from '../components/feedback/feedback.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

@Injectable({
    providedIn: 'root',
})
export class MiscService {

    private _http = inject(HttpClient);
    private _dialog = inject(MatDialog);
    private new_request = false;

    emit = new EventEmitter();
    tokenUpdate = new EventEmitter();
    feedbackDialogRef!: MatDialogRef<any>;

    processData(rsp: Schedule[]): any[] {
        const dataGroupedByYear = new Map<number, Schedule[]>();
        rsp.forEach((row: Schedule) => {
            const year = new Date(row.due_date).getFullYear();
            if (!dataGroupedByYear.has(year)) {
                dataGroupedByYear.set(year, []);
            }
            dataGroupedByYear.get(year)?.push(row);
        });
        // Flatten the grouped data with a year "header"
        const processedData: any[] = [];
        dataGroupedByYear.forEach((rows, year) => {
            processedData.push({ year } as unknown as Schedule);
            processedData.push(...rows);
        });
        return processedData;
    }

    onPageChange(component: any, event: any, router: any, route: any, totalPages: number): void {
        if (event.page < 1 || event.page > totalPages) {
            return;
        }
        const queryParams: any = { page: event.page, limit: event.limit || component.limit };
        if (event.query && event.query !== '') {
            queryParams.query = event.query;
        }
        if (component.query) {
            queryParams.query = component.query;
        }
        router.navigate([], {
            relativeTo: route,
            queryParams,
        });
    }

    onSearch(component: any, event: any, router: any, route: any): void {
        if (component.query !== event.query) {
            event.page = 1;
        }
        const queryParams: any = { page: event.page || component.currentPage, limit: event.limit || component.limit };
        if (event.query && event.query !== '') {
            queryParams.query = event.query;
        }
        router.navigate([], {
            relativeTo: route,
            queryParams,
        });
    }

    getCategories(): Observable<any> {
        return this._http.get('client-request/filters')
    }

    set newRequest(value: boolean) {
        this.new_request = value;
    }

    get newRequest() {
        return this.new_request;
    }

    sendData(payload: any) {
        this.emit.next(payload);
    }

    emitTokenUpdate(token: string) {
        this.tokenUpdate.next(token);
    }

    requestDocument(payload: any): Observable<any> {
        return this._http.post('document-request-queue/request-document', payload);
    }

    sendAgreements(payload: any): Observable<any> {
        return this._http.post('data-send/consents', payload);
    }

    sendFeedback(payload: any): Observable<any> {
        return this._http.post('feedback/feedback-save', payload);
    }

    public openFeedbackIfAllowed(): any {
        this.checkLastFeedback().subscribe((rsp: boolean) => {
            if (rsp) {
                this.feedbackDialogRef = this._dialog.open(FeedbackComponent, {
                    width: '700px'
                });
                return true;
            } else {
                return false;
            }
        });
    }

    checkLastFeedback(): Observable<any> {
        return this._http.post('feedback/feedback-availability', {});
    }

    searchPartner(query: string): Observable<any> {
        return this._http.get<any[]>(`partners/search?query=${query}`);
    }

    checkLastRequest(payload: any): Observable<any> {
        return this._http.post('document-request-queue/check-request-status', payload);
    }

    exchange(): Observable<any> {
        return this._http.get('currency/get-exchange-rate');
    }

    downloadReport(payload: any): Observable<any> {
        return this._http.post('excel/export', payload, {responseType: 'blob'});
    }
}
