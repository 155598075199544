<div class="head" style="display: flex; align-items: center;">
    <img src="assets/images/mobilize-logo-small.png" alt="logo" width="150px">
    <a href="javascript: window.close()" class="mobilize-btn btn-orange">inchide</a>
</div>
<div class="static-page-wrapper">
    <img src="assets/images/logo.png">
    <div class="static-page-title">Termeni și condiții</div>

    <div class="static-page-content">
        <p>Website-ul www.my.mobilize-fs.ro este un spatiu online dedicat clientilor Mobilize Financial Services,
            denumire comerciala sub care opereaza societatile RCI Romania, cu acces securizat si personalizat la
            informatiie utile din contractul individual de finantare incheiat cu RCI Leasing Romania IFN S.A si/sau RCI
            Romania Finantare SRL si/sau RCI Broker de Asigurare SRL. Proprietarii site-ului www.my.mobilize-fs.ro sunt
            societatile RCI Leasing România IFN S.A., RCI Finantare Romania SRL si RCI Broker de Asigurare SRL avand
            datele de identificare mentionate in sectiunea DATE DE CONTACT</p>

        <strong>Definitii</strong><br><br>
        Termenii “site”, “acest site” si “site-ul” folositi in acest document se refera la site-ul www.my.mobilize-fs.ro
        și subdomeniile lui.<br><br>
        RCI Romania – orice referire la una dintre companiile din grupul RCI: RCI Leasing România IFN S.A. , RCI
        Finantare Romania SRL sau RCI Broker de Asigurare SRL.<br><br>
        Vizitator – orice persoana care acceseaza site-ul www.my.mobilize-fs.ro , indiferent de motiv.<br><br>
        Utilizator – persoana fizica care are sau obtine acces la continut, prin mijloacele de comunicare puse la
        dispozitie de RCI Romania sau in baza unui acord de utilizare existent intre RCI Romania si acesta și care și-a
        dat acordul în mod implicit cu termenii și condițiile de uitilizare a site-ului.<br><br>
        Prin „continut” intelegem:<br><br>
        • toate informatiile de pe site care pot fi vizitate, vizualizate sau altfel accesate prin utilizarea unui
        echipament numeric;<br><br>
        • continutul oricarui e-mail trimis utilizatorilor sau clientilor sai de catre RCI, prin mijloace electronice
        si/sau orice alt mijloc de comunicare disponibil;<br><br>
        • informatii legate de produsele, serviciile si/sau tarifele practicate de RCI.<br><br>
        Client – poate fi orice persoana fizica care are varsta peste 18 ani sau persoana juridica care are sau obtine
        acces la CONTINUT, prin orice mijloc de comunicare pus la dispozitie de catre RCI Româna (electronic, telefonic,
        etc) sau in baza unui acord de utilizare existent intre RCI Romania si acesta si care necesita crearea si
        utilizarea unui Cont.<br><br>
        Date cu caracter personal – informatiile pe care le colectam de la dumneavoastra, pe baza carora puteti fi
        identificat si care va sunt asociate. Pentru o prezentare a modului în care gestionăm datele dumneavoastră
        personale vă rugăm să consultați din meniul site-ului documentul „Politica de Confidentialitate”.<br><br>

        Nickname – pseudonim prin care un anumit Utilizator/Client poate adauga Continut pe Site. Nickname-ul este
        asociat informatiilor din Site ale Utilizatorului/Clientului sub denumirea de “Nume Utilizator”.<br><br>

        Cont – sectiunea din Site formata dintr-o adresa de e-mail si o parola care permite Clientului transmiterea
        Comenzii si care contine informatii despre Client/Client si istoricul Clientului in Site (Comenzi, facturi
        fiscale, garantii Bunuri, contracte de asigurare etc.). Utilizatorul este responsabil si se va asigura ca toate
        informatiile introduse la crearea Contului sunt corecte, complete si actualizate. Lista – sectiunea din Favorite
        in care Clientul/Utilizatorul poate adauga Servicii pe care doreste sa le urmareasca in vederea unei eventuale
        achizitii si pe care, ulterior, le poate sterge sau le poate adauga in cosul de cumparaturi („Cosul meu”).<br><br>

        Site – spatiul personal online gazduit la adresa web www.my.mobilize-fs.ro si subdomeniile acestuia.<br><br>

        Campanie – actiunea de a expune in scop comercial, un numar finit de Servicii avand un stoc limitat si
        predefinit, pentru o perioada limitata de timp stabilita de catre RCI Romania.<br><br>

        Continut – reprezinta:<br><br>

        • – toate informatiile de pe Site care pot fi vizitate, vizualizate sau altfel accesate prin utilizarea unui
        echipament electronic;<br><br>

        • – continutul oricarui e-mail trimis Clientilor de catre RCI Romania prin mijloace electronice si/sau orice alt
        mijloc de comunicare disponibil;<br><br>

        • – orice informatie comunicata prin orice mijloc de catre un angajat/colaborator al RCI Romania, Clientului,
        conform informatiilor de contactare, specificate sau nu de catre acesta;<br><br>

        • – informatii legate de Bunurile si/sau Serviciile si/sau tarifele practicate de RCI Romania intro anumita
        perioada;<br><br>

        • – informatii legate de Bunurile si/sau Serviciile si/sau tarifele practicate de catre un tert cu care RCI
        Romania are incheiate contracte de parteneriat, intr-o anumita perioada;<br><br>

        • – date referitoare la RCI Romania, sau alte date privilegiate ale acestuia Document – prezentele Termene si
        Conditii. Comunicari Comerciale – orice tip de mesaj trimis (cum ar fi: e-mail/SMS/telefonic/mobile
        push/webpush/etc.) continand informatii generale si tematice, informatii cu privire la produse similare sau
        complementare cu cele pe care le-ati achizitionat, informatii cu privire la oferte sau promotii, informatii
        referitoare la Bunuri si Servicii adaugate in sectiunea “Cont/Cosul meu” sau sectiunea “Cont/Favorite” precum si
        alte comunicari comerciale cum ar fi cercetari de piata si sondaje de opinie.<br><br>

        Tranzactie – incasarea sau rambursarea unei sume rezultata din vanzarea unui Serviciu de catre RCI Romania prin
        plata online cu cardul bancar, Clientului, prin utilizarea serviciilor procesatorului de carduri agreat de catre
        RCI Romania, indiferent de modalitatea de livrare. Serviciile care pot fi achitate prin plata online cu cardul
        sunt cele de mai jos, fara a se limita la acestea :<br><br>

        - Plata ratelor lunare<br><br>

        - Plata facturilor lunare<br><br>

        - Plata sold total restant<br><br>

        - Plata sold total<br><br>

        - Plata primelor de asigurare de tip RCA, CASCO, Trafic Accident. Procesatorul de plati agreat de RCI Romania
        este BRD - GROUPE SOCIETE GENERALE SA . Platile se vor efectua pe site exclusiv in moneda RON.<br><br>

        ACCEPTAREA “TERMENILOR SI CONDITIILOR”<br><br>

        Inainte de a viziona acest site, va rugam sa cititi cu atentie precizarile urmatoare. Ele stabilesc termenii si
        conditiile utilizarii de catre dumneavoastra a tuturor materialelor care alcatuiesc prezentul site. Accesarea
        site-ului si folosirea aplicatiilor sale presupun acceptarea explicita sau implicita a tuturor Termenilor si
        Conditiilor publicate, acestea avand valoare contractuala deplina.<br><br>

        RCI Romania isi rezerva dreptul de a modifica in orice moment continutul prezentului site.. Folosirea in
        continuare a site-ului de catre utilizator constituie un raspuns afirmativ de acceptare a modificarilor aduse
        prezentului contract.<br><br>

        CONTINUTUL SITE-ULUI<br><br>

        Continutul, astfel cum este definit, incluzand dar nelimitandu-se la logo-uri, reprezentari stilizate, simboluri
        comerciale, imagini statice, imagini dinamice, text si/sau continut multimedia prezentate pe site, sunt
        proprietatea exclusiva a RCI Romania, acesteia fiindu-i rezervate toate drepturile obtinute in acest sens, in
        mod direct sau indirect (prin licente de utilizare si/sau publicare). Utilizatorilor nu le este permisa
        copierea, distribuirea, publicarea, transferul catre terte parti, modificarea si/sau alterarea in alt mod,
        utilizarea, expunerea, includerea oricarui continut in orice alt context decat cel original. De asemenea
        utilizatorilor nu le este permis să copieze sau să modifice codul sursă a aplicațiilor ce susțin webserver-ul și
        pagina web, să facă teste de penetrare și să falsifice în orice mod originea website-ului. Informatiile
        publicate pe site sunt informatii de interes general despre www.my.mobilize-fs.ro, produsele prezentate,
        opiniile utilizatorilor despre produse cat si alte informatii considerate ca fiind de interes pentru
        utilizatori. Proprietarul site-ului isi rezerva dreptul sa completeze si sa modifice orice informatie de pe
        acest site. RCI Romania isi rezerva dreptul de a corecta eventuale omisiuni sau erori in afisare care pot
        surveni in urma unor greseli de dactilografiere, lipsa de acuratete sau erori ale produselor software, fara a
        anunta in prealabil. Implicit, RCI Romania nu isi asuma raspunderea pentru eventualele erori de pret sau stoc.
        Aceste erori nu obliga RCI Romania la nicio actiune. Preturile si disponibilitatea serviciilor comercializate
        pot suferi modificari ulterioare, acest lucru fiind influentat de factori externi precum politica de preturi a
        distribuitorilor sau disponibilitatea produselor pe stocul acestora. De obicei, informatiile sunt puse gratuit
        la dispozitia utilizatorilor.<br><br>

        COMUNICARI COMERCIALE<br><br>

        Clientul/Utilizatorul isi poate modifica in orice moment optiunea cu privire la acordul dat RCI Romania pentru
        Comunicarile Comerciale continand informatii generale si tematice inclusiv informatii cu privire la oferte sau
        promotii, dupa cum urmeaza: - prin modificarea setarilor din Contul Meu; - prin accesarea link-ului de
        dezabonare afisat in Comunicarile Comerciale primite de la RCI Romania; sau - prin contactarea RCI Romania.<br><br>

        RASPUNDERE<br><br>

        RCI Romania nu poate fi responsabil pentru daune de orice fel pe care Clientul sau oricare terta parte o poate
        suferi ca rezultat al indeplinirii de catre RCI Romania a oricarei din obligatiile sale conform Comenzii si
        pentru daune care rezulta din utilizarea Bunurilor si Serviciilor dupa livrare si in special pentru pierderea
        acestora. Prin crearea si utilizarea Contului, Utilizatorul/ Clientul isi asuma raspunderea pentru mentinerea
        confidentialitatii datelor de Cont (user si parola) si pentru gestionarea accesarii Contului, si, in masura
        permisa de legislatia in vigoare, este responsabil de activitatea derulata prin intermediul Contului sau. Prin
        crearea Contului si/sau utilizarea Continutului, Clientul / Utilizatorul/ Clientul accepta in mod expres si fara
        echivoc Termenele si Conditiile Site-ului in ultima versiune actualizata care este comunicata in cadrul
        Site-ului, existenta la data crearii Contului si/sau utilizarii continutului. RCI Romania isi rezervă dreptul de
        a actualiza și modifica periodic Termenele si Conditiile Site-ului pentru a reflecta orice modificări ale
        modului si conditiilor de functionare a Site-ului sau orice modificări ale cerințelor legale. Documentul este
        opozabili Clientilor / Utilizatorilor de la momentul afisarii in Site. În cazul oricărei astfel de modificări,
        vom afișa pe Site versiunea modificată a documentului.<br><br>

        COPYRIGHT<br><br>

        Continutul site-ului www.my.mobilize-fs.ro – imagini, texte, grafice, simboluri, elemente de grafica WEB,
        scripturi, programe si alte date – este proprietatea RCI Romania si a furnizorilor sai si este aparat de legea
        pentru protectia drepturilor de autor. Asadar, folosirea in scopuri comerciale, fara acordul RCI Romania, a
        oricaruia dintre elementele enumerate mai sus se pedepseste conform legilor in vigoare.<br><br>

        LEGATURI<br><br>

        Site-ul RCI Romania poate gazdui legaturi (link-uri) catre alte site-uri World Wide Web sau alte resurse.
        Raspunderea pentru aceste site-uri o poarta in intregime proprietarii acestora. RCI Romania nu isi asuma
        responsabilitatea pentru nicio paguba sau pierdere cauzata sau presupusa a fi cauzata de informatiile furnizate
        de site-urile sau resursele spre care trimit aceste link-uri.<br><br>

        PARTENERI<br><br>

        Despre calitatea produselor, despre autenticitatea marcii si despre orice alte nelamuriri referitoare la o
        anumita marca sau la un anumit serviciu clientul se poate adresa reprezentantului marcii respective sau
        furnizorului de serviciu.<br><br>

        DECLINAREA RESPONSABILITATII<br><br>

        O parte din continutul site-ului, respectiv textele, descrierile produselor, caracteristicile lor tehnice,
        imaginile si simbolurile lor, este pusa la dispozitia noastra de furnizorii parteneri. Din acest motiv, RCI
        Romania nu isi asuma responsabilitatea pentru prejudiciile create de erorile, lipsa de acuratete sau
        neactualizarea informatiilor publicate sau mentinute pe site. RCI Romania nu-si asuma responsabilitatea cu
        privire la continutul comentariilor postate de utilizatori referitor la serviciile comercializate, in schimb,
        isi asuma dreptul de a sterge (indeparta) orice continut care incalca Termenii si conditiile prezentului site
        sau care este in alt mod incompatibil cu scopul site-ului. Cu toate acestea, RCI Romania nu isi asuma
        responsabilitatea pentru monitorizarea permanenta a intregului continut gazduit pe site. RCI Romania nu raspunde
        pentru pagubele, costurile, procesele, pretentiile sau cheltuielile aparute ca urmare a nerespectarii de catre
        utilizatori a “Termenilor si conditiilor”. RCI Romania nu raspunde pentru prejudiciile create ca urmare a
        nefunctionarii site-ului precum si pentru cele rezultand din imposibilitatea de accesare a anumitor link-uri
        afisate pe site.<br><br>

        PROMOTII SI CONCURSURI<br><br>

        Promotiile si concursurile care se desfasoara pe site respecta regulamentele create de RCI Romania. Aceste
        regulamente sunt aduse la cunostinta utilizatorilor prin intermediul site-ului propriu prin mesaje de posta
        electronica sau in reteaua sa de distributie. RCI Romania isi rezerva dreptul de a intrerupe sau de a anula o
        promotie in orice moment, fara nicio notificare prealabila.<br><br>

        FRAUDA<br><br>

        Orice incercare de a accesa datele cu caracter personal ale altui utilizator, de a modifica continutul site-ului
        www.my.mobilize-fs.ro sau de a afecta functionarea serverului care gazduieste siteul va fi considerata o
        tentativa de fraudare si va putea avea drept consecinta angajarea raspunderii autorului sau autorilor acestor
        fapte.<br><br>

        *versiune 22.03.2023
    </div>
</div>
