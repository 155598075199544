<mobilize-page-header title="Plata anticipata" [reverse]="true"></mobilize-page-header>

<div class="mobilize-card no-bg">
    <div class="tabs-wrapper">
        <div class="tabs-header">
            <div class="tabs-header__tab" [ngClass]="{'selected': selectedTab === 'new-payment'}"
                 (click)="changeTab('new-payment')"><span>Plata noua</span></div>
            <div class="tabs-header__tab" [ngClass]="{'selected': selectedTab === 'history'}"
                 (click)="changeTab('history')"><span>Istoric</span></div>
        </div>

        <div class="tabs-content">
            <div class="tabs-content__tab new-payment-tab" *ngIf="selectedTab === 'new-payment'">
                <div class="payment-type">
                    <div class="title">{{ pageStep === 1 ? 'Alege tipul platii' : 'Ai ales tipul platii' }}</div>

                    <div class="contracts-list">
                        @for (contract of contracts; track contract; let first = $first) {
                            <mobilize-early-repayment-contract [contract]="contract"
                                                               (onPay)="finalizePayment($event)"
                                                               [firstContract]="first"
                                                               (onHistoryTab)="changeTab('history')"
                                                               [selectedContract]="data"
                                                               [selectedPaymentMethod]="selectedPaymentMethod"
                                                               (onFullReimbursement)="fullReimbursement($event)"
                                                               (notifyParent)="onChildEvent($event)"></mobilize-early-repayment-contract>
                        }
                    </div>
                    @if (!showFinalConfirmation) {
                        <div class="title" *ngIf="pageStep === 2">Plateste</div>

                        @if (!isProcessing) {
                            <div class="payment-options" *ngIf="pageStep === 2">
                                <div class="payment-options__item" [ngClass]="{'active': selectedPaymentMethod === 1}"
                                     (click)="selectPay(1);">
                                    <span><span></span></span> Platesc online, cu cardul
                                </div>
                                <div class="payment-options__item" [ngClass]="{'active': selectedPaymentMethod === 2}"
                                     (click)="selectPay(2);">
                                    <span><span></span></span> Platesc cu OP
                                </div>
                            </div>
                        }

                        <form #paymentForm action="{{paymentUrl}}" method="POST">
                            @for (inp of inputsArray; track inp) {
                                <input type="hidden" name="{{ inp.name }}" value="{{ inp.value }}"/>
                            }
                        </form>

                        <div class="payment-info" *ngIf="pageStep === 2 && selectedPaymentMethod === 1">
                            Vei fi transferat in pagina procesatorului de plati
                        </div>

                        <ng-template #targetAlertContainer></ng-template>

                        <div class="payment-actions" *ngIf="pageStep === 2">

                            @if (selectedPaymentMethod === 1) {
                                <button class="mobilize-btn btn-orange" [mobilizeButtonLoader]="isProcessing"
                                        [originalContent]="'plateste securizat'"
                                        (click)="pay()">
                                </button>
                            } @else {
                                <button class="mobilize-btn btn-orange" [mobilizeButtonLoader]="isProcessing"
                                        [originalContent]="'genereaza proforma'"
                                        (click)="pay()">
                                </button>
                            }
                            @if (!isProcessing) {
                                <button class="mobilize-btn btn-clear" (click)="back()">inapoi</button>
                            }
                        </div>
                    } @else {
                        <div class="final-confirmation">
                            <div class="title"><span class="icon-mobilize-check-mark"></span> Factura proforma a fost
                                generata
                            </div>
                            <p>Trebuie sa faci plata in contul de pe factura in urmatoarele 5 zile.</p>
                            <button class="mobilize-btn btn-orange" (click)="downloadProforma()">descarca proforma
                            </button>

                            <div class="payment-details">
                                <div class="title">Detalii pentru OP:</div>
                                <p>Suma: <span
                                    [innerHTML]="formatAmount(fullReimbursementData.grand_total.total, reimbursementContract.currency_code)"></span>
                                </p>
                                <p>Beneficiar: <span>RCI Leasing SA</span></p>
                                <p>Detalii plata: <span>AVN{{ reimbursementContract.partner.fiscal_code }}</span></p>
                            </div>
                        </div>

                        <button class="mobilize-btn btn-orange new-payment-button" (click)="newPayment()">Adauga o plata
                            noua
                        </button>
                    }
                </div>
            </div>

            <div class="tabs-content__tab history-tab" [hidden]="selectedTab !== 'history'">
                @for (item of proformaInvoices; track item) {
                    <mobilize-proforma-invoice [proforma]="item"></mobilize-proforma-invoice>
                }
            </div>
        </div>
    </div>
</div>
