<div class="mobilize-modal">
    <div class="modal-title">{{!feedbackSent ? 'Te putem ajuta mai mult?' : 'Feedback trimis!'}} <i class="icon-mobilize-close" (click)="closeModal()"></i> </div>

    <div class="modal-content">
        @if (!feedbackSent) {
            <div class="feedback-row">
                <p>Cat de mult te-a ajutat my.mobilize-fs.ro sa faci din ceea ce ti-ai propus?</p>
                <mobilize-star-rating row="help" (onRatingSet)="setRating($event)"></mobilize-star-rating>
            </div>

            <mat-form-field>
                <mat-label>Ai o sugestie?</mat-label>
                <textarea matInput [(ngModel)]="ratingMessage" rows="5"></textarea>
            </mat-form-field>
        } @else {
            <div class="success-message text-center">
                Va multumim pentru timpul acordat sondajului!
            </div>
        }
    </div>

    <div class="modal-buttons">
        @if (!feedbackSent) {
            <button class="mobilize-btn btn-orange" [mobilizeButtonLoader]="isProcessing"
                    originalContent="trimite feedback"
                    (click)="sendRating()">trimite feedback
            </button>
        } @else {
            <button class="mobilize-btn btn-clear" (click)="closeModal()">inchide
            </button>
        }
    </div>
</div>
