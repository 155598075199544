import { Component, computed, ElementRef, inject, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router, RouterLink, RouterOutlet } from '@angular/router';
import { MainMenuComponent } from './components/main-menu/main-menu.component';
import { KeycloakEventType, KeycloakService } from 'keycloak-angular';
import { UserService } from './services/user.service';
import { filter, Subscription } from 'rxjs';
import { NgClass } from '@angular/common';
import { MatProgressBar } from '@angular/material/progress-bar';
import { LoadingService } from './services/loading.service';
import { SocketService } from './services/socket.service';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { CookieService } from 'ng2-cookies';
import { MatDialog } from '@angular/material/dialog';
import { FeedbackComponent } from './components/feedback/feedback.component';
import { MiscService } from './services/misc.service';

@Component({
    selector: 'mobilize-root',
    standalone: true,
	imports: [RouterOutlet, MainMenuComponent, NgClass, MatProgressBar, RouterLink, MatProgressSpinner],
    templateUrl: './app.component.html',
    styleUrl: './app.component.scss'
})
export class AppComponent implements OnInit, OnDestroy {

    private _user = inject(UserService);
    private _kc = inject(KeycloakService);
    private _misc = inject(MiscService);
    private _router = inject(Router);
    private _route = inject(ActivatedRoute);
    private _loading = inject(LoadingService);
    private _dialog = inject(MatDialog);
    private _elementRef = inject(ElementRef);

    superAccount = true;
    impersonatedAccount = false;
    registerPage = false;
    dataLoading = computed(() => this._loading.getLoadingState());
    title = 'mobilize-fs';
    userLogged = false;
    backOffice = false;
    showMenu = false;
    impersonatedPartner: string = '';
    processing = false;

    private subscription: Subscription = new Subscription();

    ngOnInit() {
        this._elementRef.nativeElement.removeAttribute("ng-version");
        this._router.events
            .pipe(
                filter(event => event instanceof NavigationEnd)
            )
            .subscribe(() => {
                const route = this.getChild(this._route);

                route.data.subscribe(data => {
                    this.registerPage = data['registerPage'];
                    this.backOffice = data['backoffice'];
                    this.showMenu = true;
                });
            });

        this._kc.getToken().then(token => {
            if (token) {
                this.userLogged = true;
                this._user.superStatus$.subscribe(rsp => {
                    this.superAccount = rsp;
                });
                this.subscription = this._user.impersonatedStatus$.subscribe(rsp => {
                    if (rsp && rsp.active) {
                        this.backOffice = false;
                        this.impersonatedAccount = rsp.active;
                        this.impersonatedPartner = rsp.name
                    }
                });
            }
        });

        // this._socket.initConnection();

        this._kc.keycloakEvents$.subscribe({
            next: (event) => {
                switch (event.type) {
                    case KeycloakEventType.OnAuthSuccess:
                        break;

                    case KeycloakEventType.OnTokenExpired:
                        this._kc.updateToken(2).then(rsp => {
                            this._kc.getToken().then(token => {
                                console.log('emit token')
                                this._misc.emitTokenUpdate(token);
                            });
                        });
                        break;
                }
            }
        });
    }

    getChild(activatedRoute: ActivatedRoute): ActivatedRoute {
        if (activatedRoute.firstChild) {
            return this.getChild(activatedRoute.firstChild);
        } else {
            return activatedRoute;
        }
    }

    showFeedback() {
        this._dialog.open(FeedbackComponent, {
            width: '700px'
        })
    }

    cancelImpersonation() {
        this.processing = true;
        this._user.cancelImpersonation().subscribe(rsp => {
            window.location.reload();
        });
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }
}
