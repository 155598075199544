<div class="head" style="display: flex; align-items: center;">
    <img src="assets/images/mobilize-logo-small.png" alt="logo" width="150px">
    <a href="javascript: window.close()" class="mobilize-btn btn-orange">inchide</a>
</div>
<div class="static-page-wrapper">
    <img src="assets/images/logo.png">
    <div class="static-page-title">Date de contact</div>

    <div class="static-page-content">
        <span style="font-size: 20px;">RCI LEASING ROMANIA IFN SA</span><br>
        București, str. Preciziei, nr. 3G, Clădirea A et.1, camera AE1 20, sector 6, cod postal 062202<br>
        J40/96/2002, CUI RO14378619, nr. de înregistrare în Registrul General al BNR RG-PJR-41-110108 şi în Registrul Special al BNR RS-PJR-41-110016,<br>
        Tel. 021.201.2000 E-mail: rcileasing.romania&#64;mobilize-fs.com<br><br><br>


        <span style="font-size: 20px;">RCI FINANŢARE ROMÂNIA SRL</span><br>
        București, str. Preciziei, nr. 3G, Clădirea A et.1, camera AE1 23, sector 6, cod postal 062202<br>
        J40/10673/2000, CUI RO13542496,<br>
        Tel. 021.201.2000 E-mail: rcileasing.romania&#64;mobilize-fs.com<br><br><br>

        <span style="font-size: 20px;">RCI BROKER DE ASIGURARE</span><br>
        București, str. Preciziei, nr. 3G, Clădirea A et.1, camera AE1 14 si AE1 15, sector 6, cod postal 062202<br>
        J40/14872/2005, CUI 17907641, Autorizație de funcționare nr. 3966/23.11.2005 <br>
        emisa de Comisia de Supraveghere a Asigurărilor, numărul de înmatriculare in Registrul brokerilor de asigurare : RBK-326/24.11.2005<br>
        Telefon: 021.201.2000 E-mail: rcibroker.romania&#64;mobilize-fs.com<br><br><br>

        Program: Luni-Vineri, 9.00-18.00<br><br>

        *versiune 22.03.2023
    </div>
</div>
