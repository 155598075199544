export const environment = {
    apiUrl: 'https://mobilize-fs.gambitdigital.net/api/',
    authUrl: 'https://mobilize-fs.gambitdigital.net/auth',
    userInfoUrl: 'https://mobilize-fs.gambitdigital.net/auth/realms/mobilize/protocol/openid-connect/userinfo',
    captchaKey: '6LeMxmoqAAAAANUdGElbNuE4Qp0z8rzRbGxewTv_',
    socketUrl: 'https://mobilize-fs.gambitdigital.net/ws',
    paymentUrl: 'https://www.activare3dsecure.ro/iteste3d/cgi-bin/',
    displayOptions: {
        showContactCodeLabels: true
    }
}
