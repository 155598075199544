import { Component, inject, OnInit } from '@angular/core';
import { PageHeaderComponent } from '../../../components/page-header/page-header.component';
import { RequestsSearchComponent } from '../../components/requests-search/requests-search.component';
import { UsersFilterComponent } from '../../components/users-filter/users-filter.component';
import { saveAs } from 'file-saver';
import { PaymentService } from '../../../services/payment.service';
import { BaseComponent } from '../../../components/base.component';
import { LoadingService } from '../../../services/loading.service';
import { ActivatedRoute, Router } from '@angular/router';
import { DatePipe, NgClass } from '@angular/common';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { finalize } from 'rxjs';
import { PaginationComponent } from '../../../components/pagination/pagination.component';
import { MiscService } from '../../../services/misc.service';
import moment from 'moment/moment';
import { PaymentsFilterComponent } from '../../components/payments-filter/payments-filter.component';

@Component({
    selector: 'mobilize-online-payments',
    standalone: true,
    providers: [
        DatePipe
    ],
    animations: [
        trigger('rotateChevron', [
            state('open', style({
                transform: 'rotate(180deg)'
            })),
            state('closed', style({
                transform: 'rotate(0deg)'
            })),
            transition('open <=> closed', [
                animate('0.3s ease-in-out')
            ]),
        ]),
        trigger('slideToggle', [
            state('open', style({
                height: '*',
                opacity: 1,
                overflow: 'hidden'
            })),
            state('closed', style({
                height: '0px',
                minHeight: '0px',
                padding: 0,
                opacity: 0,
                overflow: 'hidden'
            })),
            transition('open <=> closed', [
                animate('0.4s ease-in-out')
            ]),
        ]),
    ],
    imports: [
        PageHeaderComponent,
        RequestsSearchComponent,
        UsersFilterComponent,
        PaginationComponent,
        NgClass,
        PaymentsFilterComponent
    ],
    templateUrl: './online-payments.component.html',
    styleUrl: './online-payments.component.scss'
})
export class OnlinePaymentsComponent extends BaseComponent implements OnInit {

    private _payment = inject(PaymentService);
    private _loading = inject(LoadingService);
    private _router = inject(Router);
    private _misc = inject(MiscService);
    private _route = inject(ActivatedRoute);
    private _datePipe = inject(DatePipe);

    filtersLoaded = false;
    showFilter = false;
    query = '';
    isProcessing = false;
    existingFilters: any = {};
    filterActive = false;
    currentPage = 1;
    limit = 10;
    dataReady = false;
    payments: any[] = [];
    pagesArray: any[] = [];
    totalPages: any;

    private currentFilters: any;

    ngOnInit() {
        this._route.queryParams.subscribe(params => {
            console.log(params)
            if (params['role'] || params['partner'] || params['status']) {
                this.showFilter = true;
            }
            this.existingFilters = params;
            this.currentPage = params['page'] ? +params['page'] : 1;
            this.limit = params['limit'] ? +params['limit'] : this.limit;
            const partner = params['partner'] ? params['partner'] : '';
            const status = params['status'] ? params['status'] : '';
            const startDate = params['startDate'] ? params['startDate'] : '';
            const endDate = params['endDate'] ? params['endDate'] : '';
            this.query = params['query'] ? params['query'] : '';
            this.currentFilters = {query: this.query, partner, status, startDate, endDate}
            this.loadPayments({currentPage: this.currentPage, ...this.currentFilters});
        });
    }

    loadPayments(payload: any) {
        this._loading.setLoadingState(true);
        this.dataReady = false;
        this._payment.getAllPayments({
            ...payload,

            page: this.currentPage,
            limit: this.limit
        })
            .pipe(
                finalize(() => {
                    this._loading.setLoadingState(false);
                })
            )
            .subscribe((rsp: any) => {
                this.payments = rsp.payments;
                this.currentPage = parseInt(rsp.currentPage, 10);
                this.totalPages = rsp.totalPages;
                this.pagesArray = Array.from({length: this.totalPages}, (_, i) => i + 1);
                this.dataReady = true;
                this.isProcessing = false;
                this._loading.setLoadingState(false);
            });
    }

    parseDate(date: any) {
        return moment(date).format('DD/MM/YYYY-HH:mm');
    }

    canShowFilters(event: any) {
        this.filtersLoaded = event;
    }

    paymentDetails(payment: any) {

    }

    search(event: any) {
        this._router.navigate([], {
            relativeTo: this._route,
            queryParams: {query: event}
        });
    }

    toggleFilter() {
        if (!this.filtersLoaded) return;
        this.showFilter = !this.showFilter;
    }

    onPageChange(event: any) {
        this.dataReady = false;
        this._misc.onPageChange(this, event, this._router, this._route, this.totalPages);
    }

    applyFilters(event: any) {
        console.log(event)
        this._loading.setLoadingState(true);
        this.currentFilters = event;
        this.isProcessing = true;
        this._router.navigate([], {
            relativeTo: this._route,
            queryParams: event
        });
    }

    downloadReport() {
        this._misc.downloadReport({
            filters: this.currentFilters,
            query: this.query,
            section: '48d9c419-110a-4375-8cb8-5555c3ad1ecc'
        }).subscribe(rsp => {
            const date = moment().format('DD/MM/YYYY-HH:mm')
            saveAs(rsp, `raport-plati-online-${date}.xlsx`);
        });
    }
}
