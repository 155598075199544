import { Component } from '@angular/core';
import { window } from 'rxjs';

@Component({
  selector: 'mobilize-contact-data',
  standalone: true,
  imports: [],
  templateUrl: './contact-data.component.html',
  styleUrl: './contact-data.component.scss'
})
export class ContactDataComponent {

    protected readonly window = window;
}
