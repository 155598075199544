import { Component, signal } from '@angular/core';
import { RouterLink } from '@angular/router';
import {
    MatAccordion,
    MatExpansionPanel,
    MatExpansionPanelDescription, MatExpansionPanelHeader,
    MatExpansionPanelTitle
} from '@angular/material/expansion';

@Component({
  selector: 'mobilize-cookies-policy',
  standalone: true,
    imports: [
        RouterLink,
        MatExpansionPanel,
        MatExpansionPanelDescription,
        MatAccordion,
        MatExpansionPanelTitle,
        MatExpansionPanelHeader,
    ],
  templateUrl: './cookies-policy.component.html',
  styleUrl: './cookies-policy.component.scss'
})
export class CookiesPolicyComponent {
    readonly panelOpenState = signal(false);
}
