import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    ElementRef,
    inject,
    OnInit,
    SecurityContext,
    ViewChild
} from '@angular/core';
import { DecimalPipe, NgClass, NgForOf } from '@angular/common';
import { Contract } from '../../../interfaces/contract';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { LoadingService } from '../../../services/loading.service';
import { DashboardService } from '../../dashboard/dashboard.service';
import { RequestService } from '../../../services/request.service';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { ButtonLoaderDirective } from '../../../directives/button-loader.directive';
import { finalize, forkJoin, of } from 'rxjs';
import { MatProgressBar } from '@angular/material/progress-bar';
import { FileUploader, FileItem, FileUploadModule, FileLikeObject } from 'ng2-file-upload';
import { environment } from '../../../../environments/environment';
import { KeycloakEventType, KeycloakService } from 'keycloak-angular';
import { MatSnackBar } from '@angular/material/snack-bar';
import { RecaptchaComponent, RecaptchaModule } from 'ng-recaptcha';
import { CountDownPipe } from '../../../pipes/count-down.pipe';
import { RecaptchaService } from '../../../services/recaptcha.service';
import { catchError } from 'rxjs/operators';
import { MiscService } from '../../../services/misc.service';

const MAX_SIZE = 5 * 1024 * 1024; // 5MB in bytes

@Component({
    selector: 'mobilize-new',
    standalone: true,
    imports: [
        NgClass,
        ReactiveFormsModule,
        FormsModule,
        ButtonLoaderDirective,
        MatProgressBar,
        FileUploadModule,
        DecimalPipe,
        NgForOf,
        RecaptchaModule,
        CountDownPipe
    ],
    templateUrl: './new.component.html',
    styleUrl: './new.component.scss'
})
export class NewComponent implements OnInit, AfterViewInit {

    @ViewChild('requestsContainer', {static: false}) requestsContainer!: ElementRef;
    @ViewChild('contractsContainer', {static: false}) contractsContainer!: ElementRef;

    private resizeObserver!: ResizeObserver;
    private cdr = inject(ChangeDetectorRef);
    private _dashboard = inject(DashboardService);
    private _fb = inject(FormBuilder);
    private _loading = inject(LoadingService);
    private _request = inject(RequestService);
    private _sanitizer = inject(DomSanitizer);
    private _misc = inject(MiscService);
    private _router = inject(Router);
    private _kc = inject(KeycloakService);
    private _snackbar = inject(MatSnackBar);

    requestForm: FormGroup;
    selectedContract!: Contract;
    selectedRequest: any;
    changing = false;
    pageStep = 1;
    otherRequestText = '';
    requestsContainerHeight!: any;
    contractsContainerHeight!: any;
    contracts: Contract[] = [];
    types: any[] = [];
    showContractsList = false;
    showForm = false;
    hasOtherRequest = false;
    uploadUrl: any = 'upload';
    hasBaseDropZoneOver: any;
    uploader!: FileUploader;
    tokenLoaded = false;
    uploadedFiles: any[] = [];
    recaptchaToken: string = '';
    newToken: string = '';

    private totalSize: number = 0;

    @ViewChild('captchaRef', {static: false}) recaptchaComponent!: RecaptchaComponent;  // Correct typing

    constructor() {
        this.requestForm = this._fb.group({
            message: ['', Validators.required],
            recaptcha: ['']
        });
    }

    ngOnInit() {
        this._loading.setLoadingState(true);

        forkJoin({
            dashboardContracts: this._dashboard.getDashboardContracts(),
            types: this._request.getTypes()
        }).subscribe({
            next: ({dashboardContracts, types}) => {
                this.contracts = dashboardContracts.data;
                this.contracts.forEach((item, idx) => {
                    item.extendable = false;
                    item.visible = true;
                });
                this.types = types.data;
                this.types.forEach((item, idx) => {
                    item.visible = true;
                })
                this._loading.setLoadingState(false);
            },
            error: (err) => {
                // Handle errors here if needed
                console.error('Error occurred while fetching data:', err);
                this._loading.setLoadingState(false);
            }
        });
        this.resizeObserver = new ResizeObserver(entries => {
            for (const entry of entries) {
                if (entry.target === this.requestsContainer.nativeElement) {
                    this.requestsContainerHeight = entry.contentRect.height;
                    this.cdr.detectChanges();
                }
                if (entry.target === this.contractsContainer.nativeElement) {
                    this.contractsContainerHeight = entry.contentRect.height;
                    this.cdr.detectChanges();
                }
            }
        });
    }

    validateData() {
        if (this.requestForm.invalid) {
            this.requestForm.markAllAsTouched();
            this.changing = false;
            return;
        }

        this.recaptchaComponent.execute();
    }

    resolved(captchaResponse: any) {
        if (captchaResponse) {
            this.recaptchaToken = captchaResponse;
            this.requestForm.patchValue({recaptcha: this.recaptchaToken});
            this.execUpload();
        } else {
            this.changing = false;
            console.error('reCAPTCHA token is missing.');
        }
    }

    private calculateTotalSize(): number {
        return this.uploader.queue.reduce((total, file) => total + file.file.size, 0);
    }

    fileOverBase(hasBaseDropZoneOver: any): void {
        this.hasBaseDropZoneOver = hasBaseDropZoneOver;
    }

    sanitizeInput(input: string): any {
        return this._sanitizer.sanitize(SecurityContext.HTML, input);
    }

    ngAfterViewInit() {
        if (this.requestsContainer && this.requestsContainer.nativeElement) {
            this.requestsContainerHeight = this.requestsContainer.nativeElement.offsetHeight;
            this.resizeObserver.observe(this.requestsContainer.nativeElement);
        }
        if (this.contractsContainer && this.contractsContainer.nativeElement) {
            this.contractsContainerHeight = this.contractsContainer.nativeElement.offsetHeight;
            this.resizeObserver.observe(this.contractsContainer.nativeElement);
        }
    }

    selectContract(contract: any) {
        this.contracts.filter(item => {
            if (item.contract_id !== contract.contract_id) {
                item.visible = false;
            }
        });
        this.showForm = true;
        this.pageStep = 3;
        this.selectedContract = contract;

        this._kc.getToken().then(rsp => {
            this.tokenLoaded = true;
            this.uploader = new FileUploader({
                url: environment.apiUrl + this.uploadUrl,
                allowedMimeType: [
                    'image/jpeg',
                    'image/JPEG',
                    'image/JPG',
                    'image/jpg',
                    'application/pdf',
                    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                    'application/vnd.ms-excel', // .xls files
                    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' // .xlsx files
                ],
                method: 'POST',
                autoUpload: false,
                headers: [
                    {
                        name: 'Authorization',
                        value: `Bearer ${this.newToken !== '' ? this.newToken : rsp}`
                    }
                ],
            });
            this.totalSize = this.calculateTotalSize();
            this.uploader.onErrorItem = (item: FileItem, response: string, status: number): any => {
                // error
                this.uploader.removeFromQueue(item);
            };

            this.uploader.onWhenAddingFileFailed = (item: FileLikeObject): any => {
                // error
                this._snackbar.open(`Tipul fisierului nu este permis! (${item.name})`, 'Ok', {
                    panelClass: 'error-snack'
                })
            };

            this.uploader.onBeforeUploadItem = () => {
                if (this.uploader.queue && this.uploader.queue.length > 1) {
                    if (this.uploader.queue.length === 0) {
                        return;
                    }
                    const item = this.uploader.queue[0];
                    if (item.isUploading) {
                        this.uploader.cancelItem(item);
                    }
                }
            };

            this.uploader.onCancelItem = (file) => {
                this.totalSize -= file.file.size;
            };

            this.uploader.onAfterAddingFile = (file) => {
                file.withCredentials = false; // Disable credentials flag

                const newTotalSize = this.totalSize + file.file.size;
                if (newTotalSize > MAX_SIZE) {
                    this.uploader.removeFromQueue(file);
                    this._snackbar.open(`Dimensiunea totala a fisierelor depaseste 5MB.`, 'Ok', {
                        panelClass: 'snack-error'
                    });
                } else {
                    this.totalSize = newTotalSize;
                }
            };

            this.uploader.response.subscribe(res => {
                console.log(res)
                this.uploadedFiles.push(res);
            });
        });
    }

    async refreshTokenIfNeeded(): Promise<string> {
        if (!this.newToken) {
            this.newToken = await this._kc.getToken(); // Or call your Keycloak refresh logic
        }
        return this.newToken;
    }

    selectRequestType(req: any) {
        this.types.forEach(item => {
            if (item.request_type_name !== req.request_type_name) {
                item.visible = false;
            }
        });
        if (req.name === 'other') {
            if (this.sanitizeInput(this.otherRequestText) === '') {
                this.changeRequestSelection();
                return;
            }
            this.types.push({
                request_type_name: this.otherRequestText,
                request_type_uid: '-',
                visible: true,
                temporary: true
            });
        }
        this.showContractsList = true;
        this.hasOtherRequest = false;
        this.selectedRequest = this.types.filter(req => req.visible)[0];
        this.pageStep = 2;
    }

    changeRequestSelection() {
        this.pageStep = 1;
        this.types.forEach(item => item.visible = true);
        this.types = this.types.filter(item => !item.temporary);
        this.hasOtherRequest = false;
        this.showContractsList = false;
        this.otherRequestText = '';
    }

    changeContractSelection() {
        this.pageStep = 2;
        this.contracts.forEach(item => item.visible = true);
        this.showForm = false;
    }

    otherRequest() {
        this.types.forEach(item => item.visible = false);
        this.hasOtherRequest = true;
        this.pageStep = 1;
    }

    sendRequest() {
        const payload: any = {
            request: this.selectedRequest,
            contract_id: this.selectedContract.contract_id,
            contract_number: this.selectedContract.contract_number,
            message: this.requestForm.value.message,
            files: this.uploadedFiles,
            recaptcha: this.recaptchaToken
        }
        if (this.selectedRequest.request_type_uid !== '-') {
            delete payload.request.request_type_name;
        } else {
            payload.request_name = this.selectedRequest.request_type_name
        }
        delete payload.request.visible;
        this.changing = true;

        this._request.sendRequest(payload)
            .pipe(
                catchError((err: any) => {
                    return of({})
                }),
                finalize(() => {
                    this.changing = false;
                    this.recaptchaComponent.reset();
                })
            )
            .subscribe(rsp => {
                if (rsp.success) {
                    this._misc.newRequest = true;
                    this._router.navigate([`/my-requests/inbox/${rsp.data.client_request_uid}`]);
                }
                return;
            });


    }

    async execUpload() {
        this.changing = true;
        if (this.uploader.queue.length) {
            this.uploader.options.headers = [
                {
                    name: 'Authorization',
                    value: `Bearer ${await this.refreshTokenIfNeeded()}`,
                },
            ];
            this.uploader.uploadAll();
            this.uploader.onCompleteAll = () => {
                this.sendRequest();
            };
        } else {
            this.sendRequest();
        }
    }

    removeFile(item: FileItem) {
        this.uploader.removeFromQueue(item);
    }
}
