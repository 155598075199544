<div class="star-container">
    <i
        *ngFor="let star of [].constructor(maxStars); let i = index"
        class="star"
        [ngClass]="{
        'icon-mobilize-star-full': i < (hoverIndex !== null ? hoverIndex : rating),
        'icon-mobilize-star-outline': i >= (hoverIndex !== null ? hoverIndex : rating)
      }"
        (click)="setRating(i + 1)"
        (mouseover)="setHover(i + 1)"
        (mouseleave)="clearHover()"
    ></i>
</div>
