<mobilize-page-header title="Confirmari plati online" [reverse]="true"></mobilize-page-header>

<div class="requests-filter">
    <div class="requests-filter__search">
        <mobilize-requests-search (onSearch)="search($event)" [query]="query" placeholder="Cauta plata"></mobilize-requests-search>
    </div>
    <div class="requests-filter__filter-trigger" (click)="toggleFilter()">
        <div class="label"><i class="icon-mobilize-filter"></i> Filtreaza</div>
        <i class="icon-mobilize-chevron-down" [@rotateChevron]="showFilter ? 'open' : 'closed'"></i>
    </div>
    <button class="mobilize-btn btn-orange" (click)="downloadReport()">Descarca Raport</button>
</div>

<div class="filter-wrapper mobilize-card" [@slideToggle]="showFilter ? 'open' : 'closed'">
    <mobilize-payments-filter
        [processing]="isProcessing"
        [filterActive]="filterActive"
        (filtersLoaded)="canShowFilters($event)"
        (onApplyFilters)="applyFilters($event)"
        [existingFilters]="existingFilters"
        section="online-payments"
        (onCloseFilters)="toggleFilter()"></mobilize-payments-filter>
</div>

<div class="payments-table">
    <div class="payments-table__header">
        <div class="payments-table-row">
            <div class="table-cell reference">Referinta</div>
            <div class="table-cell date">Data initiere</div>
            <div class="table-cell partner">Partener</div>
            <div class="table-cell amount">Suma</div>
            <div class="table-cell status">Status</div>
            <div class="table-cell date">Data status</div>
        </div>
    </div>
    <div class="payments-table__content">
        @for (payment of payments; track payment) {
            <div class="payments-table-row" (click)="paymentDetails(payment);">
                <div class="table-cell reference">{{ payment.transaction_id || '-' }}</div>
                <div class="table-cell date">{{ parseDate(payment.created_on) }}</div>
                <div class="table-cell partner">{{ payment.partner.partner_name || '-' }}</div>
                <div class="table-cell amount" [innerHTML]="formatAmount(payment.amount, 'RON')"></div>
                <div class="table-cell status">{{ payment.internal_status }}</div>
                <div class="table-cell date">{{ parseDate(payment.updated_on) }}</div>
            </div>
        } @empty {
            <div class="mobilize-card small-card text-center">
                Nu exista plati
            </div>
        }
    </div>
</div>

@if (dataReady) {
    <mobilize-pagination [totalPages]="totalPages" [currentPage]="currentPage" [limit]="limit" [pagesArray]="pagesArray"
                         (onPageChange)="onPageChange($event)"></mobilize-pagination>
}
