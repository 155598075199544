import {
    AfterViewInit,
    Component,
    ElementRef,
    inject, OnDestroy,
    OnInit, QueryList,
    ViewChild, ViewChildren,
    ViewContainerRef
} from '@angular/core';
import { PageHeaderComponent } from '../../components/page-header/page-header.component';
import { DatePipe, NgClass, NgIf, NgStyle } from '@angular/common';
import { InstallmentsSliderComponent } from '../../components/installments-slider/installments-slider.component';
import { BaseComponent } from '../../components/base.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatSlider, MatSliderThumb } from '@angular/material/slider';
import { MatDialog } from '@angular/material/dialog';
import { AgreementModalComponent } from './components/agreement-modal/agreement-modal.component';
import { EarlyPaymentContract } from '../../interfaces/early-payment-contract';
import { AlertService } from '../../components/alert/alert.service';
import { EarlyPaymentHistory } from '../../interfaces/early-payment-history';
import { ContractService } from '../../services/contract.service';
import { LoadingService } from '../../services/loading.service';
import { InvoiceService } from '../../services/invoice.service';
import { ButtonLoaderDirective } from '../../directives/button-loader.directive';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import {
    EarlyRepaymentContractComponent
} from '../../components/early-repayment-contract/early-repayment-contract.component';
import { Router } from '@angular/router';
import { EarlyRepaymentService } from '../../services/early-repayment.service';
import { of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { ProformaInvoiceComponent } from '../../components/proforma-invoice/proforma-invoice.component';
import { ConfirmModalComponent } from '../../components/confirm-modal/confirm-modal.component';

@Component({
    selector: 'mobilize-early-repayment',
    standalone: true,
    imports: [
        FormsModule,
        PageHeaderComponent,
        NgClass,
        InstallmentsSliderComponent,
        NgIf,
        MatSlider,
        MatSliderThumb,
        ReactiveFormsModule,
        NgStyle,
        ButtonLoaderDirective,
        MatProgressSpinner,
        EarlyRepaymentContractComponent,
        DatePipe,
        ProformaInvoiceComponent
    ],
    templateUrl: './early-repayment.component.html',
    styleUrl: './early-repayment.component.scss'
})
export class EarlyRepaymentComponent extends BaseComponent implements OnInit, OnDestroy, AfterViewInit {

    constructor() {
        super();

        const navigation: any = this._router.getCurrentNavigation();
        if (navigation?.extras.state) {
            this.data = navigation?.extras.state['data'];
        }
    }

    @ViewChildren(EarlyRepaymentContractComponent) contractChildComponents!: QueryList<EarlyRepaymentContractComponent>;
    @ViewChild('paymentForm', {static: false}) paymentForm!: ElementRef;
    @ViewChild('contractsContainer', {static: false}) contractsContainer!: ElementRef;
    @ViewChild('targetAlertContainer', {read: ViewContainerRef}) targetViewContainerRef!: ViewContainerRef;

    private _dialog = inject(MatDialog);
    private _alert = inject(AlertService);
    private _router = inject(Router);
    private _invoice = inject(InvoiceService);
    private _contract = inject(ContractService);
    private _loading = inject(LoadingService);
    private _earlyRepayment = inject(EarlyRepaymentService);

    contracts: any[] = [];
    data: any;

    selectedTab = 'new-payment';
    isProcessing: boolean = false;
    documentReady = false;
    downloadURL: any;
    finalExchangeRate: any;
    partialReimbursementData: any;
    proformaInvoices: any[] = [];
    reimbursementContract!: any;
    documentLink: any;
    paymentUrl = environment.paymentUrl;
    backUpContracts: EarlyPaymentContract[] = [];
    selectedPaymentMethod = 1;
    currentStep = 1;
    inputsArray: any[] = [];
    contract: any;
    processing = false;
    showFinalConfirmation = false;
    pageStep = 1;
    amount!: number;
    monthsRemaining = 0;
    fullReimbursementActive = false;
    fullReimbursementData: any;

    ngOnInit() {
        this._loading.setLoadingState(true);
        this._contract.getContracts({checkProforma: true}).subscribe(rsp => {
            this.contracts = rsp.data;
            let count = 0;
            this.contracts.forEach((item, idx) => {
                item.maximumInstallments = 0;
                item.totalPayed = 0;
                count = 0;
                item.contract_schedules.forEach((schedule: any) => {
                    if (count < item.previous_instalments && schedule.rate_name !== 'Valoare reziduala') {
                        item.totalPayed += parseFloat(schedule.principal_amount);
                        count++;
                    }
                    if (schedule.rate_name !== 'Avans' && schedule.rate_name !== 'Valoare reziduala') {
                        item.maximumInstallments++;
                    }
                });
            });
            this._loading.setLoadingState(false);
        });
    }

    onChildEvent(data: any) {
        this.contractChildComponents.forEach((child) => {
            if (data.selected.contract_id !== child.contract.contract_id) {
                child.toggleContractState(true);
            }
        });
    }

    ngAfterViewInit() {
        this._alert.setDefaultViewContainerRef(this.targetViewContainerRef);
    }

    historyStateClass(state: string): string {
        return `state-${state}`;
    }

    back() {
        this.pageStep = 1;
        this.currentStep = 1;
        this.contracts = this.backUpContracts;

        this.contractChildComponents.forEach(child => {
            if (this.contract.contract_id === child.contract.contract_id) {
                child.paymentPhase = false;
                child.currentStep = 1;
                child.contract.state = 'extended';
            }
        })
    }

    selectPay(method: number) {
        this.selectedPaymentMethod = method;
        if (method === 1) {
            this._alert.closeAlert();
        } else if (method === 2) {
            // de scos cursul din lista si Total de plata (inclusiv TVA)
            this._alert.showAlert('warning', 'Dupa generarea proformei, nu mai poti reveni la pasul 1', 'icon-mobilize-warning', this.targetViewContainerRef)
        }
    }

    finalizePayment(payload: any) {
        this.contract = payload.contract;
        this.reimbursementContract = payload.contract;
        this.partialReimbursementData = payload.partialReimbursementData;
        this._dialog.open(AgreementModalComponent, {
            width: '600px'
        }).afterClosed().subscribe(rsp => {
            if (rsp) {
                this.pageStep = 2;
                this.backUpContracts = this.contracts;
                this.contracts = this.contracts.filter(item => item.state === 'extended');
                this.contracts[0].state = 'ready';

                this.contractChildComponents.forEach(child => {
                    if (payload.contract.contract_id === child.contract.contract_id) {
                        child.paymentPhase = true;
                    }
                })
            }
        });
    }

    pay() {
        if (this.selectedPaymentMethod === 1) {
            this.isProcessing = true;
            let finalAmount = 0;
            let amountToPay = 0
            if (this.fullReimbursementData) {
                if (this.reimbursementContract.contract_type === 'Credit' || this.reimbursementContract.currency_code === 'RON') {
                    finalAmount = this.fullReimbursementData.grand_total.total;
                } else {
                    finalAmount = this.fullReimbursementData.grand_total.total * this.finalExchangeRate;
                }
                amountToPay = parseFloat(finalAmount.toString()) -
                    parseFloat(this.fullReimbursementData.total_advance) + parseFloat(this.reimbursementContract.unpaid_invoices_ron);
            } else {
                if (this.reimbursementContract.currency_code === 'RON') {
                    finalAmount = this.reimbursementContract.amount;
                } else {
                    finalAmount = this.reimbursementContract.amount * this.finalExchangeRate;
                }
            }


            const payload: any = {
                total: this.fullReimbursementActive ? amountToPay : finalAmount,
                contract: this.reimbursementContract.contract_id,
                reimbursementType: this.fullReimbursementActive ? 'full' : 'partial'
            };
            this._invoice.payEarly(payload)
                .pipe(
                    catchError(() => {
                        this.isProcessing = false;
                        return of({})
                    })
                )
                .subscribe(rsp => {
                    Object.keys(rsp).forEach(key => {
                        this.inputsArray.push({name: key, value: rsp[key]})
                    });
                    setTimeout(() => {
                        this.paymentForm.nativeElement.submit();
                        this.processing = false;
                        this.isProcessing = false;
                    }, 500)
                });
        } else {
            this._dialog.open(ConfirmModalComponent, {
                data: {
                    title: 'Sigur vrei sa generam proforma?'
                }
            }).afterClosed().subscribe(res => {
                if (res) {
                    this.isProcessing = true;
                    this.generateInvoice();
                }
            });

        }
    }

    generateDocument() {
        const payload = {contract_id: this.reimbursementContract.contract_id, payment_type: 'full'};
        this._earlyRepayment.generateDocument(payload).subscribe((response: any) => {
            this.isProcessing = false;
            const contentDisposition = response.headers.get('Content-Disposition');
            const match = contentDisposition.match(/filename="?(.+)"?/);

            let filename = "default.pdf"; // Fallback filename

            if (match && match[1]) {
                filename = match[1];
            }

            if (contentDisposition) {
                const match = contentDisposition.match(/filename="?(.+)"?/);
                if (match != null && match[1]) {
                    filename = match[1];
                }
            }
            const blob = new Blob([response.body], { type: 'application/pdf' });
            this.documentReady = true;
            this.showFinalConfirmation = true;
            this.downloadURL = window.URL.createObjectURL(blob);
            this.documentLink = document.createElement('a');
            this.documentLink.href = this.downloadURL;
            this.documentLink.download = filename;
        });
    }

    generateInvoice() {
        this.generateDocument();
    }

    fullReimbursement(e: any) {
        console.log(e)
        this.fullReimbursementActive = true;
        this.backUpContracts = this.contracts;
        this.contracts = this.contracts.filter(item => item.state === 'extended');
        this.contracts[0].state = 'ready';
        this.pageStep = 2;
        this.finalExchangeRate = e.exchangeRate;
        this.contractChildComponents.forEach(child => {
            if (e.contract.contract_id === child.contract.contract_id) {
                child.paymentPhase = true;
            }
        });
        this.reimbursementContract = e.contract;
        this.fullReimbursementData = e.data;
    }

    downloadProforma() {
        this.documentLink.click();
    }

    changeTab(tab: string) {
        this.selectedTab = tab;

        if (tab === 'history') {
            if (this.proformaInvoices.length === 0) {
                this._loading.setLoadingState(true)
                this._contract.getProformaInvoices().subscribe(rsp => {
                    this.proformaInvoices = rsp.data.map((invoice: any) => ({
                        ...invoice,
                        uiState: 'collapsed'
                    }));
                    this._loading.setLoadingState(false);
                });
            }
        }
    }

    ngOnDestroy() {
        // Clean up the URL object after download
        window.URL.revokeObjectURL(this.downloadURL);
    }

    newPayment() {
        window.location.reload();
    }
}
