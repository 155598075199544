import { Component, inject } from '@angular/core';
import { StarRatingComponent } from '../star-rating/star-rating.component';
import { MatFormField } from '@angular/material/form-field';
import { FormsModule } from '@angular/forms';
import { MatLabel } from '@angular/material/select';
import { MatInput } from '@angular/material/input';
import { MiscService } from '../../services/misc.service';
import { ButtonLoaderDirective } from '../../directives/button-loader.directive';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'mobilize-feedback',
    standalone: true,
    imports: [
        StarRatingComponent,
        MatFormField,
        FormsModule,
        MatLabel,
        MatInput,
        ButtonLoaderDirective
    ],
    templateUrl: './feedback.component.html',
    styleUrl: './feedback.component.scss'
})
export class FeedbackComponent {
    private _misc = inject(MiscService);
    private dialogRef = inject(MatDialogRef)

    ratingMessage = '';
    isProcessing = false;
    feedbackSent = false;
    rating = [
        {
            type: 'help',
            rate: 0
        }
    ];

    setRating(e: any) {
        this.rating.forEach(item => {
            if (item.type === e.row) {
                item.rate = e.rating;
            }
        });
    }

    sendRating() {
        this.isProcessing = true;
        this._misc.sendFeedback({rating: this.rating, message: this.ratingMessage}).subscribe((rsp) => {
            this.isProcessing = false;
            if (rsp.success) {
                this.feedbackSent = true
            }
        });
    }

    closeModal() {
    this.dialogRef.close();
    }
}
