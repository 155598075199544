import {
    AfterViewInit,
    Component,
    ElementRef,
    inject,
    Input,
    OnInit,
    ViewChild,
    ViewContainerRef
} from '@angular/core';
import { PageHeaderComponent } from '../../components/page-header/page-header.component';
import { AlertService } from '../../components/alert/alert.service';
import { DatePipe, NgClass, NgIf } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { PadNumberPipe } from '../../pipes/pad-number.pipe';
import { BaseComponent } from '../../components/base.component';
import { finalize, retry } from 'rxjs';
import { RouterLink } from '@angular/router';
import { InvoiceService } from '../../services/invoice.service';
import { ButtonLoaderDirective } from '../../directives/button-loader.directive';
import { LoadingService } from '../../services/loading.service';
import { MatFormField, MatInput } from '@angular/material/input';
import { PayResponse } from '../../interfaces/responses';
import { Invoice } from '../../interfaces/invoice';
import moment from 'moment';
import { environment } from '../../../environments/environment';
import { MatSnackBar } from '@angular/material/snack-bar';
import { UserService } from '../../services/user.service';

@Component({
    selector: 'mobilize-pay',
    standalone: true,
    imports: [
        PageHeaderComponent,
        NgClass,
        FormsModule,
        PadNumberPipe,
        NgIf,
        RouterLink,
        DatePipe,
        ButtonLoaderDirective,
        MatInput,
        MatFormField
    ],
    templateUrl: './pay.component.html',
    styleUrl: './pay.component.scss'
})
export class PayComponent extends BaseComponent implements OnInit, AfterViewInit {

    @ViewChild('targetAlertContainer', {read: ViewContainerRef}) targetViewContainerRef!: ViewContainerRef;
    @ViewChild('paymentForm', {static: false}) paymentForm!: ElementRef;
    @Input() modal: boolean = false;

    private _invoice = inject(InvoiceService);
    private _snackBar = inject(MatSnackBar);
    private _loading = inject(LoadingService);
    private _alert = inject(AlertService);
    private _user = inject(UserService);

    constructor() {
        super();
    }

    totalAmountForPayment = 0;
    paymentUrl = environment.paymentUrl;

    invoices: any[] = [];
    checkedInvoices: any[] = [];
    inputsArray: any[] = [];
    customAmountActive = false;
    checked!: any;
    invoicesLoaded = false;
    processing = false;
    overDues = 0;
    otherAmount: any = 0;
    paymentOptions = [
        {
            label: 'Total de plata',
            checked: true,
            value: 1
        },
        {
            label: 'Sold restant',
            checked: false,
            value: 2
        },
        {
            label: 'Alta suma (RON)',
            checked: false,
            value: 3
        }
    ];
    creditPaymentOptions = [
        {
            label: 'Urmatoarea rata',
            checked: true,
            value: 1
        },
        {
            label: 'Alta suma (RON)',
            checked: false,
            value: 3
        }
    ];
    nextInstallments: any[] = [];
    nextInstallment: any;
    disablePayment = false;

    ngAfterViewInit() {
    }

    ngOnInit() {
        this._user.impersonatedStatus$.subscribe(account => {
            if (account.active) {
                this.disablePayment = true;
            }
        });

        this._loading.setLoadingState(true);
        this._invoice.unpaidInvoices()
            .pipe(
                finalize(() => {
                    this._loading.setLoadingState(false);
                })
            )
            .subscribe((rsp: PayResponse) => {
                this.invoices = rsp.data;
                this.invoicesLoaded = true;
                this.nextInstallment = rsp.nextInstallment;

                this.invoices.forEach((item: Invoice) => {
                    item.checked = true;
                    this.totalAmountForPayment += parseFloat(item.rest_amount);
                    item.data_scadenta = moment(item.data_scadenta).format('DD/MM/YYYY');
                    this.checkedInvoices.push(item);
                    if (this.invoiceStatusText(item) === 'restant') {
                        this.overDues++;
                    }
                });

                if (this.overDues > 0) {
                    this.openAlert(this.overDues);
                }

                if (!this.invoices.length) {
                    this.nextInstallments = rsp.nextInstallments;
                    this.totalAmountForPayment = this.nextInstallment;
                }
            });
    }

    extendBill(bill: Invoice) {
        bill.extended = !bill.extended;
    }

    checkBill(e: any, invoice: Invoice) {
        if (this.invoiceStatusText(invoice) === 'restant') {
            invoice.checked = true;
            return;
        }

        if (invoice.checked) {
            this.totalAmountForPayment += parseFloat(invoice.rest_amount);
            this.checkedInvoices.push(invoice);
            this.paymentOptions.forEach(item => {
                item.checked = item.value === 3;
            });
        } else {
            this.totalAmountForPayment -= parseFloat(invoice.rest_amount);
            this.checkedInvoices = this.checkedInvoices.filter(item => item.invoice_id !== invoice.invoice_id);
        }

        this.otherAmount = parseFloat(this.totalAmountForPayment.toString()).toFixed(2);
    }

    private openAlert(no: number) {
        this._alert.showAlert('danger', `Ai ${no} rate cu scadenta depasita. Nu poti deselecta restantele.`, 'icon-mobilize-warning', this.targetViewContainerRef)
    }

    processPayment() {
        const payload: any = {
            total: this.totalAmountForPayment,
            invoices: this.checkedInvoices,
            amountType: this.customAmountActive ? 'custom' : 'other'
        };

        if (this.disablePayment) {
            this._snackBar.open('Nu poti face o plata pe un cont impersonat', 'OK', {
                panelClass: 'error-snack',
                duration: 3000
            });
            return;
        }

        if (this.totalAmountForPayment < 1) {
            this._snackBar.open('Suma de plata nu trebuie sa fie mai mica de 1 RON', 'OK', {
                panelClass: 'error-snack',
                duration: 3000
            });
            return;
        }
        this.processing = true;

        this._invoice.payInvoice(payload)
            .subscribe(rsp => {
                Object.keys(rsp).forEach(key => {
                    this.inputsArray.push({name: key, value: rsp[key]})
                });
                setTimeout(() => {
                    this.paymentForm.nativeElement.submit();
                    this.processing = false;
                }, 500)
            });
    }

    onRadioChange(e: any, option: any) {
        this.paymentOptions.forEach(item => {
            item.checked = item.label === option.label;
        });

        switch (option.value) {
            case 1:
                this.customAmountActive = false;
                this.selectAll();
                break;

            case 2:
                this.customAmountActive = false;
                this.selectOverdue();
                break;

            case 3:
                this.customAmountActive = true;
                this.customAmount();
                break;
        }
    }

    onCreditRadioChange(e: any, option: any) {
        this.creditPaymentOptions.forEach(item => {
            item.checked = item.label === option.label;
        });

        switch (option.value) {
            case 1:
                this.customAmountActive = false;
                this.otherAmount = 0;
                this.totalAmountForPayment = this.nextInstallments[0].total_amount_with_vat;
                break;

            case 3:
                this.customAmountActive = true;
                this.totalAmountForPayment = 0;
                this.customAmount();
                break;
        }
    }

    selectOverdue() {
        this.checkedInvoices = [];
        this.totalAmountForPayment = 0;
        this.invoices.forEach((item: Invoice) => {
            if (this.invoiceStatusText(item) === 'restant') {
                item.checked = true;
                this.totalAmountForPayment += parseFloat(item.rest_amount);
                this.checkedInvoices.push(item);
                this.overDues++;
            } else {
                item.checked = false;
            }
        });
    }

    private selectAll() {
        this.checkedInvoices = [];
        this.totalAmountForPayment = 0;
        this.invoices.forEach((item: Invoice) => {
            item.checked = true;
            this.totalAmountForPayment += parseFloat(item.rest_amount);
            this.checkedInvoices.push(item);
            if (this.invoiceStatusText(item) === 'restant') {
                this.overDues++;
            }
        });
    }

    private customAmount() {
        this.invoices.forEach((item: Invoice) => {
            item.checked = false;
            this.otherAmount = 10;
            this.totalAmountForPayment = this.otherAmount;
            this.checkedInvoices = []
            if (this.invoiceStatusText(item) === 'restant') {
                this.overDues++;
            }
        });
    }

    updateTotalAmount() {
        if (this.paymentOptions.find((opt) => opt.value === 3 && opt.checked)) {
            this.totalAmountForPayment = parseFloat(this.otherAmount.toString());
        }
    }

    creditUpdateTotalAmount() {
        if (this.creditPaymentOptions.find((opt) => opt.value === 3 && opt.checked)) {
            this.totalAmountForPayment = parseFloat(this.otherAmount.toString());
        }
    }

    noInvoiceTotalAmount() {
        this.totalAmountForPayment = parseFloat(this.otherAmount.toString());

    }
}
