<mobilize-page-header [title]="pageTitle" [reverse]="true"></mobilize-page-header>

<div class="requests-filter">
    <div class="requests-filter__search">
        <mobilize-requests-search (onSearch)="search($event)" [query]="query"></mobilize-requests-search>
    </div>
    <div class="requests-filter__filter-trigger" (click)="toggleFilter()">
        <div class="label"><i class="icon-mobilize-filter"></i> Filtreaza</div>
        <i class="icon-mobilize-chevron-down" [@rotateChevron]="showFilter ? 'open' : 'closed'"></i>
    </div>
</div>

<div class="filter-wrapper mobilize-card" [@slideToggle]="showFilter ? 'open' : 'closed'">
    <mobilize-requests-filter
        [processing]="isProcessing"
        [filterActive]="filterActive"
        (filtersLoaded)="canShowFilters($event)"
        (onApplyFilters)="applyFilters($event)"
        (onCloseFilters)="toggleFilter()"></mobilize-requests-filter>
</div>


<div class="requests-list">
    @for (request of requests; track request) {
        <mobilize-bo-request (click)="markAsRead(request)" [request]="request"></mobilize-bo-request>
    } @empty {
        <div class="mobilize-card small-card text-center">Nu exista solicitari inregistrate si necitite</div>
    }
    @if (dataReady) {
        <mobilize-pagination [totalPages]="totalPages" [currentPage]="currentPage" [limit]="limit" [pagesArray]="pagesArray"
                             (onPageChange)="onPageChange($event)"></mobilize-pagination>
    }
</div>
