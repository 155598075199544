import { Component, inject, OnInit } from '@angular/core';
import { PageHeaderComponent } from '../../components/page-header/page-header.component';
import { ContractComponent } from '../../components/contract/contract.component';
import { Contract } from '../../interfaces/contract';
import { DashboardService } from '../dashboard/dashboard.service';
import { MatProgressBar } from '@angular/material/progress-bar';
import { LoadingService } from '../../services/loading.service';
import { UserService } from '../../services/user.service';
import { ContractService } from '../../services/contract.service';
import { DataFilterComponent } from '../../components/data-filter/data-filter.component';
import { ActivatedRoute, Router } from '@angular/router';
import { MiscService } from '../../services/misc.service';

@Component({
    selector: 'mobilize-contracts',
    standalone: true,
    imports: [
        PageHeaderComponent,
        ContractComponent,
        MatProgressBar,
        DataFilterComponent
    ],
    templateUrl: './contracts.component.html',
    styleUrl: './contracts.component.scss'
})
export class ContractsComponent implements OnInit {

    private _loading = inject(LoadingService);
    private _user = inject(UserService);
    private _contract = inject(ContractService)
    private _route = inject(ActivatedRoute)
    private _router = inject(Router)
    private _misc = inject(MiscService)

    contracts: Contract[] = [];
    filterProcessing: boolean = false;
    filterOptions: string[] = ['search', 'state'];
    dataReady = false;
    filterArgs: any;
    noContracts = false;

    ngOnInit() {
        this.getContracts({state: 'Valid'});
    }

    getContracts(payload?: any) {
        this.dataReady = false;
        this._loading.setLoadingState(true);
        this._contract.getContracts({
            ...payload,
            withInsurance: true
        }).subscribe((response) => {
            if (response.data.length === 0) {
                this.noContracts = true;
            }
            this.dataReady = true;
            this.filterProcessing = false;
            this.contracts = response.data
            this.contracts.forEach(item => {
                item.contract_extended_state = 'opened';
            })
            this._loading.setLoadingState(false);
        });
    }

    execFilter(e: any) {
        delete e.contracts;
        delete e.endDate;
        delete e.startDate;

        this.dataReady = false;
        this.filterProcessing = true;
        this.filterArgs = e;
        this._router.navigate([], {
            relativeTo: this._route,
            queryParams: {}
        }).then(() => {
            this.getContracts(e);
        });
    }
}
