import { Component, inject, Input } from '@angular/core';
import { DatePipe, NgClass } from '@angular/common';
import { EarlyPaymentHistory } from '../../interfaces/early-payment-history';
import { BaseComponent } from '../base.component';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { EarlyRepaymentService } from '../../services/early-repayment.service';
import { ProformaDownloadService } from '../../services/proforma-download.service';
import { ButtonLoaderDirective } from '../../directives/button-loader.directive';

@Component({
    selector: 'mobilize-proforma-invoice',
    standalone: true,
    animations: [
        trigger('rotateChevron', [
            state('open', style({
                transform: 'rotate(180deg)'
            })),
            state('closed', style({
                transform: 'rotate(0deg)'
            })),
            transition('open <=> closed', [
                animate('0.3s ease-in-out')
            ]),
        ]),
        trigger('slideToggle', [
            state('open', style({
                height: '*',
                opacity: 1,
                overflow: 'hidden'
            })),
            state('closed', style({
                height: '0px',
                minHeight: '0px',
                padding: 0,
                opacity: 0,
                overflow: 'hidden'
            })),
            transition('open <=> closed', [
                animate('0.4s ease-in-out')
            ]),
        ]),
    ],
    imports: [
        DatePipe,
        NgClass,
        ButtonLoaderDirective
    ],
    templateUrl: './proforma-invoice.component.html',
    styleUrl: './proforma-invoice.component.scss'
})
export class ProformaInvoiceComponent extends BaseComponent {
    @Input() proforma: any;

    private _earlyRepayment = inject(EarlyRepaymentService);
    private _proformaDownload = inject(ProformaDownloadService);

    isProcessing = false;

    extendProforma(item: EarlyPaymentHistory) {
        if (item.uiState === 'collapsed') {
            item.uiState = 'extended';
        } else {
            item.uiState = 'collapsed';
        }
    }

    requestStatus(status: string) {

        switch (status) {
            case 'pending':
                return 'in asteptarea confirmatii platii  <span class="state-bullet state-red"></span>';

            case 'incasat':
                return 'plata a fost procesata, vei primi pe email documentele ce atesta modificarea solicitata  <span class="state-bullet state-green"></span>';

            case 'anulata':
                return 'plata neefectuata - solicitarea a fost anulata  <span class="state-bullet state-red"></span>';
        }

        return '';
    }

    downloadProforma() {
        this.isProcessing = true;
        this._earlyRepayment.downloadProforma({proforma_id: this.proforma.proforma_invoice_id}).subscribe((response: any) => {
            this._proformaDownload.downloadFile(response, 'default.pdf');

            this.isProcessing = false;
        });
    }

}
