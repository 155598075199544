import { Component, EventEmitter, inject, Input, OnInit, Output } from '@angular/core';
import { AsyncPipe, DatePipe, NgForOf } from '@angular/common';
import { MatAutocomplete, MatAutocompleteTrigger, MatOption } from '@angular/material/autocomplete';
import {
    MatDatepicker,
    MatDatepickerInput,
    MatDatepickerToggle,
    MatDateRangeInput, MatDateRangePicker, MatEndDate, MatStartDate
} from '@angular/material/datepicker';
import { MatFormField, MatLabel, MatSuffix } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { FormBuilder, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { ReactiveTypedFormsModule } from '@rxweb/reactive-form-validators';
import { debounceTime, distinctUntilChanged, finalize, map, Observable, of, startWith } from 'rxjs';
import { ButtonLoaderDirective } from '../../../directives/button-loader.directive';
import { MatSelect } from '@angular/material/select';
import { PaymentService } from '../../../services/payment.service';
import { MomentDateAdapter, provideMomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_LOCALE } from '@angular/material/core';
import { switchMap } from 'rxjs/operators';
import { MiscService } from '../../../services/misc.service';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import moment from 'moment';

const MY_FORMATS = {
    parse: {
        dateInput: 'DD/MM/YYYY',
    },
    display: {
        dateInput: 'DD/MM/YYYY',
        monthYearLabel: 'MMM YYYY',
        dateA11yLabel: 'LL',
        monthYearA11yLabel: 'MMMM YYYY',
    },
};
@Component({
    selector: 'mobilize-payments-filter',
    standalone: true,
    imports: [
        AsyncPipe,
        MatAutocomplete,
        MatAutocompleteTrigger,
        MatDatepicker,
        MatDatepickerInput,
        MatDatepickerToggle,
        MatFormField,
        MatInput,
        MatLabel,
        MatOption,
        MatSuffix,
        NgForOf,
        ReactiveFormsModule,
        ReactiveTypedFormsModule,
        ButtonLoaderDirective,
        MatDateRangeInput,
        MatDateRangePicker,
        MatEndDate,
        MatStartDate,
        MatSelect,
        MatProgressSpinner
    ],
    providers: [
        DatePipe,
        provideMomentDateAdapter(MY_FORMATS),
    ],
    templateUrl: './payments-filter.component.html',
    styleUrl: './payments-filter.component.scss'
})
export class PaymentsFilterComponent implements OnInit {

    private _fb = inject(FormBuilder);
    private _payment = inject(PaymentService);
    private _misc = inject(MiscService);

    @Input() processing: boolean = false;
    @Input() existingFilters: any = {};
    @Input() filterActive: boolean = false;
    @Input() section: string = '';
    @Output() filtersLoaded = new EventEmitter<any>();
    @Output() onCloseFilters = new EventEmitter<any>();
    @Output() onApplyFilters = new EventEmitter<any>();

    partners: any[] = [];
    statuses: any[] = [];
    filterForm: FormGroup;
    filters = false;
    isLoading = false;

    filteredPartners!: Observable<any[]>;

    constructor() {
        this.filterForm = this._fb.group({
            partner: [''],
            status: [''],
            startDate: [''],
            endDate: ['']
        });
    }

    ngOnInit() {
        this.filteredPartners = this.filterForm.controls['partner'].valueChanges.pipe(
            debounceTime(300), // Wait 300ms before making the request
            distinctUntilChanged(), // Trigger only if the value changes
            switchMap((query) => {
                if (!query) {
                    return of([]); // Return an empty array if the input is empty
                }
                this.isLoading = true; // Start the loader
                return this._misc.searchPartner(query).pipe(
                    finalize(() => {
                        this.isLoading = false; // Stop the loader
                    })
                );
            })
        );

        if (this.existingFilters) {
            this.filterForm.patchValue({
                role: this.existingFilters.role,
                partner: parseInt(this.existingFilters.partner, 10),
                status: this.existingFilters.status
            });
        }

        this._payment.getPaymentFilters().subscribe(rsp => {
            this.partners = rsp.partners;
            this.statuses = rsp.statuses;
            this.filtersLoaded.next(true);
            this.filters = true;
        });
    }

    getPartnerName(partner: any): string {
        return partner ? partner.partner_name : '';
    }

    applyFilters() {
        if (Object.keys(this.filterForm.value).every(key => this.filterForm.value[key] === null)) {
            return;
        }
        this.processing = true;
        this.filterActive = true;
        const filters = this.filterForm.value;
        console.log(filters)
        filters.partner = this.filterForm.value.partner.partner_id;
        filters.startDate = moment(this.filterForm.value.startDate).format('DD-MM-YYYY');
        filters.endDate = moment(this.filterForm.value.endDate).format('DD-MM-YYYY');
        this.onApplyFilters.next(filters);
    }

    closeFilter() {
        this.filterForm.reset();
        this.onCloseFilters.next(true);
    }

    resetFilter() {
        this.filterForm.reset();
        this.onApplyFilters.next({...this.filterForm.value, reset: true });
        this.onCloseFilters.next(true);
        this.filterActive = false;
    }
}
