import { Component } from '@angular/core';
import { RouterLink } from '@angular/router';

@Component({
  selector: 'mobilize-partners',
  standalone: true,
    imports: [
        RouterLink
    ],
  templateUrl: './partners.component.html',
  styleUrl: './partners.component.scss'
})
export class PartnersComponent {
    close() {
        window.close()
    }
}
