<div class="filter-wrapper__title">Filtreaza</div>

@if (filters) {
    <form [formGroup]="filterForm">
        <div class="form-group">
            <div class="form-control">
                <mat-form-field class="example-full-width">
                    <mat-label>Partener</mat-label>
                    <input type="text"
                           placeholder="Alege un partener"
                           aria-label="Partener"
                           matInput
                           formControlName="partner"
                           [matAutocomplete]="partners">
                    <mat-autocomplete #partners="matAutocomplete" [displayWith]="getPartnerName.bind(this)">
                        <!-- Show Loader While Fetching -->
                        @if (isLoading) {
                            <mat-option class="loading-option">
                                <mat-progress-spinner class="orange-spinner" diameter="20" strokeWidth="2"
                                                      mode="indeterminate"></mat-progress-spinner>
                            </mat-option>
                        }

                        <!-- Show Partners -->
                        <mat-option
                            *ngFor="let partner of filteredPartners | async"
                            [value]="partner">
                            {{ partner.partner_name }}
                        </mat-option>
                    </mat-autocomplete>
                </mat-form-field>
            </div>
            <div class="form-control">
                <mat-form-field>
                    <mat-label>Status</mat-label>
                    <mat-select formControlName="status">
                        @for (status of statuses; track status) {
                            <mat-option [value]="status.internal_status">{{ status.internal_status }}</mat-option>
                        }
                    </mat-select>
                </mat-form-field>
            </div>
        </div>

        <div class="form-group">
            <div class="form-control">
                <mat-form-field>
                    <mat-label>Alege un interval</mat-label>
                    <mat-date-range-input [rangePicker]="picker">
                        <input formControlName="startDate" matStartDate placeholder="Data start">
                        <input formControlName="endDate" matEndDate placeholder="Data sfarsit">
                    </mat-date-range-input>
                    <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-date-range-picker #picker></mat-date-range-picker>
                </mat-form-field>
            </div>
            <div class="form-control">
            </div>
        </div>
    </form>
}

<div class="filter-wrapper__actions">
    <button class="mobilize-btn btn-orange" (click)="applyFilters()" [mobilizeButtonLoader]="processing"
            originalContent="Aplica">Aplica
    </button>
    <button class="mobilize-btn btn-clear" (click)="closeFilter()">Cancel
    </button>

    @if (filterActive) {
        <button class="mobilize-btn btn-clear right-stick" (click)="resetFilter()">Reseteaza
        </button>
    }
</div>
