import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NgClass, NgForOf } from '@angular/common';

@Component({
    selector: 'mobilize-star-rating',
    templateUrl: './star-rating.component.html',
    styleUrls: ['./star-rating.component.scss'],
    imports: [
        NgClass,
        NgForOf
    ],
    standalone: true
})
export class StarRatingComponent {
    @Input() row: any;
    @Output() onRatingSet = new EventEmitter();

    maxStars: number = 5; // Maximum stars
    rating: number = 0; // Current rating
    hoverIndex: number | null = null; // For hover effect

    // Set the rating on click
    setRating(star: number): void {
        this.rating = star;
        this.onRatingSet.next({row: this.row, rating: this.rating});
    }

    // Set the hover index while hovering stars
    setHover(index: number): void {
        this.hoverIndex = index;
    }

    // Clear hover index when no hover is active
    clearHover(): void {
        this.hoverIndex = null;
    }
}
