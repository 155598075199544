<div class="modal-wrapper">
    <div class="modal-title">
        @if (insuranceOption) {
            {{ insurance.insurance_type }}
        } @else {
            {{ data.name }}
        }
        <span class="icon-mobilize-close" (click)="closeModal()"></span>
    </div>
    @if (!requestSent) {
        @if (showButton && data.name !== 'Intretinere' && insurance.insurance_type !== 'Asistenta Rutiera') {
            <button class="mobilize-btn btn-orange" (click)="getDocument()"
                    [mobilizeButtonLoader]="isProcessing"
                    [originalContent]="insuranceOption ? 'descarca polita' : 'descarca documentul'"></button>
        }
    } @else {
        <p class="custom-inline-alert">Documentul solicitat va fi trimis pe adresa ta de e-mail</p>
    }

    <div class="modal-content">
        @if (insuranceOption) {
            <div class="detail-row">
                <span>Nume Asigurator</span>
                <span>{{ insurance.insurer.partner_name }}</span>
            </div>
        }

        <div class="detail-row">
            <span>Data emiterii</span>
            <span>{{ (insuranceOption ? insurance.insurance_start_date : insurance.service_start_date) | date: 'dd/MM/YYYY':'Europe/Bucharest' }}</span>
        </div>

        <div class="detail-row">
            <span>Valabilitate</span>
            @if (insuranceOption) {
                <span>{{ insurance.insurance_start_date | date: 'dd/MM/YYYY' }}
                    - {{ insurance.insurance_end_date | date: 'dd/MM/YYYY' }}</span>
            } @else {
                <span>{{ insurance.service_start_date | date: 'dd/MM/YYYY' }}
                    - {{ insurance.service_end_date | date: 'dd/MM/YYYY' }}</span>
            }

        </div>

        @if (insurance.periodicity) {
            <div class="detail-row">
                <span>Periodicitatea platilor</span>
                @if (insurance.periodicity === 1) {
                    <span>LUNAR</span>
                } @else if (insurance.periodicity === 12) {
                    <span>ANUAL</span>
                }
            </div>
        }

        @if (insurance.next_instalment_amount) {
            <div class="detail-row">
                <span>Valoarea urmatoarei rate</span>
                <span [innerHTML]="formatAmount(insurance.next_instalment_amount, data.contract.currency_code)"></span>
            </div>
        }

        @if (insurance.next_instalment_date) {
            <div class="detail-row">
                <span>Data urmatoarei rate</span>
                <span>{{ insurance.next_instalment_date | date: 'dd/MM/YYYY' }}</span>
            </div>
        }

        <div class="detail-row">
            <span>Prima de plata</span>
            @if (insurance.premium_amount) {
                <span
                    [innerHTML]="formatAmount(insurance.premium_amount, (insurance.insurance_type === 'RCA' ? 'RON' : data.contract.currency_code))"></span>
            } @else {
                <span [innerHTML]="formatAmount(insurance.service_amount, data.contract.currency_code)"></span>
            }
        </div>

        <div class="detail-row">
            <span>Bun asigurat</span>
            <span>
                {{ data.contract.contract_objects[0].vehicle_brand }} {{ data.contract.contract_objects[0].vehicle_model }}
            </span>
        </div>

        @if (insurance.chassis_number) {
            <div class="detail-row">
                <span>Serie sasiu</span>
                <span>{{ insurance.chassis_number }}</span>
            </div>
        }

        @if (data.contract?.contract_objects[0]?.registration_number) {
            <div class="detail-row">
                <span>Numar de inmatriculare</span>
                <span>{{ data.contract.contract_objects[0].registration_number }}</span>
            </div>
        }
        @if (insuranceOption) {
            <div class="detail-row">
                <span>Numar polita</span>
                <span>{{ insurance.insurance_number }}</span>
            </div>
        }
    </div>

</div>
