export const DocumentDetailsPersonControls = [
    {
        name: 'name',
        label: 'Nume imputernicit',
        value: '',
        type: 'text',
        placeholder: 'Nume imputernicit',
        validators: {
            required: true
        }
    },
    {
        name: 'series',
        label: 'Serie CI imputernicit',
        value: '',
        type: 'text',
        placeholder: 'Serie CI imputernicit',
        validators: {
            required: true
        }
    },
    {
        name: 'number',
        label: 'Numar CI imputernicit',
        value: '',
        type: 'text',
        placeholder: 'Numar CI imputernicit',
        validators: {
            required: true
        }
    }
];

export const DocumentDetailsDateControls = [
    {
        name: 'startDate',
        label: 'Data start',
        value: '',
        type: 'date',
        placeholder: 'zz/ll/aaaa',
        validators: {
            required: true
        }
    },
    {
        name: 'endDate',
        label: 'Data sfarsit',
        value: '',
        type: 'date',
        placeholder: 'zz/ll/aaaa',
        validators: {
            required: true
        }
    }
];

export const DocumentTypes = [
    {
        name: 'Formular lichidare anticipata contract credit',
        controls: [],
        contractType: ['credit'],
        visible: true
    },
    {
        name: 'Formular rambursare partiala contract credit',
        controls: [],
        contractType: ['credit'],
        visible: true
    },
    {
        name: 'Imputernicire deschidere dosar dauna',
        controls: DocumentDetailsPersonControls,
        contractType: ['financiar', 'operational'],
        visible: true
    },
    {
        name: 'Imputernicire iesire din tara',
        controls: [...DocumentDetailsPersonControls, ...DocumentDetailsDateControls],
        contractType: ['financiar', 'operational', 'wholesale'],
        visible: true
    },
    {
        name: 'Formular lichidare anticipata contract leasing',
        controls: [],
        contractType: ['financiar', 'operational', 'wholesale'],
        visible: true
    },
    {
        name: 'Formular rambursare partiala contract leasing',
        controls: [],
        contractType: ['financiar', 'operational', 'wholesale'],
        visible: true
    },
    {
        name: 'Cerere modificare data scadenta',
        controls: [],
        contractType: ['credit', 'financiar', 'operational', 'wholesale'],
        visible: true
    },
    {
        name: 'Copie CIV (Cartea de identitate a vehiculului',
        controls: [],
        contractType: ['financiar', 'operational', 'wholesale'],
        visible: true
    },
];
