<div class="mobilize-card small-card filter-card">
    @if (contractsLoading && filterOptions.includes('contracts')) {
        <div class="loader-overlay">
            <mat-progress-spinner class="orange-spinner" diameter="30" strokeWidth="2"
                                  mode="indeterminate"></mat-progress-spinner>
        </div>
    }

    <h5 (click)="toggleFilterContent()"><span class="icon-mobilize-filter"></span> Filtreaza <span
        [@rotateChevron]="isFilterContentVisible" class="icon-mobilize-chevron-down"></span></h5>

    <div class="filter-content" [@slideDown]="isFilterContentVisible">
        <form [formGroup]="filterForm">
            @if (filterOptions.includes('search')) {
                <div class="form-control filter-control">
                    <mat-form-field>
                        <mat-label>Cauta</mat-label>
                        <input matInput formControlName="query" type="text" [placeholder]="searchPlaceholder">
                        <span class="icon-mobilize-close reset-filter-control"
                              *ngIf="filterForm.controls['query'].value !== ''" (click)="reset('query')"></span>
                    </mat-form-field>
                </div>
            }

            @if (filterOptions.includes('range')) {
                <div class="form-control">
                    <mat-form-field>
                        <mat-label>Alege un interval</mat-label>
                        <mat-date-range-input [rangePicker]="picker" [max]="maxDate" [min]="minDate">
                            <input formControlName="startDate" matStartDate placeholder="Data start">
                            <input formControlName="endDate" matEndDate placeholder="Data sfarsit">
                        </mat-date-range-input>
                        <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-date-range-picker #picker></mat-date-range-picker>
                    </mat-form-field>
                </div>
            }

            @if (filterOptions.includes('state')) {
                <div class="form-control">
                    <div class="form-control">
                        <mat-form-field>
                            <mat-label>Status</mat-label>
                            <mat-select formControlName="state" (selectionChange)="statusChange()">
                                @for (state of states; track state) {
                                    <mat-option [value]="state.id">
                                        {{ state.name }}
                                    </mat-option>
                                }
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
            }

            @if (filterOptions.includes('categories')) {
                <div class="form-control">
                    <div class="form-control">
                        <mat-form-field>
                            <mat-label>Categorie</mat-label>
                            <mat-select formControlName="categories">
                                @for (category of categories; track category) {
                                    <mat-option [value]="category.request_type_uid">
                                        {{ category.request_type_name }}
                                    </mat-option>
                                }
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
            }

            @if (filterOptions.includes('contracts')) {
                <div class="form-control">
                    <div class="form-control">
                        <mat-form-field>
                            <mat-label>Contracte</mat-label>
                            <mat-select formControlName="contracts" multiple>
                                <mat-select-trigger>
                                    {{ getContractNumber(filterForm.controls['contracts'].value) || '' }}
                                    @if ((filterForm.controls['contracts'].value.length || 0) > 2) {
                                        <span class="example-additional-selection">
                                            {{ otherLabelText }}
                                        </span>
                                    }
                                </mat-select-trigger>
                                <mat-option value="select-all" mobilizeSelectAll [allValues]="contracts">Select all
                                </mat-option>
                                @for (contract of contracts; track contract) {
                                    <mat-option [value]="contract.contract_id">
                                        <div>Contract {{ contract.contract_number }}</div>
                                        <div>{{ contract.contract_objects[0].vehicle_brand }}
                                            {{ contract.contract_objects[0].vehicle_model }},
                                            {{ contract.contract_objects[0].colour }},
                                            serie sasiu: {{ contract.contract_objects[0].chassis_number }}
                                        </div>
                                    </mat-option>
                                }
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
            }
        </form>
    </div>

    <div class="filter-bottom">

        @if (filterOptions.includes('range')) {
            <span class="selected-filter">interval: {{ currentFilter }}</span>
        }

        @if (filterOptions.includes('state')) {
            <span class="selected-filter">stare: {{ currentFilter }}</span>
        }
        @if (canExport) {
            @if (page !== 'invoice') {
                <span class="export-results" (click)="export()"><i class="icon-mobilize-export"></i> Exporta </span>
            } @else {
                <span class="export-results hidden" (click)="exportClientFile()"><i class="icon-mobilize-export"></i> Descarca fisa client </span>
            }
        }
    </div>
    <div class="filter-actions" [@buttonAnimation]="isFilterContentVisible ? 'visible' : 'hidden'">
        <button class="mobilize-btn btn-orange" (click)="execFilter()" [mobilizeButtonLoader]="processing"
                originalContent="filtreaza">filtreaza
        </button>
    </div>
</div>
