<div class="contract" [ngClass]="contract.contract_extended_state">
    <div class="header">
        <div class="contract-name">
            Contract {{ contract.contract_number }} -
            {{ contract.contract_objects ? contract.contract_objects[0].vehicle_brand : '' }}
            {{ contract.contract_objects ? contract.contract_objects[0].vehicle_model : '' }}
            {{ contract.contract_objects ? contract.contract_objects[0].registration_number : '' }}
        </div>
        @if (contract.extendable) {
            <div class="chevron">
                <span (click)="toggleState()" class="icon-mobilize-chevron-up"></span>
            </div>
        }
    </div>

    <div class="content">
        <div class="details">
            @if (section === 'timetable') {
                <div class="details__row">bun finantat:
                    <span>{{ contract.contract_objects ? contract.contract_objects[0].item_name : '' }}</span>
                </div>
                <div class="details__row">nr. inmatriculare:
                    <span>{{ contract.contract_objects ? contract.contract_objects[0].registration_number : '-' }}</span>
                </div>
            }

            @if (contract.next_installment) {
                <div class="details__row">urmatoarea rata: <span
                    [innerHTML]="formatAmount(contract.next_installment, contract.currency_code)"></span>
                    ({{ contract.next_due_date | date: 'dd/MM/YYYY' }})
                </div>
            }

            @if (section !== 'timetable') {
                <div class="details__row bullet-row">sold restant: <span
                    [innerHTML]="formatAmount(contract.overdue_invoices_ron, 'RON')"></span>
                    <span class="bullet"
                          [ngClass]="{'online': parseInt(contract.overdue_invoices_ron.toString(), 10) === 0, 'offline': contract.overdue_invoices_ron > 0}"></span>
                </div>
            }

            <div class="details__row">documente: <span>0</span></div>

            <mobilize-installments-slider [showInstallments]="contract.contract_state !== 'Inchis'"
                                          [type]="installmentsDisplayType"
                                          [maxInstallments]="maxInstallments"
                                          [payedInstallments]="contract.previous_instalments"></mobilize-installments-slider>

            @if (section === 'timetable') {
                <div class="payment-details">
                    <div>Ai achitat din capital: <span
                        [innerHTML]="formatAmount(payedAmount, contract.currency_code)"></span>
                    </div>
                    @if (contract.outstanding) {
                        <div>Rest de plata capital: <span
                            [innerHTML]="formatAmount(contract.outstanding + contract.rezidual_amount, contract.currency_code)"></span>
                        </div>
                    }
                </div>
            }

            @if (section !== 'timetable') {
                <button class="mobilize-btn btn-orange" (click)="contractDetails(contract.contract_id)">detalii</button>
            } @else {
                <div class="contract-actions">
                    <button class="mobilize-btn btn-orange hidden">descarca scadentar</button>
                    @if (contract.contract_type !== 'Operational') {
                        <button class="mobilize-btn btn-clear" (click)="earlyRepayment()">plateste anticipat</button>
                    }
                </div>
            }
        </div>

        @if (section !== 'timetable') {
            <div class="options">
                @for (option of services; track option) {
                    <mobilize-contract-option class="option-component" [contract]="contract"
                                              [contractState]="contract.contract_state"
                                              [option]="option"></mobilize-contract-option>
                }
            </div>
        }
    </div>
</div>
