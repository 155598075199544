import { Component, inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { UsersService } from '../../../services/users.service';
import { ConfirmModalComponent } from '../../../components/confirm-modal/confirm-modal.component';

@Component({
    selector: 'mobilize-edit-user',
    standalone: true,
    imports: [
        FormsModule,
        MatFormField,
        MatInput,
        MatLabel,
        ReactiveFormsModule
    ],
    templateUrl: './edit-user.component.html',
    styleUrl: './edit-user.component.scss'
})
export class EditUserComponent implements OnInit {
    private _fb = inject(FormBuilder);
    private _dialog = inject(MatDialog);
    private _users = inject(UsersService);
    public data = inject(MAT_DIALOG_DATA);
    private _dialogRef = inject(MatDialogRef<EditUserComponent>)

    form!: FormGroup;

    constructor() {
    }

    ngOnInit() {
        this.form = this._fb.group({
            fullName: [{
                value: this.data.user.full_name,
                disabled: this.data.user.role !== 'eCare Admin'
            }, Validators.required],
            email: [{value: this.data.user.email, disabled: true}],
            role: [{value: this.data.user.role, disabled: true}],
        })
    }

    closeModal() {
        this._dialogRef.close({success: false});
    }

    deleteUser() {
        this._dialog.open(ConfirmModalComponent, {
            data: {
                title: 'Stergere utilizator (client)',
                content: `Esti sigur ca vrei sa stergi utilizatorul '${this.data.user.email}'?`
            }
        }).afterClosed().subscribe(res => {
            if (res) {
                this._users.deleteUser({email: this.data.user.email}).subscribe({
                    next: () => {
                        this._dialogRef.close({
                            success: true,
                            action: 'delete',
                            message: 'Contul a fost sters!',
                            user: this.data.user.email
                        });
                    },
                    error: (error: any) => {
                        this._dialogRef.close({message: error.error})
                    }
                });
            }
        });
    }

    save() {
        if (this.form.invalid) {
            this.form.markAllAsTouched();
            return;
        }
        this._users.updateUser({
            email: this.data.user.email,
            fullName: this.form.controls['fullName'].value
        }).subscribe({
            next: () => {
                this._dialogRef.close({
                    success: true,
                    action: 'update',
                    message: 'Contul a fost modificat!',
                    user: this.data.user.email,
                    fullName: this.form.controls['fullName'].value
                });
            },
            error: (error: any) => {
                this._dialogRef.close({message: error.error})
            }
        });
    }

    disableUser() {
        this._dialog.open(ConfirmModalComponent, {
            data: {
                title: 'Dezactiveaza utilizator eCare Admin',
                content: `Esti sigur ca vrei sa dezactivezi utilizatorul '${this.data.user.email}'?`
            }
        }).afterClosed().subscribe(res => {
            if (res) {
                this._users.disableUser({email: this.data.user.email}).subscribe({
                    next: () => {
                        this._dialogRef.close({
                            success: true,
                            action: 'disable',
                            message: 'Contul a fost dezactivat!',
                            user: this.data.user.email
                        });
                    },
                    error: (error: any) => {
                        this._dialogRef.close({message: error.error})
                    }
                });
            }
        });
    }

    enableUser() {
        this._dialog.open(ConfirmModalComponent, {
            data: {
                title: 'Activeaza utilizator eCare Admin',
                content: `Esti sigur ca vrei sa activezi utilizatorul '${this.data.user.email}'?`
            }
        }).afterClosed().subscribe(res => {
            if (res) {
                this._users.enableUser({email: this.data.user.email}).subscribe({
                    next: () => {
                        this._dialogRef.close({
                            success: true,
                            action: 'enable',
                            message: 'Contul a fost activat!',
                            user: this.data.user.email
                        });
                    },
                    error: (error: any) => {
                        this._dialogRef.close({message: error.error})
                    }
                });
            }
        });
    }
}
