import { Component, inject, Input, OnInit } from '@angular/core';
import { InstallmentsSliderComponent } from '../installments-slider/installments-slider.component';
import { DatePipe, NgClass, NgIf } from '@angular/common';
import { Contract } from '../../interfaces/contract';
import { BaseComponent } from '../base.component';
import { Router, RouterLink } from '@angular/router';
import { ContractOptionComponent } from '../contract-option/contract-option.component';

@Component({
    selector: 'mobilize-contract',
    standalone: true,
    imports: [
        InstallmentsSliderComponent,
        NgClass,
        NgIf,
        ContractOptionComponent,
        DatePipe,
        RouterLink
    ],
    templateUrl: './contract.component.html',
    styleUrl: './contract.component.scss'
})
export class ContractComponent extends BaseComponent implements OnInit {

    private _router = inject(Router);

    @Input() contract!: Contract;
    @Input() section!: string;

    maxInstallments = 0;
    payedAmount = 0;
    services: any[] = [];

    ngOnInit() {
        this.payedAmount = 0;
        let count = 0;
        this.contract.contract_schedules.forEach((schedule: any) => {
            if (count < this.contract.previous_instalments && schedule.rate_name !== 'Valoare reziduala') {
                this.payedAmount += parseFloat(schedule.principal_amount);
                if (schedule.schedule_type_id === 4) {
                    count++;
                }
            }
            if (schedule.schedule_type_id === 4) {
                this.maxInstallments++;
            }
        });
        const services = this.contract.contract_services_list.filter(item => item.service_code !== 'LS_COM_GL');
        services.forEach((service: any) => {
            if (this.isServiceActive(service.service_start_date, service.service_end_date)) {
                service.active = this.isServiceActive(service.service_start_date, service.service_end_date);
            }
        });
        this.services.push(...services);
        if (this.contract.casco_insurance) {
            this.services.push(this.contract.casco_insurance)
        }
        if (this.contract.rca_insurance) {
            this.services.push(this.contract.rca_insurance)
        }
        if (this.contract.adi_insurance) {
            this.services.push(this.contract.adi_insurance)
        }
    }

    private isServiceActive(serviceStartDate: string, serviceEndDate: string): boolean {
        const currentDate = new Date();
        const startDate = new Date(serviceStartDate);
        const endDate = new Date(serviceEndDate);

        return currentDate >= startDate && currentDate <= endDate;
    }

    earlyRepayment() {
        this._router.navigateByUrl('/early-repayment', {state: {data: {contract: this.contract}}})
    }

    get installmentsDisplayType() {
        switch (this.section) {
            case 'timetable':
                return 'extended';

            case 'dashboard':
                return 'no-details';

            default:
                return 'no-details';
        }
    }

    contractDetails(id: number) {
        this._router.navigateByUrl(`/contract-details/${id}`);
    }

    toggleState() {
        if (this.contract.contract_extended_state === 'opened') {
            this.contract.contract_extended_state = 'closed';
        } else {
            this.contract.contract_extended_state = 'opened';
        }
    }

    protected readonly parseInt = parseInt;
}
