import { inject, Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { KeycloakService } from 'keycloak-angular';
import { environment } from '../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class UserService {

    user: any = null
    private userRoles!: any;
    role = '';
    superAccount = new Subject<any>();
    superStatus$ = this.superAccount.asObservable();
    private impersonatedAccountSubject = new BehaviorSubject<{ active: boolean, name: string }>({ active: false, name: '' });
    impersonatedStatus$: Observable<any> = this.impersonatedAccountSubject.asObservable();

    loggedUser = new Subject();
    private _keyCloak = inject(KeycloakService);
    private _http = inject(HttpClient);

    constructor() {
    }

    userData(): Observable<any> {
        if (this.user) {
            this.userRoles = this.user.roles;
            return of(this.user);
        }

        return this._http.get(environment.userInfoUrl);
    }

    sendSuperAccount(value: boolean) {
        this.superAccount.next(value);
    }

    sendImpersonatedAccount(payload: any) {
        this.impersonatedAccountSubject.next(payload);
    }

    impersonate(partner: any): Observable<any> {
        return this._http.post('remote-auth/admin', { partner }, {
           //withCredentials: true
        })
    }

    cancelImpersonation(): Observable<any> {
        return this._http.post('remote-auth/cancel-admin', null, {
            //withCredentials: true
        })
    }

    changePassword(payload: any): Observable<any> {
        return this._http.post('user/change-password', payload);
    }

    changeEmail(payload: any): Observable<any> {
        return this._http.post('user/change-email', payload);
    }

    getAddress(): Observable<any> {
        return this._http.get('user/address');
    }

    createMobilizeAdmin(payload: any) {
        return this._http.post('user/create-admin', payload);
    }
}
