import { inject, Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class PaymentService {
    private _http = inject(HttpClient);

    constructor() {
    }

    downloadReport(payload: any): Observable<any> {
        return this._http.post('payment/download-report', payload);
    }

    getAllPayments(payload: any): Observable<any> {
        return this._http.post('payment/card-payments', payload);
    }

    getPaymentFilters(): Observable<any> {
        return this._http.get('payment/filters');
    }
}
