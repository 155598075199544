<mobilize-page-header [title]="pageTitle" [reverse]="true"></mobilize-page-header>

<div class="search-box">
    <mobilize-requests-search (onSearch)="search($event)" [query]="query" placeholder="Cauta dupa cod fiscal sau nume partner"></mobilize-requests-search>
</div>

<div class="partners-table">
    <div class="partners-table__row">
        <div class="header">ID Partener</div>
        <div class="header">Cod Fiscal</div>
        <div class="header">Denumire partener</div>
        <div class="header"></div>
    </div>

    @for (partner of partners; track partner) {
        <div class="partners-table__row">
            <div class="cell">{{ partner.partner_id }}</div>
            <div class="cell">{{ partner.fiscal_code }}</div>
            <div class="cell">{{ partner.partner_name }}</div>
            <div class="cell font-orange">
                @if (!processing) {
                    <div (click)="accessPartnerAccount(partner)"><span class="icon-mobilize-user"></span> Acceseaza
                    </div>
                } @else {
                    <mat-progress-spinner class="orange-spinner" diameter="15" strokeWidth="2"
                                          mode="indeterminate"></mat-progress-spinner>
                }
            </div>
        </div>
    }
</div>

@if (dataReady) {
    <mobilize-pagination [totalPages]="totalPages" [currentPage]="currentPage" [limit]="limit" [pagesArray]="pagesArray"
                         (onPageChange)="onPageChange($event)"></mobilize-pagination>
}
