<div class="new-request-wrapper">
    <div class="progress-tracking">
        <div class="bullet" [ngClass]="{'active': pageStep === 1, 'passed': pageStep > 1}">1</div>
        <div class="tracking-bar" [ngClass]="{'passed': pageStep > 1}"
             [style.height]="(requestsContainerHeight + 20) + 'px'"></div>
        <div class="bullet" [ngClass]="{'active': pageStep === 2, 'passed': pageStep > 2}">2</div>
        <div class="tracking-bar" [ngClass]="{'passed': pageStep > 2}"
             [style.height]="(contractsContainerHeight + 20) + 'px'"></div>
        <div class="bullet" [ngClass]="{'active': pageStep === 3}">3</div>
    </div>

    <div class="requests-type-wrapper">
        <div class="title">Cu ce te putem ajuta?</div>

        <div class="request-selection" #requestsContainer>
            @for (req of types; track req) {
                @if (req.visible) {
                    <div class="requests-type-wrapper__row card-row"
                         (click)="selectRequestType(req)">{{ req.request_type_name }}
                        <span
                            class="icon-mobilize-chevron-right"></span></div>
                }
            } @empty {
                <mat-progress-bar mode="indeterminate"></mat-progress-bar>
            }

            @if (!showContractsList) {
                <div class="other-request">
                    @if (!hasOtherRequest) {
                        <div class="title">Nu gasesti in lista?</div>
                        <button class="mobilize-btn btn-orange" (click)="otherRequest()">am alta solicitare</button>
                    } @else {
                        <form class="custom-request-form">
                            <div class="form-group">
                                <div class="form-control single-control custom-control">
                                    <label>Subiectul solicitarii:</label>
                                    <input [(ngModel)]="otherRequestText" type="text"
                                           maxlength="100"
                                           placeholder="scrie, pe scurt, titlul solicitarii">
                                    <div class="counter">{{ otherRequestText | countdown:100 }} caractere
                                        ramase
                                    </div>
                                </div>
                            </div>
                        </form>
                        <div class="other-request-actions">
                            <button class="mobilize-btn btn-orange" (click)="selectRequestType({name: 'other'})">pasul
                                urmator
                            </button>
                            <button class="mobilize-btn btn-clear" (click)="changeRequestSelection()">inapoi</button>
                        </div>
                    }
                </div>
            }
        </div>

        <div class="title">Alege contractul</div>

        <div class="contracts-wrapper" [ngClass]="{'contracts-hidden': !showContractsList}" #contractsContainer>
            @if (showContractsList) {
                @for (contract of contracts; track contract) {
                    @if (contract.visible) {
                        <div class="contract-row card-row" (click)="selectContract(contract)">
                            <div class="name">
                                <span class="title">Contract {{ contract.contract_number }}</span>
                                <span
                                    class="subtitle">{{ contract.contract_objects ? contract.contract_objects[0].vehicle_brand : '-' }} {{ contract.contract_objects ? contract.contract_objects[0].vehicle_model : '-' }}
                                    ,
                                    <strong>{{ contract.contract_objects ? contract.contract_objects[0].registration_number : '-' }}</strong>
                                    , {{ contract.contract_objects ? contract.contract_objects[0].colour : '-' }}
                                    , serie sasiu {{ contract.contract_objects ? contract.contract_objects[0].chassis_number : '-' }}</span>
                            </div>
                            <span class="icon-mobilize-chevron-right"></span>
                        </div>
                    }
                }

                @if (!showForm) {
                    <button class="mobilize-btn btn-clear" (click)="changeRequestSelection()">inapoi</button>
                }
            }
        </div>

        <div class="title">Mesaj, fisiere si trimitere</div>

        @if (showForm) {
            <div class="request-form-wrapper">
                <form [formGroup]="requestForm">
                    <div class="form-group">
                        <div class="form-control single-control">
                            <label>Mesaj:</label>
                            <textarea placeholder="scrie mesajul" rows="5" formControlName="message"
                                      maxlength="200"></textarea>
                            <div class="counter">{{ requestForm.controls['message'].value | countdown:200 }} caractere
                                ramase
                            </div>
                        </div>
                    </div>
                </form>

                <div class="upload-area" ng2FileDrop (fileOver)="fileOverBase($event)"
                     [ngClass]="{'file-over': hasBaseDropZoneOver}">
                    <label>Adauga fisiere:</label>
                    <div class="uploaded-files">
                        <div *ngFor="let item of uploader.queue">
                            {{ item?.file?.name }} <span class="icon-mobilize-close" (click)="removeFile(item)"></span>
                        </div>
                    </div>
                    <label class="file-upload">
                        <i class="options-icon-upload-cloud-02"></i>
                        <input type="file" ng2FileSelect [multiple]="true" [uploader]="uploader" #file>
                    </label>
                    <div class="upload-action">
                        <button class="" (click)="file.click()">
                            adauga fisier
                        </button>
                    </div>
                </div>

                <re-captcha
                    #captchaRef="reCaptcha"
                    (resolved)="resolved($event)"
                ></re-captcha>

                <div class="actions">
                    <button class="mobilize-btn btn-orange" (click)="validateData()" [mobilizeButtonLoader]="changing"
                            originalContent="trimite">trimite
                    </button>
                    <button class="mobilize-btn btn-clear" (click)="changeContractSelection()">inapoi</button>
                </div>
            </div>
        }
    </div>
</div>
